import { Autocomplete, Box, Card, CircularProgress, Paper, Table, TableBody, TableCell, TableCellProps, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { contentBoxStyle } from "../styles/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { fi } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, formatDuration, sub } from "date-fns";
import { ReactNode, useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import { enqueueSnackbar } from "notistack";
import { East, NorthEast, SouthEast, TrendingDown, TrendingFlat, TrendingUp } from "@mui/icons-material";

type DriverCardRes = {
    months: {
        [key: string]: DriverCard
    },
    monthsAll: {
        [key: string]: DriverCard
    },
    status: string
}

type DriverCard = {
    jobCount: number | null,
    kilometresTotal: number | null,
    timeTotal: number | null,
    tonsAverage: number | null,
    tonsTotal: number | null,
    loadAverage: number | null,
    unloadAverage: number | null,
    fuelConsumption: number | null,
    fuelTotal: number | null,
    speedAverage: number | null,
    emissionsAverage: number | null,
    emissionsTotal: number | null,
    emissionsPerTon: number | null,
    emissionsPerKm: number | null,
    scoreAnticipation: number | null,
    scoreBraking: number | null,
    scoreCoasting: number | null,
}

const minutesFormatter = (minutes: null | number | string | Date, stack = false) => {
    if (typeof minutes !== "number") return "–";
    const hrs = Math.floor(minutes / 60);
    const mins = Math.round(minutes - (hrs * 60));
    if (stack) return (
        <Box>
            {hrs > 0 && <Box>{hrs} h</Box>}
            <Box>{mins} min</Box>
        </Box>
    )
    return hrs > 0 ? `${hrs} h ${mins} min` : `${mins} min`;
}

const tableColumns: { header: string, dateHeader?: (current: Date, prev: Date) => string }[] = [
    { header: "Nykyinen jakso", dateHeader: (current, _prev) => `Oma ${format(current, "LLLL", { locale: fi })}` },
    { header: "Edellinen jakso", dateHeader: (_current, prev) => `Oma ${format(prev, "LLLL", { locale: fi })}` },
    { header: "Muutos edelliseen jaksoon", dateHeader: (_current, prev) => `Muutos ${format(prev, "LLLL", { locale: fi })}lta` },
    { header: "Kaikkien kulj. jakso", dateHeader: (current, _prev) => `Kaikkien kuljettajien ${format(current, "LLLL", { locale: fi })}` },
    { header: "Ero kaikkien kulj. jaksoon", dateHeader: (current, _prev) => `Ero kaikkien kuljettajien ${format(current, "LLLL", { locale: fi })}hun` },
];

const tableRows: { header: string, field: keyof DriverCard, valueFormatter?: (value: null | number | string | Date) => ReactNode, target?: "positive" | "negative", breakLine?: boolean }[] = [
    { header: "Kuorman tonnit KA", field: "tonsAverage", target: "positive" },
    { header: "Kulutus KA (L/100km)", field: "fuelConsumption", target: "negative" },
    { header: "Keskinopeus (km/h)", field: "speedAverage", target: "positive" },
    { header: "Päästöt KA (CO₂)", field: "emissionsAverage", target: "negative" },
    { header: "Lastausaika KA", field: "loadAverage", target: "negative", valueFormatter: minutesFormatter },
    { header: "Purkuaika KA", field: "unloadAverage", target: "negative", valueFormatter: minutesFormatter },
    { header: "Pisteet (0–100)", field: "jobCount", breakLine: true },
    { header: "Ennakointi", field: "scoreAnticipation", target: "positive" },
    { header: "Jarrutus", field: "scoreBraking", target: "positive" },
    { header: "Rullaus", field: "scoreCoasting", target: "positive" },
]

export default function DriverScore() {
    const driverMode = LogiTarUser.current?.isDriver();

    const [users, setUsers] = useState<{ id: number, name: string }[]>([]);
    const [selectedUser, setSelectedUser] = useState<number | null>(driverMode && LogiTarUser.current ? LogiTarUser.current.info.id : null);
    const [selectedDate, setSelectedDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [prevMonthData, setPrevMonthData] = useState<DriverCard | null>(null);
    const [monthData, setMonthData] = useState<DriverCard | null>(null);
    const [monthDataAll, setMonthDataAll] = useState<DriverCard | null>(null);
    const [fetchingData, setFetchingData] = useState(true);

    const theme = useTheme();

    // Initial fetch for driver options
    useEffect(() => {
        if (driverMode) return;
        setFetchingData(true);
        LogitarApi.getUsers({ userType: LogiTarUserType.DRIVER, extent: "list" })
            .then(res => {
                const typedRes = res as { users: { id: number, name: string, passive: number }[] };
                setUsers(typedRes.users.filter(u => !u.passive).sort((a, b) => a.id - b.id));
            }).catch(err => {
                console.error(err);
                enqueueSnackbar("Kuljettajakortin tietojen haku epäonnistui", { variant: "error" });
            }).finally(() => setFetchingData(false));
    }, []);

    useEffect(() => {
        const currentMonth = format(selectedDate, "yyyy-MM");
        const prevMonth = format(sub(selectedDate, { months: 1 }), "yyyy-MM");
        if (selectedUser) {
            setFetchingData(true);
            LogitarApi.getDriverCards(selectedUser, currentMonth)
                .then(res => {
                    const typedRes = res as DriverCardRes;
                    setMonthData(typedRes.months[currentMonth] || null);
                    setPrevMonthData(typedRes.months[prevMonth] || null);
                    setMonthDataAll(typedRes.monthsAll[currentMonth] || null);
                }).catch(err => {
                    console.error(err);
                    enqueueSnackbar("Kuljettajakortin tietojen haku epäonnistui", { variant: "error" });
                }).finally(() => setFetchingData(false));
        }
    }, [selectedDate, selectedUser]);

    return (
        <Box sx={{ ...contentBoxStyle, px: 1, pb: 1, overflow: "auto" }} >
            <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <Autocomplete
                            options={users}
                            getOptionLabel={option => `${option.id}: ${option.name}`}
                            onChange={(_e, v) => {
                                setSelectedUser(v?.id || null);
                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                    size="small"
                                    label="Kuljettaja"
                                    sx={{ width: "12rem", "& .MuiInputBase-root": { height: "48.5px" } }}
                                    InputLabelProps={{ sx: { py: 0.5 } }}
                                />}
                            disabled={driverMode}
                        />
                        <DatePicker
                            views={['year']}
                            value={selectedDate}
                            onYearChange={(y) => setSelectedDate(prev => {
                                const newDate = new Date(prev);
                                newDate.setFullYear(y.getFullYear());
                                return newDate;
                            })}
                            sx={{ width: "7rem", minWidth: "7rem", "& .MuiInputBase-root": { height: "48.5px" } }}
                            label="Vuosi"
                        />
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            value={selectedDate.getMonth()}
                            onChange={(_e, v) => {
                                if (v == null) return;
                                setSelectedDate(prev => {
                                    const newDate = new Date(prev);
                                    newDate.setMonth(v);
                                    newDate.setDate(1);
                                    return newDate;
                                })
                            }}
                        >
                            {Array.from(Array(12)).map((_v, i) => {
                                return (
                                    <ToggleButton key={`month-${i}`} value={i}>
                                        {format(new Date(selectedDate.getFullYear(), i), "LLL", { locale: fi })}
                                    </ToggleButton>
                                )
                            })}
                        </ToggleButtonGroup>
                    </Box>
                    <Paper sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        width: "70vw",
                        minWidth: "70rem",
                        maxWidth: "90rem",
                        height: "70vh",
                        minHeight: "40rem",
                        maxHeight: "50rem",
                        p: 0.5,
                    }}
                    >
                        {
                            fetchingData &&
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                background: "#0006",
                            }}>
                                <CircularProgress />
                            </Box>
                        }
                        <Box sx={{ display: "flex", flexDirection: "column", width: "15%", height: "100%" }}>
                            <SummaryValueCard title="Kuormat kpl" value={monthData ? monthData.jobCount : "–"} />
                            <SummaryValueCard title="Kilometrit" value={monthData ? monthData.kilometresTotal : "–"} />
                            <SummaryValueCard title="Tonnit" value={monthData ? monthData.tonsTotal : "–"} />
                            <SummaryValueCard title="Ajoaika" value={monthData ? minutesFormatter(monthData.timeTotal, true) : "–"} />
                        </Box>
                        <Card variant="outlined" sx={{ width: "70%", overflow: "auto" }}>
                            <Table sx={{ width: "100%", height: "100%", "& .MuiTableCell-root": { fontSize: "1rem" } }}>
                                <TableHead>
                                    <TableRow sx={{ background: theme.palette.primary.dark, height: "3rem" }}>
                                        <TableCell />
                                        {tableColumns.map(c =>
                                            <TableCell align="right" size="small" sx={{ color: "white" }}>
                                                {c.dateHeader ? c.dateHeader(selectedDate, sub(selectedDate, { months: 1 })) : c.header}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows.map(r =>
                                    (
                                        r.breakLine ?
                                            <TableRow sx={{ height: "3rem" }}>
                                                <TableCell
                                                    component="th"
                                                    scope="colgroup"
                                                    size="small"
                                                    sx={{ background: theme.palette.primary.dark, color: "white", fontWeight: "500" }}>
                                                    {r.header}
                                                </TableCell>
                                                <TableCell colSpan={999} size="small" />
                                            </TableRow>
                                            :
                                            <TableRow
                                                sx={{
                                                    "&:nth-child(even)": { background: theme.palette.mode === "light" ? "#0001" : "#FFF1" },
                                                    "&:last-child td, &:last-child th": { borderBottom: 0 },
                                                }}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    size="small"
                                                    sx={{ borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: theme.palette.mode === "light" ? "#0004" : "#FFF4" }}
                                                >
                                                    {r.header}
                                                </TableCell>
                                                <TableCell align="right" size="small">
                                                    {monthData && r.valueFormatter ? r.valueFormatter(monthData[r.field]) : monthData ? monthData[r.field] || "–" : "–"}
                                                </TableCell>
                                                <TableCell align="right" size="small">
                                                    {prevMonthData && r.valueFormatter ? r.valueFormatter(prevMonthData[r.field]) : prevMonthData ? prevMonthData[r.field] || "–" : "–"}
                                                </TableCell>
                                                <PercentChangeCell
                                                    field={r.field}
                                                    month={monthData}
                                                    compareTo={prevMonthData}
                                                    target={r.target}
                                                    align="right"
                                                    size="small"
                                                />
                                                <TableCell align="right" size="small">
                                                    {monthDataAll && r.valueFormatter ? r.valueFormatter(monthDataAll[r.field]) : monthDataAll ? monthDataAll[r.field] || "–" : "–"}
                                                </TableCell>
                                                <PercentChangeCell
                                                    field={r.field}
                                                    month={monthData}
                                                    compareTo={monthDataAll}
                                                    target={r.target}
                                                    align="right"
                                                    size="small"
                                                />
                                            </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </Card>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "15%", height: "100%" }}>
                            <SummaryValueCard title="Polttoaine" value={monthData ? monthData.fuelTotal : "–"} />
                            <SummaryValueCard title="Päästöt (CO₂)" value={monthData ? monthData.emissionsTotal : "–"} />
                            <SummaryValueCard title="Päästöt per tonni" value={monthData ? monthData.emissionsPerTon : "–"} />
                            <SummaryValueCard title="Päästöt per kilometri" value={monthData ? monthData.emissionsPerKm : "–"} />
                        </Box>
                    </Paper>
                </Box>
            </LocalizationProvider>
        </Box>
    )
}

function SummaryValueCard(props: { title: string, value: string | number | null | ReactNode }) {
    return (
        <Card variant="outlined" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 1, width: "100%", height: "25%", textAlign: "center", p: 1 }}>
            <Typography variant="h6" sx={{ fontSize: "1.2rem" }}>{props.title}</Typography>
            <Typography variant="h4" sx={{ fontSize: "2rem" }}>{props.value == null ? "–" : props.value}</Typography>
        </Card>
    )
}

interface PercentChangeCellProps extends TableCellProps {
    field: keyof DriverCard,
    month: DriverCard | null,
    compareTo: DriverCard | null,
    target: "positive" | "negative" | undefined,
}

function PercentChangeCell({ field, month, compareTo, target, ...rest }: PercentChangeCellProps) {
    const theme = useTheme();
    const current = month ? month[field] : null;
    const prev = compareTo ? compareTo[field] : null;
    if (!current || !prev) {
        return (
            <TableCell {...rest}>–</TableCell>
        )
    }
    const change = Math.round(((current - prev) / prev) * 1000) / 10;
    const trendingBetter = change === 0 ? null : target === "positive" ? change > 0 : target === "negative" ? change < 0 : null;
    const colors = {
        positive: theme.palette.mode === "light" ? "#0A0" : theme.palette.mode === "dark" ? "#6D6" : "unset",
        negative: theme.palette.mode === "light" ? "#C33" : theme.palette.mode === "dark" ? "#F77" : "unset",
        neutral: theme.palette.mode === "light" ? "#777" : theme.palette.mode === "dark" ? "#777" : "unset",
    }
    console.log(`${field} - ${trendingBetter} - ${change}`)
    return (
        <TableCell {...rest} sx={{
            // // Alternative gradient background
            // background: trendingBetter === true ?
            //     `linear-gradient(to right, #0000, ${colors.positive}2 75%, #0000)` :
            //     trendingBetter === false ?
            //         `linear-gradient(to right, #0000, ${colors.negative}2 75%, #0000)` :
            //         "unset",
            px: 2,
            py: 0,
            ...rest.sx,
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                alignItems: "center",
                gap: 1,
                color: trendingBetter === true ? colors.positive : trendingBetter === false ? colors.negative : "unset",
                background: trendingBetter === true ?
                    `${colors.positive}2` :
                    trendingBetter === false ?
                        `${colors.negative}2` :
                        `${colors.neutral}2`,
                width: "min-content",
                justifySelf: "end",
                ml: "auto",
                p: 0.5,
                pl: 1.5,
                pr: 0.75,
                borderRadius: "9999px",
            }}>
                {change > 0 && "+"}{change}%
                {trendingBetter === true && <NorthEast sx={{ mb: 0.3 }} />}
                {trendingBetter === false && <SouthEast sx={{ mb: 0.3 }} />}
                {trendingBetter === null && <East sx={{ mb: 0.3 }} />}
            </Box>
        </TableCell>
    )
}