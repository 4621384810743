
import {Box, FormControl, FormGroup, TextField, Typography, Autocomplete, Grid, MenuItem, Select, InputLabel, Stack, 
   Dialog, DialogActions, DialogTitle, Button, CircularProgress, Checkbox, IconButton,
   FormControlLabel,useTheme,useMediaQuery} from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import React from 'react'
import LogitarApi from '../api/LogitarApi'
import PopupScreen from '../components/PopupScreen'
import uuid from 'react-uuid';
import { DataGridPro } from '@mui/x-data-grid-pro'
import {GridActionsCellItem} from '@mui/x-data-grid'
import { Delete, Save, Send, Clear} from '@mui/icons-material'
import { TipButton } from '../misc/InternalFeatures'
import { AutocompleteVehicle } from '../misc/AutocompleteVehicle'
import { AutocompleteUser } from '../misc/AutocompleteUser'
import { LocalizationProvider } from '@mui/x-date-pickers'
import fi from 'date-fns/locale/fi'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format } from 'date-fns'
import NewRepairField from './NewRepairMan';
import { useSnackbar } from 'notistack' 
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import FileManager from "./FileManager.tsx";
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

export default function NewServiceOrder(props) {
    const [serviceHistory, setServiceHistory] = React.useState(props.serviceHistory)
    const [postServiceHistory, setPostServiceHistory] = React.useState((!props.new) ? {id: props.serviceHistory.id, orderid: props.serviceHistory.id, vehicle: props.serviceHistory.vehicle, driver: props.serviceHistory.driver, 
      orderDate: props.serviceHistory.orderDate, beforeKm: props.serviceHistory.beforeKm, beforeDate: props.serviceHistory.beforeDate, alarm: props.serviceHistory.alarm,   
      servicedByExt: props.serviceHistory.servicedByExt , ExternalService_id: props.serviceHistory.ExternalService_id, requestInfo: props.serviceHistory.requestInfo,
      priority: props.serviceHistory.priority, connection: props.serviceHistory.connection
    } : {...props.serviceHistory, vehicle: LogiTarUser.current.vehicle? LogiTarUser.current.vehicle.id : props.serviceHistory.vehicle })
    const [openServiceHistory, setOpenServiceHistory] = React.useState([])
    const [vehicles, setVehicles] = React.useState(props.vehicles)  
    const [vehiclesList, setVehiclesList] = React.useState([])    
    const [drivers, setDrivers] = React.useState([]) 
    const [openServiceRequestInfo, setOpenServiceRequestInfo] = useState("");
    const [rowId, setRowId] = useState(-1);
    const [newUuid, setNewUuid] = useState("");
    const [selectedExternalService, setSelectedExternalService] = React.useState([null])
    const [serviceTimeStamp, setServiceTimeStamp] = useState("");
    const [serviceRowsTimeStamp, setServiceRowsTimeStamp] = useState("");
    const [selectedDriver, setSelectedDriver] = React.useState(props.serviceHistory.driver)  
    const [newRepairman, setNewRepairman] = React.useState(false) 
    const [externalServices, setExternalServices] = React.useState([])
    const [users, setUsers] = React.useState([])
    const { enqueueSnackbar, closeSnackbar} = useSnackbar()
    const [rowSelectedFlag, setRowSelectedFlag] = useState(0);
    const [dialog, setDialog] = useState(false)
    const [deleteOrderOrRow, setDeleteOrderOrRow] = useState({type:"",id:null});
    const [confirmMsg, setConfirmMsg] = useState("")
    const [inProgressIcon, setInProgressIcon] = useState(false);
    const [saved, setSaved] = React.useState(null);
    const [selectedUuid, setSelectedUuid] = React.useState("");
    const [selectedVehicle, setSelectedVehicle] = React.useState(props.serviceHistory.vehicle)    
    const [workPriority, setWorkPriority] = React.useState([])
    const [autofuturConnection, setAutofuturConnection] = React.useState([])
    const [selectedPriority, setSelectedPriority] = React.useState(null)   
    const [selectedConnection, setSelectedConnection] = React.useState(null) 
    const [externalServiceTimeStamp, setExternalServiceTimestamp] = useState("")
    const [anchorEl, setAnchorEl] = useState(false);
    const theme = useTheme();
    const lgMediaQuery = useMediaQuery(theme.breakpoints.up("lg"))
    const [currentVehicle, setCurrentVehicle] = React.useState(" ")
    const [selectedRowId, setSelectedRowId] = useState(-1);
    const [popupSummary, setPopupSummary] = useState(false);

    const diaClose = () => {
      setDialog(false)
    }

    const diaOpen = (id) => () => {
      setDialog(true)
    }

    let columns = []
    // Driver has narrow display
    if (LogiTarUser.current.info.userType != LogiTarUserType.DRIVER) {
      columns.push(
        { 
          field: 'orderDate2',
          headerName: 'Tilauspäivä',
          width: 100,
          editable: false,
          sortable: false,
        },
        { 
          field: 'driverByName',
          headerName: 'Tilaaja',
          width: 150,
          editable: false,
          sortable: false,
        },
        { 
          field: 'requestInfo', 
          headerName: 'Huoltokuvaus', 
          width: 400,
          editable: true,
          sortable: false,
        },
        { 
          field: 'servicedByExt',
          headerName: 'Ulkopuolinen huolto',
          width: 180,
          editable: false,
          sortable: false,
        },
        { 
          field: 'externalServiceNote',
          headerName: 'Lisätieto',
          width: 250,
          editable: false,
          sortable: false,
        }
      )
    } else {
      columns.push(
        { 
          field: 'orderDate2',
          headerName: 'Tilauspäivä',
          width: 100,
          editable: false,
          sortable: false,
        },
        { 
          field: 'driverByName',
          headerName: 'Tilaaja',
          width: 150,
          editable: false,
          sortable: false,
        },
        { 
          field: 'requestInfo', 
          headerName: 'Huoltokuvaus', 
          width: 300,
          editable: true,
          sortable: false,
        },
        { 
          field: 'servicedByExt',
          headerName: 'Ulkopuolinen huolto',
          width: 150,
          editable: false,
          sortable: false,
        },
        { 
          field: 'externalServiceNote',
          headerName: 'Lisätieto',
          width: 150,
          editable: false,
          sortable: false,
        }
      )
    }

    //driver don't need autofutur fields
    if (LogiTarUser.current.info.userType != LogiTarUserType.DRIVER) {
      columns.push(
        { 
          field: 'status',
          headerName: 'Status',
          width: 150,
          editable: false,
          sortable: false,
        },
        { 
          field: 'workOrderNr',
          headerName: 'Työnumero',
          width: 100,
          editable: false,
          sortable: false,
        },
        { 
          field: 'inProgress2',
          headerName: 'Huoltopäivä',
          width: 100,
          editable: false,
          sortable: false,
        },
      )
    }

    columns.push(
      { 
        field: 'actions',
        type: 'actions',
        headerName: (LogiTarUser.current.info.userType != LogiTarUserType.DRIVER) ? 'Toiminto': '',
        width: (LogiTarUser.current.info.userType != LogiTarUserType.DRIVER) ? 140 : 140,
        cellClassName: 'actions',
        align: "left",



        getActions: ({ id }) => {
          const orderrow = openServiceHistory.find((row) => row.id === id);
          let autofuturCheck = false;
          let removeCheck = false;
          let acceptedCheck = false;
          let isExternalService = false;
          let historyrows = "[]"
          let historyrowsCount = 0
          let connectionCheck = false;

          if (orderrow) {
            autofuturCheck = orderrow.isSendAutofutur == 'true' && (orderrow.status2 && orderrow.status2.toString().length != 0) && orderrow.status2 != "KUSTANNUSARVIO" && orderrow.status2 != "MUUTETTU";
            removeCheck = (orderrow.isSendAutofutur == 'true')
            acceptedCheck= (orderrow.acceptedDate != null)
            isExternalService = orderrow.ExternalService_id > 0 || (orderrow.servicedByExt && orderrow.servicedByExt.length > 0)
            historyrows = openServiceHistory.filter((row) => row.uuid === orderrow.uuid && row.riviId > -1);  
            historyrowsCount= historyrows.length
            if (orderrow.connection != null) {connectionCheck = true}         
          }
          else {
            return [];
          }

          //Drivers functions
          if (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ) {
            if (orderrow.riviId === -1 && (isExternalService === false || isExternalService === null) ) {
              return [
                <Stack direction="row" sx={{width:100}}>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  title="Koko huoltotilauksen poisto" 
                  onClick={() => {setHandleRemove(id)}}
                  color="inherit"
                />

                <Typography sx={{ p:2 }}></Typography>  
                </Stack>
              ];
            }
            else if (orderrow.riviId === -1 && isExternalService === true) {
              return [
                <Stack direction="row" sx={{width:100}}>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  title="Koko huoltotilauksen poisto" 
                  onClick={() => {setHandleRemove(id)}}
                  color="inherit"
                />

               <Button sx={{ml:1, px:5, fontFamily: 'Arial', fontSize: '10px'}} variant="contained" Title="Hyväksy ja vie historiaan" icon={Save} 
                onClick={handleSaveExternalService(id)}  disabled={(acceptedCheck == true && !isExternalService) || (props.new == true && (saved == false || saved == null)) }
                title="Huoltotilauksen hyväksyminen" 
                >Hyväksy</Button>
     
                </Stack>
              ];
            }

            // Row selected when orderrow.riviId > 0
            else {
              return [
                <Stack direction="row" sx={{width:100}}>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  title="Kuvausrivin poisto" 
                  onClick={() => {setHandleDeleteRowClick(id)}}
                  color="inherit"
                  disabled={autofuturCheck == true}
                />
                <Typography sx={{ p:2 }}></Typography>  
                </Stack>
              ];
            }
          }

          //Office functions
          else {
            if (orderrow.riviId === -1 && (isExternalService === false || isExternalService === null) ) {
              return [
                <Stack direction="row" sx={{width:100}}>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  title="Koko huoltotilauksen poisto" 
                  onClick={() => {setHandleRemove(id)}}
                  color="inherit"
                  disabled={removeCheck === true}
                />
      
                <GridActionsCellItem
                  icon={<Send />}
                  label="Autofutur" 
                  title="Autofutur-lähetys" 
                  onClick={handleSendAutofutur(id)}
                  color="inherit"
                  disabled={autofuturCheck === true || historyrowsCount === 0 || saved == false || saved == null || connectionCheck == false}                
                /> 
                </Stack>
              ];
            }
            else if (orderrow.riviId === -1 && isExternalService === true) {
              return [
                <Stack direction="row" sx={{width:100}}>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  title="Koko huoltotilauksen poisto" 
                  onClick={() => {setHandleRemove(id)}}
                  color="inherit"
                  disabled={acceptedCheck == true}
                />

                <Button sx={{ml:1, px:5, fontFamily: 'Arial', fontSize: '10px'}} variant="contained" Title="Hyväksy ja vie historiaan" icon={Save} 
                  onClick={handleSaveExternalService(id)}  disabled={(acceptedCheck == true && !isExternalService) || (saved == false || saved == null) }
                  title="Huoltotilauksen hyväksyminen" 
                  >Hyväksy</Button>
       
                </Stack>
              ];
            }
            // Row selected when orderrow.riviId > 0
            else {
              return [
                <Stack direction="row"  sx={{width:100}}> 
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  title="Kuvausrivin poisto" 
                  onClick={() => {setHandleDeleteRowClick(id)}}
                  //onClick={<ConfirmDelete/>}
                  color="inherit"
                  disabled={(acceptedCheck == true && isExternalService ) || autofuturCheck == true}
                  // width="150"
                />
                </Stack>
              ];
            }
          }
        },
      },
    )
  
    let columnsSummary = [
      { field: "vehicleByNumber", headerName: "Auto"},
      { field: "driverByName", headerName: "Tilaaja"},
      { field: "beforeDate2", headerName: "Huoltoviim. pvm"},
      { field: "beforeKm", headerName: "Huoltoviim. km"},
      { field: "requestInfo", headerName: "Pyydetty huolto"},
      { field: "orderDate2", headerName: "Tilauspäivä"},
      { field: "priorityname", headerName: "Kiireellisyys"},
      { field: "inProgress2", headerName: "Huoltopäivä"},
      { field: "servicedByExt", headerName: 'Ulkopuolinen huoltaja'},
      { field: "externalServiceNote", headerName: "Lisätieto"},
      { field: 'workOrderNr',headerName: 'Työnumero'},
      { field: 'connectionname',headerName: 'Toimipaikka'},   
      { field: 'status',headerName: 'Status'},
      { field: "done", headerName: 'Valmis'},
      { field: "serviceDesc", headerName: 'Suoritettu huolto'},
      { field: "acceptedByName", headerName: "Hyväksyjä"},
      { field: "acceptedDate2", headerName: "Hyväksymispäivä"},
    ];
    const dialogTextStyle = {
      display: "flex", justifyContent: "space-between", my: 0
    }
  
    // Initializing 
    useEffect(()=>{
      const uuidname = 'uuid';
      if (props.new) {
          const newuuid = uuid();
          setNewUuid(newuuid); 
          // alert("Uusi uuid " + newuuid);
          let dateFormatNull =  null;
          let dateFormat = null;

          if ( props.serviceHistory.orderDate ) {
            dateFormat= format(props.serviceHistory.orderDate, "yyyy-MM-dd"); 
          }
          else {
            dateFormat= format(Date.now(), "yyyy-MM-dd"); 
          }
          
          setPostServiceHistory({...postServiceHistory, 'uuid': newuuid, id: null, 'orderDate': dateFormat, 'beforeDate':  dateFormatNull, 'driver': LogiTarUser.current.token.user, 'driverByName': LogiTarUser.current.info.name});
          setServiceHistory({...serviceHistory, 'orderDate': dateFormat, 'beforeDate':  dateFormatNull, 'driver': LogiTarUser.current.token.user, 'driverByName': LogiTarUser.current.info.name})

          // Remove the first empty vehicle if marked '-'
          let originalvehicles = [...vehicles]
          if (props.serviceMonitor != 'true') 
          {
            originalvehicles.shift()
          }
          setVehiclesList(originalvehicles)
          
          if (props.serviceMonitor == 'true') {
            if (props.serviceHistory.orderInfoText) {
              setOpenServiceRequestInfo(props.serviceHistory.orderInfoText)
            }
          }    
      }
      else {
          setPostServiceHistory({...postServiceHistory, 'uuid': props.serviceHistory.uuid});
      }

      setOpenServiceHistory([])
         
      if (!props.new) {
        setServiceTimeStamp(Date.now())
        setServiceRowsTimeStamp(Date.now());
      }
    }, [])

     //Get control data
     useEffect(() => {
      const title="ServiceHistory"

      LogitarApi.getControlValues({title: title}).then((result) => {   
        let workpriority = result.controlvalues.filter((j) => j.subtitle == "Priority");
        workpriority = ([{value:null, name:'-'}, ...workpriority]) 
        setWorkPriority(workpriority);
        let autofuturconn = result.controlvalues.filter((j) => j.subtitle ==  "AutofuturOffice"); 
        autofuturconn = ([{value:null, name:'-'}, ...autofuturconn])
        setAutofuturConnection(autofuturconn); 
 
        //Default value
        setSelectedPriority(workpriority[0])
        workpriority.forEach((value) => {
            if (value.name == 'Ei kiireellinen') {
                setSelectedPriority(value)
            }
        })

        //Default value 
        if (props.new) {
          if (serviceHistory.connection != null) {
            autofuturconn.forEach((value, index) => {
              if (value.value == serviceHistory.connection) {
                const ind = Number(index); 
                // Ensure the next index is within bounds
                if (ind < autofuturconn.length) {
                  setSelectedConnection(autofuturconn[ind]);
                }
              }
            });
          }
          else {
            setSelectedConnection(autofuturconn[0])
            if (autofuturconn.length == 2) {
              //alert("oletus on " + autofuturconn[1].name)
              setSelectedConnection(autofuturconn[1])    
            }   
          }
        }
      })
      .catch((err) => {});
    }, []);

    //Get vehicles
    useEffect(() => {
      if (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ) {
          if (LogiTarUser.current.vehicle ? LogiTarUser.current.vehicle.id : null) {
            const userid = LogiTarUser.current.info.id
            const vehicleid = LogiTarUser.current.vehicle.id 
            LogitarApi.getVehiclelist({userid: userid, method: "drivervehiclelist", vehicleid: vehicleid})
            .then((msg) => {
              setVehiclesList(msg.vehicles);  
              console.log("Current vehicle", msg.vehicles)
              setCurrentVehicle(msg.vehicles[0]["vehicle1"])
            }) 
            .catch((err) => {});
          }
      } 
    }, []);

    // //Get drivers
    // useEffect(() => {
    //   LogitarApi.getUsers({extent: "all"})
    //     .then((msg) => {
    //       setUsers(msg.users);
    //        setDrivers(msg.users);
    //     })
    //     .catch((err) => {});
    // }, []);

    //Get external service garages
    useEffect(() => {
      const GetExternalServices = async() => {
        const result = await LogitarApi.getExternalServices({id: null});
        setExternalServices(result.externalservice);  

        // console.log("Haetut ulkopuoliset huoltajat")
        setSelectedExternalService(null)
        result.externalservice.forEach((value) => {
            // console.log(value.id);
            // console.log(value.companyName);
            // console.log(serviceHistory.ExternalService_id);
            if (serviceHistory.ExternalService_id === value.id) {
              setSelectedExternalService(value)
            }
        })  
      }       
      GetExternalServices() 
    }, [externalServiceTimeStamp])
    
    //Get vehicles serviceHistory order
    useEffect(() => {
      const uuid=postServiceHistory.uuid;
      if (uuid){
        const method="serviceorder"
        LogitarApi.getServiceHistoryV3({uuid, method: method, extent: "allfile"}).then((result) => {   
          // alert(result.servicehistory[0].id)
  
          setServiceHistory(result.servicehistory[0]);
          setPostServiceHistory(result.servicehistory[0]);

          let data = externalServices;  
          let externalServiceExists = 0
          setSelectedExternalService(null)
          data.forEach((value) => {
              if (result.servicehistory[0].ExternalService_id === value.id) {
                if (result.servicehistory[0].servicedByExt.length > 0) {
                  externalServiceExists = 1
                  setSelectedExternalService(value)
                }
              }
          })  

          data = workPriority;  
          setSelectedPriority(null)
          data.forEach((value) => {
              if (result.servicehistory[0].priority == value.value) {
                  setSelectedPriority(value)
              }
          })
  
          if (externalServiceExists === 0) {
            data = autofuturConnection;  
            setSelectedConnection(null)
            data.forEach((value) => {
                if (result.servicehistory[0].connection == value.value) {
                    setSelectedConnection(value)
                }
            }) 
          }

        }).catch(err => { 
          console.log("VIRHE TILAUKSEN HAUSSA");
          enqueueSnackbar("Huoltotilauksen haku epäonnistui", {variant: "error"})
        })
      }
    }, [serviceTimeStamp])

    //Get vehicles serviceHistory order rows
    useEffect(() => {
      const uuid=postServiceHistory.uuid;
      if (uuid) {
        const method="servicerows"
        LogitarApi.getServiceHistoryV3({uuid: uuid, method: method}).then((result) => {    
          setOpenServiceHistory(result.servicehistory);

        }).catch(err => { 
          console.log("VIRHE RIVIEN HAUSSA");
          enqueueSnackbar("Huoltokuvausten haku epäonnistui", {variant: "error"})
        })
      }  
    }, [serviceRowsTimeStamp])

    //Save order and rows to database
    const handleSave = (row) => { 
      props.onClick(true)     
      setInProgressIcon(true); 

      let serviceHistoryRow = '';
      if (openServiceRequestInfo.length > 0)
      {
        if (rowId === -1) {
          //insert
          //alert("Rivin lisays")
          serviceHistoryRow = ({'uuid':postServiceHistory.uuid, 'requestInfo':openServiceRequestInfo}); 
          LogitarApi.setServiceHistoryRow(serviceHistoryRow).then((result) => {
            //setRowId(result.latestId);
            setRowId(-1); 
          })
        }
        else {
          //update
          //alert("Rivin muutos")
          serviceHistoryRow = ({'id':rowId, 'uuid':postServiceHistory.uuid, 'requestInfo':openServiceRequestInfo}); 
          LogitarApi.setServiceHistoryRow(serviceHistoryRow).then((result) => {})
        }
      }

      let servicehistoryorder = postServiceHistory;
      
      if(servicehistoryorder.files) {
        servicehistoryorder.files.forEach((e) => {
              if(!String(e.id).includes("NEW-")) {
                  // Delete data if it's not a new file
                  delete e.data;
              }
          })
      }
      
      if (!postServiceHistory.driver) {
        servicehistoryorder = ({...servicehistoryorder, 'driver': LogiTarUser.current.token.user })
        servicehistoryorder = ({...servicehistoryorder, 'driverByName': LogiTarUser.current.info.name })
      }

      //Priority combobox default
      if (!postServiceHistory.priority) {
        workPriority.forEach((value) => {
            if (value.name == 'Ei kiireellinen') {
                servicehistoryorder = ({...servicehistoryorder, 'priority': value.value })
            }
        })
      }

      //Autofutur connection combobox default if no external service
      if (!postServiceHistory.servicedByExt) {
        if (!postServiceHistory.connection) {
          if (autofuturConnection.length == 2) {
            servicehistoryorder = ({...servicehistoryorder, 'connection': autofuturConnection[1].value })
          }      
        }
      }
   
      //Save order 
      LogitarApi.setServiceHistory(servicehistoryorder, false).then((result) => {
        console.log("Otsikko tallennettu ")
        console.log(postServiceHistory)
        //alert(result.latestId);
        setServiceHistory({...serviceHistory, 'id':result.latestId})
        setPostServiceHistory({...postServiceHistory, 'id':result.latestId})

        props.rowUpdate()
        enqueueSnackbar("Huoltotilaus tallennettu")
        setSaved(true);

        //Get order
        setServiceTimeStamp(Date.now());
        
        //Get order rows
        setServiceRowsTimeStamp(Date.now());

        //Initialize new row
        setOpenServiceRequestInfo("")
        setRowId(-1);

      })
      .catch((err) => {
          // enqueueSnackbar("Huoltotilauksen tallennus epäonnistui", { variant: 'error' });
          enqueueSnackbar("Huoltokuvauksen tallennus epäonnistui. " + err.dberr, { variant: 'error' });
      })
      .finally(() => {
          setInProgressIcon(false);
          setRowSelectedFlag(0)
      })
    }

    const setHandleRemove = (id) => {
      setDialog(true); setDeleteOrderOrRow({'type':'order','id':id});
      const vehicle = vehiclesList.find((row) => row.id == serviceHistory.vehicle);
      setConfirmMsg(`Haluatko varmasti poistaa huoltotilauksen: ajoneuvo: ${vehicle.id} : ${vehicle.licenseNumber}, tilauspäivä: ${serviceHistory.orderDate} ?`)
    }
  
    //Remove whole order
    const handleRemove = () => {   
      props.onClick(true)    
      let id= deleteOrderOrRow.id;
      let orderrow = openServiceHistory.find((row) => row.id === id);  
      if (orderrow === null) {return;} 

      let serviceHistoryOrder = ({'uuid':orderrow.uuid}); 
      LogitarApi.setServiceHistoryRow(serviceHistoryOrder, false, true, false).then((result) => {
          LogitarApi.setServiceHistory(serviceHistoryOrder, true).then((result) => {
  
              enqueueSnackbar("Huoltotilaus poistettu")
              
              // Remove id field for inserting new order
              let updatedServiceHistory = { ...postServiceHistory};
              delete updatedServiceHistory.id              
              setPostServiceHistory(updatedServiceHistory);
  
              // Clear order rows
              setOpenServiceHistory([])
              props.rowUpdate()
          })
      })
    }

    const setHandleDeleteRowClick = (id) => {
      setDialog(true); setDeleteOrderOrRow({'type':'row','id':id});
      const orderrow = openServiceHistory.find((row) => row.id === id);  
      setConfirmMsg(`Haluatko varmasti poistaa huoltokuvauksen: ${orderrow.requestInfo} ?`)
    }

    const handleDeleteRowClick = () => {
      // alert("Rivin poisto tietokannasta");
      // alert(id);

      let id= deleteOrderOrRow.id;
      const orderrow = openServiceHistory.find((row) => row.id === id);  

      if (orderrow) {
          //alert(orderrow.id);
          //alert(orderrow.riviId);

          let uuid =  orderrow.uuid;
          let workOrderNr = orderrow.workOrderNr2;
          let rowNumber = orderrow.autofuturRowNumber;
          let connection = orderrow.connection;

          orderrow.id = orderrow.riviId;
          if (orderrow.riviId === -1) {return}; //order header
  
          setOpenServiceHistory(openServiceHistory.filter((row) => row.id !== id));
          LogitarApi.setServiceHistoryRow(orderrow, true, false, false).then((result) => {
            enqueueSnackbar("Huollon korjausrivi poistettu")

            //TODO CHECK THIS
            if (workOrderNr > 0) {
              // alert(workOrderNr)
              // alert(rowNumber)
              // alert(connection)
              let workorder = ({'uuid':uuid, 'workOrderNr':workOrderNr ,  'rowNumber':rowNumber, 'connection':connection}); 
              LogitarApi.sendAutofutur(workorder, true).then((result) => {
                console.log("Rivi poistettu Autofuturesta");
              })
            }
 
            //Get order rows
            setServiceRowsTimeStamp(Date.now());

            setOpenServiceRequestInfo("")
            props.rowUpdate()
        })
      }
    };

    //Send Autofutur using API
    const handleSendAutofutur  = (id) => () => {    
      // alert("handleSendAutofutur " + id);
      setInProgressIcon(true);
      let uuid = ""
      let licenseNumber = ""
      let orderDate = ""
      let descriptions = []
      let currentDate = format(Date.now(), "yyyy-MM-dd"); 
      let workOrderNr = ""
      let rowNumber = ""
      let connection = ""

      let orderrow = openServiceHistory.find((row) => row.id === id);  
      if (orderrow === null) {
        return;
      } else {
        uuid = orderrow.uuid;
        licenseNumber = orderrow.licenseNumber;
        orderDate = orderrow.orderDate;
        workOrderNr = orderrow.workOrderNr;
        rowNumber = orderrow.autofuturRowNumber;
        connection = orderrow.connection;
      }

      //-1 = order header 
      if (orderrow.riviId === -1) {
        let historyrows = openServiceHistory.filter((row) => row.uuid === uuid);  
        historyrows.forEach((value) => { 
          if (value.riviId > 0) {
            let description = {'taskDescription': value.requestInfo, 'rowNumber': value.autofuturRowNumber}
            descriptions.push(description)
          }
        })
      }; 

      if (descriptions.length === 0) {
        return;
      } 
      
      // alert("Yhteys " +  connection)
      let workorder = ""
      //Update workorder into Autofuture
      if (workOrderNr && workOrderNr.toString().length > 1){
        workorder = ({'uuid':uuid, 'registrationNr':licenseNumber, 'orderDate':orderDate, 'workOrderNr':workOrderNr, 'rowNumber': rowNumber, 'workItems':descriptions, 'connection':connection}); 
      }
      //Insert workorder into Autofuture
      else {
        workorder = ({'uuid':uuid, 'registrationNr':licenseNumber, 'orderDate':orderDate, 'workItems':descriptions, 'acceptedDate':currentDate, 'acceptedBy': LogiTarUser.current.token.user, 'connection':connection}); 
      }
  
      LogitarApi.sendAutofutur(workorder, false).then((result) => {
        enqueueSnackbar("Huoltotilaus lähetetty Autofutureen")
        //Get order
        setServiceTimeStamp(Date.now());    
        //Get order rows
        setServiceRowsTimeStamp(Date.now());
        props.rowUpdate()
      })
      .catch((err) => {
        enqueueSnackbar("Autofutur lähetys epäonnistui", { variant: 'error' });
      })
      .finally(() => {
          setInProgressIcon(false);
      })
    }

    //If external servive, make only order accepting 
    const handleSaveExternalService  = (id) => () => { 
      let orderrow = openServiceHistory.find((row) => row.id === id);  
      //-1 means order header 
      if (orderrow === null || orderrow.riviId != -1) {
        return;
      } 

      let currentDate = format(Date.now(), "yyyy-MM-dd"); 
      let servicehistoryorder = '';
      servicehistoryorder = ({'uuid':orderrow.uuid, 'method':'acceptExternalService', 'acceptedDate': currentDate,  'acceptedBy': LogiTarUser.current.token.user}); 
    
      LogitarApi.setServiceHistory(servicehistoryorder, false).then((result) => {
        console.log("Otsikko tallennettu ")
        //alert(result.latestId);

        setServiceHistory({...serviceHistory, 'id':result.latestId})
        setPostServiceHistory({...postServiceHistory, 'id':result.latestId})
        props.rowUpdate()
        enqueueSnackbar("Huoltotilaus hyväksytty")
        //setSaved(true);

        //Get selected order
        setSelectedUuid(postServiceHistory.uuid);
        setServiceTimeStamp(Date.now());

        //Get all servicehistory
        setSelectedVehicle(postServiceHistory.vehicle)
        setServiceRowsTimeStamp(Date.now());

        //Possible to save serviceDesc (tehty huolto)
        setPopupSummary(true)
                
      })
      .catch((err) => {
          enqueueSnackbar("Huoltotilauksen hyväksyminen epäonnistui", { variant: 'error' });
      })   
    }

    //Save serviceDesc
    const handleChangeOrder = (event) => {    
      let servicehistoryorder = '';
      servicehistoryorder = ({...postServiceHistory, 'serviceDesc': serviceHistory['serviceDesc']}); 
      LogitarApi.setServiceHistory(servicehistoryorder, false).then((result) => {
        console.log("Tehty huolto tallennettu ")
        console.log(servicehistoryorder)
        enqueueSnackbar("Huolto tallennettu")
      })
    }

    //Change serviceDesc
    const handleModifyOrder = (event) => {
      event.preventDefault();
      setServiceHistory({...serviceHistory, [event.target.name]: event.target.value})
      setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value})
    }
    
    const handleOpenServiceRowClick = (params) => {

        //Set color
        setSelectedRowId(params.row.id);

        //Set row for update
        setOpenServiceRequestInfo(params.row.requestInfo)
        setRowId(params.row.riviId);

        //Flag is order or row selected: initialized 0, order -1, row > 1
        setRowSelectedFlag(params.row.riviId)
        if (params.row.riviId === -1 ) {
          setRowSelectedFlag(-1)
        }

        let historyrows = "[]"
        let historyrowsCount = 0
        historyrows = openServiceHistory.filter((row) => row.uuid === params.row.uuid && row.riviId > -1);  
        historyrowsCount= historyrows.length
        if (historyrowsCount === 0 ) {
          setRowSelectedFlag(0)
        }
      
        //Get selected order for update
        setSelectedUuid(params.row.uuid);
        setServiceTimeStamp(Date.now());
    };

    const handleChange = (event) => {
      if (!event.target.name) {
        return;
      }
      if (saved) { setSaved(false);}

      if (event.target.type === "checkbox") {
        if (event.target.checked) {
          setServiceHistory({...serviceHistory, [event.target.name]: 1})
          setPostServiceHistory({...postServiceHistory, [event.target.name]: 1})

          if (event.target.name == 'alarm') {
            const prevalarmChk = serviceHistory.alarm == 1 ? true: false
            if (event.target.checked !== prevalarmChk ) {
              setPostServiceHistory({...postServiceHistory, [event.target.name]: 1, 'alarmChanged': 1})
            }
          }          
        } else {
          setServiceHistory({...serviceHistory, [event.target.name]: 0})
          setPostServiceHistory({...postServiceHistory, [event.target.name]: 0})
        }
      } else{
        setServiceHistory({...serviceHistory, [event.target.name]: event.target.value})
        setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value})
      }
    }

    const handleChangeVehicle = (event) => {

      let vehicleByNumber=event.target.label;
      if (!props.new) {
        //Not allow change vehicle on update mode
        if (serviceHistory.id) {
          if (event.target.value !== serviceHistory.vehicle) {
            props.onClick(false)   
          }
        }
      }
      else {
        //Possible to insert a new vehicle workorder 
        if (serviceHistory.id) {
          if (event.target.value !== serviceHistory.vehicle) {
            setOpenServiceHistory([])   
            const newuuid = uuid();
            setNewUuid(newuuid); 
            setSelectedExternalService([null]);
            setSelectedConnection(null)
            setSelectedPriority(null)
            setOpenServiceRequestInfo('');
            serviceHistory.externalServiceNote = null;
            setServiceHistory({...serviceHistory, [event.target.name]: event.target.value, 'vehicleByNumber': vehicleByNumber, 'uuid': newuuid, id: null, 'workOrderNr' : null, 'status': null, 'inProgress': null, 'done' : null, 'acceptedBy' : null, 'acceptedDate' : null,
              'externalServiceNote': null, 'beforeKm': null, 'beforeDate':null, 'alarm': null, 'priority':null, 'connection':null, 'ExternalService_id':null, 'servicedByExt':null
            })
            setPostServiceHistory({...serviceHistory, [event.target.name]: event.target.value, 'vehicleByNumber': vehicleByNumber, 'uuid': newuuid, id: null, 'workOrderNr' : null, 'status': null, 'inProgress': null, 'done' : null, 'acceptedBy' : null, 'acceptedDate' : null,
              'externalServiceNote': null, 'beforeKm': null, 'beforeDate':null, 'alarm': null, 'priority':null, 'connection':null, 'ExternalService_id':null, 'servicedByExt':null     
             })
            return;
          }
        } 
      }
     
      setServiceHistory({...serviceHistory, [event.target.name]: event.target.value, 'vehicleByNumber': vehicleByNumber})
      setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value, 'vehicleByNumber': vehicleByNumber})
      setOpenServiceRequestInfo('');
    }

    const handleChangeDriver = (event) => {
      //let drivername = event.target.value.username + ':' +  event.target.value.name;
      let drivername = event.target.label;
      //alert(drivername);
      // setServiceHistory({...serviceHistory, [event.target.name]: event.target.value, 'driverByName':drivername})
      // setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value, 'driverByName':drivername})
      setServiceHistory({...serviceHistory, [event.target.name]: event.target.value})
      setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value})
      setSelectedDriver(event.target.value)
      if (saved) { setSaved(false);}
    }

    const handleChangePriority = (event) => {
      setServiceHistory({...serviceHistory, 'priority': event.target.value.value})
      setPostServiceHistory({...postServiceHistory, 'priority': event.target.value.value})
      setSelectedPriority(event.target.value)
      if (saved) { setSaved(false);}
    }

    const handleChangeConnection = (event) => {
      setServiceHistory({...serviceHistory, 'connection': event.target.value.value, 'ExternalService_id': null, 'servicedByExt': null})
      setPostServiceHistory({...postServiceHistory, 'connection': event.target.value.value, 'ExternalService_id': null, 'servicedByExt': null})
      setSelectedConnection(event.target.value)
      //Flag to disable Autofutur button
      if (saved) { setSaved(false);}
      //Autofutur connection or external service is allowed
      setSelectedExternalService(null)
    }
  
    const handleChangeExternalService = (event) => {
      let companyid = event.target.value.id ? event.target.value.id: null
      let companyname =  event.target.value.companyName ? event.target.value.companyName : null
      setServiceHistory({...serviceHistory, 'ExternalService_id': companyid, 'servicedByExt': companyname, 'connection': null})
      setPostServiceHistory({...postServiceHistory, 'ExternalService_id': companyid, 'servicedByExt': companyname, 'connection': null})
      setSelectedExternalService(event.target.value);
      //Flag to disable Autofutur button
      if (saved) { setSaved(false);}
      //Autofutur connection or external service is allowed
      setSelectedConnection(null)
    };
    
    const handleDateChange = (value, name) => {
      if (value == "Invalid Date") {
          //alert("Viallinen päivämäärä");
      } else {
          let dateFormat = null;
          if (value) {
              dateFormat= format(value, "yyyy-MM-dd"); 
          }        
          //alert(name);
          //alert(dateFormat);
          setServiceHistory({...serviceHistory, [name]: dateFormat})
          setPostServiceHistory({...postServiceHistory, [name]: dateFormat})
      }
      if (saved) { setSaved(false);}
    }

    //Close external services
    const setPopup = (b) => {
      //alert("suljetaan");
      setNewRepairman(false);
    };
 
    const CheckErrors = () => {
      let error = false;
      // if (serviceHistory.acceptedDate != null) {return(false)}
      if (postServiceHistory.vehicle && postServiceHistory.vehicle.toString().length > 0) {error=error} else {error = true;}
      if (postServiceHistory.driver && postServiceHistory.driver.toString().length > 0) {error=error} else {error = true;}
      if (postServiceHistory.orderDate && postServiceHistory.orderDate.length > 0) {error=error} else {error = true;}
      if (rowSelectedFlag > 0 || (rowSelectedFlag == 0 && props.new)) {
        if (openServiceRequestInfo && openServiceRequestInfo.length > 0) {error=error} else {error = true;}
      } 
      return(error)
    }

    const open = Boolean(anchorEl)

    const setNewExternalServiceTimestamp= () => {
      setExternalServiceTimestamp(Date.now());
    };

    const clearText = () => {
      setOpenServiceRequestInfo("")
      setRowId(-1)
    };

    const NoDataSavedIcon = () => { 
      return (
        <div style={{ marginLeft: 850, marginTop: -10, color: 'red' }}>
          <WarningIcon />
          <span style={{ marginLeft: 10 }}>Muutoksia ei ole tallennettu</span>
        </div>
      );
    }

    return (
      <PopupScreen
        draggable
        title={!props.new ? "Huoltotilauksen muokkaus" : "Uusi huoltotilaus"}
        onClose={() => props.onClick(false)}
        zIndex={1210}
        forceRightEdge
      >
        <Box>
          <form onChange={handleChange}>
            <FormControl 
            sx={{
              '& .MuiTextField-root': { flex: 1 },
              '& .MuiFormGroup-root': { display: "flex" },
              mx: 2
          }}
        > 
            <FormGroup row sx={{gap: 1.5, flexWrap: lgMediaQuery ? "nowrap": "wrap"}}>
              <FormGroup sx={{mt:1, flex: "1 1 70%"}}>            
                  <FormGroup row>
                      <Typography sx={{fontWeight: 'bold', mb:1}}>Tilauksen tiedot </Typography>        
                      {saved == false && <NoDataSavedIcon/>  }        
                      {inProgressIcon && <CircularProgress size={30}  sx={{ml:70, mt:-1}}/>}
                  </FormGroup>
  
                  <FormGroup row sx={{gap: 1.5, mb: 1.5}}>    
                       <Autocomplete
                        disablePortal 
                        id='combo-box'
                        name='vehicle'
                        value={serviceHistory.vehicleByNumber || currentVehicle}
                        options={vehiclesList.length > 0 ? vehiclesList.map(item => { return { label: item.id + ':' + item.licenseNumber.toString(), value: item.id, extra: { ...item } } }) : []}
                        renderInput={(params) => <TextField {...params} label='Auto' required={true} />}
                        renderOption={(props, option) => <AutocompleteVehicle key={(option.value)} props={props} option={option} />}
                        onChange={(event, newValue) => handleChangeVehicle({ target: { name: 'vehicle', value: newValue?.value ?? null,  label:newValue?.label ?? null }})}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        sx={{ minWidth: 280, heigth: 30, m: 0, flex: 1}}      
                        disabled={!props.new && rowSelectedFlag == 0}                                  
                      /> 
   
                      <LocalizationProvider adapterLocale={fi}  dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker      
                          id="date-picker"                          
                          mask={"__.__.____"}                                        
                          value={Date.parse(serviceHistory.orderDate)}
                          onChange={(val) => handleDateChange(val, "orderDate")}
                          name="orderDate"
                          label="Tilauspäivä"
                          inputFormat='dd.MM.yyyy'        
                          disabled={true}              
                          renderInput={(params) => <TextField {...params}  helperText={'Date is required'} />}       
                          sx={{minWidth: 180, flex: 1}}
                          // required={true} 
                         ></DesktopDatePicker>
                      </LocalizationProvider>
  
                      <Autocomplete
                          disablePortal
                          id='combo-box'
                          name='driver'
                          value={serviceHistory.driverByName}
                          options={drivers.length > 0 ? drivers.map(item => { return { label: item.username.toString() + ':' + item.name.toString(), value: item.id.toString(), extra: { ...item } } }) : []}
                          // renderInput={(params) => <TextField {...params} label='Tilaaja' required={true} />}
                          renderInput={(params) => <TextField {...params} label='Tilaaja'/>}
                          renderOption={(props, option) => <AutocompleteUser key={(option.value)} props={props} option={option} />}
                          onChange={(event, newValue) => handleChangeDriver({ target: { name: 'driver', value: newValue?.value ?? null, label:newValue?.label ?? null  } })}
                          isOptionEqualToValue={(option, value) => option.value === value}
                          defaultValue={serviceHistory.driverByName}
                          disabled={true}
                          sx={{ minWidth: 180, heigth: 30, m: 0, flex: 1}}
                       />  
  
                      <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                        <FormControl sx={{ minWidth: 250, flex: 1}}>
                          <InputLabel id="service-report-type-label" sx={{ fontSize: 16 }}>
                            Kiireellisyys
                          </InputLabel>
                          <Select
                            name="priority"
                            labelId="service-report-type-label"
                            label="Kiireellisyys"
                            value={selectedPriority || ' '}
                            onChange={(e) => handleChangePriority(e)}
                            disabled={!props.new && rowSelectedFlag == 0}   
                            // sx={{ width:200, heigth: 30}}
                          >
                            {/* <MenuItem value={0}>-</MenuItem> */}
                            {[...workPriority].map((value, key) => {
                              return (
                                <MenuItem value={value} key={key}>
                                  {value.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </LocalizationProvider>

                      <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                        <FormControl sx={{ minWidth: 250, flex: 1}}>
                          <InputLabel id="service-report-type-label" sx={{ fontSize: 16 }}>
                            Huollon toimipaikka
                          </InputLabel>
                          <Select
                            name="connection"
                            labelId="service-report-type-label"
                            label="Huollon toimipaikka"
                            value={selectedConnection || ' '}
                            onChange={(e) => handleChangeConnection(e)}
                            // sx={{ width: 200, heigth: 30}}
                            //disabled={(postServiceHistory.workOrderNr && postServiceHistory.workOrderNr > 0)}
                            disabled={!props.new && (rowSelectedFlag == 0 || (serviceHistory.status && serviceHistory.status.length > 0 && serviceHistory.status != "KUSTANNUSARVIO" && serviceHistory.status != "MUUTETTU"))}
                          >
                            {/* <MenuItem value={0}>-</MenuItem>  */}
                            {[...autofuturConnection].map((value, key) => {
                              return (
                                <MenuItem value={value} key={key}>
                                  {value.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </LocalizationProvider>
                    <FormControl sx={{minWidth:280, flex: 1}}> 
                        <InputLabel id="service-external-repairman-label">Ulkopuolinen huolto</InputLabel> 
                        <Select
                            labelId="service-external-repairman-label"
                            label="Ulkopuolinen huolto"
                            value={selectedExternalService || ' '}
                            // onChange={(e) => externalServiceSelected(e.target.value)}
                            onChange={(e) => handleChangeExternalService(e)}
                            sx={{height: 55}}
                            //disabled={!props.new && (rowSelectedFlag == 0 || (postServiceHistory.workOrderNr && postServiceHistory.workOrderNr > 0))}
                            disabled={!props.new && (rowSelectedFlag == 0)}
                            >
                                <MenuItem onClick={() => setNewRepairman(true)} sx={{ color: "green" }}>Uusi ulkopuolinen huollon tekijä</MenuItem>
                                {<MenuItem value={0}>-</MenuItem> }
                                {[...externalServices].map((value,key) => {
                                    return(
                                        <MenuItem value={value} key={key}>{value.companyName}</MenuItem>
                                    )
                                })}
                        </Select>
                      </FormControl>  
                      
                      <TextField
                        label="Lisätieto"
                        placeholder="Lisätieto ulkopuoliseen huoltoon"
                        name="externalServiceNote"
                        value={serviceHistory.externalServiceNote}
                        defaultValue={serviceHistory.externalServiceNote}
                        disabled={!props.new && (rowSelectedFlag == 0 || !selectedExternalService)}
                        sx={{minWidth: 250, flex: 1}}
                      />           
                  </FormGroup>
  
                  { (LogiTarUser.current.info.userType != LogiTarUserType.DRIVER) && (
                  <FormGroup row sx={{gap: 1.5, mb: 1.5}}>
                    <TextField
                      label="Km hälytysraja" type={"number"} step={1}
                      placeholder="Mittarilukema"
                      name="beforeKm"
                      value = {serviceHistory.beforeKm}
                      disabled={!props.new && rowSelectedFlag == 0}
                      sx={{width: 200, maxWidth: 200, minWidth: 160}}
                    />
                    <LocalizationProvider adapterLocale={fi}  dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker      
                          id="date-picker"                          
                          mask={"__.__.____"}                                        
                          value={serviceHistory.beforeDate ? Date.parse(serviceHistory.beforeDate) : null}
                          onChange={(val) => handleDateChange(val, "beforeDate")}
                          name="beforeDate"
                          label="Pvm hälytysraja"
                          inputFormat='dd.MM.yyyy'       
                          disabled={!props.new && rowSelectedFlag == 0} 
                          renderInput={(params) => <TextField {...params} helperText={'Date is required'} fullWidth  />}
                          sx={{width: 200, maxWidth: 200, minWidth: 160}}
                          // required={true} 
                      ></DesktopDatePicker>
                    </LocalizationProvider>
                    <FormControlLabel control={
                      <Checkbox
                      color="primary"
                      name="alarm"
                      checked={!!serviceHistory.alarm}
                      />
                    } label="Tee huoltomuistutuksen sähköpostihälytys"/> 
                  </FormGroup>      
                  )}
                
                  <FormGroup row>
                    <TextField
                        label="Huoltokuvaus"
                        placeholder={"Uusi huoltokuvaus"}
                        name="requestInfo"
                        value = {openServiceRequestInfo}
                        onChange={(e) => setOpenServiceRequestInfo(e.target.value)}
                        required={rowSelectedFlag == -1 ? false: true}
                        disabled={!props.new && rowSelectedFlag == 0}
                        multiline = {true}                        
                        InputProps={{
                          endAdornment: openServiceRequestInfo &&(
                            <IconButton onClick={clearText} edge="end">
                              <Clear />
                            </IconButton>
                          ),
                        }}
                        inputProps={{
                          maxLength: 100, // Autofututen laskutuksen takia rajoitus
                        }}
                        helperText={`${openServiceRequestInfo.length}/100 merkkiä`}
                    />
                  </FormGroup>   
                </FormGroup>   
  
                <Box sx={{display: 'flex', flex: "1 0 30%", minWidth: "12rem", maxWidth: "80vw", minHeight: "12rem"}}>
                  <FileManager 
                      files={serviceHistory.files}
                      sx={{ flex: 1, my: 1 }}
                      onChange={(files) => {
                        if (saved) { setSaved(false);}
                        setServiceHistory({
                                ...serviceHistory,
                                'files': files
                        })
                        setPostServiceHistory({
                          ...postServiceHistory,
                          'files': files
                        })
                      }}
                  />
              </Box>    
            </FormGroup>
  
            <FormGroup row>
              <FormGroup sx={{width: "100%"}}>
                <FormGroup row sx={{width: "100%", justifyContent: "space-between", flexWrap: "nowrap"}}>

                  <Stack direction="row">
                    <Typography sx={{fontWeight: 'bold', alignSelf: "end", mb: 0.5}}>Huoltotilaukset</Typography>
                    <Tooltip title="Tietojen muuttamiseksi valitse listalta otsikko- tai huoltokuvausrivi. " placement="right" 
                      arrow
                    >
                      <InfoIcon 
                        sx={{
                          color: 'primary.main',          
                          fontSize: '24px',               
                          cursor: 'pointer',              
                          '&:hover': { color: 'secondary.main' }, 
                          ml: 3,   
                          mt: 3                       
                        }}
                      />
                    </Tooltip>
                  </Stack>
 
                  <TipButton variant="contained" icon={Save} 
                        sx={{mb:1, mr: "10vw", mt: 1}}                 
                        title="Tallenna" 
                        onClick={() => handleSave()} 
                        // disabled={CheckErrors() === true || serviceHistory.isSendAutofutur ==='true' }
                        //disabled={CheckErrors() === true}
                        disabled={CheckErrors() === true || (props.new == false && rowSelectedFlag == 0)}
                  />
                </FormGroup>
                
                  <Box
                    sx={{
                      height: 300,
                      width: '100%',
                      position: "relative"
                      // minWidth: 1350,
                    }}                                             
                    > 
                    <DataGridPro
                      rows={openServiceHistory}
                      columns={columns}
                      onRowClick={handleOpenServiceRowClick}   
                      density='compact'
                      getRowClassName={(params) => {
                        if (params.row.done === null || params.row.done === undefined) {
                          if (params.row.status == 'MUUTETTU' && params.row.riviId == -1) {
                            return "autofutur-resend";
                          }
                          // else if (params.row.id == selectedRowId) {
                          //   return "service-edit"
                          // } 
                        }
                      }}
                      sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                      }}
                      disableColumnMenu
                    />   
                  </Box>           
              </FormGroup>
            </FormGroup>
            </FormControl>
          </form>
  
          {newRepairman &&
            <div>
                  <PopupScreen>
                    <NewRepairField externalServices={externalServices} onClick={setPopup}  rowUpdate={setNewExternalServiceTimestamp}></NewRepairField>
                  </PopupScreen>
              </div>
          }

          {/* Update serviceDesc (tehty huolto) when externalService is given*/}
          {popupSummary && (     
            <PopupScreen
              draggable
              title={"Huoltotilauksen yhteenveto"}
              onClose={() => {setPopupSummary(false);}}
              onSave={() => {handleChangeOrder();}}
            >
                <Box style={{ width: 600, maxWidth: 600 }}>
                {columnsSummary.map((row, key) => {
                    if (row.field == "requestInfo" ) {
                      return (
                        <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                          <span style={{flex: 1 }}>{row.headerName}:</span> 
                          <span style={{flex: 3 }}>
                          <TextField sx={{"& fieldset": { border: 'none'}, width: '100%'}} multiline value =  {serviceHistory[row.field]}></TextField>
                          </span> 
                        </Box>
                      )
                    }
                    else if (row.field == "serviceDesc") {
                      return (
                          <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                            <span style={{flex: 1 }}>{row.headerName}:</span> 
                            <span style={{flex: 3 }}>
                            <TextField name = {row.field} onChange={handleModifyOrder} sx={{width: '100%'}} multiline value = {serviceHistory[row.field]} ></TextField>
                            </span> 
                          </Box>
                        )
                    } else {
                      return(
                        <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                          <span style={{flex: 1 }}>{row.headerName}:</span> 
                          <span style={{ marginLeft: 25, textAlign: "left", flex: 3, overflowWrap: "anywhere" }}>{serviceHistory[row.field]}</span>
                        </Box>
                      )
                    }
                }
                )}
                <br></br>
                </Box>
            </PopupScreen>
          )}
  
          <Dialog
                open={dialog===true}
                onClose={diaClose}
            >
                <DialogTitle>
                    {confirmMsg}
                </DialogTitle>
  
                <DialogActions sx={{justifyContent: "space-between"}}>
                    <Button onClick={diaClose}>Peru</Button>
  
                    {deleteOrderOrRow.type === 'order' && (
                    <Button onClick={() => {handleRemove(); setDialog(false)}} autoFocus>
                        Kyllä
                    </Button>
                    )}
                    {deleteOrderOrRow.type === 'row' && (
                    <Button onClick={() => {handleDeleteRowClick(); setDialog(false)}} autoFocus>
                        Kyllä
                    </Button>
                    )}
                </DialogActions>
          </Dialog> 
        </Box>
      </PopupScreen>
    )
}