import { createTheme } from "@mui/material"
import { defaultBodyClasses, defaultBodyClassesDark } from "./DefaultTheme"

export const tarkkalaTheme = createTheme({

    palette: {
        background: {
            default: '#f0f0f0',
            paper: '#f0f0f0'
        },
        primary: {
            main: '#45ac70',
            contrastText: '#FFF'
        },
        secondary: {
            main: '#5fc2da'
        },
        text: {
            primary: '#333333'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        background: 'inherit'
                    }
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    ...defaultBodyClasses
                }
            }
        }
    },
    sidebar: {
        background: {
            backgroundColor: 'f0f0f0'
        }
    }
})

export const tarkkalaThemeDark = createTheme({
    ...tarkkalaTheme,
    palette: {
        mode: "dark",
        background: {
            default: '#191919',
            paper: '#191919'
        },
        primary: {
            main: '#338053',
            dark: '#154d2c',
            contrastText: '#FFF'
        },
        secondary: {
            main: '#5fc2da',
            dark: '#5fc2da',
        },
        text: {
            primary: '#c0c0c0'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        background: 'inherit'
                    }
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                sx: {
                    background: 'background.default'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                sx: {
                    background: 'background.default'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    ...defaultBodyClassesDark,
                    ".logo": {
                        filter: "invert(100%)"
                    }
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'text', color: 'primary' },
                    style: {
                        color: '#26834c'
                    }
                }
            ]
        }
    },
    sidebar: {
        background: {
            backgroundColor: 'f0f0f0'
        }
    }
})