import { Save } from "@mui/icons-material";
import { Autocomplete, Chip, CircularProgress, FormControl, FormGroup, TextField } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useEffect, useState, useRef } from "react";
import LogitarApi from "../api/LogitarApi";
import ItemEdit from "./ItemEdit";
import PopupScreen from "./PopupScreen";
import AlertPop from "./AlertPop";


export default function CargoPop({ cargo, popupOpen, update, divisions, mainGroups, items, detailOptions }) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [postCargo, setPost] = useState({ ...cargo })
    const [clients, setClients] = useState([])

    const [editItem, setEditItem] = useState(null);

    const [saveLoading, setSaveLoading] = useState(false);

    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const isNew = cargo.id ? false : true

    const [saveMode, setSaveMode] = useState(null);
    const formRef = useRef();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        console.log(cargo)
        const GetClients = async() => {
            setLoading(true);
            try {
                const r = await LogitarApi.getClients({ extent: "list" });
                console.log("Asiakkaat", r.clients);
                setClients(r.clients.map((c) => ({ id: c.id, name: c.name })));
            } catch (err) {
                console.error("Failed to fetch clients", err);
            } finally {
                setLoading(false);
            }
        }
        GetClients();
    }, []);

    const saveCargo = (c) => {
        console.log(c)

        setSaveMode(true);
        if (checkSaveIsValid() == false) {
            enqueueSnackbar("Täytä kaikki pakolliset kentät", {variant: "error"})
            return;
        }

        Object.keys(c).forEach((field) => {
            if (c[field] !== null && c[field].length === 0 && typeof c[field] === "string") {
                c[field] = null
            }
        })
        console.log(c)
        LogitarApi.setCargoType({ ...c }).then((result) => {
            if (result.status) {
                console.log(result)
                popupOpen(false)
                update(prev => prev + 1)
                enqueueSnackbar(`Tuote: ${c.name} tallennettu`)

            }
        })
            .catch((e) => { enqueueSnackbar(`Tuotteen: ${c.name} tallennus epäonnistui`, { variant: "error" }) })
    }

    const handleCargoChange = (e, v, r) => {

        if (!e || !e.target.name) {
            return
        }

        console.log(postCargo)

        setPost(prev => ({ ...prev, [e.target.name]: e.target.value }))

    }

    const autocompleteSelect = (v, name) => {

        console.log(v)
        setPost(prev => ({ ...prev, [name]: v }))
    }

    const handleItemPopupOpen = (id) => {
        LogitarApi.getItems({id: id, extent: "allfile"}).then((result) => {
            if (result.status) {
                let item = result.items[0]
                setEditItem(item)
                console.log(item)
                console.log("Fetched: " + item.id)
            }
        })
        .catch((err) => console.error(err))
    }

    const deleteCargo = () => {
        LogitarApi.setCargoType({ ...postCargo, del: true })
        .then((res) => {
            enqueueSnackbar("Tuote poistettu");
            setShowDeletePopup(false);
            popupOpen(false, true);
        })
        .catch((err) => {
            console.error(err)
            enqueueSnackbar("Tuotteen poisto epäonnistui", { variant: "error" });
        })
    }

    const checkIsEmpty = (field, value) => {
        if (isNew == true && saveMode == null) return false;
        if (!value) {
            return true;
        }
        if (value && value.length < 1) {
          return true;
        }
        return false; 
    };

    const checkSaveIsValid = () => {
        if (postCargo.mainGroup == null || postCargo.mainGroup.length < 1 ) return false;
        if (postCargo.division == null || postCargo.division.length < 1 ) return false;
        if ((clients.filter((v) => postCargo.clients.includes(v.id)).length) < 1 ) return false;
        return true;
    }

    if(postCargo.items)
    console.log(postCargo, postCargo.items);

    return (
        <PopupScreen
            title={isNew ? "Tuotteen luonti" : "Tuotteen muokkaus"}
            onClose={() => popupOpen(false)}
            onSave={() => saveCargo(postCargo)}
            saveIcon={
                <>
                    <Save />
                    Tallenna
                </>
            }
            deleteProps={{
                disabled: (postCargo.items && postCargo.items.length > 0) || (postCargo.clients && postCargo.clients.length > 0)
            }}
            onDelete={postCargo.id ? () => setShowDeletePopup(true) : undefined}
            saveLoading={saveLoading}
            draggable
            staticButtons
        >
            <form onChange={handleCargoChange} ref={formRef}>
            <FormControl
                sx={{
                    mt: 1,
                    '& .MuiTextField-root': { mr: 1, mb: 1 },
                    '& .MuiAutocomplete-root': { mr: 1, mb: 1, flex: 1 },
                    width: 600
                }}
                
            >
                <FormGroup row>
                    <Autocomplete
                        label="Pääryhmä"
                        options={mainGroups}
                        freeSolo
                        onInputChange={handleCargoChange}
                        value={postCargo.mainGroup}
                        onChange={(e, v) => autocompleteSelect(v, "mainGroup")}
                        renderInput={(params) => (<TextField label="Pääryhmä" name="mainGroup" required aria-required 
                            {...params} error={checkIsEmpty("mainGroup",postCargo.mainGroup)}></TextField>)}
                    />
                    <Autocomplete
                        name="division"
                        label="Jae"
                        options={divisions}
                        freeSolo
                        onInputChange={handleCargoChange}
                        value={postCargo.division}
                        onChange={(e, v) => autocompleteSelect(v, "division")}
                        renderInput={(params) => (<TextField label="Jae" name="division" required aria-required 
                            {...params} error={checkIsEmpty("division",postCargo.division)} ></TextField>)}
                    />
                </FormGroup>
                <FormGroup row>
                    <TextField
                        name="code"
                        label="Koodi"
                        onChange={handleCargoChange}
                        value={postCargo.code}
                    />
                    <TextField
                        name="name"
                        label="Laji"
                        onChange={handleCargoChange}
                        value={postCargo.name}
                    />
                </FormGroup>
                <FormGroup>
                    {((postCargo.clients || isNew) && clients) &&
                        <Autocomplete
                            sx={{ maxHeight: 300 }}
                            name="clients"
                            label="Asiakkaat"
                            value={(clients.filter((v) => postCargo.clients.includes(v.id)))}                            
                            options={clients}
                            getOptionLabel={option => option.name}
                            multiple
                            renderTags={(value, getTagProps) =>
                                value.map((opt, i) => (
                                    <Chip variant="outlined" label={opt.name} deleteIcon={<></>} {...getTagProps({ i })} />
                                ))
                            }
                            onChange={(e, v, r, d) => { autocompleteSelect(v ? v.map(y => y.id) : null, "clients") }}
                            renderInput={(params) => (<TextField label="Asiakaskoodit" required aria-requored 
                                {...params} error={!loading && checkIsEmpty("clients",clients.filter((v) => postCargo.clients.includes(v.id)))} ></TextField>)}
                        />
                    }
                    {((postCargo.items) && items) &&
                        <DataGridPro 
                            columns={[
                                { field: "id", headerName: "ID", width: 30 },
                                { field: "name", headerName: "Nimike", flex: 1 },
                            ]}
                            columnHeaderHeight={40}
                            hideFooter
                            disableRowSelectionOnClick
                            rowHeight={32}
                            rows={items.filter((v) => postCargo.items.includes(v.id))}
                            size="small"
                            sx={{
                                maxHeight: 300,
                                width: '100%'
                            }}
                            onRowClick={(e) => handleItemPopupOpen(e.row.id)}
                        />
                    }
                </FormGroup>
            </FormControl>
            </form>

            {
                (!clients) &&
                <CircularProgress />
            }
            {
                editItem &&
                <ItemEdit 
                    editItem={editItem} detailOptions={detailOptions} popupOpen={(b) => setEditItem(b ? editItem : null)} newItem={false} rowUpdate={() => {}}
                />
            }
            {
            showDeletePopup &&
            <AlertPop
                title="Haluatko varmasti poistaa tuotteen?"
                cancellable
                onClose={(accepted) => {
                    if(accepted)
                        deleteCargo();
                    else
                        setShowDeletePopup(false);
                }}
            />
        }

        </PopupScreen>
    )
}

