import { Box, Tooltip } from "@mui/material";
import { contentBoxStyle } from "../styles/styles";
import { useEffect, useState } from "react";
import { UserAction } from "../api/LogitarApiTypes";
import LogitarApi from "../api/LogitarApi";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import LogiTarUser, { LogitarUserIcon, LogiTarUserTypeInfo } from "../misc/User";


const cols: GridColDef[] = [
    {
        field: "dateTime",
        headerName: "Pvm",
        valueFormatter: (params) => (format(new Date((params.value as string) + "Z"), 'dd.MM.yyyy HH:mm:ss')),
        width: 180,
    },
    { 
        field: 'user.userName',
        headerName: 'Käyttäjä', 
        renderCell: (params) => {
            const UserIcon = LogitarUserIcon[params.row?.user?.userType];
            return (<>
            <Tooltip title={params.row?.user?.name}><span>{params.row?.user?.userName}</span></Tooltip>
            <Tooltip title={LogiTarUserTypeInfo.find(e => e.userType === params.row?.user?.userType)?.label}><span style={{paddingLeft: 4}}><UserIcon /></span></Tooltip>
            </>)
        },
        width: 100
    },
    {
        field: 'action',
        headerName: 'Toiminto',
        flex: 1
    }

];

export default function UserActions () {

    const [actions, setActions] = useState<UserAction[]>([]);
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        // Fetch user actions
        LogitarApi.getUserActions(page)
            .then((response) => {
                setActions(response.actions);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [page]);


    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', ...contentBoxStyle }}>
            <DataGridPro 
                columns={cols}
                rows={actions}
                

            />
        </Box>
    )

}