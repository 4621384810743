import Areas from "../../views/Areas";
import CargoTypes from "../../views/CargoTypes";
import Checkup from "../../views/Checkup";
import ClientReportGrid from "../../views/ClientReportGrid";
import Clients from "../../views/Clients";
import DriverReports from "../../views/DriverReports";
import EmissionReports from "../../views/EmissionReports";
import Fuel from "../../views/Fuel";
import FuelReports from "../../views/FuelReports";
import GPSReRunner from "../../views/GPSReRunner";
import Items from "../../views/Items";
import JobReports from "../../views/JobReports";
import MapView from "../../views/MapView";
import Messages from "../../views/Messages";
import News from "../../views/News";
import Orders from "../../views/Orders";
import Planning from "../../views/Planning";
import PlanningTimeline from "../../views/PlanningTimeline";
import ServiceHistory from "../../views/ServiceHistory";
import ServiceMonitor from "../../views/ServiceMonitor";
import SubcontractorReports from "../../views/SubcontractorReports";
import Training from "../../views/Training";
import Users from "../../views/Users";
import Vechicles from "../../views/Vehicles";
import WeekOrders from "../../views/WeekOrders";
import { PermissionTemplate, ReadPermissions } from "../Permissions";

/**
 * @type {{[key: string]: {header: string, component: JSX.Element, path: string, perms: BigInt}[]}}
 */
export const planningRoutes = {
    "Toimisto": [ 
        {
            header: "Ajetut/Tarkastus",
            component: <Checkup />,
            path: "/checkup",
            perms: ReadPermissions.PERM_GETJOBS
        }, {
            header: "Kartta",
            component: <MapView />,
            path: "/mapview",
            perms: ReadPermissions.PERM_GETPLANNING
        }, {
            header: "Polttoaine",
            component: <Fuel />,
            path: "/fuel",
            perms: ReadPermissions.PERM_GETVEHICLES
        }
    ],
    "Ajojärjestely": [
        {
            header: "Suunnittelu staattinen",
            component: <Planning />,
            path: "/planning",
            perms: ReadPermissions.PERM_GETPLANNING
        }, {
            header: "Suunnittelu dynaaminen",
            component: <PlanningTimeline />,
            path: "/timeline",
            perms: ReadPermissions.PERM_GETPLANNING
        }, {
            header: "Tilaukset",
            component: <Orders />,
            path: "/orders",
            perms: ReadPermissions.PERM_GETORDERS
        }, {
            header: "Viikkotilaukset",
            component: <WeekOrders />,
            path: "/weekorders",
            perms: ReadPermissions.PERM_GETORDERS
        }
    ],
    "Hallinta": [
        {
            header: "Asiakkaat",
            component: <Clients />,
            path: "/clients",
            perms: ReadPermissions.PERM_GETCLIENTS
        }, {
            header: "Alueet",
            component: <Areas />,
            path: "/areas",
            perms: ReadPermissions.PERM_GETCLIENTS
        }, {
            header: "Nimikkeet",
            component: <Items />,
            path: "/items",
            perms: ReadPermissions.PERM_GETITEMS
        },{
            header: "Kalusto",
            component: <Vechicles />,
            path: "/vehicles",
            perms: ReadPermissions.PERM_GETVEHICLES
        }, {
            header: "Tuotteet",
            component: <CargoTypes />,
            path: "/cargotypes",
            perms: ReadPermissions.PERM_GETCARGOS
        }
    ],
    "Tiedotteet": [
        {
            header: "Tiedotelista",
            component: <News />,
            path: "/news",
            perms: ReadPermissions.PERM_GETNEWS
        },
        {
            header: "Viestit",
            component: <Messages />,
            path: "/messages",
            perms: ReadPermissions.PERM_GETMESSAGES
        },
        {
            header: "Koulutukset",
            component: <Training />,
            path: "/training",
            perms: ReadPermissions.PERM_GETNEWS
        }
    ],
    "Raportit": [
        {
            header: "Polttoaineraportit",
            component: <FuelReports />,
            path: "/fuelreports",
            perms: ReadPermissions.PERM_GETVEHICLES | ReadPermissions.PERM_GETJOBS
        },
        {
            header: "Asiakasraportit",
            component: <ClientReportGrid />,
            path: "/clientreportgrid",
            perms: ReadPermissions.PERM_GETCLIENTS | ReadPermissions.PERM_GETJOBS | ReadPermissions.PERM_GETPLANNING
        },
        {
            header: "Alihankintaraportit",
            component: <SubcontractorReports />,
            path: "/subcontractorreports",
            perms: PermissionTemplate.PERM_DEF_JOBS
        },
        {
            header: "Kuljettajaraportit",
            component: <DriverReports />,
            path: "/driverreports",
            perms: ReadPermissions.PERM_GETUSERS | ReadPermissions.PERM_GETJOBS | ReadPermissions.PERM_GETPLANNING
        },
        {
            header: "Kuormaraportit",
            component: <JobReports />,
            path: "/jobreports",
            perms: ReadPermissions.PERM_GETUSERS | ReadPermissions.PERM_GETJOBS | ReadPermissions.PERM_GETPLANNING
        },
        {
            header: "Päästöraportit",
            component: <EmissionReports />,
            path: "/emissionreports",
            perms: ReadPermissions.PERM_GETVEHICLES | ReadPermissions.PERM_GETCLIENTS | ReadPermissions.PERM_GETJOBS
        }
    ],
    "Huolto": [
        {
            header: "Huoltotapahtuma",
            component: <ServiceHistory />,
            path: "/servicehistory",
            perms: ReadPermissions.PERM_GETSERVICEHISTORY
        },
        {
            header: "Huoltoseuranta",
            component: <ServiceMonitor />,
            path: "/servicemonitor",
            perms: ReadPermissions.PERM_GETSERVICEHISTORY
        }
    ],
    "_hidden": [
        {
            header: "GPS lastaus- ja purkuaikojen uudelleenlaskenta",
            component: <GPSReRunner />,
            path: "/gpsrerunner",
            perms: PermissionTemplate.PERM_DEF_JOBS
        }
    ]
}
