import { useEffect, useState } from "react"
import LogitarApi from "../api/LogitarApi";
import { Box } from "@mui/system";
import CustomToolbar, { toolbarSlotProps } from "../components/CustomToolbar";
import { localeText } from "../misc/LocaleText";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { contentBoxStyle } from '../styles/styles'
import AreaEdit from "../components/AreaEdit";
import { DataGridPro } from '@mui/x-data-grid-pro'
import PersistentDataGridPro from "../components/persistent/PersistentDataGridPro";
import { useUserConfig } from "../misc/UserConfig";

/**
 * @type {import("@mui/x-data-grid").GridColDef[]}
 */
const columns = [
    { field: 'id', headerName: 'ID', flex: 1, maxWidth: 60 },
    { field: 'name', headerName: 'Nimi', flex: 1 },
    { field: 'address', headerName: 'Osoite', flex: 1 },
    { field: 'coordinates', headerName: 'Koordinaatit', flex: 1 },
    { field: 'radius', headerName: 'Säde', flex: 1, valueFormatter: (v) => { return v.value ? (v.value + " m") : "" }, maxWidth: 100 }
]

export default function Areas() {
    /** @type {{config: import("../components/persistent/PersistentDataGridPro").UserConfigDataGrid}} */
    const userConfig = useUserConfig('areas');

    const searchParams = new URLSearchParams(window.location.search);

    const [showDockingType, setShowDockingType] = useState(/** @type {'loading'|'unloading'} */(
        userConfig.config.viewType === 'loading' ? 'loading' : 
        userConfig.config.viewType === 'unloading' ? 'unloading' : 'loading'));
    const [loadingAreas, setLoadingAreas] = useState([]);
    const [unloadingAreas, setUnloadingAreas] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedArea, setSelectedArea] = useState(null);

    const [count, setCount] = useState(0);

    useEffect(() => {
        setFetching(true);
        LogitarApi.getAreas({ extent: 'list' })
            .then((resp) => {
                if (resp.status) {
                    setLoadingAreas(resp.areas.filter(e => e.type === 0));
                    setUnloadingAreas(resp.areas.filter(e => e.type === 1));
                    if(count === 0) {
                        // Only on first load
                        if(searchParams.has('id')) {
                            const id = parseInt(searchParams.get('id'));
                            const area = resp.areas.find(e => e.id === id);
                            console.log(id, area)
                            if(area) {
                                setSelectedArea(area);
                                setShowPopup(true);
                                setShowDockingType(area.type === 0 ? 'loading' : 'unloading');
                            }
                        }
                    }
                }
                else {
                    console.error("Error while fetching areas");
                }
            })
            .catch((err) => {
                console.error("Error while fetching areas: " + err);
            })
            .finally(() => {
                setFetching(false);
            })
    }, [count]);

    return <Box
        sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', ...contentBoxStyle }}
    >
        <ToggleButtonGroup
            onChange={(e, v) => { if (v !== null) {
                setShowDockingType(v);
                userConfig.config = { ...userConfig.config, viewType: v }
            }}}
            value={showDockingType}
            exclusive
            sx={{ mb: 1 }}
        >
            <ToggleButton value="loading">Lastaus</ToggleButton>
            <ToggleButton value="unloading">Purku</ToggleButton>

        </ToggleButtonGroup>
        <PersistentDataGridPro
            userConfig={userConfig}
            rows={showDockingType === 'loading' ? loadingAreas : unloadingAreas}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick={true}
            width="100%"
            onRowClick={(params) => { setShowPopup(true); setSelectedArea(params.row) }}
            slots={{ toolbar: () => <CustomToolbar userConfig={userConfig} text={"Uusi lastaus- tai purkualue"} onClick={() => { setSelectedArea(null); setShowPopup(true); }} hidePrinting></CustomToolbar> }}
            localeText={localeText}
            loading={fetching}
            cursor="pointer"
            density="compact"
            slotProps={toolbarSlotProps}
        />
        {
            showPopup &&
            <AreaEdit
                onClose={() => { setShowPopup(false); setCount(count + 1) }}
                info={selectedArea}
            />
        }
    </Box>
}