import { Autocomplete, Box, FormGroup, MenuItem, TextField, Typography, Select, InputLabel, FormControl, CircularProgress, Table, TableHead, TableRow, TableBody, TableCell, Button } from "@mui/material";
import PopupScreen from "./PopupScreen";
import LogitarApi from "../api/LogitarApi";
import { useEffect, useState } from "react";
import { ServiceMonitorType } from "../api/LogitarApiTypes";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LogiTarUserType } from "../misc/User";
import { enqueueSnackbar } from "notistack";

/***
 * @typedef {{
 *  vehicle: number,
 *  onClose: () => void,
 * }} ServiceMonitorPopProps 
 */

/**
 * 
 * @param {ServiceMonitorPopProps} props 
 * @returns 
 */
export default function ServiceMonitorPop(props) {

    const [vehicle, setVehicle] = useState(null);

    const [inspection, setInspection] = useState({
        inspectionDate: new Date(),
        inspectionByDate: new Date()
    });

    useEffect(() => {
        LogitarApi.getVehicles({ id: props.vehicle, extent: 'all' })
            .then((resp) => {
                if (resp.vehicles.length > 0) {
                    setVehicle(resp.vehicles[0]);
                    setInspection({
                        inspectionDate: new Date(resp.vehicles[0].inspectionDate),
                        inspectionByDate: new Date(resp.vehicles[0].inspectionByDate)
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar("Kaluston tietojen haku epäonnistui", { variant: 'error' });
            })
    }, [props.vehicle]);

    const saveInspection = () => {

        LogitarApi.setVehicle({
            id: vehicle.id,
            inspectionDate: format(inspection.inspectionDate, "yyyy-MM-dd"),
            inspectionByDate: format(inspection.inspectionByDate, "yyyy-MM-dd")
        })
            .then((resp) => {
                enqueueSnackbar("Katsastus tallennettu");
                props.onClose();
            })
            .catch((err) => {
                enqueueSnackbar("Katsastuksen tallennus epäonnistui", { variant: 'error' });
            })
    }

    const setLastInspectionToToday = () => {
        const ndate = new Date();
        const nextDate = new Date(ndate);
        nextDate.setFullYear(nextDate.getFullYear() + 1);
        setInspection({ inspectionDate: ndate, inspectionByDate: nextDate });
    }

    const setNextInspection = (years) => {
        if (!isNaN(inspection.inspectionDate)) {
            const ndate = new Date(inspection.inspectionDate);
            ndate.setFullYear(ndate.getFullYear() + years);
            setInspection({ ...inspection, inspectionByDate: ndate });
        }
    }

    return <PopupScreen
        title={"Aseta katsastustiedot"}
        onClose={props.onClose}
        onSave={saveInspection}
        draggable
        staticButtons
    >
        <Box sx={{
            p: 1
        }}>
            {
                // Wait until vehicle data loaded
                !vehicle &&
                <CircularProgress size={64} />
            }

            {
                !!vehicle &&
                <FormControl
                    sx={{
                        '& .MuiTextField-root': { mr: 1, mb: 1 },
                        '& .MuiAutocomplete-root': { mr: 1, mb: 1 },
                        '& .DayButton': {
                            lineHeight: 1,
                            m: 1,
                            p: 1
                        }
                    }}
                >
                    <FormGroup row>
                        <Table sx={{
                            "& .MuiTableCell-root": {
                                textAlign: 'center'
                            },
                            mb: 1
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Kalusto</TableCell>
                                    <TableCell>Rek. nro</TableCell>
                                    <TableCell>Selite</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{vehicle.id}</TableCell>
                                    <TableCell>{vehicle.licenseNumber}</TableCell>
                                    <TableCell>{vehicle.name}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                        <FormGroup row>
                            <DatePicker
                                value={inspection.inspectionDate}
                                onChange={(v) => setInspection({ ...inspection, inspectionDate: v })}
                                label="Viime katsastus"
                                sx={{ m: 0.5 }}
                            />
                            <Button
                                variant="contained"
                                className="DayButton"
                                onClick={() => setLastInspectionToToday()}
                            >
                                Tänään
                            </Button>
                        </FormGroup>
                        <FormGroup row>
                            <DatePicker
                                value={inspection.inspectionByDate}
                                onChange={(v) => setInspection({ ...inspection, inspectionByDate: v })}
                                label="Katsastus viimeistään"
                                sx={{ m: 0.5 }}
                            />
                            <Button
                                variant="contained"
                                className="DayButton"
                                onClick={() => setNextInspection(1)}
                            >
                                +1v
                            </Button>
                            <Button
                                variant="contained"
                                className="DayButton"
                                onClick={() => setNextInspection(2)}
                            >
                                +2v
                            </Button>
                        </FormGroup>
                    </LocalizationProvider>
                </FormControl>
            }
        </Box >

    </PopupScreen >
}