import { createTheme } from "@mui/material"
import { defaultBodyClasses, defaultBodyClassesDark } from "./DefaultTheme"
import {} from '@mui/x-data-grid-pro/themeAugmentation'

export const metsanenTheme = createTheme({

    palette: {
        background: {
            default: '#f0f0f0',
            paper: '#f0f0f0'
        },
        primary: {
            main: '#13181e',
            contrastText: '#EEE',
        },
        secondary: {
            main: '#13181e',
            contrastText: '#EEE'
        },
        text: {
            primary: '#333333'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        background: 'inherit'
                    }
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    ...defaultBodyClasses
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                textColorPrimary: {
                    "&.Mui-selected": {
                        color: "#000",
                    }
                }
            }
        },
    },
    sidebar: {
        background: {
            backgroundColor: 'f0f0f0'
        }
    }
})

export const metsanenThemeDark = createTheme({
    ...metsanenTheme,
    palette: {
        mode: "dark",
        background: {
            default: '#171a1e',
            paper: '#171a1e'
        },
        primary: {
            main: '#292e33',
            contrastText: '#eee'
        },
        text: {
            primary: '#c0c0c0'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        background: 'inherit'
                    }
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                sx: {
                    background: 'background.default'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                sx: {
                    background: 'background.default'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    ...defaultBodyClassesDark
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: {
                    color: '#c0c0c0',
                    "&.Mui-checked": {
                        color: '#c0c0c0'
                    },
                    "&.MuiCheckbox-indeterminate": {
                        color: '#c0c0c0'
                    },
                    "&.Mui-disabled": {
                        color: "#777"
                    },
                    "&:hover": {
                        backgroundColor: "#FFFFFF14"
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                colorPrimary: {
                    color: '#c0c0c0',
                    "&.Mui-checked": {
                        color: '#c0c0c0'
                    },
                    "&.Mui-disabled": {
                        color: '#777'
                    },
                    "&:hover": {
                        backgroundColor: "#FFFFFF14"
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#DDD'
                },
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: "#384047"
                    }
                },
                outlinedPrimary: {
                    borderColor: "#DDD7",
                    "&:hover": {
                        borderColor: "#DDDD",
                        backgroundColor: "#DDDDDD09"
                    }
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    color: '#DDD'
                },
                primary: {
                    "&:hover": {
                        backgroundColor: "#384047"
                    }
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: "#AAA",
                }
            },
            variants: [
                {
                    props: { color: "primary" },
                    style: {
                        "&.Mui-selected": {
                            color: "#FFF",
                            backgroundColor: "#5555",
                            "&:hover": {
                                backgroundColor: "#6666"
                            }
                        }
                    }
                }
            ]
        },
        MuiTab: {
            styleOverrides: {
                textColorPrimary: {
                    "&:hover": {
                        backgroundColor: "#FFF1"
                    },
                    "&.Mui-selected": {
                        color: "#FFF",
                        backgroundColor: "#4447"
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: "#777"
                },
                colorPrimary: {
                    "&.Mui-checked": {
                        color: "#DDD",
                        "&:hover": {
                            backgroundColor: "#DDD2"
                        }
                    }
                },
                track: {
                    opacity: 0.1,
                    backgroundColor: "#FFF",
                    ".Mui-checked.Mui-checked + &": {
                        opacity: 0.4,
                        backgroundColor: "#FFF",
                    }
                },
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: {
                    color: "#CCC9"
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#CCC",
                    textDecorationColor: "#CCC5",
                    ":hover": {
                        color: "#EEE",
                        textDecorationColor: "#EEEB"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#999"
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: "#DDD"
                    }
                }
            }
        },
        MuiIconButton: {
            variants: [
                {
                    props: { color: "primary" },
                    style: {
                        color: "#DDD"
                    }
                }
            ]
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .MuiTableCell-root": {
                        color: "#DDD"
                    }
                }
            }
        }
    },
    sidebar: {
        background: {
            backgroundColor: 'f0f0f0'
        }
    }
})