import { createTheme } from "@mui/material"
import { defaultBodyClasses, defaultBodyClassesDark } from "./DefaultTheme"

export const softrainTheme = createTheme({

    palette: {
        background: {
            default: '#f0f0f0',
            paper: '#f0f0f0'
        },
        primary: {
            main: '#5fc2da',
            contrastText: '#EEE',
        },
        secondary: {
            main: '#5fc2da',
            contrastText: '#EEE'
        },
        text: {
            primary: '#333333'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        background: 'inherit'
                    }
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    ...defaultBodyClasses
                }
            }
        }
    },
    sidebar: {
        background: {
            backgroundColor: 'f0f0f0'
        }
    }
})

export const softrainThemeDark = createTheme({
    ...softrainTheme,
    palette: {
        mode: "dark",
        background: {
            default: '#081d21',
            paper: '#081d21'
        },
        primary: {
            main: '#5fc2da',
            dark: '#215c6b',
            contrastText: '#eee'
        },
        text: {
            primary: '#c0c0c0'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        background: 'background.default'
                    }
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                sx: {
                    background: 'background.default'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                sx: {
                    background: 'background.default'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    ...defaultBodyClassesDark
                }
            }
        }
    },
    sidebar: {
        background: {
            backgroundColor: 'f0f0f0'
        }
    }
})