import { Box } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useRef, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import CargoPop from '../components/CargoPop'
import CustomToolbar, { toolbarSlotProps } from '../components/CustomToolbar'
import { localeText } from '../misc/LocaleText'
import { contentBoxStyle } from '../styles/styles'
import PersistentDataGridPro from '../components/persistent/PersistentDataGridPro'
import { useUserConfig } from '../misc/UserConfig'

export default function CargoTypes() {
    const userConfig = useUserConfig('cargoTypes');

    const [rows, setRows] = useState([])
    const [count, setCount] = useState(1)
    const [postCargo, setPost] = useState({})
    const [popup, setPopup] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    // const clientRef = useRef([]) // Removed for now. Using params.row.clientNames instead.
    const itemRef = useRef([])
    const predictRef = useRef({ divisions: [], mainGroups: [] })
    const detailOptions = useRef([])

    const apiRef = useGridApiRef();

    const columns = useMemo(() => [
        { field: 'id', headerName: 'id', width: 20 },
        { field: "mainGroup", headerName: "Pääryhmä", flex: 1 },
        { field: "division", headerName: "Jae", flex: 1 },
        { field: "code", headerName: "Koodi", flex: 1 },
        { field: 'name', headerName: 'Laji', flex: 2 },
        {
            field: 'clients', headerName: "Asiakaskoodit", valueGetter: (params) => {
                let cs = ""
                if (params?.row?.clientNames) {
                    params.row.clientNames.forEach(name => cs += `${name}, `);
                }
                // clientRef.current.filter((c) => params.value.includes(c.id)).forEach((client) => cs += `${client.name}, `)
                return cs.substring(0, cs.length - 2)
            },
            flex: 2
        }
    ], []);

    useEffect(() => {
        Promise.all([
            () => LogitarApi.getCargoTypes(),
            () => LogitarApi.getClients({ extent: "list" }),
            () => LogitarApi.getItems({ extent: "list" }),
        ].map(c => c())).then((r) => {
            const cargoRows = r[0].cargoTypes.map(type => ({
                ...type, clientNames: r[1].clients.filter(c => type.clients.includes(c.id)).map(c => c.name)
            }));
            setRows(cargoRows);
            console.log(r)

            const divisions = new Set();
            const mainGroups = new Set();

            r[0].cargoTypes.forEach((ct, i) => {
                divisions.add(ct.division)
                mainGroups.add(ct.mainGroup)
            })                
            
            const sortedDivisions = [...divisions].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

            // clientRef.current = r[1].clients
            itemRef.current = r[2].items
            predictRef.current = { "divisions": sortedDivisions, "mainGroups": [...mainGroups] }

            const _details = new Set();

            r[2].items.forEach((item) => {
                if (item.detailDesc)
                    _details.add(String(item.detailDesc).trim())
            })

            detailOptions.current = Array.from(_details)

            console.log(predictRef.current)
        })
            .catch((err) => { console.error(err); setRows([]) })

    }, [count])

    useEffect(() => {
        apiRef.current.updateRows(rows);
        apiRef.current.autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
        })
    }, [rows])

    const handleCargoPop = (par) => {
        setPost({ ...par.row })
        setPopup(true)
    }

    return (
        <Box sx={{
            ...contentBoxStyle, '& .MuiDataGrid-row': {
                cursor: "pointer"
            },
            display: 'flex', flexDirection: 'column', height: '100%', width: '100%'
        }}>
            <PersistentDataGridPro
                userConfig={userConfig}
                apiRef={apiRef}
                columns={columns}
                rows={rows}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: () =>
                        <CustomToolbar text={"Uusi Tuote"} userConfig={userConfig} hidePrinting
                            onClick={() => {
                                setPost({ name: "", clients: [], division: "", mainGroup: "", code: "" });
                                setPopup(true);
                            }}>
                        </CustomToolbar>
                }}
                localeText={localeText}
                loading={!rows[0]}
                density='compact'
                onRowClick={(params) => { handleCargoPop(params) }}
                autosizeOnMount
                slotProps={toolbarSlotProps}
            />

            {popup &&
                <CargoPop
                    popupOpen={(open, update) => {
                        setPopup(open);
                        if (update) setCount(count + 1)
                    }}
                    cargo={postCargo}
                    update={setCount}
                    divisions={predictRef.current.divisions}
                    mainGroups={predictRef.current.mainGroups}
                    items={itemRef.current}
                    detailOptions={detailOptions.current}
                />
            }
        </Box>
    )
}