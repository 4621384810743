import { Brightness4, Brightness7, KeyboardDoubleArrowRight, Logout, Person, Wysiwyg } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Button, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React from "react";
import LogitarApi from "../api/LogitarApi";
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import { drawerWidth, headerBarHeight } from "../styles/styles";
import { logitarTheme, logitarThemeMode } from "../config/Themes";
import { Link } from "react-router-dom";

export default function HeaderBar(props) {

    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const [vehicles, setVehicles] = React.useState([]);
    const [selectedVehicle, setSelectedVehicle] = React.useState(LogiTarUser.current.vehicle);
    const profileOpen = Boolean(profileAnchorEl);
    const handleProfileClick = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };

    React.useEffect(() => {
        let _subId = null;
        LogitarApi.getVehicles().then(msg => {
            setVehicles(msg.vehicles)

            _subId = LogiTarUser.current.addChangeListener(() => {
                // On user changed
                if (LogiTarUser.current.vehicle && selectedVehicle !== LogiTarUser.current.vehicle.id) {
                    setSelectedVehicle(LogiTarUser.current.vehicle);
                }
            })

        }).catch(err => {

        })

        setSelectedVehicle(LogiTarUser.current.vehicle);

        return () => {
            LogiTarUser.current.removeChangeListener(_subId);
        }

    }, [])

    const clearUserCurrentVehicle = () => {
        LogitarApi.setUser({id: LogiTarUser.current.info.id, currentVehicle: null}).catch(err => console.error(err));
    }

    return (
        <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${props.sidebarExpanded ? drawerWidth : 0}px)`,
                height: headerBarHeight,
                backgroundColor: logitarThemeMode === 'light' ? undefined : 'primary.dark',
                color: 'primary.contrastText'
            }}
            enableColorOnDark
        >
            <Toolbar
                variant="dense"
            >
                {
                    !props.sidebarExpanded &&
                    <Button
                        sx={{ color: 'white', ml: -4 }}
                        onClick={() => {
                            props.onExpand();
                        }}
                    >
                        <KeyboardDoubleArrowRight
                            color="white"
                        />
                    </Button>
                }
                <Typography variant="h6" noWrap component="div" sx={{ width: '100%' }}>
                    {props.header}
                </Typography>
                {
                    LogiTarUser.current.isDriver() && selectedVehicle &&
                    <Typography sx={{ mr: 3, whiteSpace: 'nowrap' }}>{selectedVehicle.id}: {selectedVehicle.licenseNumber}</Typography>
                }
                <Button
                    sx={{ flexShrink: 0, color: "white", textTransform: "none" }}
                    id="basic-button"
                    aria-controls={profileOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={profileOpen ? 'true' : undefined}
                    onClick={handleProfileClick}
                >
                    {LogiTarUser.current.info.name}
                    <MenuIcon sx={{ ml: 1 }} />
                </Button>

                <Menu
                    id="basic-menu"
                    anchorEl={profileAnchorEl}
                    open={profileOpen}
                    onClose={handleProfileClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        sx: {
                            backgroundColor: logitarTheme.palette.background.default
                        }
                    }}
                >
                    <MenuItem onClick={() => { props.onProfileOpen(); handleProfileClose(); }}><Person /> Profiili</MenuItem>
                    <MenuItem onClick={() => { props.onChangeThemeMode(); }}>{logitarThemeMode === 'light' ? <Brightness7 /> : <Brightness4 />} Teema</MenuItem>
                    {
                        LogiTarUser.current.info.userType === LogiTarUserType.ADMIN &&
                        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={"/serverstatus"}>
                            <MenuItem onClick={() => { }}>
                                <Wysiwyg />
                                System Status
                            </MenuItem>
                        </Link>
                    }
                    <MenuItem onClick={() => { handleProfileClose(); clearUserCurrentVehicle(); LogitarApi.logout(); }}><Logout /> Kirjaudu ulos</MenuItem>
                </Menu>

            </Toolbar>
        </AppBar>
    )
}