export const drawerWidth = 240
export const drawerWidthCollapsed = 64;

export const headerBarHeight = 48;


export const contentBoxStyle = {
    pt: 1.5
}

export const popBottomStyle = {
    background: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
    zIndex: 10000,
    bottom: 0,
    position: "absolute"
}

export const headerStyle = {
    
}

export const checkboxWithLabelStyle = {
    borderRadius: 1, p: 1, mb: 1, flexDirection: "row-reverse", userSelect: "none", WebkitUserSelect: "none", msUserSelect: "none"
}

export const exitIconStyle = { 
    color: "#4055bf", 
    cursor: "pointer",
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 10,
    pointerEvents: "auto",
}

export const exitIconStyleDark = { 
    color: "#9fabdf",
}

export const exitContainerStyle = { display: "flex", flexDirection: "row", justifyContent: "space-between" }