import VehicleView from "../../driver/VehicleView";
import Messages from "../../views/Messages";
import News from "../../views/News";
import ServiceHistory from "../../views/ServiceHistory";
import ServiceMonitor from "../../views/ServiceMonitor";
import Training from "../../views/Training";
import { WorkHourReport } from "../../views/WorkHourReport";
import WorkHours from "../../views/WorkHours";
import { PermissionTemplate, ReadPermissions } from "../Permissions";
import MapView from "../../views/MapView";

/**
 * @type {{[key: string]: {header: string, component: JSX.Element, path: string, perms: BigInt}[]}}
 */

export const driverRoutes = {
    "Auto": [
        {
            header: "Toteutus",
            component: <VehicleView />,
            path: "/vehicleview",
            perms: PermissionTemplate.PERM_DEF_JOBS
        },
        {
            header: "Kartta",
            component: <MapView />,
            path: "/mapview",
            perms: ReadPermissions.PERM_GETPLANNING
        }
    ],
    "Tuntikirjaus": [
        {
            header: "Täytä",
            component: <WorkHours />,
            path: "/workhoursfill",
            perms: PermissionTemplate.PERM_DEF_JOBS
        },
        {
            header: "Raportti",
            component: <WorkHourReport />,
            path: "/workhourreport",
            perms: PermissionTemplate.PERM_DEF_JOBS
        }
    ],
    "Tiedotteet": [
        {
            header: "Tiedotelista",
            component: <News />,
            path: "/news",
            perms: ReadPermissions.PERM_GETNEWS
        },
        {
            header: "Viestit",
            component: <Messages />,
            path: "/messages",
            perms: ReadPermissions.PERM_GETMESSAGES
        },
        {
            header: "Koulutukset",
            component: <Training />,
            path: "/training",
            perms: ReadPermissions.PERM_GETNEWS
        }
    ],
    "Huolto": [
        {
            header: "Huoltotapahtuma",
            component: <ServiceHistory />,
            path: "/servicehistory",
            perms: ReadPermissions.PERM_GETVEHICLES
        },
        {
            header: "Huoltoseuranta",
            component: <ServiceMonitor />,
            path: "/servicemonitor",
            perms: ReadPermissions.PERM_GETVEHICLES
        }
    ]
}
