import ApiDoc from "../../views/api-doc/ApiDoc";
import { ReadPermissions } from "../Permissions";

/**
 * @type {{[key: string]: {header: string, component: JSX.Element, path: string, perms: BigInt}[]}}
 */
export const developerRoutes = {
    "Tiedotteet": [
        {
            header: "API dokumentaatio",
            component: <ApiDoc />,
            path: "/apidoc",
            perms: ReadPermissions.PERM_GETAPIDOC
        }
    ]
}