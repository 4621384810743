import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { isValidElement } from "react";

/**
 * @typedef {{
 *  title: string,
 *  content?: string | React.JSX.Element
 *  zIndex?: number,
 *  onClose?: (accepted: boolean) => void,
 *  cancellable?: boolean,
 *  stopContextMenuPropagate?: boolean,
 * }} AlertPopProps
 */

/**
 * 
 * @param {AlertPopProps} props 
 */
export default function AlertPop(props) {

    return <Dialog
        open
        onContextMenu={props.stopContextMenuPropagate ? (e) => e.stopPropagation() : undefined}
    >
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            {
                (props.content && (typeof props.content === 'string')) &&
                <DialogContentText>
                    {props.content}
                </DialogContentText>
            }
            {
                (props.content && isValidElement(props.content)) &&
                <>
                    {props.content}
                </>
            }
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
            {
                props.cancellable &&
                <Button variant="contained" onClick={() => { if(props.onClose) props.onClose(false) }}>Peruuta</Button>
            }
            <Button variant="contained" onClick={() => { if(props.onClose) props.onClose(true) }}>OK</Button>
        </DialogActions>
    </Dialog>

}