import { Box, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { format } from "date-fns";
import { DriverReportRow } from "../../api/LogitarApiTypes";

export type ScoreData = {
    anticipation: number;
    braking: number;
    coasting: number;
};

export type DriverScoreData = {
    id: number;
    total: number;
    scoreData: ScoreData;
    avgFuelConsumption: number;
    avgSpeed: number;
    totalTime: number;
    totalDistance: number;
    co2Emissions: number;
    dateTime: string;
    User: number;
};

type DriverScoreGraphProps = {
    data: DriverReportRow
}

export default function DriverScoreGraph(props: DriverScoreGraphProps) {

    const data : DriverScoreData[] = (props.data.scores || []).sort((a, b) => { return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime() });

    let dates: Date[] = [];
    let total: (number | null)[] = [];
    let anticipation: (number | null)[] = [];
    let braking: (number | null)[] = [];
    let coasting: (number | null)[] = [];

    data.forEach((e, i) => {
        dates.push(new Date(e.dateTime));

        total.push(e.total);
        anticipation.push(e.scoreData.anticipation);
        braking.push(e.scoreData.braking);
        coasting.push(e.scoreData.coasting);
    })

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {
                data.length > 0 ?
                    <LineChart
                        
                        xAxis={[{
                            id: 'dates',
                            data: dates,
                            label: 'Päivämäärä',
                            valueFormatter: (v: Date) => {
                                return format(new Date(v), 'dd.MM')
                            },
                            scaleType: 'band',

                        }]}
                        series={[
                            {
                                id: 'total',
                                label: 'Yhteispisteet',
                                data: total,
                            },
                            {
                                id: 'anticipation',
                                label: 'Ennakointi',
                                data: anticipation,
                            },
                            {
                                id: 'braking',
                                label: 'Jarrutus',
                                data: braking,
                            },
                            {
                                id: 'coasting',
                                label: 'Rullaus',
                                data: coasting,
                            },
                        ]}
                        height={200}
                        margin={{ top: 50, bottom: 50 }}
                    />
                    :
                    <Typography variant="caption" sx={{mt: 5}} >Ei dataa</Typography>
            }

        </Box>
    );

}