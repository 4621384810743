import { useEffect } from "react";
import LogiTarUser from "../misc/User";
import LogitarEventProvider from "../api/LogitarEventProvider";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Box, Button, Divider, Typography } from "@mui/material";

export enum VehicleSeverity {
    OTHER = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3
}

export type VehicleAlert = {
    VIN: string,
    vehicle: number,
    licenseNumber: string,
    severity: VehicleSeverity,
    dateTime: Date,
    driverId?: string | null,
    user?: number | null,
    driverName?: string | null,
    alertType: string,
    eventType?: string
    alertData: any
}

type VehicleAlertTranslationType = {
    [key: string]: {
        name: string,
        description?: ((alertData: any, eventType?: string) => string) | string,
    }
}

export const VehicleAlertTranslation : VehicleAlertTranslationType = {
    "DRIVING_WITHOUT_BEING_LOGGED_IN": {
        name: "Ajo ilman kirjautumista"
    },
    "PTO": {
        name: "Voimanotto",
        description: (ad, et) => {
            return `Tila: ${et === "ENGAGED" ? "päällä" : "pois päältä"}`;
        }
    },
    "OVERSPEED": {
        name: "Ylinopeus",
        description: (ad, et) => {
            return `${et === "STARTED" ? "Alkoi" : "Päättyi"}, nopeus: ${ad.wheelBasedSpeed ? (ad.wheelBasedSpeed as number).toFixed(1) : "-"} km/h`;
        }
    },
    "IDLING": {
        name: "Tyhjäkäynti",
        description: (ad, et) => {
            return `${et === "STARTED" ? "Alkoi" : "Päättyi"}`;
        }
    },
    "CATALYST": {
        name: "Katalysaattori",
        description: (ad, et) => {
            return `Katalysaattoripolttoaineen taso: ${ad.catalystFuelLevel ? (ad.catalystFuelLevel as number).toFixed(1) : "-"}% ` + 
                    `${ad.catalystFuelLevelChangePercent ? ("(" + (ad.catalystFuelLevelChangePercent >= 0 ? "+" : "-") + (ad.catalystFuelLevelChangePercent as number).toFixed(1) + "%)") : ""}`;
        }
    },
    "TPM": {
        name: "Rengaspaine",
        description: (ad, et) => {
            return `${ad.source === "TRUCK" ? "Auton" : "Perävaunun"} rengaspaine: (akseli: ${ad.axleId}, sijainti: ${ad.location})`;
        }
    },
    "TTM": {
        name: "Rengaslämpötila",
        description: (ad, et) => {
            return `${ad.source === "TRUCK" ? "Auton" : "Perävaunun"} rengaslämpötila: (akseli: ${ad.axleId}, sijainti: ${ad.location})`;
        }
    },
    "DAS": {
        name: "Kuljettajan tukijärjestelmä",
        description: (ad, et) => {
            return `${et === "ENABLED" ? "Päällä" : "Pois päältä"}`;
        }
    },
    "ESP": {
        name: "Vakautusjärjestelmä",
        description: (ad, et) => {
            return `(vaatii selvennystä)`;
        }
    },
    "AEBS": {
        name: "Lukkiutumattomat jarrut",
        description: (ad, et) => {
            return `${et === "ENABLED" ? "Päällä" : "Pois päältä"}`;
        }
    },
    "HARSH": {
        name: "Voimakas",
        description: (ad, et) => {
            return `${et === "ACCELERATION" ? "Kiihdytys" : "Jarrutus"}`;
        }
    },
    "LKS": {
        name: "Kaistavahti",
        description: (ad, et) => {
            return `${et === "ENABLED" ? "Päällä" : "Pois päältä"} (muuttaja: ${ad.actorType})`;
        }
    },
    "FUEL": {
        name: "Polttoaine",
        description: (ad, et) => {
            return `Polttoainetaso: ${ad.fuelLevel1 ? (ad.fuelLevel1 as number).toFixed(1) : "-"}% ` + 
                    `${ad.fuelLevel1ChangePercent ? ("(" + (ad.fuelLevel1ChangePercent >= 0 ? "+" : "-") + (ad.fuelLevel1ChangePercent as number).toFixed(1) + "%)") : ""}`;
        }
    },
    // AKUT: TODO
    "BATTERY_CHARGE_CHANGE": {
        name: "Akun lataustilan muutos"
    },
    "BATTERY_HD": {
        name: "Akun korkea purkaus"
    },
    "BATTERY_USAGE": {
        name: "Akun energiankulutus"
    },
}

export const GetVehicleAlertTranslation = (alert: VehicleAlert) => {
    const alertTranslation = VehicleAlertTranslation[alert.alertType];

    const alertDescriptionObject = alertTranslation ? alertTranslation.description : null;
    const alertName = alertTranslation ? alertTranslation.name : alert.alertType;
    
    let alertDescription : string | null;

    if(typeof alertDescriptionObject === "function") {
        alertDescription = alertDescriptionObject(alert.alertData, alert.eventType);
    }
    else if(typeof alertDescriptionObject === "string") {
        alertDescription = alertDescriptionObject;
    }
    else {
        alertDescription = null;
    }

    return { name: alertName, description: alertDescription };
}

const VehicleAlertSnackbarContent = (props: { alert: VehicleAlert }) => {
    const { alert } = props;
    // Show vehicle on the top left small font, user on the top right small font
    // Show alert message in the middle aligned to left normal font

    const alertTranslation = VehicleAlertTranslation[alert.alertType];

    const alertDescriptionObject = alertTranslation ? alertTranslation.description : null;
    const alertName = alertTranslation ? alertTranslation.name : alert.alertType;
    let alertDescription : string | null;

    if(typeof alertDescriptionObject === "function") {
        alertDescription = alertDescriptionObject(alert.alertData, alert.eventType);
    }
    else if(typeof alertDescriptionObject === "string") {
        alertDescription = alertDescriptionObject;
    }
    else {
        alertDescription = null;
    }

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box 
                sx={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Typography variant={"caption"} sx={{ fontSize: '0.8em' }}>{alert.vehicle}:{alert.licenseNumber}</Typography>
                {
                    alert.user &&
                    <Typography variant={"caption"} sx={{ fontSize: '0.8em', marginLeft: 'auto' }}>{alert.user}:{alert.driverName}</Typography>
                }
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Box>{alertName}</Box>
                <Divider sx={{mx: 1}} orientation="vertical" flexItem />
                {alertDescription && <Box>{alertDescription}</Box>}
            </Box>
        </Box>
    </Box>
}


export default function VehicleAlertNotifier () {

    useEffect(() => {
        /*
        // Test snackbar
        enqueueSnackbar(<VehicleAlertSnackbarContent 
                            alert={{
                                VIN: "1234567890",
                                vehicle: 1,
                                licenseNumber: "ABC-123",
                                severity: VehicleSeverity.HIGH,
                                dateTime: new Date(),
                                driverId: null,
                                user: 1,
                                driverName: "Test Driver",
                                alertType: "PTO",
                                eventType: "ENGAGED",
                                alertData: {}
                            }}
                        />, 
        {
            variant: 'error',
            persist: true,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            action: (key) => (
                <Button
                    sx={{
                        color: 'primary.contrastText',
                    }}
                    onClick={() => {
                        closeSnackbar(key);
                    }}
                >
                    OK
                </Button>
            )
        });
        */
    }, [])
    
    if(!LogiTarUser.current || !LogiTarUser.current.isManagement()) {
        return null;
    }

    return <LogitarEventProvider 
        subscriptions={['vehiclealert']}
        onEvent={(source, data: VehicleAlert[], id) => {
            for(const alert of data) {
                // If VehicleAlertTranslation does not have a translation for the alert type, skip
                if(!VehicleAlertTranslation[alert.alertType]) 
                    continue;

                if(alert.severity <= VehicleSeverity.LOW) 
                    continue;

                enqueueSnackbar(<VehicleAlertSnackbarContent alert={alert} />, {
                    variant: alert.severity === VehicleSeverity.HIGH ? 'error' : alert.severity === VehicleSeverity.MEDIUM ? 'warning' : 'info',
                    persist: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    action: (key) => (
                        <Button
                            sx={{
                                color: 'primary.contrastText',
                            }}
                            onClick={() => {
                                closeSnackbar(key);
                            }}
                        >
                            OK
                        </Button>
                    )
                });
            }
        }}
    />
}