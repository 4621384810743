import PopupScreen from "./PopupScreen";
import { Box, FormGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";
import { useEffect, useState } from "react";
import LogiTarUser from "../misc/User";
import { format } from "date-fns";
import { GetServiceMonitorRow, ServiceMonitorType } from "../api/LogitarApiTypes";
import LogitarApi from "../api/LogitarApi";
import { logitarVehicleTypes } from "../views/Vehicles";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export type ServiceMonitorApproveForm = {
    dateTime: Date | string,
    km: number,
    details: string,
    detailsTrailer: string,
    servicedByExt: string
}

export type ServiceMonitorApprovePopProps = {
    row: any,
    onApprove: (row: any, info: ServiceMonitorApproveForm) => void,
    onClose: () => void
}

function ErrorMessagesPopup(props: {
    content: string; open: boolean; onClose: () => void; 
}) {
    const formattedMessage = props.content.split('\n').map((item, i) => <p key={i}>{item}</p>);
    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle sx={{textAlign: 'center', fontWeight: 'bold', mt: 2}}>
                    Huollon hyväksymisen peruminen
                    <IconButton
                        aria-label="close"
                        onClick={props.onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{textAlign: 'center', width: 'max-content'}}>
                    {formattedMessage}
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.onClose} variant="contained">Sulje</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function ServiceMonitorApprovePop (props: ServiceMonitorApprovePopProps) {

    const [approveInfo, setApproveInfo] = useState<ServiceMonitorApproveForm>({
        dateTime: new Date(),
        km: props.row.km,
        details: props.row.vehicleType === 2 ? "Rasvaus" : props.row.type,
        detailsTrailer: "Rasvaus",
        servicedByExt: (props.row.orderInfo && props.row.orderInfo.place) ? props.row.orderInfo.place : null
    });

    const [trailer, setTrailer] = useState<any>(null);

    const [errorMessage, setErrorMessage] = useState("")

    const onSaveApproval = () => {
        const oi = {...approveInfo};

        oi.dateTime = format(oi.dateTime as Date, "yyyy-MM-dd HH:mm:ss");

        console.log("Kuittauksen data ", props.row)
        let date1 = null;
        let date2 = null;
        let date3 = null;
        let date4 = null;

        if (props.row.orderInfo && props.row.orderInfo.dateTime) {
            date1 = new Date(props.row.orderInfo.dateTime);
            date3 = format(date1, "dd.MM.yyyy HH:mm")
        }
        if (props.row.orderInfo2 && props.row.orderInfo2.dateTime) {
            date2 = new Date(props.row.orderInfo2.dateTime);
            date4 = format(date2, "dd.MM.yyyy HH:mm")
        }
        
        if ((date1 && date2) && (date1 > date2)) {
            if (props.row.serviceType == 1 ) {
                setErrorMessage("Kuittaa välihuolto ennen öljynvaihtoa" + "\nVälihuolto " + date4 + "\n Öljynvaihto " + date3)
            }
            else if  (props.row.serviceType == 2 ) {
                setErrorMessage("Kuittaa öljynvaihto ennen välihuoltoa" + "\nÖljynvaihto " + date4 + "\n Välihuolto " + date3)
            }
        } else {
            props.onApprove(props.row, oi);
        }
    }

    useEffect(() => {
        if(props.row.serviceType == ServiceMonitorType.INTERIM_SERVICE || props.row.serviceType == ServiceMonitorType.OIL_CHANGE) {
            LogitarApi.getVehicles()
                .then((res) => {
                    // Find trailer
                    const trailer = res.vehicles.find((v: any) => v.tractorUnit === props.row.vehicleId);
                    if(trailer) {
                        setTrailer(trailer);
                    }
                    else {
                        console.log("No trailer found");
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
        }
    }, [])
 
    return <PopupScreen
        title="Hyväksy huolto"
        staticButtons
        draggable
        onClose={props.onClose}
        onSave={() => onSaveApproval()}
    >
        <Box
            sx={{
                ".MuiTextField-root": {
                    m: 0.5,
                    flex: 1
                }
            }}
        >
            <FormGroup row>
                <Table sx={{m: 0.5}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Kalusto</TableCell>
                            <TableCell>Tyyppi</TableCell>
                            <TableCell>Suorittaja</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{props.row.vehicle}</TableCell>
                            <TableCell>{props.row.type}</TableCell>
                            <TableCell>{LogiTarUser.current?.info.name || '-'}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </FormGroup>
            <FormGroup row>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                    <DateTimePicker 
                        value={approveInfo.dateTime}
                        onChange={(v) => {
                            if(v)
                                setApproveInfo({...approveInfo, dateTime: v})
                        }}
                        label="Päivämäärä ja aika"
                        sx={{m: 0.5}}
                    />
                </LocalizationProvider>
                <TextField 
                    label="Kilometrit"
                    type="number"
                    value={approveInfo.km}
                    onChange={(e) => setApproveInfo({...approveInfo, km: Number(e.target.value)})}
                />
            </FormGroup>
            <FormGroup row>
                <TextField 
                    label="Suoritettu huolto"
                    value={approveInfo.details}
                    multiline
                    rows={4}
                    onChange={(e) => setApproveInfo({...approveInfo, details: e.target.value})}
                />
            </FormGroup>
            {
                ((props.row.serviceType == ServiceMonitorType.OIL_CHANGE || props.row.serviceType == ServiceMonitorType.INTERIM_SERVICE) && !!trailer) &&
                <FormGroup row>
                    <TextField 
                        label={"Perävaunulle suoritettu huolto " + (trailer ? ` (${trailer.licenseNumber})` : "")}
                        value={approveInfo.detailsTrailer}
                        multiline
                        rows={4}
                        onChange={(e) => setApproveInfo({...approveInfo, detailsTrailer: e.target.value})}
                    />
                </FormGroup>
            }
            <FormGroup row>
                <TextField 
                    label="Ulk. huollon suorittaja"
                    value={approveInfo.servicedByExt}
                    onChange={(e) => setApproveInfo({...approveInfo, servicedByExt: e.target.value})}
                />
            </FormGroup>

            {(errorMessage && errorMessage.length > 0) && (
                        <ErrorMessagesPopup
                            content={errorMessage}
                            open={true}
                            onClose={() => {
                                setErrorMessage("")
                            }}      
                        />
                    )}
           
        </Box> 

    </PopupScreen>
}