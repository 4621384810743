
import { Box, Button, FormGroup, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { format } from 'date-fns'
import { useContext, useEffect, useState, useRef } from 'react'
import LogitarApi from '../api/LogitarApi'
import PopupScreen from './PopupScreen'
import { useSnackbar } from 'notistack'

const trainingPopStyle = {
    pb: 0, display: "flex", flexDirection: "column",
}

export default function TrainingPop(props) {

    const [training, setTraining] = useState({ ...props.training })
    const [file, setFile] = useState((props.training.fileID) ? {
        id: props.training.fileID, name: props.training.fileName,
        MIME: props.training.MIME, size: props.training.size,
        data: props.training.data
    } : {})
    const [postTraining, setPostTraining] = useState((!props.new) ? { id: props.training.id, dateTime: props.training.dateTime } : { ...props.training })
    const [fileState, setFileState] = useState(false)
    const { enqueueSnackbar, closeSnackbar} = useSnackbar()

    const theme = useTheme();
    const mdBreakPoint = useMediaQuery(theme.breakpoints.up("md"));

    const handleChange = (event) => {
        setTraining(prev => ({ ...prev, [event.target.name]: event.target.value }))
        setPostTraining(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    //Wrap functions here to reduce Button clutter
    const handleUpdate = () => {
        props.onClick(false)

        LogitarApi.setTraining({ ...postTraining, dateTime: format(new Date(postTraining.dateTime), "yyyy-MM-dd HH:mm:ss") }).then((result) => {
            let snackInfo = "Koulutus '" + training.header + "' tallennettu"

            //Ei ainakaan toistaiseksi käytössä
            // let latestid = result.latestId
            // if (latestid) {
            //     let training = ({...postTraining, 'id':latestid })
            //     LogitarApi.insertTrainingNotification(training).then((result) => {
            //         // props.rowUpdate(props.count + 1)
            //     })
            // }

            //If file set, update after file set, if not then just update because waiting is not necessary
            if (fileState) {
                LogitarApi.setFile({ ...file, training: props.new ? result.latestId : postTraining.id }, "training").then((result) => {

                    props.rowUpdate(props.count + 1)
                })
                    .catch((err) => console.error(err))
            } else {
                props.rowUpdate(props.count + 1)
            }

            enqueueSnackbar(snackInfo)
        })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(`Koulutuksen ${training.header} tallennus epäonnistui`, {variant: "error"})
                
            })


    }


    //base64 encode file, so it can be sent to server or shown in page
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }


    const onFileChange = (event) => {
        getBase64(event.target.files[0]).then((result) => {
            let fileData = result.split(",")[1]
            setFile(prev => ({
                ...prev,
                name: event.target.files[0].name,
                MIME: event.target.files[0].type,
                size: event.target.files[0].size,
                data: fileData
            }))
            setFileState(true)
        })
    }

    //Removes photo from the item
    const removeFile = () => {
        setFileState(true)
        setFile({ ...file, name: null, MIME: null, size: null, data: null })
        fileInput.current.value = "";
    }

    const fileInput = useRef()

    if (props.admin) {
        return (
            <PopupScreen
                title={"Koulutus"}
                draggable
                onClose={() => props.onClick(false)}
                onSave={() => handleUpdate()}
                staticButtons
            >
                <div>

                    <Box sx={{
                        ...trainingPopStyle, '& .MuiTextField-root': {
                            mb: 1.5,
                            mt: 1
                        },
                        '& .MuiButton-root': {
                            mb: 1
                        }, '& .MuiTypograpgy-root': { mb: 0 }
                    }}>

                        <TextField label="Otsikko" onChange={handleChange} name="header" value={training.header}></TextField>
                        <TextField label="Pvm" disabled name="dateTime" value={format(new Date(training.dateTime), "yyyy.MM.dd HH:mm")}></TextField>
                        <TextField label="Linkki" onChange={handleChange} name="link" value={training.link}></TextField>
                        <TextField label="Kuvaus" onChange={handleChange} name="description" sx={{ width: "100%" }} multiline rows={17} value={training.description}></TextField>
                        <div>
                            <FormGroup row >
                                <Button onClick={() => fileInput.current.click()} sx={{ mr: 1 }} variant="contained" >Valitse Tiedosto</Button>
                                <input
                                    name="Liitetiedosto"
                                    ref={fileInput}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={onFileChange}
                                />
                                <Button variant="contained" onClick={() => removeFile()}>Poista tiedosto</Button>

                                <div>
                                    <Typography sx={{ color: (file.MIME) ? (theme.palette.mode === 'light') ? "blue" : "lightblue" : "text.primary", mt: 1, ml: 5, cursor: (file.MIME) ? "pointer" : "normal", ":hover": (file.MIME) ? {textDecoration: "underline"} : {textDecoration: "none"}}}
                                        onClick={(file.MIME) ? (event) => { if (fileState == true) { props.openFile(event, file.id, file) } else { props.openFile(event, file.id) } } : () => { }}>
                                        {file.MIME ? file.name : "Ei tiedostoa"}
                                    </Typography>
                                </div>
                            </FormGroup>
                        </div>
                    </Box>
                </div>
            </PopupScreen>
        )
    }

    return (
        <PopupScreen
            title={props.training.header}
            draggable
            onClose={() => props.onClick(false)}

        >
            <div style={{ width: mdBreakPoint ? 600 : "100%", maxWidth: 600 }}>
                <Box>

                </Box>
                <Box sx={{
                    ...trainingPopStyle, '& .MuiTypography-root': {
                        mb: 2,
                        mt: 1,
                        wordWrap: "break-word",
                    }
                }}>
                    <Typography >{props.training.dateTime}</Typography>
                    <Paper elevation={5} sx={{ display: "block", p: 1, mb: 3, width: "100%", maxHeight: "50vh", overflowY: "auto" }}>
                        <Typography sx={{ whiteSpace: "pre-line" }}>{props.training.description}</Typography>

                    </Paper>
                    <Typography sx={{ color: (file.name) ? (theme.palette.mode === 'light') ? "blue" : "lightblue" : "text.primary", mt: 1, cursor: (file.name) ? "pointer" : "normal", ":hover": (file.name) ? {textDecoration: "underline"} : {textDecoration: "none"}}}
                        onClick={(file.id) ? (event) => { props.openFile(event, file.id) } : () => { }}>
                        {file.name ? file.name : "Ei tiedostoa"}</Typography>
                </Box>
            </div>

        </PopupScreen>
    )
}