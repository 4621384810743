import { Autocomplete, Button, Checkbox, IconButton, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Add } from "@mui/icons-material";

type ItemTalenomVariantTableProps = {
    value: { name: string, product: string, isDefault: boolean }[];
    products: { productNumber: string, productName: string }[];
    onChange: (value: { name: string, product: string, isDefault: boolean }[]) => void;
};

export default function ItemTalenomVariantTable(props: ItemTalenomVariantTableProps) {

    const [variants, setVariants] = useState(props.value);
    const [newVariant, setNewVariant] = useState({ name: '', product: props.products.length > 0 ? props.products[0].productNumber : '', isDefault: false });
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    const [productNumberList, setProductNumberList] = useState<{ productNumber: string, productName: string }[]>(props.products);
    const [selectedProductNumber, setSelectedProductNumber] = useState<{ id: string, label: string }>(
        props.products.length > 0 ? { id: props.products[0].productNumber, label: props.products[0].productName } : { id: '', label: '' }
    );

    useEffect(() => {
        setVariants(props.value);
    }, [props.value]);

    useEffect(() => {
        setProductNumberList(props.products);
        if(newVariant.product === '' && props.products.length > 0) {
            setSelectedProductNumber({ id: props.products[0].productNumber, label: props.products[0].productName });
        }
    }, [props.products]);

    const handleAddVariant = () => {
        if (newVariant.name && newVariant.product) {
            const updatedVariants = [...variants, newVariant];
            if(newVariant.isDefault) {
                // Set all other variants isDefault to false
                updatedVariants.forEach((variant, index) => {
                    if(index !== updatedVariants.length - 1) {
                        variant.isDefault = false;
                    }
                });
            }
            setVariants(updatedVariants);
            props.onChange(updatedVariants);
            setNewVariant({ name: '', product: props.products.length > 0 ? props.products[0].productNumber : '', isDefault: false });
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const { value } = e.target;
        setNewVariant({ ...newVariant, [field]: value });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewVariant({ ...newVariant, isDefault: e.target.checked });
    };

    const handleEdit = (index: number) => {
        setEditingIndex(index);
        setNewVariant(variants[index]);
    };

    const handleSaveEdit = () => {
        const updatedVariants = variants.map((variant, index) =>
            index === editingIndex ? newVariant : variant
        );
        if(newVariant.isDefault) {
            updatedVariants.forEach((variant, index) => {
                if(index !== editingIndex) {
                    variant.isDefault = false;
                }
            });
        }
        setVariants(updatedVariants);
        props.onChange(updatedVariants);
        setEditingIndex(null);
        setNewVariant({ name: '', product: props.products.length > 0 ? props.products[0].productNumber : '', isDefault: false });
    };

    const handleDelete = (index: number) => {
        const updatedVariants = variants.filter((_, i) => i !== index);
        setVariants(updatedVariants);
        props.onChange(updatedVariants);
    };

    return (
        <TableContainer component={Paper} style={{ maxWidth: 'auto', margin: 'auto' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Laji</TableCell>
                        <TableCell>Tuotenumero</TableCell>
                        <TableCell>Oletus</TableCell>
                        <TableCell>Toiminnot</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {variants.map((variant, index) => {
                        const product = productNumberList.find(e => e.productNumber === variant.product);
                        return (<TableRow key={index}>
                            {editingIndex === index ? (
                                <>
                                    <TableCell>
                                        <TextField
                                            value={newVariant.name}
                                            onChange={(e) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>, 'name')}
                                            placeholder="Laji"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            disablePortal
                                            size="small"
                                            options={productNumberList.map(e => { return { id: e.productNumber, label: e.productName } })}
                                            renderInput={(params) => <TextField {...params} label="Tuotenumero" />}
                                            getOptionLabel={(option) => `${option.id} - ${option.label}`}
                                            sx={{ flex: 1, width: 200 }}
                                            value={selectedProductNumber}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onChange={(e, v) => {
                                                if (v) {
                                                    setSelectedProductNumber(v);
                                                    setNewVariant({ ...newVariant, product: v!.id });
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Radio
                                            checked={newVariant.isDefault}
                                            onChange={handleCheckboxChange}
                                            inputProps={{ 'aria-label': 'Oletus' }}
                                            onClick={(e) => {
                                                if (newVariant.isDefault) {
                                                    setNewVariant({ ...newVariant, isDefault: false });
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={handleSaveEdit}>
                                            <SaveIcon />
                                        </IconButton>
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell>{variant.name}</TableCell>
                                    <TableCell>{product ? (product.productNumber + " - " + product.productName) : (variant.product)}</TableCell>
                                    <TableCell>
                                        <Radio checked={variant.isDefault} disabled />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(index)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                        )
                    })}
                    {
                        editingIndex === null &&
                        <TableRow>
                            <TableCell>
                                <TextField
                                    value={newVariant.name}
                                    onChange={(e) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>, 'name')}
                                    placeholder="Laji"
                                    size="small"
                                />
                            </TableCell>
                            <TableCell>
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    size="small"
                                    options={productNumberList.map(e => { return { id: e.productNumber, label: e.productName } })}
                                    renderInput={(params) => <TextField {...params} label="Tuotenumero" />}
                                    getOptionLabel={(option) => `${option.id} - ${option.label}`}
                                    sx={{ flex: 1, width: 200 }}
                                    value={selectedProductNumber}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(e, v) => {
                                        setSelectedProductNumber(v);
                                        setNewVariant({ ...newVariant, product: v!.id });
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Radio
                                    checked={newVariant.isDefault}
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    inputProps={{ 'aria-label': 'Oletus' }}
                                    onClick={(e) => {
                                        if (newVariant.isDefault) {
                                            setNewVariant({ ...newVariant, isDefault: false });
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={handleAddVariant}>
                                    <SaveIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    }
                    {
                        editingIndex != null &&
                        <TableRow>
                            <TableCell size="small">
                                <TextField size="small" disabled placeholder="Laji"></TextField>
                            </TableCell>
                            <TableCell size="small">
                                <TextField size="small" disabled placeholder="Tuotenumero"></TextField>
                            </TableCell>
                            <TableCell size="small">
                                <Radio size="small" disabled></Radio>
                            </TableCell>
                            <TableCell size="small">
                                <IconButton disabled>
                                    <SaveIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
