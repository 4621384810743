import VehicleView from "../../driver/VehicleView";
import Messages from "../../views/Messages";
import News from "../../views/News";
import SubcontractorReports from "../../views/SubcontractorReports";
import { PermissionTemplate, ReadPermissions } from "../Permissions";

/**
 * @type {{[key: string]: {header: string, component: JSX.Element, path: string, perms: BigInt}[]}}
 */
export const contractorRoutes = {
    "Auto": [
        {                
            header: "Toteutus",
            component: <VehicleView />,
            path: "/vehicleview",
            perms: PermissionTemplate.PERM_DEF_JOBS
        }
    ],
    "Raportit": [
        {
            header: "Alihankintaraportit",
            component: <SubcontractorReports />,
            path: "/subcontractorreports",
            perms: PermissionTemplate.PERM_DEF_JOBS
        }
    ],
    "Tiedotteet": [
        {
            header: "Tiedotelista",
            component: <News />,
            path: "/news",
            perms: ReadPermissions.PERM_GETNEWS
        },
        {
            header: "Viestit",
            component: <Messages />,
            path: "/messages",
            perms: ReadPermissions.PERM_GETMESSAGES
        },
    ]
}
