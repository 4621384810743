import { Theme } from "@emotion/react"
import { Box, Button, Card, SxProps, TextField, Tooltip, Typography } from "@mui/material"
import { DataGridPro, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridTreeNodeWithRender } from "@mui/x-data-grid-pro"
import CustomToolbar from "./CustomToolbar"
import { Clear, Delete, Download, FileUpload, Undo } from "@mui/icons-material"
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react"
import FilePopup from "./FilePopup"
import LogitarApi from "../api/LogitarApi"


export type LogitarFile = {
    id: number | string,
    name: string,
    data?: string,
    MIME: string,
    size: number,
    ">del"?: boolean
}

type FileManagerProps = {
    title?: string[],
    disableEditing?: boolean,
    accept?: string[],
    files?: LogitarFile[],
    sx?: SxProps<Theme>,
    onChange?: (files: LogitarFile[]) => void
}
//base64 encode file, so it can be sent to server or shown in page
export const getBase64 = (file: File): Promise<string | null> => {
    return new Promise<string | null>((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result as string)
        }
        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

let fileTempIdCounter = 0;

export default function FileManager(props: FileManagerProps) {

    const fileInput = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<LogitarFile[]>([]);
    const [selectedFile, setSelectedFile] = useState<LogitarFile | null>(null);

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: 'Tiedosto', flex: 4,
            renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {

                if (!params.row.data) {
                    return (
                        <>
                            {
                                params.row[">del"] ?
                                    <s>
                                        <span className="table-cell-trucate">{params.row.name}</span>
                                    </s> :
                                    <span className="table-cell-trucate">{params.row.name}</span>
                            }
                        </>
                    );

                }

                return (
                    <Tooltip
                        title={<img src={`data:${params.row.MIME};base64,${params.row.data}`} alt={params.row.name} style={{ width: 200 }} />}
                    >
                        {
                            params.row[">del"] ?
                                <s>
                                    <span className="table-cell-trucate">{params.row.name}</span>
                                </s> :
                                <span className="table-cell-trucate">{params.row.name}</span>

                        }

                    </Tooltip>
                )
            }
        },
        {
            field: 'actions', type: 'actions', headerName: 'Toiminnot',
            getActions: (params: GridRowParams) => {
                if(props.disableEditing) {
                    return [
                        <GridActionsCellItem
                            icon={<Download />}
                            onClick={() => {
                                LogitarApi.downloadFile(params.row.id as number);
                            }}
                            label="Lataa"
                            sx={{ display: params.row.id.toString().startsWith("NEW-") ? 'none' : undefined }}
                        />
                    ]
                }
                if (params.row[">del"]) {
                    return [
                        <GridActionsCellItem icon={<Undo />} onClick={() => removeFile(params.id, true)} label="Peruuta" />
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<Download />}
                        onClick={() => {
                            LogitarApi.downloadFile(params.row.id as number);
                        }}
                        label="Lataa"
                        sx={{ display: params.row.id.toString().startsWith("NEW-") ? 'none' : undefined }}
                    />,
                    <GridActionsCellItem icon={<Delete />} onClick={() => removeFile(params.id)} label="Delete" />,
                ]
            }
        }
    ]

    useEffect(() => {
        if (props.files)
            setFiles(props.files);
    }, [props.files]);

    const removeFile = (id: number | string, undo?: boolean) => {
        let nFiles = [...files];

        if (undo) {
            const file = nFiles.find((f) => f.id === id);
            if (file) {
                file[">del"] = false;
            }
            setFiles(nFiles);
            if (props.onChange) {
                props.onChange(nFiles);
            }
            return;
        }

        // If file is new, just remove it from the list
        if (id.toString().startsWith("NEW-")) {
            nFiles = files.filter((f) => f.id !== id);
        }
        else {
            // If file is not new, mark it as removed
            const file = nFiles.find((f) => f.id === id);
            if (file) {
                file[">del"] = true;
            }
        }
        setFiles(nFiles);
        if (props.onChange) {
            props.onChange(nFiles);
        }
    }

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0)
            return;

        getBase64(event.target.files[0]).then((result) => {
            // Check to make tsc happy
            if (!result || !event.target.files || event.target.files.length === 0)
                return;

            let fileData = result.split(",")[1];

            const nFiles = [...files];
            nFiles.push({
                id: "NEW-" + fileTempIdCounter++,
                name: event.target.files[0].name,
                MIME: event.target.files[0].type,
                size: event.target.files[0].size,
                data: fileData
            });

            setFiles(nFiles);

            if (props.onChange) {
                props.onChange(nFiles);
            }

            if (fileInput.current) {
                fileInput.current.value = "";
            }
        })
    }

    const onOpenFile = (file: LogitarFile) => {
        setSelectedFile(file);
    }

    return (
        <Card
            sx={{ display: 'flex', flexDirection: 'column', p: 1, ...props.sx }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h6">
                        {props.title && props.title.length > 0 ? props.title: "Tiedostot"}
                    </Typography>
                </Box>
                {
                    !props.disableEditing &&
                    <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
                        {/**file things */}
                        <input
                            name="file"
                            ref={fileInput}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={onFileChange}
                        />
                        <Button
                            onClick={(e) => {
                                if (fileInput.current)
                                    fileInput.current.click();

                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            variant="contained"
                            sx={{ mb: 1 }}
                        >
                            <FileUpload />
                        </Button>
                    </Box>
                }
            </Box>
            <DataGridPro
                columns={columns}
                rows={files}
                hideFooter
                sx={{ width: '100%' }}
                onRowClick={(params) => onOpenFile(params.row as LogitarFile)}
                columnHeaderHeight={40}
                rowHeight={40}
            />
            {
                selectedFile &&
                <FilePopup
                    file={selectedFile || undefined}
                    onClose={() => setSelectedFile(null)}

                />
            }
        </Card>
    )

}