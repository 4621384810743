import { Box, Button, FormGroup, TextField } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { fi } from "date-fns/locale"

const buttonStyle = {
    height: 40,
    p: 0,
    fontSize: 24,
    fontWeight: "bold",
    minWidth: 60,
}

/**
 * @brief DatePick component for reducing code 
 * @param {} dateSet Set date hook function
 * @param {Date} date date hook var
 * @param {} buttonSet Arrow button pressed function
 * @param {Object{from: Date, to: Date} | Date} timeframe Timeframe if wanted, if not set then only one datepicker returned
 * @param {} customInput Set custom renderInput
 * @param rest rest of the parameters go to the parent box element
 * @returns 
 */
export function DatePick({ dateSet, date, buttonSet = null, customInput = false, disabled = false, height = undefined, ...rest }) {


    const datepstyle = {
        '& .MuiFormControl-root ': {
            height: height ? height : 35,
        },
        '& .MuiOutlinedInput-root': {
            height: height ? height : 35,
        },
        '& .MuiButton-root': {
            height: height ? height : 35,
        }
    }

    if (buttonSet === null) {
        return (
            <Box {...rest} sx={{...datepstyle, ...rest.sx}}>
                <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        inputFormat='dd.MM.yyyy'
                        mask={"__.__.____"}
                        value={date}
                        disabled={disabled}
                        onChange={(value) => { dateSet(value) }}
                        renderInput={(params) => <TextField size="small" {...params} sx={{}} />}
                    ></DesktopDatePicker>
                </LocalizationProvider>
            </Box>
        )
    }


    if (typeof date === 'object' && date !== null) {
        return (
            <Box {...rest} sx={{...datepstyle, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', rowGap: 0.5, ...rest.sx}}>
                <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                    <FormGroup row>
                        <Button sx={buttonStyle} size="small" onClick={() => buttonSet(false)} >{"<"}</Button>
                        <DesktopDatePicker
                            inputFormat='dd.MM.yyyy'
                            mask={"__.__.____"}
                            value={date.from}
                            disabled={disabled}
                            label="Alkaen"
                            sx={{width: disabled ? 115 : 145, mr: 0.5}}
                            onChange={(value) => { dateSet(prev => ({ ...prev, from: value })) }}
                            renderInput={(params) => <TextField size="small" sx={{ mr: 0.5 }} {...params} />}
                            disableOpenPicker={disabled}
                        ></DesktopDatePicker>
                    </FormGroup>
                    <FormGroup row>
                        <DesktopDatePicker
                            inputFormat='dd.MM.yyyy'
                            mask={"__.__.____"}
                            value={date.to}
                            disabled={disabled}
                            label="Asti"
                            sx={{width: disabled ? 115 : 145, ml: 0.5}}
                            onChange={(value) => { dateSet(prev => ({ ...prev, to: value })) }}
                            renderInput={(params) => <TextField size="small" sx={{ ml: 0.5 }} {...params} />}
                            disableOpenPicker={disabled}
                        ></DesktopDatePicker>
                        <Button sx={buttonStyle} size="small" onClick={() => buttonSet(true)}>{">"}</Button>
                    </FormGroup>
                </LocalizationProvider>
            </Box>
        )
    }

    return (
        <Box {...rest} sx={{...datepstyle, ...rest.sx}}>
            <Button size="small" sx={buttonStyle} onClick={() => buttonSet(false)}>{"<"}</Button>
            <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    inputFormat='dd.MM.yyyy'
                    mask={"__.__.____"}
                    value={date}
                    disabled={disabled}
                    onChange={(value) => { dateSet(value) }}
                    renderInput={(params) => <TextField size="small" {...params} sx={{}} />}
                    disableOpenPicker={disabled}
                ></DesktopDatePicker>
            </LocalizationProvider>
            <Button size="small" sx={buttonStyle} onClick={() => buttonSet(true)}>{">"}</Button>
        </Box>
    )
}