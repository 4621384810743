// This file contains collections of common formatters

/**
 * @brief Formats a number with a thousand separator
 * @param {string|number} value Value to format
 * @param {string?} separator String to separate with
 * @returns {string} Value with thousands separated with separator
 */
export const thousandsSeparator = (value, separator = " ") => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}