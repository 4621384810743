import { useEffect, useRef, useState } from "react";
import { ArrowLeft, ArrowRight, Download } from "@mui/icons-material"
import { Box, Button, SxProps, Typography, useTheme } from "@mui/material"
import { Document, Page } from 'react-pdf';
import { LogitarFile } from "./FileManager";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'pdfjs-dist/webpack.mjs';

const bottomHeight = 56;

async function download(logitarFile: LogitarFile) {
    let data: string | Blob = "";
    if (logitarFile.data!.startsWith("blob:")) {
        data = await fetch(logitarFile.data!).then(r => r.blob());
    } else {
        data = await fetch(`data:${logitarFile.MIME};base64,${logitarFile.data}`).then(r => r.blob());
    }
    const file = new File([data], logitarFile.name, { type: logitarFile.MIME });
    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export default function PDFViewer(props: { file: LogitarFile, sx?: SxProps, width?: string, height?: string }) {
    const [pageNumber, setPageNumber] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const [width, setWidth] = useState(1000);
    const [height, setHeight] = useState(1000);

    const boxRef = useRef<HTMLElement>(null);

    const theme = useTheme();

    useEffect(() => {
        if (boxRef.current && boxRef.current?.parentElement) {
            setWidth(boxRef.current.parentElement.clientWidth);
            setHeight(boxRef.current.parentElement.clientHeight);
        }
    }, [boxRef.current])

    if (!props.file) return <div />;

    return (
        <Box sx={{ ...props.sx }} ref={boxRef}>
            <Box sx={{ overflow: "auto" }}>
                <Document
                    file={(props.file.data!.startsWith("blob:") || props.file.data!.startsWith("data:")) ?
                        `${props.file.data}` : `data:${props.file.MIME};base64,${props.file.data}`}
                    onLoadSuccess={pdf => setMaxPages(pdf.numPages)}
                    loading={<Box width={props.width} height={props.height} />}
                >
                    <Page
                        width={width}
                        height={height - bottomHeight}
                        canvasBackground={theme.palette.background.paper}
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        scale={0.9}
                    />
                </Document>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, justifyContent: "center", alignItems: "center", width: "100%", my: 1 }}>
                <Button variant="outlined" onClick={() => setPageNumber(prev => Math.max(1, prev - 1))}>
                    <ArrowLeft />
                </Button>
                <Typography>{pageNumber} / {maxPages}</Typography>
                <Button variant="outlined" onClick={() => setPageNumber(prev => Math.min(maxPages, prev + 1))}>
                    <ArrowRight />
                </Button>
                <Button variant="outlined" onClick={() => download(props.file)}>
                    <Download />
                </Button>
            </Box>
        </Box>
    )
}