
/**
 * @typedef {(
*  "ring"
* )} PlaySoundType
*/

/**
 * @typedef {{[key: PlaySoundType]: string}}
 */
const playSoundPaths = {
    ring: "sounds/ring.mp3"
}

/**
 * @typedef {{[key: PlaySoundType]: HTMLAudioElement}}
 */
const currentlyPlaying = {

}

/**
 * 
 * @param {PlaySoundType} sound Sound or path
 * @param {boolean} loop
 */
export function PlaySound (sound, loop) {

    if(currentlyPlaying[sound]) {
        // Do not replay audio
        return;
    }

    /**
     * @type {HTMLAudioElement|null}
     */
    let audio = null;

    if(playSoundPaths[sound]) {
        audio = new Audio(playSoundPaths[sound]);
    }
    else {
        // Find path
        try {
            audio = new Audio(sound);
        }
        catch(e) {
            audio = null;
        }
    }

    if(audio) {
        audio.loop = loop;
        // TODO: Should be using event "canplaythrough" https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement/Audio
        audio.play()
            .then(() => {
            })
            .catch(err => {
                // Catch error. chrome gives error if user does not 
                // interact with the document when the audio plays
            })
            .finally(() => {

            });
        if(loop) {
            currentlyPlaying[sound] = audio;
        } 
        
    }
    else {
        console.warn("Incorrect audio name or path: " + sound);
    }
}

/**
 * 
 * @param {PlaySoundType} sound Sound or path
 */
export function StopSound (sound) {
    if(currentlyPlaying[sound]) {
        currentlyPlaying[sound].pause();
        delete currentlyPlaying[sound];
    }
}