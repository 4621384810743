import { Box, Typography } from "@mui/material";
import { LineChart, useDrawingArea, useYScale } from "@mui/x-charts";
import { format } from "date-fns";
import { DriverReportRow } from "../../api/LogitarApiTypes";

type DriverJobGraphProps = {
    data: DriverReportRow
}

function ColorSwich(params: { threshold: number, color1: string, color2: string, id: string }) {
    const { threshold, color1, color2, id } = params;
    const { top, height, bottom } = useDrawingArea();
    const svgHeight = top + bottom + height;

    const scale = useYScale(); // You can provide the axis Id if you have multiple ones
    const y0 = scale(threshold); // The coordinate of of the origine
    const off = y0 !== undefined ? y0 / svgHeight : 0;

    return (
        <defs>
            <linearGradient
                id={id}
                x1="0"
                x2="0"
                y1="0"
                y2={`${svgHeight}px`}
                gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
            >
                <stop offset={off} stopColor={color1} stopOpacity={1} />
                <stop offset={off} stopColor={color2} stopOpacity={1} />
            </linearGradient>
        </defs>
    );
}

export default function DriverJobGraph(props: DriverJobGraphProps) {

    const data = props.data.jobs.sort((a, b) => { return new Date(a.date).getTime() - new Date(b.date).getTime() });

    let items: string[] = [];
    let jobDeltas: (number | null)[] = [];

    let max = 0;

    data.forEach((e, i) => {
        const gpsLoadStart = e.gpsLoadStart ? new Date(e.gpsLoadStart) : null;
        const gpsLoadEnd = e.gpsLoadEnd ? new Date(e.gpsLoadEnd) : null;
        const gpsUnloadStart = e.gpsUnloadStart ? new Date(e.gpsUnloadStart) : null;
        const gpsUnloadEnd = e.gpsUnloadEnd ? new Date(e.gpsUnloadEnd) : null;
        const totalTime = gpsLoadStart && gpsUnloadEnd ? gpsUnloadEnd.getTime() - gpsLoadStart.getTime() : null;
        const hours = Number(e.item.hours);
        if(totalTime) {
            items.push(`${i}: ${format(new Date(e.date), "dd.MM")} [${e.item.id}]`);
            const delta = totalTime - (hours * 3600000);
            jobDeltas.push(delta);
            if(Math.abs(delta) > max) max = Math.abs(delta);
        }
    })

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {
                props.data.jobs.length > 0 ?
                    <LineChart
                        tooltip={{
                            trigger: 'axis'
                        }}
                        axisHighlight={{
                            x: 'band'
                        }}
                        xAxis={[{ data: items, scaleType: 'point'}]}
                        yAxis={[{
                            id: 'jobDeltas',
                            scaleType: 'linear',
                            valueFormatter: (v: number | null) => {
                                if(v === null) return "Ei dataa";
                                const minutes = Math.floor(v / 60000);
                                const hours = Math.floor(minutes / 60);
                                return `${hours}h ${minutes - (hours * 60)}min`;
                            },
                            min: -max,
                            max: max
                        }]}
                        series={[{ 
                            data: jobDeltas, 
                            showMark: true, 
                            area: true,
                            valueFormatter: (v: number | null) => {
                                if(v === null) return "Ei dataa";
                                const minutes = Math.floor(v / 60000);
                                const hours = Math.floor(minutes / 60);
                                return (v > 0 ? '+' : '') + `${hours}h ${minutes - (hours * 60)}min`;
                            }
                        }]}
                        height={200}
                        margin={{ top: 20, bottom: 30, left: 75 }}
                        sx={{
                            '& .MuiAreaElement-root': {
                                fill: 'url(#swich-color-id-1)',
                            },
                        }}
                    >
                        <ColorSwich
                            color1="#FF3143" // red
                            color2="#11B678" // green
                            threshold={0}
                            id="swich-color-id-1"
                        />
                        <rect x={0} y={0} width={5} height="100%" fill="url(#swich-color-id-1)" />
                    </LineChart>
                    :
                    <Typography variant="caption" sx={{ mt: 5 }} >Ei dataa</Typography>
            }

        </Box>
    );

}