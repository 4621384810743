import { FormGroup, TextField } from "@mui/material";
import PopupScreen from "./PopupScreen";
import { useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { enqueueSnackbar } from "notistack";

type BasicType = {
    id?: number,
    name: string,
}

export default function BasicTypeEdit(props: { value?: BasicType, onClose: (saved: boolean, newId?: number) => void }) {
    const editing = props.value != null;
    const [newBasicType, setNewBasicType] = useState<BasicType>(props.value != null ? props.value : { name: "" });
    const [saveLoading, setSaveLoading] = useState(false);

    const handleSaveBasicType = () => {
        setSaveLoading(true);
        LogitarApi.setBasicTypes(newBasicType).then((r: any) => {
            if (r && r.status) {
                enqueueSnackbar("Tyyppi tallennettu", { variant: "success" });
            }
            props.onClose(true, r.latestID ? r.latestID : newBasicType.id ? newBasicType.id : undefined);
        }).catch(err => {
            console.error(err);
            enqueueSnackbar("Tyypin tallentaminen epäonnistui", { variant: "error" });
        }).finally(() => setSaveLoading(false));
    }

    return (
        <PopupScreen
            draggable
            title={editing ? "Muokkaa tyyppiä" : "Lisää uusi tyyppi"}
            staticButtons
            onClose={() => props.onClose(false)}
            onSave={() => handleSaveBasicType()}
            saveLoading={saveLoading}
        >
            <FormGroup sx={{ mt: 2, mb: 1 }}>
                <TextField label="Nimi" name="name"
                    value={newBasicType.name}
                    onChange={(e) => setNewBasicType(prev => ({ ...prev, name: e.target.value }))} />
            </FormGroup>
        </PopupScreen>
    )
}