import { Box, Button, Fade, TextField, Typography, Autocomplete, Card, Skeleton, useTheme, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import React, { useContext, useEffect, useRef } from 'react'
import { TransitionGroup } from 'react-transition-group';
import LogitarApi from '../api/LogitarApi';
import { Send } from '@mui/icons-material';
import PopupScreen from './PopupScreen';
import { useSnackbar } from 'notistack';
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import LogitarEventProvider from '../api/LogitarEventProvider';
import { PlaySound } from '../misc/SoundPlayer';
import "../styles/animation.css";
import { headerBarHeight } from '../styles/styles';

const chainPopStyle = {
    display: "flex", flexDirection: "column",
    '& .MuiTypography-root': {
        mb: 2,
        wordWrap: "break-word"
    },
    '& .MuiTextField-root': {
        my: 1
    },
    '& .MuiButton-root': {
        mb: 1
    },
    height: "auto",
    maxHeight: `calc(75vh - ${headerBarHeight}px)`,
    maxWidth: "600px",
}

export default function ChainPop(props) {

    const [userMsg, setUserMsg] = React.useState("")
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [chain, setChain] = React.useState(props.admin ? props.chain : { ...props.chain, vehicle: LogiTarUser.current.vehicle.id })
    const [messages, setMessages] = React.useState(props.messages)
    const [user, setUser] = React.useState(LogiTarUser.current.info.id)
    const [vehicles, setVehicles] = React.useState([])
    const [vehicleSelectList, setVehicleSelectList] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const latestSenders = props.admin ? [] : props.messages.filter((j) => j.sender !== LogiTarUser.current.info.id); // not yourself
    const latestSender = props.new ? "" : latestSenders[0] ? latestSenders[0].sender : "Toimisto/Ajoj";
    const newSentMessageId = useRef(-1);
    const messagesToAccept = useRef(false);
    const previousWaitToAccept = useRef(props.waitToAccept);
    const newestMessage = useRef(null);

    const theme = useTheme();
    const mdBreakPoint = useMediaQuery(theme.breakpoints.up("md"));

    const handleMessageList = () => {
        if (userMsg.trim() == "") {

        } else {
            let sentData = formSendingData()
            let tempData = { ...sentData, id: newSentMessageId.current, sent: null, senderName: null, content: null }
            newSentMessageId.current = newSentMessageId.current - 1;
            setMessages((prev) => [tempData, ...prev])
            sendMessage(sentData)
        }
        setUserMsg("")

        props.onAccept(true)
    }

    const onChange = (event) => {
        setChain({ ...chain, [event.target.name]: event.target.value })
    }

    //Forms an message object to be sent
    const formSendingData = () => {
        return { 
            messageChain: chain.id, 
            sent: format(new Date(Date.now()), "yyyy-MM-dd H-mm-ss"), 
            sender: user, 
            content: userMsg
        }
    }


    const sendMessage = data => {

        LogitarApi.setMessage(data).then((result) => {
            console.log(result)
            if (props.rowUpdate) props.rowUpdate();
            enqueueSnackbar("Viesti lähetetty")
        })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar("Viestin lähetys epäonnistui", { variant: "error" })
            })
    }


    //Send chain, and the first message
    const handleUpdate = () => {

        if (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER || LogiTarUser.current.info.userType === LogiTarUserType.CONTRACTOR) {
            let uservehicle = { id: LogiTarUser.current.vehicle.id }
            vehicleSelectList.push(uservehicle)
            if ((!chain.header) || (!chain.content)) {
                enqueueSnackbar("Anna viestin otsikko ja sisältö ", { variant: 'error' });
                return;
            }
        }
        else if ((vehicleSelectList && vehicleSelectList.length == 0) || (!chain.header) || (!chain.content)) {
            enqueueSnackbar("Anna viestin otsikko, sisältö ja valitse kalusto", { variant: 'error' });
            return;
        }

        props.onClick(false)

        if ((vehicleSelectList && vehicleSelectList.length > 0)) {
            vehicleSelectList.forEach((value) => {

                LogitarApi.setChain({ ...chain, startedBy: user, vehicle: value.id }, false,).then((result) => {
                    let snackInfo = "Viestiketju otsikolla: '" + chain.header + "' luotu."

                    enqueueSnackbar(snackInfo)

                    let sendingData = { ...formSendingData(), messageChain: result.latestId, content: chain.content }

                    if (!chain.content) {
                        if (props.rowUpdate) props.rowUpdate();
                    } else {
                        sendMessage(sendingData)
                    }
                })
                    .catch((err) => {
                        console.error(err);
                        enqueueSnackbar("Viestiketju otsikolla: '" + chain.header + "' lisäys epäonnistui.", { variant: "error" })
                    })
            })
        }
    }

    const handleExit = () => {
        props.onClick(false)
        if (props.onClose) props.onClose();
        if (props.rowUpdate) props.rowUpdate();
    }

    const updateChainMessages = (id) => {
        LogitarApi.getChainMessages(id).then(res => {
            if (res.messages.find(msg => (msg.seen === 0 && msg.sender !== LogiTarUser.current.info.id))) {
                newestMessage.current = res.messages[0] ? res.messages[0].id : null;
                if (props.waitToAccept != null) {
                    messagesToAccept.current = true;
                    return;
                }
                if (props.driverMode) PlaySound("ring");
                acceptMessageChain();
            }
            setMessages(res.messages);
        }).catch(err => console.error(err));
    }

    useEffect(() => {
        if (messagesToAccept.current === true && props.waitToAccept !== previousWaitToAccept) {
            previousWaitToAccept.current = props.waitToAccept;
            messagesToAccept.current = false;
            if (props.driverMode) PlaySound("ring");
            acceptMessageChain();
        }
    }, [props.waitToAccept])

    useEffect(() => {
        if (props.new) {
            LogitarApi.getVehicles().then((result) => {
                //only pulling vehicles
                let vehicles = result.vehicles.filter((j) => j.type == 1);
                setVehicles(vehicles.map((value, key) => {
                    return { value: value.id, label: value.licenseNumber }
                }))
            })
                .catch((err) => console.error(err))
        } else {
            acceptMessageChain();
        }
    }, [])

    const acceptMessageChain = () => {
        if (!props.new) {
            //alert("Hyväksytään viestiketju viestiä avattaessa. Käyttäätyyppi: " + LogiTarUser.current.info.userType )
            LogitarApi.acceptChain({ ...chain }, LogiTarUser.current.info.userType).then((result) => {
                let snackInfo = "Viestiketju '" + chain.header + "' hyväksytty"
                // enqueueSnackbar(snackInfo)
            })
        }
    }

	useEffect(() => {
		if (events.length < 1) return;
		events.forEach(event => {
            if ((event.data.type === "sent" || event.data.type === "accepted") && props.chain.id === event.data.chain) {
                updateChainMessages(props.chain.id);
            } else if (event.data.type === "deleted" && props.chain.id === event.data.chain) {
                handleExit();
            }
		});
		setEvents([]);
	}, [events])

    //if creating new message chain
    if (props.new) {
        return (
            <PopupScreen
                title={"Uusi viesti"}
                draggable
                onClose={() => handleExit()}
                onSave={() => handleUpdate()}
                staticButtons
                saveIcon={
                    <>
                        <Send />
                        Lähetä
                    </>
                }
                disableScroll
            >
                <div>
                    <Box sx={{...chainPopStyle, width: mdBreakPoint ? "600px" : "100%"}} onChange={onChange}>

                        <TextField value={chain.header} label="Otsikko" name="header"></TextField>
                        {/* {props.admin &&
                            <TextField value={chain.vehicle}
                                select
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true,
                                }} label="Kalusto" name="vehicle">



                                {vehicles.map((option) => (
                                    <option key={option.value} value={option.value}>
                                         {option.value}: {option.label}
                                    </option>
                                ))}
                             </TextField>
                        } */}

                        {props.admin &&
                            <Autocomplete
                                sx={{
                                    pl: 0,
                                    minWidth: 200
                                }}
                                autoComplete={false}
                                multiple
                                id="vehicle-select"
                                options={vehicles.map((e) => ({ id: e.value, licenseNumber: e.label }))}
                                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                                getOptionLabel={(opt) => opt.id + ": " + opt.licenseNumber}
                                renderInput={(params) => <TextField {...params} label="Kalusto" autoComplete="off" />}
                                value={vehicleSelectList}
                                onChange={(e, v) => {
                                    setVehicleSelectList(v);
                                }}
                            />
                        }

                        <TextField multiline rows={10} label="Sisältö" name="content" value={chain.content}></TextField>
                    </Box>
                </div>
            </PopupScreen>

        )
    }

    return (
        <PopupScreen
            title={chain.header}
            draggable
            onClose={() => handleExit()}
            onSave={() => handleMessageList()}
            staticButtons
            saveIcon={
                <>
                    <Send />
                    Lähetä
                </>
            }
            disableScroll
        >
            <div>
                <Box sx={{ ...chainPopStyle, pr: (messages.length > 5) ? 2 : 0, width: mdBreakPoint ? "600px" : "100%" }}>

                    {/* <Typography>Vastaanottajat: {!chain.vehicle ? "Toimisto/Ajoj" : chain.vehicle}</Typography> */}
                    <Typography>Vastaanottaja: {!props.admin ? latestSender : chain.vehicle}</Typography>
                    <TextField value={userMsg} onChange={(event) => setUserMsg(event.target.value)} label="Kirjoita viesti" multiline rows={4}></TextField>
                    {/* <Button variant="contained" onClick={handleMessageList}><Send>Lähetä</Send></Button> */}

                    <Box sx={{ overflowY: 'auto', height: '55vh' }}>
                        <TransitionGroup>
                            {messages.map((value, key) => (
                                <Fade timeout={{appear: 0, enter: 1000, exit: 0}} key={value.id}>
                                    <Card sx={{ mb: 1, p: 1, borderRadius: 1, position: "relative" }}>
                                        { value.id === newestMessage.current && <Box className="default-orange" sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, animation: "fadeOut 2s ease 0s 1 normal forwards" }}/> }
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography sx={{ fontWeight: "bold" }}>{value.senderName ? value.senderName : <Skeleton width={200}/>}</Typography>
                                            <Typography sx={{ fontWeight: "bold" }}>{value.sent ? format(new Date(value.sent), "dd.MM.yyyy HH:mm") : <Skeleton width={130}/>}</Typography>
                                        </Box>
                                        <Typography sx={{ ml: 1, wordWrap: "break-word", whiteSpace: "pre-line" }}>{value.content ? value.content : <Skeleton/>}</Typography>
                                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                                            {value.sender === LogiTarUser.current.info.id && 
                                                (value.seen === 1 ?
                                                    <Typography style={{ margin: 0 }} sx={{ fontSize: "0.75rem", opacity: 0.75 }}>Vastaanottaja nähnyt</Typography> :
                                                    <Typography style={{ margin: 0 }} sx={{ fontSize: "0.75rem", opacity: value.sent ? 0.75 : 0 }}>Lähetetty</Typography>
                                                )
                                            }
                                        </Box>
                                    </Card>
                                </Fade>
                            ))}
                        </TransitionGroup>
                    </Box>
                </Box>
            </div>
			{ LogiTarUser.current.isDriver() && <LogitarEventProvider subscriptions={["message"]} onEvent={(s, d, i) => setEvents(prev => [...prev, { source: s, data: d, id: i }])}/> }
			{ LogiTarUser.current.isManagement() && <LogitarEventProvider subscriptions={["messageOffice"]} onEvent={(s, d, i) => setEvents(prev => [...prev, { source: s, data: d, id: i }])}/> }
        </PopupScreen>
    )
}