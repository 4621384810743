import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { CustomPopper } from "./InternalFeatures";
import React, { useEffect, useState } from "react";


//TODO: Extend mui autocomplete(?)
export default function ListSelect({
    sx = null,
    getCall = null,
    value = null,
    callParams = {},
    dataHandle,
    customLabel = null,
    externalOptions = null,
    async = false,
    label = null,
    initialValue = null,
    disabled = false,
    loading = null,
    disableClearable = false,
}) {

    const [val, setVal] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([])
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(loading !== null ? loading : true);

    useEffect(() => {

        //expecting LogitarApi call

        if (!isLoading && async) {
            return undefined;
        }

        if (getCall && !externalOptions) {
            setIsLoading(true);
            getCall(callParams).then((result) => {

                if (result.status) {
                    setOptions(result[Object.keys(result)[0]].sort((a, b) => a.id - b.id))
                    setVal(result[Object.keys(result)[0]].find((option) => option.id === value))

                }

            })
                .catch((err) => console.error(err))
                .finally(() => setIsLoading(false))
        }
    }, [])

    useEffect(() => {
        if (loading !== null) setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (!open && async) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (externalOptions) {
            setOptions(externalOptions)
            
        } else {
            setOptions([{}])
        }
    },[externalOptions])

    useEffect(() => {
        if (options.length > 0 && val === null && initialValue) {
            setVal(options.find((v) => v.id === initialValue));
        }
    }, [options, val, initialValue])

    return (
        <Autocomplete

            options={options}
            PopperComponent={CustomPopper}

            componentsProps={{
                paper: {
                    elevation: 10,
                },
            }}

            onOpen={() => {
                if (options && !options.length || options && !options[0].id) {
                    return
                }
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}

            open={open}
            label={label}
            size="small"
            sx={sx || {}}
            onChange={(event, value) => { dataHandle(event, value); setVal(value) }}

            getOptionLabel={customLabel || ((option) => `${option.id}:${option.name}` || "")}

            inputValue={inputValue}
            value={val || null}

            isOptionEqualToValue={initialValue ? (option, v) => option.id === v.id : undefined}
            onInputChange={(event, newInputValue, reason) => {
                
                setInputValue(newInputValue);
                
            }}

            loading={isLoading}

            disabled={disabled}
            disableClearable={disableClearable}

            renderInput={(params) =>
                <TextField
                    {...params}
                    
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={15} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />}
        />
    )
}