import { Add, Delete } from '@mui/icons-material';
import { Box, Fade, Table, Typography, useMediaQuery, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import LogitarApi from '../api/LogitarApi';
import FilePopup, { base64PDFToBlobUrl } from '../components/FilePopup.tsx';
import NewsPop from '../components/NewsPop';
import { TipButton } from '../misc/InternalFeatures';
import LogiTarUser from '../misc/User';
import { contentBoxStyle } from '../styles/styles';

export default function News(props) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [rows, setRows] = useState([])
    const [singleNews, setSingleNews] = useState()
    const [popup, setPopup] = useState(false)
    const admin = LogiTarUser.current.isManagement()
    const [count, setCount] = useState(0)
    const [createNew, setCreateNew] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [file, setFile] = useState({})

    const theme = useTheme();
    const mdBreakPoint = useMediaQuery(theme.breakpoints.up("md"));

    //Popover functions
    const open = Boolean(anchorEl)

    const handleFileClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleFileClose = () => {
        setAnchorEl(null)
        setFile({})
    }
    ///

    let columns = [
        { field: 'header', headerName: 'Otsikko', width: 400 },
        { field: 'file', headerName: 'Tiedosto', width: 250 },
        { field: 'date', headerName: 'Päivämäärä', width: 200 },
    ]

    const handleNewNews = () => {
        setSingleNews({ header: "", content: "", date: new Date(Date.now()), isUsersChecked: true, isContractorsChecked: false })
        setPopup(true)
        setCreateNew(true)
    }

    //Fetch file data for a news
    const getNewsFile = (id) => {
        return new Promise((resolve, reject) => {
            LogitarApi.getFile(id).then((result) => {
                resolve(result.file)
            })
        })
    }

    const handleRemove = (row) => {
        //True parameter is for deleting 
        LogitarApi.setNews(row, true).then((result) => {
            setCount(count + 1)

            enqueueSnackbar("Rivi otsikolla: '" + row.header + "' poistettu")
        })
            .catch(() => enqueueSnackbar("Rivi otsikolla: '" + row.header + "' poisto epäonnistui", { variant: "error" }))
    }

    useEffect(() => {
        //Get all the news (tiedotteet)
        LogitarApi.getNews({ extent: "list" }).then((result) => {
            setRows(result.news)
        })
            .catch((err) => console.error(err))

    }, [count])

    const handlePopupNews = (rowId) => {
        //Fetch news info set to a hook, give it to newspopup, row id is supplied

        LogitarApi.getNews({ id: rowId, extent: "allfile" }).then((result) => {
            if (result.status) {
                let result2 = { ...result.news[0], isUsersChecked: false, isContractorsChecked: false }
                if (result.news[0].isUsersChecked == null) {
                    setSingleNews(result2)
                }
                else {
                    setSingleNews(result.news[0])
                }
                setPopup(true)
                setCreateNew(false)
            }
        })
            .catch((err) => console.error(err))
    }

    const newsStyle = {
        cursor: "pointer",

    }

    const openFile = (event, fileID, file = null) => {
        if (file) {
            setFile(file)
        } else {
            getNewsFile(fileID).then((file) => {
                setFile({
                    ...file,
                    data: file.MIME.includes("text") ? file.data : base64PDFToBlobUrl(file.data)
                });
            })
        }
        handleFileClick(event)
    }

    return (
        <Box sx={{ ...contentBoxStyle, maxHeight: "85vh", overflowY: "auto" }}>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        {columns.map((value, key) => {
                            return <TableCell sx={{ fontWeight: "bold" }} key={key} width={value.width}>{value.headerName}</TableCell>
                        })}
                        {admin &&
                            <TableCell>

                                <TipButton size="small" variant="contained" icon={Add} onClick={() => handleNewNews()} title={"Uusi tiedote"}></TipButton>
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => {
                        return (
                            <Fade timeout={700} in key={row.id}>
                                <TableRow sx={newsStyle} hover>
                                    <TableCell onClick={() => handlePopupNews(row.id)}>{row.header}</TableCell>
                                    <TableCell
                                        sx={{ color: (row.fileName) ? (theme.palette.mode === 'light') ? "blue" : "lightblue" : "text.primary", ":hover": { textDecoration: "underline" } }}
                                        onClick={(row.fileID) ? (event) => { openFile(event, row.fileID) } : () => { handlePopupNews(row.id) }}
                                    >
                                        <Typography>{row.fileName}</Typography>
                                    </TableCell>
                                    <TableCell onClick={() => handlePopupNews(row.id)}>{format(new Date(row.date), "dd.MM.yyyy H:mm")}</TableCell>
                                    {admin &&
                                        <TableCell><TipButton onClick={() => handleRemove(row)} icon={Delete} variant="contained" title="Poista"></TipButton></TableCell>
                                    }
                                </TableRow>
                            </Fade>
                        )
                    })}
                </TableBody>
            </Table>
            {file.data && <FilePopup file={file} onClose={handleFileClose} />}

            {popup &&
                <NewsPop onClick={setPopup} admin={admin} openFile={openFile} rowUpdate={setCount} new={createNew} count={count} news={singleNews}></NewsPop>
            }
        </Box>
    )
}