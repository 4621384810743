import { useState, useEffect } from "react";
import { TextField, Box, Button, Typography, Stack, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { exitContainerStyle, exitIconStyle } from "../styles/styles";
import { TipButton } from "../misc/InternalFeatures";
import { Save, Delete, Close } from "@mui/icons-material";
import LogitarApi from "../api/LogitarApi";
import { useSnackbar } from "notistack";
import {GridActionsCellItem} from '@mui/x-data-grid'
import PopupScreen from '../components/PopupScreen'

export default function NewRepairField(props) {
  const [externalServices, setExternalServices] = useState(
    props.externalServices
  );
  const { exit } = props;
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setId] = useState(-1);
  const { enqueueSnackbar, closeSnackbar} = useSnackbar()
  const [searchTimeStamp, setSearchTimeStamp] = useState(Date.now());
  const [count, setCount] = useState(1);
  const [isRowSelected, setIsRowSelected] = useState(false);

 const columnsExternalServices = [
    { field: "companyName", headerName: "Yritys", width: 350 },
    { field: "address", headerName: "Osoite", width: 270 },
    { field: "phone", headerName: "Puhelin", width: 150 },
    { 
      field: 'actions',
      type: 'actions',
      headerName: 'Toiminto',
      width: 100,
      cellClassName: 'actions',
  
      getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<Delete />}
              label="Delete"
              onClick={handleRemoveService(id)}
              color="inherit"
            />
          ];
      },
    },
  ];

  const handleUpdate = () => {
  if (companyName.length === 0) return;
    //alert(id);

    let insertExternalService = {
      companyName: companyName,
      address: address,
      phone: phone,
    };
    let updateExternalService = {
      id: id,
      companyName: companyName,
      address: address,
      phone: phone,
    };

    let postExternalService = "";
    if (id === -1) {
      postExternalService = insertExternalService;
    } else {
      postExternalService = updateExternalService;
    }

    LogitarApi.setExternalService(postExternalService)
      .then((result) => {
        //alert(result.latestId);
        setId(result.latestId);
        if (result.status) {
          enqueueSnackbar("Ulkopuolinen huoltaja tallennettu")
        }
        if (exit) {
          //alert("exit")
          //exit()
        }

        //Page update after saving
        //setSearchTimeStamp(Date.now());
        //alert(count);
        //setCount(count +1);

        LogitarApi.getExternalServices({ id: null }).then((result) => {
          setExternalServices(result.externalservice);
        });

        props.rowUpdate()

      })
      .catch((err) => console.error(err));

  };

  const handleRemoveService = (id) => () => {    

    const r = window.confirm(
      `Haluatko varmasti poistaa huoltajan ${companyName}?`
    );

    if (r) {
      let postExternalService = {
        id: id,
        companyName: companyName,
        address: address,
        phone: phone,
      };
      LogitarApi.setExternalService(postExternalService, true).then(
        (result) => {
          LogitarApi.getExternalServices({ id: null }).then((result) => {
            setExternalServices(result.externalservice);
            enqueueSnackbar("Ulkopuolinen huoltaja poistettu")
          });
        }
      );
    }
  } 

  //Refresh external service garages
  useEffect(() => {
    //alert("ulkopuolisten haku")
    //alert(count)
    const GetExternalServices = async () => {
      const result = await LogitarApi.getExternalServices({ id: null });
      setExternalServices(result.externalservice);

      // console.log("Haetut ulkopuoliset huoltofirmat")
      // result.externalservice.forEach((value) => {
      //     console.log(value.id);
      //     console.log(value.companyName);
      // })
    };
    GetExternalServices();
  }, []);

  const handleRowClick = (params) => {
    setIsRowSelected(true);

    if (params.row.companyName) {
      setCompanyName(params.row.companyName);
    }
    if (params.row.phone) {
      setPhone(params.row.phone);
    }
    if (params.row.address) {
      setAddress(params.row.address);
    }
    if (params.row.id) {
      setId(params.row.id);
      //alert(params.row.id)
    }
  };

  const handleNewRow = () => {
    setCompanyName("");
    setPhone("");
    setAddress("");
    setId(-1);
  };

  return (

    <PopupScreen
      draggable
      title='Ulkopuolinen huollon tekijä'
      onClose={() => props.onClick(false)}
    >

    <Box
      // sx={{
      //   // height: 300,
      //   //width: 600 ,
      //   //height: 1000,
      //   maxHeight: "85vh",
      // }}
    >
      <Box sx={{ width: 800, mt:2 }}>
        <Stack
          spacing={{ xs: 2, sm: 1 }}
          direction="column"
          useFlexGap
          flexWrap="wrap"
        >
          <TextField
            sx={{ width: 500 }}
            placeholder="Nimi"
            value={companyName}
            label="Nimi"
            name="name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextField
            sx={{ width: 500 }}
            placeholder="Osoite"
            value={address}
            label="Osoite"
            name="name"
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            sx={{ width: 250 }}
            placeholder="Puhelin"
            value={phone}
            label="Puhelin"
            name="name"
            onChange={(e) => setPhone(e.target.value)}
          />
        </Stack>
      </Box>

      <Box sx={{ width: 900 }}>
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={5} sx={{ ml: 8 }}>
              {/* <TipButton
                variant="contained"
                icon={Delete}
                sx={{ ml: 1 }}
                title="Poista"
                onClick={() => handleRemove()}
                disabled={!isRowSelected}
              /> */}

              <Button
                variant="contained"
                sx={{ ml: 4 }}
                title="Alusta uusi"
                onClick={() => handleNewRow()}
              >
                Alusta uusi
              </Button>

              <TipButton
                variant="contained"
                icon={Save}
                sx={{ ml: 1 }}
                title="Tallenna"
                onClick={() => handleUpdate()}
              />
            </Grid>
          </Grid>

          <Grid item container direction="row">
            <Grid item xs={11} sx={{ ml: 0 }}>
              <Box
                sx={{
                  // height: 300,
                  width: 900,
                  // height: 600,
                }}
              >
                <Typography sx={{ p: 2 }}>Ulkopuoliset huoltajat</Typography>
                <DataGrid
                  //height={200}
                  rows={externalServices}
                  columns={columnsExternalServices}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick={true}
                  width="100%"
                  onRowClick={handleRowClick}
                  density='compact'
                  sx={{maxHeight: '85vh'}}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </PopupScreen>
  );
}
