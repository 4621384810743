import { useEffect, useState } from "react";
import LogitarEvent, { getEventCounter } from "./LogitarEvent";

/**
 * @typedef {{
 *  subscriptions: import("./LogitarEvent").LogitarEventSource[],
 *  onEvent?: import("./LogitarEvent").EventSubscriptionCallback,
 *  onConnect?: import("./LogitarEvent").ConnectionEventCallback,
 *  onDisconnect?: import("./LogitarEvent").ConnectionEventCallback
 * }} LogitarEventProviderProps
 */

/**
 * 
 * @param {LogitarEventProviderProps} props 
 */
export default function LogitarEventProvider (props) {

    const [eventSources, setEventSources] = useState(props.subscriptions);

    useEffect(() => {

        const _eventListener = LogitarEvent.subscribe(eventSources, (source, data) => {
            if(props.onEvent)
                props.onEvent(source, data, getEventCounter());
        });

        const _connectionListener = LogitarEvent.addConnectionListener((count) => {
            if(props.onConnect)
                props.onConnect(count);
        });
        const _disconnectionListener = LogitarEvent.addDisconnectionListener((count) => {
            if(props.onDisconnect)
                props.onDisconnect(count);
        });

        return () => {
            LogitarEvent.unsubscribe(_eventListener);
            LogitarEvent.removeConnectionListener(_connectionListener);
            LogitarEvent.removeDisconnectionListener(_disconnectionListener);
        }
    }, [])

    return null;
}