import { useState, useCallback, useRef, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { mapThemes } from "../config/Themes/MapThemes";
import "../styles/map.css";
import { Circle } from "./map/Circle";
import { Map } from "@vis.gl/react-google-maps";

/**
 * @typedef {{
 *  onChange?: (point: {lat: number, lng: number, radius: number} | null) => void,
 *  initialCenter?: {lat: number, lng: number},
 *  initialZoom?: number,
 *  point?: {lat: number, lng: number},
 *  radius?: number,
 *  style?: React.CSSProperties,
 *  allowCircleResize?: boolean,
 *  setCoords?: (coords: string) => void
 * }} MapSelectorProps
 */

const containerStyle = {
    width: 500,
    height: 300,
    cursor: "pointer"
};

const defaultCenter = { lat: 65.518, lng: 26.785 }

const coordRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

/**
 * @brief Checks if coordinates are correctly formed
 * @param {string} coordinates
 * @returns 
 */
export function checkLatLng(coordinates) {
    let valid = coordRegex.test(coordinates);
    return valid;
}

/**
 * @brief Parses coordinates
 * @param {string} coordinates 
 * @return {{lat: number, lng: number} | null}
 */
export function parseLatLngString(coordinates) {
    if (!checkLatLng(coordinates))
        return null;

    const c = coordinates.split(",");
    return {
        lat: Number(c[0]),
        lng: Number(c[1])
    }
}

/**
 * 
 * @param {MapSelectorProps} props 
 * @returns 
 */
export default function MapSelector(props) {

    const [center, setCenter] = useState(props.initialCenter ? props.initialCenter : defaultCenter)
    const [zoom, setZoom] = useState(props.initialZoom ? props.initialZoom : 6);

    let circleRef = useRef(null)

    const [circleCenter, setCircleCenter] = useState(props.point);
    const [circleRadius, setCircleRadius] = useState(props.radius);

    const [circleOverwriteLock, setCircleOverwriteLock] = useState(false);

    const [circleReturnState, setCircleReturnState] = useState(props.point ? {
        ...props.point,
        radius: props.radius ? props.radius : 1000
    } : null);

    const theme = useTheme();

    const onClickMap = e => {
        console.log(e);
        setCircleCenter({ lat: e.detail.latLng.lat, lng: e.detail.latLng.lng });

        const nstate = {
            radius: (circleReturnState && circleReturnState.radius) ? circleReturnState.radius : 1000,
            lat: e.detail.latLng.lat,
            lng: e.detail.latLng.lng
        };

        setCircleReturnState(nstate)

        if (props.onChange) {
            props.onChange(nstate);
        }

        if (props.setCoords) {
            props.setCoords(`${e.detail.latLng.lat.toFixed(5)},${e.detail.latLng.lng.toFixed(5)}`);
        }
    }

    useEffect(() => {
        if (!circleOverwriteLock) {
            setCircleCenter(props.point);
            setCircleRadius(props.radius);
        }
        else {
            setCircleOverwriteLock(false);
        }
    }, [props.point, props.radius])

    return (
        <Map
            center={center}
            zoom={zoom}
            style={{...containerStyle, ...props.style}}
            onCameraChanged={(ev) => {
                setCenter(ev.detail.center);
                setZoom(ev.detail.zoom);
            }}
            onClick={onClickMap}
            zoomControl={false}
            //styles={theme.palette.mode === 'light' ? mapThemes.light : mapThemes.dark}
            mapId={theme.palette.mode === 'light' ? "3faac850744b4148" : "90d08146b99b2543"}
            id={"logitar3-map"}
        >
            {
                circleCenter &&
                <Circle
                    draggable
                    editable={props.allowCircleResize}
                    ref={circleRef}
                    center={circleCenter}
                    radius={props.allowCircleResize ? circleRadius : 500}
                    onCenterChanged={() => {
                        if (!circleRef.current)
                            return;

                        const c = circleRef.current;
                        setCircleReturnState({
                            ...circleReturnState,
                            lat: c.center.lat(),
                            lng: c.center.lng()
                        })
                        // Prevent circular update
                        setCircleOverwriteLock(true);

                        if (props.onChange) {
                            props.onChange({
                                ...circleReturnState,
                                lat: c.center.lat(),
                                lng: c.center.lng()
                            });
                        }
                    }}
                    onRadiusChanged={() => {
                        if (!circleRef.current)
                            return;

                        const c = circleRef.current;
                        setCircleReturnState({
                            ...circleReturnState,
                            radius: c.radius
                        });
                        // Prevent circular update
                        setCircleOverwriteLock(true);
                        if (props.onChange) {
                            props.onChange({
                                ...circleReturnState,
                                radius: c.radius
                            });
                        }
                    }}
                    options={{
                        strokeColor: theme.palette.mode === 'light' ? mapThemes.circleStrokeLight : mapThemes.circleStrokeDark,
                        strokeOpacity: 0.9,
                        fillColor: theme.palette.mode === 'light' ? mapThemes.circleFillLight : mapThemes.circleFillDark,
                        fillOpacity: 0.3,
                        strokeWeight: 2,
                    }}
                />
            }
        </Map>
    )
}