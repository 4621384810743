import { Box, Button, CircularProgress, IconButton, Popover, Typography } from "@mui/material";
import { headerBarHeight } from "../../styles/styles";
import { History, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";
import { useRef, useState } from "react";
import { format } from "date-fns";


type MapTimelineProps = {

    show?: boolean;

    loading?: boolean;

    //vehicles: VehicleLocation[];

    date?: Date;
    onDateChange?: (date: Date | null, live: boolean) => void;

    children?: React.ReactNode;
}

export default function MapTimeline (props: MapTimelineProps) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [date, setDate] = useState<Date>(props.date || new Date());
    const [live, setLive] = useState<boolean>(format(props.date || new Date(), "dd.MM.yyyy") === format(new Date(), "dd.MM.yyyy"));

    if(!props.show) {
        return null;
    }

    return <Box
        sx={{
            position: "absolute",
            top: headerBarHeight - 16,
            left: '40vw',
            overflow: 'visible',
            padding: 2
        }}
    >
        <Box
            sx={{
                background: 'rgba(0, 0, 0, 0.5)',
                p: 0.25,
                px: 2,
                m: 1,
                borderRadius: 24,
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <IconButton
                color="primary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <History />
            </IconButton>
            <Box
                sx={{
                    width: 32,
                    p: '4px',
                    mr: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {
                    props.loading &&
                    <CircularProgress 
                        size={24}
                        sx={{
                            flex: 1
                        }}
                    />
                }
            </Box>
            <Typography
                sx={{
                    color: 'primary.contrastText',
                    my: 1,
                    mr: 1
                }}
            >
                {date ? format(date, "dd.MM.yyyy") : "Ei valittu"}
            </Typography>
            <Button
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    py: 1,
                    color: 'primary.contrastText'
                }}
                onClick={() => {
                    setLive(true);
                    setDate(new Date());
                    if(props.onDateChange) 
                        props.onDateChange(new Date(), true);
                }}
            >
                <Typography
                    sx={{
                        mr: 0.5
                    }}
                >
                    LIVE
                </Typography>
                {
                    live ? 
                        <RadioButtonChecked />
                        : <RadioButtonUnchecked />
                }
            </Button>
            {
                props.children
            }
        </Box>
        <Popover
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            sx={{
                opacity: 0.9
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                <StaticDatePicker 
                    value={date}
                    onChange={(date) => {
                        const isToday = (date && date.toDateString() === new Date().toDateString()) || false;
                        if(date) {
                            setDate(date);
                        }
                        setLive(isToday);
                        if(props.onDateChange)
                            props.onDateChange(date, isToday);

                        setAnchorEl(null);
                    }}
                    disableFuture
                    displayWeekNumber
                    showDaysOutsideCurrentMonth
                    slotProps={{
                        actionBar: {
                            actions: [] 
                        },
                        toolbar: {
                            sx: {
                                display: 'none'
                            }
                        }
                    }}
                />
            </LocalizationProvider>
        </Popover>
    </Box>
}