import { Refresh } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";

export default function ErrorScreen({error, resetErrorBoundary}: { error: Error, resetErrorBoundary: () => void }) {
    const theme = useTheme();

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 6, justifyContent: "center", alignItems: "center", my: "4rem", width: "100%"}}>
            <Box sx={{color: theme.palette.error.main, display: "flex", flexDirection: "column", gap: 3, justifyContent: "center", alignItems: "center"}}>
                <Typography variant="h4">Ohjelmassa tapahtui virhe</Typography>
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: 1}}>
                    <Typography variant="h6">Virheen tiedot</Typography>
                    <Typography sx={{fontFamily: "monospace"}}>{error.name}: {error.message}</Typography>
                </Box>
            </Box>
            <Button startIcon={<Refresh/>} size="large" variant="outlined" color="error" onClick={() => resetErrorBoundary()}>Lataa uudelleen</Button>
        </Box>
    )
}