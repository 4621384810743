import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, TextField, Typography, useTheme } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import fi from 'date-fns/locale/fi'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import { CustomPopper } from '../misc/InternalFeatures'
import { fuelTypeList } from './FuelPriceCard'
import PopupScreen from './PopupScreen'
import { format } from 'date-fns'

const formStyle = {

    width: "100%"
}

const cars = [
    {
        value: '1',
        label: 'Vetoauto',
    },
    {
        value: '2',
        label: 'Perävaunu',
    },
    {
        value: '3',
        label: 'Pyöräkuormaaja',
    },
    {
        value: '4',
        label: 'Pakettiauto',
    },
    {
        value: '5',
        label: 'Passiivi',
    }
];

export default function VehicleEdit(props) {

    const [vehicle, setVehicle] = React.useState({ ...props.vehicle, })
    const [postVehicle, setPostVehicle] = React.useState((!props.new) ? { id: props.vehicle.id } : { ...props.vehicle })

    const [saveDisabled, setSaveDisabled] = React.useState(true);

    const formRef = useRef();

    const [groups, setGroups] = useState(props.groups);

    const [newGroup, setNewGroup] = useState("");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const theme = useTheme();

    const checkRequired = (field, value) => {
        // Check for all inputs which are required
        if (formRef.current) {
            // Select all required
            let required = formRef.current.querySelectorAll("[required]");

            let valid = true;

            // Check if all required fields are filled
            required.forEach((element) => {
                // Currently changed element
                if (element.name === field && !!value)
                    return;

                if (!element.value) {
                    valid = false;
                }
            });
            setSaveDisabled(!valid);
        }
    }

    const handleChange = (event) => {

        // Hack please fix some day
        // Prevent setting any date field
        if(event.target.name === "registrationDate" || event.target.name === "inspectionDate" || event.target.name === "inspectionByDate")
            return;

        if(event.target.name === "subcontractor") {
            let val = { [event.target.name]: event.target.checked ? 1 : 0 };
            setVehicle({ ...vehicle, ...val });
            setPostVehicle({ ...postVehicle, ...val });

            checkRequired(event.target.name, event.target.checked ? 1 : 0);
            return;
        }

        let val = { [event.target.name]: event.target.value };

        // Handle id change to only affect newId
        if (event.target.name === "id") {
            val = { newId: event.target.value };
        }

        if (event.target.type === "tel") {

        }
        else {
            setVehicle({ ...vehicle, ...val })
            setPostVehicle({ ...postVehicle, ...val })
        }

        
        // if(event.target.name === "type" && event.target.value == 5) {
        //     let valid = false;
        //     setSaveDisabled(valid);
        // } else {
        //     alert("TArkista")
        //     let valid = false;
        //     setSaveDisabled(valid);
          
        // }

        checkRequired(event.target.name, event.target.value);

    }

    useEffect(() => {
        checkRequired("VIN", vehicle.VIN);
    }, [vehicle.subcontractor]);

    useEffect(() => {
        checkRequired("type", vehicle.type);
    }, [vehicle.type]);

    const handleGroupChange = (value) => {
        setVehicle({ ...vehicle, group: value });
        setPostVehicle({ ...postVehicle, group: value });
        checkRequired("group", value);
    }

    const handleDateChange = (value, _name) => {
        if (value instanceof Date && !isNaN(value)) {
            setVehicle({ ...vehicle, [_name]: value })
            // Weird error with format if writing the date
            console.log("Set field", _name, format(value, "yyyy-MM-dd"));
            setPostVehicle({ ...postVehicle, [_name]: format(value, "yyyy-MM-dd") });
            checkRequired(_name, value);
        }
    }

    //Wrap functions here to reduce Button clutter
    const handleUpdate = () => {

        const sentVehicle = { ...postVehicle }

        console.log(sentVehicle);
    
        LogitarApi.setVehicle(sentVehicle).then((result) => {
            props.onClick(false);
            props.rowUpdate(props.count + 1);
            enqueueSnackbar("Kalusto tallennettu");
        })
            .catch((error) => {
                if (error.dberr) {
                    if (error.dberr.includes("Duplicate") && error.dberr.includes("PRIMARY")) {
                        enqueueSnackbar("Kalustonumero on jo varattu", { variant: "warning" });
                        return;
                    }
                }
                enqueueSnackbar("Kaluston tallennus epäonnistui", { variant: "error" });
            });
    }

    const InputLabelProps = { shrink: true }

    return (
        <PopupScreen
            title={"Kalusto"}
            draggable
            onClose={() => props.onClick(false)}
            onSave={() => handleUpdate()}
            saveProps={{ disabled: saveDisabled }}
            saveTooltip={saveDisabled ? "Täytä kaikki pakolliset kentät" : ""}
        >

            <form onChange={handleChange}>
                <FormControl
                    sx={{
                        '& .MuiTextField-root': { mr: 1, mb: 1.5, width: "100%" },
                        '& .MuiFormGroup-root': { display: "inline-flex", flexWrap: "nowrap", justifyContent: "space-between" },
                        overflowY: 'auto', maxHeight: '80vh', width: '100%', paddingBottom: 10, pt: 3
                    }}
                    ref={formRef}
                >
                    <FormGroup row>
                        <FormControl sx={formStyle}>
                            <FormGroup row={false}>
                                <FormGroup row>
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Kalustonumero"
                                        name="id"
                                        placeholder={!vehicle.id ? "Tyhjänä luodaan automaattisesti" : ""}
                                        error={vehicle.id && typeof vehicle.newId !== "undefined" && !vehicle.newId}
                                        type="number"
                                        value={typeof vehicle.newId !== "undefined" ? vehicle.newId : vehicle.id}
                                        style={{colorScheme: theme.palette.mode === 'light' ? "light" : "dark"}}
                                    />

                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="KustPaikka"
                                        name="costCentre"
                                        value={vehicle.costCentre}
                                        required
                                        aria-required
                                    />

                                </FormGroup>
                                <FormGroup row>
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Rekisterinumero"
                                        name="licenseNumber"
                                        value={vehicle.licenseNumber}
                                        required
                                        aria-required
                                    />

                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Tyyppi"
                                        name="type"
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        value={vehicle.type}
                                        required
                                        aria-required
                                    >
                                        {cars.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                    {  
                                        // Is subcontractor checkbox
                                        (vehicle.type == 1 || vehicle.type == 2 || vehicle.type == 3) &&
                                        <FormControlLabel
                                            sx={{ mt: -1 }}
                                            control={
                                                <Checkbox
                                                    name={"subcontractor"}
                                                    value={vehicle.subcontractor === 1}
                                                    checked={vehicle.subcontractor === 1}
                                                />
                                            }
                                            label={`Alihankkija`}
                                        />
                                    }
                                </FormGroup>

                                <FormGroup row>
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Vetoauto"
                                        name="tractorUnit"
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        value={vehicle.tractorUnit || undefined}
                                        disabled={vehicle.type != 2}
                                        /* Required only for trailers */
                                        required={vehicle.type == 2}
                                        aria-required={vehicle.type == 2}
                                    >
                                        <option value="undefined"></option>
                                        {

                                            props.tractorUnits.map((e, i) => {
                                                return <option
                                                    key={i}
                                                    value={e.id}
                                                >
                                                    {e.id} : {e.licenseNumber}
                                                </option>
                                            })
                                        }
                                    </TextField>

                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Puhelin"
                                        name="phoneNumber"
                                        value={vehicle.phoneNumber}
                                        /* Required only for tractor units */
                                        required={vehicle.type == 1}
                                        aria-required={vehicle.type == 1}
                                    />

                                </FormGroup>
                                <FormGroup row>
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Selite"
                                        name="name"
                                        value={vehicle.name}
                                        required
                                        aria-required
                                    />
                                </FormGroup>
                                <FormGroup row>

                                    <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            name="registrationDate"
                                            label="Käyttöönottopäivä"
                                            inputFormat='dd.MM.yyyy'
                                            defaultValue={!vehicle.registrationDate ? undefined : new Date(vehicle.registrationDate)}
                                            onChange={(val) => handleDateChange(val, "registrationDate")}
                                            /* Required for all but contractor */
                                            slotProps={{
                                                textField: {
                                                    required: vehicle.type == 1 || vehicle.type == 2,
                                                    name: "registrationDate"
                                                }
                                            }}
                                            required={vehicle.type != 5}
                                            aria-required={vehicle.type != 5}
                                        />
                                        <DatePicker
                                            name="inspectionDate"
                                            label="Viime katsastus"
                                            inputFormat='dd.MM.yyyy'
                                            defaultValue={!vehicle.inspectionDate ? undefined : new Date(vehicle.inspectionDate)}
                                            onChange={(val) => handleDateChange(val, "inspectionDate")}
                                            /* Required for tractor unit, trailer */
                                            slotProps={{
                                                textField: {
                                                    required: vehicle.type == 1 || vehicle.type == 2,
                                                    name: "inspectionDate"
                                                }
                                            }}
                                            required={vehicle.type == 1 || vehicle.type == 2}
                                            aria-required={vehicle.type == 1 || vehicle.type == 2}
                                        />
                                        <DatePicker
                                            name="inspectionByDate"
                                            label="Katsastus viimeistään"
                                            inputFormat='dd.MM.yyyy'
                                            defaultValue={!vehicle.inspectionByDate ? undefined : new Date(vehicle.inspectionByDate)}
                                            onChange={(val) => handleDateChange(val, "inspectionByDate") }
                                            /* Required for tractor unit, trailer */
                                            slotProps={{
                                                textField: {
                                                    required: vehicle.type == 1 || vehicle.type == 2,
                                                    name: "inspectionByDate"
                                                }
                                            }}
                                            required={vehicle.type == 1 || vehicle.type == 2}
                                            aria-required={vehicle.type == 1 || vehicle.type == 2}
                                        />
                                    </LocalizationProvider>

                                </FormGroup>
                                <FormGroup row>
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="VIN"
                                        name="VIN"
                                        value={vehicle.VIN}
                                        /* Required for tractor units */
                                        required={vehicle.type == 1 && !vehicle.subcontractor}
                                        aria-required={vehicle.type == 1 && !vehicle.subcontractor}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <Autocomplete
                                        disablePortal
                                        PopperComponent={CustomPopper}
                                        id="vehicle-group"
                                        name="group"
                                        options={[...groups.map(e => { return { id: e, label: e } }), { id: newGroup, label: newGroup, isNew: true }]}
                                        sx={{ mr: 1, flex: 1 }}
                                        renderInput={(params) => <TextField {...params} label="Ryhmä" onChange={(e) => { setNewGroup(e.target.value) }} />}
                                        value={vehicle.group}
                                        renderOption={(props, option) => option.id ? (
                                            <Box
                                                component="div"
                                                {...props}
                                                key={option.id}
                                                color={option.isNew ? 'green' : 'inherit'}
                                            >

                                                {option.isNew ? "Uusi:" : ""} {option.label}
                                            </Box>
                                        ) : null}
                                        onChange={(e, v) => {
                                            if(!v) {
                                                handleGroupChange(null);
                                                return;
                                            }
                                            if (v.isNew) {
                                                // Check for collision
                                                if (groups.includes(v.id)) {
                                                    handleGroupChange(v.id);
                                                    return;
                                                }
                                                // Create new group
                                                setGroups([...groups, v.id]);
                                                setNewGroup("");
                                                handleGroupChange(v.id);
                                            }
                                            else {
                                                handleGroupChange(v.id);
                                            }
                                        }}
                                    />
                                    {
                                        <TextField
                                            InputLabelProps={InputLabelProps}
                                            label="Käyttövoima"
                                            name="fuelType"
                                            select
                                            SelectProps={{
                                                native: true,
                                            }}
                                            value={vehicle.fuelType}
                                            disabled={vehicle.type === 2}
                                            sx={{
                                                flex: 1
                                            }}
                                            /* Required only for tractor units */
                                            required={vehicle.type == 1}
                                            aria-required={vehicle.type == 1}
                                        >
                                            <option value={0}></option>
                                            {

                                                fuelTypeList.map((e, i) => {
                                                    return <option
                                                        key={i}
                                                        value={e.value}
                                                    >
                                                        {e.label}
                                                    </option>
                                                })
                                            }
                                        </TextField>
                                    }
                                </FormGroup>
                                <FormGroup row>
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Tilavuus (m3)"
                                        name="volume"
                                        type='number'
                                        value={vehicle.volume}
                                        /* Required only for tractor units and trailers */
                                        required={vehicle.type == 1 || vehicle.type == 2}
                                        aria-required={vehicle.type == 1 || vehicle.type == 2}
                                    />
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Omamassa (kg)"
                                        name="curbWeight"
                                        type='number'
                                        value={vehicle.curbWeight}
                                        /* Required only for tractor units and trailers */
                                        required={vehicle.type == 1 || vehicle.type == 2}
                                        aria-required={vehicle.type == 1 || vehicle.type == 2}
                                    />
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Kokonaispaino (kg)"
                                        name="grossWeight"
                                        type='number'
                                        value={vehicle.grossWeight}
                                        /* Required only for tractor units and trailers */
                                        required={vehicle.type == 1 || vehicle.type == 2}
                                        aria-required={vehicle.type == 1 || vehicle.type == 2}
                                    />
                                </FormGroup>
                                <FormGroup row sx={{ display: 'flex', gap: 0 }}>
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Euro-luokka"
                                        name="euroClass"
                                        type="number"
                                        value={vehicle.euroClass}
                                        sx={{ flex: 1 }} 
                                    />
                                    <TextField
                                        InputLabelProps={InputLabelProps}
                                        label="Liikennelupanumero"
                                        name="transportLicence"
                                        value={vehicle.transportLicence}
                                        sx={{ flex: 2 }} 
                                    />
                                    </FormGroup>
                            </FormGroup>
                        </FormControl>
                    </FormGroup>
                </FormControl>
            </form>
        </PopupScreen>
    )

}