import { SxProps, Theme } from "@mui/material";
import { DatePicker, DateValidationError, LocalizationProvider, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { DateRange, DateRangePicker, DateRangeValidationError } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setDefaultOptions } from "date-fns";
import { fi } from "date-fns/locale";

setDefaultOptions({ locale: fi });

export interface DateSelectCommonProps {
    sx?: SxProps<Theme>,
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
}


export interface DateSelectProps extends DateSelectCommonProps {
    value: Date;
    onChange: (date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
}

export default function DateSelect(props: DateSelectProps) {

    return <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
        <DatePicker
            {...props}
        />
    </LocalizationProvider>
}

export interface DateRangeSelectProps extends DateSelectCommonProps {
    value: DateRange<Date>;
    onChange: (dateRange: DateRange<Date>, context: PickerChangeHandlerContext<DateRangeValidationError>) => void;
    labels?: {start: string, end: string};
}

export function DateRangeSelect(props: DateRangeSelectProps) {

    return <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
        <DateRangePicker
            displayWeekNumber
            localeText={{
                start: props.labels ? props.labels.start : 'Alku',
                end: props.labels ? props.labels.end : 'Loppu',
            }}
            {...props}
            onChange={(dateRange, context) => {
                if(dateRange[0] && dateRange[1] && dateRange[0] > dateRange[1]) {
                    dateRange = [dateRange[1], dateRange[0]]
                }
                // If one date is invalid (start > end || end < start) use same date for both
                if (!dateRange[0] && !!dateRange[1]) dateRange[0] = dateRange[1];
                if (!dateRange[1] && !!dateRange[0]) dateRange[1] = dateRange[0];
                if (!dateRange[0] && !dateRange[1]) return;
                props.onChange(dateRange, context);
            }}
        />
    </LocalizationProvider>
}