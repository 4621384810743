import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

// Store version for possible migrations
const configKeyName = "LogiTar-UserConfig";
const configKeyVersion = "0.0.1";
const configKey = `${configKeyName}-${configKeyVersion}`;

export class UserConfig<Type> {
    constructor(key: string = "") {
        if (key) this.key = key;
        this.getConfig();
    }

    key = "default";
    #config: Type = {} as Type;

    get config(): Type {
        return this.#config;
    }

    set config(val: Type) {
        this.#config = val;
        this.setConfig();
    }

    setConfig() {
        const oldItem = localStorage.getItem(configKey);
        if (oldItem) {
            const newItem = { ...JSON.parse(oldItem), [this.key]: this.#config };
            localStorage.setItem(configKey, JSON.stringify(newItem));
        } else {
            const newItem = { [this.key]: this.#config };
            localStorage.setItem(configKey, JSON.stringify(newItem))
        }
    }

    getConfig(): Type | null {
        const item = localStorage.getItem(configKey);
        if (item) {
            const config = JSON.parse(item);
            if (config && config[this.key]) {
                this.#config = config[this.key];
            }
            return config;
        };
        return null;
    }

    static hasSettings(): boolean {
        return !!localStorage.getItem(configKey);
    }

    static deleteSettings() {
        localStorage.removeItem(configKey);
    }
}

export function useUserConfig<Type>(key: string) {
    return new UserConfig<Type>(key);
}