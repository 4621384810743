
export const localeText = {
    //Columns panel text
    toolbarColumns: "Sarakkeet",
    toolbarColumnsLabel: 'Valitse sarakkeet',
    columnsPanelTextFieldLabel: 'Etsi sarake',
    columnsPanelTextFieldPlaceholder: 'Sarakkeen otsikko',
    columnsPanelDragIconLabel: 'Järjestä sarake uudelleen',
    columnsPanelShowAllButton: 'Näytä kaikki',
    columnsPanelHideAllButton: 'Piilota kaikki',

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Hakusana...',
    toolbarQuickFilterLabel: 'Hae',
    toolbarQuickFilterDeleteIconLabel: 'Tyhjennä',

    // Export selector toolbar button text
    toolbarExport: "Vie sivu",
    toolbarExportLabel: 'Vie sivu',
    toolbarExportCSV: 'Lataa CSV-muodossa',
    toolbarExportPrint: 'Tulosta',
    
    //Filter panel text
    filterPanelOperators: 'Toiminto',
    filterPanelOperatorAnd: 'Ja',
    filterPanelOperatorOr: 'Tai',
    filterPanelColumns: 'Sarakkeet',
    filterPanelInputLabel: 'Arvo',
    filterPanelInputPlaceholder: 'Suodattimen arvo',

    // Filter operators text
    filterOperatorContains: 'sisältää arvon',
    filterOperatorEquals: 'on yhtä suuri kuin',
    filterOperatorStartsWith: 'alkaa arvolla',
    filterOperatorEndsWith: 'päättyy arvoon',
    filterOperatorIs: 'on',
    filterOperatorNot: 'ei ole',
    filterOperatorAfter: 'on jälkeen',
    filterOperatorOnOrAfter: 'on sama tai jälkeen',
    filterOperatorBefore: 'on ennen',
    filterOperatorOnOrBefore: 'on sama tai ennen',
    filterOperatorIsEmpty: 'on tyhjä',
    filterOperatorIsNotEmpty: 'ei ole tyhjä',
    filterOperatorIsAnyOf: 'on jokin arvoista',

    // Filters toolbar button text
    toolbarFilters: "Suodattimet",
    toolbarFiltersLabel: 'Näytä suodattimet',
    toolbarFiltersTooltipHide: 'Piilota suodattimet',
    toolbarFiltersTooltipShow: 'Näytä suodattimet',
    filterPanelAddFilter: 'Lisää suodatin',
    filterPanelDeleteIconLabel: 'Poista',
    toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktiivista suodatinta` : `${count} aktiivinen suodatin`,

    toolbarDensity: "Rivien tiheys",
    toolbarDensityLabel: 'Tiiveys',
    toolbarDensityCompact: 'Tiheä',
    toolbarDensityStandard: 'Vakio',
    toolbarDensityComfortable: 'Tilava',

    // Header filter operators text
    headerFilterOperatorContains: 'Sisältää',
    headerFilterOperatorEquals: 'Yhtäsuuri',
    headerFilterOperatorStartsWith: 'Alkaa',
    headerFilterOperatorEndsWith: 'Loppuu',
    headerFilterOperatorIs: 'Sama kuin',
    headerFilterOperatorNot: 'Eri kuin',
    headerFilterOperatorAfter: 'Jälkeen',
    headerFilterOperatorOnOrAfter: 'Päivänä tai sen jälkeen',
    headerFilterOperatorBefore: 'Ennen',
    headerFilterOperatorOnOrBefore: 'Päivänä tai sitä ennen',
    headerFilterOperatorIsEmpty: 'On tyhjä',
    headerFilterOperatorIsNotEmpty: 'Ei ole tyhjä',
    headerFilterOperatorIsAnyOf: 'On mikä tahansa',
    'headerFilterOperator=': 'Yhtäsuuri',
    'headerFilterOperator!=': 'Erisuuri',
    'headerFilterOperator>': 'Isompi kuin',
    'headerFilterOperator>=': 'Isompi tai yhtäsuuri kuin',
    'headerFilterOperator<': 'Vähemmän kuin',
    'headerFilterOperator<=': 'Vähemmän tai yhtäsuuri kuin',
}