import { Cancel, Clear, Delete, Edit, Save } from '@mui/icons-material';
import { Box, Button, FormGroup, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, TextField, Tooltip, useTheme } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parse } from 'date-fns';
import { fi } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import LogitarApi from '../api/LogitarApi';
import { localeText } from '../misc/LocaleText';
import { contentBoxStyle } from '../styles/styles';
import AlertPop from '../components/AlertPop';

const Holidays = () => {

    const [holidays, setHolidays] = useState([]);
    const [newHoliday, setNewHoliday] = useState({ name: '', date: format(new Date(), "yyyy-MM-dd") });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [contextMenu, setContextMenu] = useState(null)
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const contextHolidayRef = useRef(null)
    const idToDelete = useRef(null);

    const theme = useTheme();

    const columns = [
        // { field: 'id', headerName: 'id', hidden: true },
        { field: 'name', headerName: 'Arkipyhä', flex: 1 },
        {
            field: 'date', headerName: 'Päivämäärä', valueGetter:
                (params) => format(new Date(params.row.date), 'dd.MM.yyyy', { locale: fi }),
            width: 120,
            sortComparator: (a, b) => parse(a, 'dd.MM.yyyy', new Date()).getTime() - parse(b, 'dd.MM.yyyy', new Date()).getTime()
        },
        { field: 'tools', headerName: "", width: 100,
            renderCell: (params) => (
                <FormGroup row sx={{gap: 0.5, flexWrap: 'nowrap'}}>
                    <Tooltip title="Muokkaa">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            handleHolidayEdit(params.row.id);
                        }}>
                            {newHoliday.id === params.row.id ? <Cancel/> : <Edit/>}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Poista">
                        <IconButton color='error' onClick={(e) => {
                            e.stopPropagation();
                            // handleHolidayDelete(params.row.id);
                            idToDelete.current = params.row.id;
                            setShowDeletePopup(true);
                        }}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </FormGroup>
            ),
            disableColumnMenu: true,
            disableExport: true,
            sortable: false,
        }
    ];

    const handleNewHolidayChange = (event) => {
        setNewHoliday({ ...newHoliday, [event.target.name]: event.target.value });
    }

    const handleHolidayEdit = (id) => {
        if (newHoliday.id === id) {
            setNewHoliday({ name: '', date: '' });
            return;
        };
        setNewHoliday(holidays.find(holiday => holiday.id === id));
    }

    const handleHolidayDelete = (id) => {
        LogitarApi.setHolidays({ id: id }, true).then((result) => {
            console.log(result);
            setHolidays(holidays.filter(h => h.id !== id));
            enqueueSnackbar('Arkipyhä poistettu');
        }).catch((error) => {
            console.log(error)
            enqueueSnackbar('Arkipyhän poisto epäonnistui', { variant: 'error' });
        }).finally(() => {
            contextHolidayRef.current = null;
            idToDelete.current = null;
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const existingHoliday = holidays.find(day => day.date === newHoliday.date);
        if (existingHoliday && existingHoliday.id !== newHoliday.id) {
            enqueueSnackbar(`Arkipyhä on jo olemassa ${format(new Date(existingHoliday.date), 'dd.MM.yyyy')}: ${existingHoliday.name}`,
                { variant: 'warning' });
            return;
        }

        LogitarApi.setHolidays(newHoliday).then((result) => {
            console.log(result);
            const holidayExists = holidays.find(h => h.id === result.latestID);

            if (holidayExists) {
                setHolidays(holidays.map(h => h.id === result.latestID ? { ...h, ...newHoliday } : h));
                enqueueSnackbar('Arkipyhä päivitetty');

            } else {
                setHolidays([...holidays, { ...newHoliday, id: result.latestID }]);
                enqueueSnackbar('Arkipyhä lisätty');
            }

            setNewHoliday({ name: '', date: '' });
        })
            .catch((error) => {
                console.log(error)
                enqueueSnackbar('Arkipyhän lisäys epäonnistui', { variant: 'error' });
            })
    };

    useEffect(() => {
        LogitarApi.getHolidays().then((result) => {
            setHolidays(result.holidays);
        })
            .catch((error) => {
                console.log(error)
                setHolidays([]);
            })
    }, []);

    const handleRowContextMenu = (e) => {
        e.preventDefault();
        contextHolidayRef.current = Number(e.currentTarget.getAttribute('data-id'))
        setContextMenu({ top: e.clientY, left: e.clientX })
    }

    return (
        <Box sx={{
            ...contentBoxStyle,
            // '& .MuiDataGrid-row': {
            //     cursor: "pointer"
            // },
            display: 'flex', flexDirection: 'column', height: '100%', width: '100%', px: 1
        }}>
            <form onSubmit={handleSubmit} style={{
                paddingBottom: 8,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                maxWidth: 780,
                gap: "8px"
            }}
            >
                <TextField
                    label="Nimi"
                    name='name'
                    value={newHoliday.name}
                    sx={{ minWidth: 250, flex: 1 }}
                    onChange={handleNewHolidayChange}
                    required
                    size='small'
                />
                <TextField
                    label="Pvm"
                    type="date"
                    name='date'
                    value={newHoliday.date}
                    onChange={handleNewHolidayChange}
                    required
                    style={{ colorScheme: theme.palette.mode === 'light' ? "light" : "dark" }}
                    size='small'
                    sx={{ width: 150 }}
                />
                <FormGroup row sx={{ gap: 1 }}>
                    <Tooltip title="Tallenna" placement="top">
                        <Button type="submit" variant="contained" sx={{ height: "40px" }}>
                            <Save></Save>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Tyhjennä" placement="top">
                        <Button
                            onClick={() => {
                                setNewHoliday({ name: '', date: '' })
                            }}
                            variant='contained' className="default-red" sx={{ height: "40px", ":hover": { bgcolor: "#d11e1e" } }}>
                            <Clear></Clear>
                        </Button>
                    </Tooltip>
                </FormGroup>
            </form>

            <DataGridPro
                sx={{ maxWidth: 780 }}
                rows={holidays}
                columns={columns}
                localeText={localeText}
                loading={!Array.isArray(holidays)}
                density='compact'
                autosizeOnMount
                disableRowSelectionOnClick
                slotProps={{
                    row: {
                        onContextMenu: (e) => handleRowContextMenu(e),
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "date", sort: "asc" }]
                    }
                }}
                disableColumnSelector
                disableColumnPinning

                // onRowClick={(params) => {
                //     console.log(params.row)
                //     if (params.row.id === newHoliday.id) {
                //         setNewHoliday({ name: '', date: '' });
                //         return
                //     };

                //     setNewHoliday({ ...params.row });
                // }}

                // on
            />

            <Popover
                onClose={() => {
                    setContextMenu(null);
                    contextHolidayRef.current = null
                }}
                anchorReference={"anchorPosition"}
                open={contextMenu !== null}
                anchorPosition={contextMenu}
            >
                <MenuItem onClick={(e) => {
                    setContextMenu(null);
                    if (contextHolidayRef.current) {
                        handleHolidayEdit(contextHolidayRef.current)
                    }
                }}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>Muokkaa arkipyhää</ListItemText>
                </MenuItem>
                <MenuItem onClick={(e) => {
                    setContextMenu(null);
                    if (contextHolidayRef.current) {
                        // handleHolidayDelete(contextHolidayRef.current);
                        idToDelete.current = contextHolidayRef.current;
                        setShowDeletePopup(true);
                    }
                }}>
                    <ListItemIcon>
                        <Delete sx={{ color: theme.palette.error.main }} />
                    </ListItemIcon>
                    <ListItemText>Poista arkipyhä</ListItemText>
                </MenuItem>
            </Popover>
            {
                showDeletePopup &&
                <AlertPop
                    title='Haluatko varmasti poistaa arkipyhän?'
                    cancellable
                    onClose={(accepted) => {
                        if (accepted && idToDelete.current) {
                            handleHolidayDelete(idToDelete.current);
                        }
                        setShowDeletePopup(false);
                    }}
                    content={(() => {
                        const holiday = holidays.find(day => day.id === idToDelete.current);
                        if (holiday) return `${holiday.name} – ${format(new Date(holiday.date), 'dd.MM.yyyy')}`;
                        else return "";
                    })()}
                />
            }
        </Box>
    );
};

export default Holidays;