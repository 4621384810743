
import {Box, Stack, FormControl, FormGroup, TextField, Typography, Autocomplete, Grid, MenuItem, Select, InputLabel, 
  IconButton, Dialog, DialogActions, DialogTitle, Button, CircularProgress, Checkbox,
  useTheme,
  useMediaQuery,
  FormControlLabel} from '@mui/material'
import { contentBoxStyle } from "../styles/styles"
import React from 'react'
import LogitarApi from '../api/LogitarApi'
import fi from 'date-fns/locale/fi'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import { popBottomStyle } from '../styles/styles'
import { Delete, Save, Send, Merge, Clear} from '@mui/icons-material'
import { TipButton } from '../misc/InternalFeatures'
import { AutocompleteVehicle } from '../misc/AutocompleteVehicle'
import { AutocompleteUser } from '../misc/AutocompleteUser'
import { useEffect, useState, useRef} from 'react'
import uuid from 'react-uuid';
import NewRepairField from './NewRepairMan';
import { DataGridPro } from '@mui/x-data-grid-pro'
import {GridActionsCellItem} from '@mui/x-data-grid'
import PopupScreen from '../components/PopupScreen'
import { useSnackbar } from 'notistack'
import FormHelperText from '@mui/material/FormHelperText'
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import FileManager from "./FileManager.tsx";
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

export default function ServiceOrderEdit(props) {
    const [serviceHistory, setServiceHistory] = React.useState(props.serviceHistory)
    const [postServiceHistory, setPostServiceHistory] = React.useState((!props.new) ? {id: props.serviceHistory.id, orderid: props.serviceHistory.id, vehicle: props.serviceHistory.vehicle, driver: props.serviceHistory.driver, 
      orderDate: props.serviceHistory.orderDate, beforeKm: props.serviceHistory.beforeKm, beforeDate: props.serviceHistory.beforeDate, alarm: props.serviceHistory.alarm,   
      servicedByExt: props.serviceHistory.servicedByExt , ExternalService_id: props.serviceHistory.ExternalService_id, requestInfo: props.serviceHistory.requestInfo,
      priority: props.serviceHistory.priority, connection: props.serviceHistory.connection
    } : {...props.serviceHistory})
    const [openServiceHistory, setOpenServiceHistory] = React.useState([])
    const [filteredOpenServiceHistory, setFilteredOpenServiceHistory] = React.useState([])
    const [vehicles, setVehicles] = React.useState(props.vehicles)    
    const [vehiclesList, setVehiclesList] = React.useState([])    
    const [newRepairman, setNewRepairman] = React.useState(false)
    const [drivers, setDrivers] = React.useState([])
    const [selectedVehicle, setSelectedVehicle] = React.useState(props.serviceHistory.vehicle)    
    const [selectedDriver, setSelectedDriver] = React.useState(props.serviceHistory.driver)    
    const [externalServices, setExternalServices] = React.useState([])
    const [selectedExternalService, setSelectedExternalService] = React.useState([null])
    const [saved, setSaved] = React.useState(null);
    const [serviceTimeStamp, setServiceTimeStamp] = useState("");
    const [serviceRowsTimeStamp, setServiceRowsTimeStamp] = useState("");
    const [controlRowsTimeStamp, setControlsRowsTimeStamp] = useState("");
    const [selectedUuid, setSelectedUuid] = React.useState("");
    const [openServiceRequestInfo, setOpenServiceRequestInfo] = useState(" ");
    const [selectedRows, setSelectedRows] = useState([]);
    const [rowId, setRowId] = useState(-1);
    const [rowSelectedFlag, setRowSelectedFlag] = useState(0);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const [errormsg, setErrorMsg] = useState("")
    const [dialog, setDialog] = useState(false)
    const [deleteOrderOrRow, setDeleteOrderOrRow] = useState({type:"",id:null});
    const [confirmMsg, setConfirmMsg] = useState("")
    const [inProgressIcon, setInProgressIcon] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [originUuid, setOriginUuid] = useState(props.serviceHistory.uuid);
    const [externalServiceTimeStamp, setExternalServiceTimestamp] = useState("")
    const [workPriority, setWorkPriority] = React.useState([])
    const [autofuturConnection, setAutofuturConnection] = React.useState([])
    const [selectedPriority, setSelectedPriority] = React.useState(props.serviceHistory.priority)   
    const [selectedConnection, setSelectedConnection] = React.useState(props.serviceHistory.connection)   
    const [selectedRowId, setSelectedRowId] = useState(-1);
    const [popupSummary, setPopupSummary] = useState(false);

    const theme = useTheme();
    const lgMediaQuery = useMediaQuery(theme.breakpoints.up("lg"));

    
  const diaClose = () => {
    setDialog(false)
  }
  const diaOpen = (id) => () => {
    setDialog(true)
  }
  
  // Initializing 
  useEffect(()=>{
    const uuidname = 'uuid';
    if (props.new) {
        // TIETOJEN SYÖTTÖ KOMPONENTISSA NewServiceOrder.js
        // const newuuid = uuid();
        // //alert(newuuid);
        // let dateFormat = null; 
        // //dateFormat= format(Date.now(), "yyyy-MM-dd"); 
        // setPostServiceHistory({...postServiceHistory, 'uuid': newuuid, 'orderDate': dateFormat, 'driver': LogiTarUser.current.token.user, 'driverByName': LogiTarUser.current.info.name});
        // setServiceHistory({...serviceHistory, 'orderDate': dateFormat, 'driver': LogiTarUser.current.token.user, 'driverByName': LogiTarUser.current.info.name})
    }
    else {
        setServiceHistory({...serviceHistory, 'uuid': props.serviceHistory.uuid});
        setPostServiceHistory({...postServiceHistory, 'uuid': props.serviceHistory.uuid});

        // Remove the first vehicle marked '-'
        let originalvehicles = [...vehicles]
        originalvehicles.shift()
        setVehiclesList(originalvehicles)
    }

    setOpenServiceRequestInfo("")
    setDialog(false)
    setOpenServiceHistory([])

    // Read autofutur status using API
    if (props.serviceHistory.workOrderNr != null && props.serviceHistory.connection != null) {      
      // alert("TYönumero " + props.serviceHistory.workOrderNr)
      // alert("Yhteys " + props.serviceHistory.connection)
      loadAutofutur()
    }
    else {
      setServiceRowsTimeStamp(Date.now());
    }
  }, [])

  //Get control data
  useEffect(() => {
      const title="ServiceHistory"
      LogitarApi.getControlValues({title: title}).then((result) => {   
        let workpriority = result.controlvalues.filter((j) => j.subtitle == "Priority");
        workpriority = ([{value:null, name:'-'}, ...workpriority]) 
        setWorkPriority(workpriority);
        let autofuturconn = result.controlvalues.filter((j) => j.subtitle ==  "AutofuturOffice"); 
        autofuturconn = ([{value:null, name:'-'}, ...autofuturconn])
        setAutofuturConnection(autofuturconn);  

        // alert("Yhteys lkm : " + autofuturconn.length )
        // alert("Kiireellisyys lkm : " + workpriority.length )
 
        // //Default value
        // setSelectedPriority(workpriority[0])
        // workpriority.forEach((value) => {
        //     if (value.name == 'Ei kiireellinen') {
        //         setSelectedPriority(value)
        //     }
        // })

        // //Default value
        // setSelectedConnection(autofuturconn[0])
        // if (autofuturconn.length == 2) {
        //   setSelectedConnection(autofuturconn[1])    
        // }    

        //Get selected order
        setSelectedUuid(props.serviceHistory.uuid);
        setServiceTimeStamp(Date.now())
    })
    .catch((err) => {});
  }, []);
  
  // //Get drivers
  // useEffect(() => {
  //   const GetUsers = async() => {
  //     const result = await LogitarApi.getUsers({extent: "all"})
  //     const usersTemp = result.users;
  //     // console.log("Haetut kayttajat ovat");
  //     // result.users.forEach((value) => {
  //     //      console.log(value.name);
  //     //})  

  //     //let drivers = result.users.filter((i) => i.userType == '1')
  //     setDrivers(result.users);    
  //   }
    
  //   if (LogiTarUser.current.info.userType != LogiTarUserType.DRIVER ) {
  //     GetUsers() 
  //   }
  // }, [])

  //Get external service garages
  useEffect(() => {
    const GetExternalServices = async() => { 
      const result = await LogitarApi.getExternalServices({id: null});
      setExternalServices(result.externalservice);  
      setSelectedExternalService(null)

       // console.log("Haetut ulkopuoliset huoltajat")
      result.externalservice.forEach((value) => {
          // console.log(value.id);
          // console.log(value.companyName);
          // console.log(serviceHistory.ExternalService_id);
          if (serviceHistory.ExternalService_id === value.id) {
            setSelectedExternalService(value)
          }
      })  
    }       
    GetExternalServices() 
  }, [externalServiceTimeStamp])

  function loadAutofutur(){
    let workOrders= []
    let licenseNumbers= []

    let loadmethod = "loadbyordernr"
    let workOrder = ({'workOrderNr':props.serviceHistory.workOrderNr, 'connection':props.serviceHistory.connection}); 
    workOrders.push(workOrder)

    //let loadmethod =  "loadbyvehicle"
    //licenseNumbers.push(props.serviceHistory.licenseNumber)

    const TIMEOUT_DURATION = 30000; // Katkaistaan jos Autofutur statuksen haku päättyisi aikakatkaisuun 30 sek.
    const loadAutofuturRequest  = LogitarApi.loadAutofutur(licenseNumbers, workOrders, loadmethod);
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timeout')), TIMEOUT_DURATION)
    );

    Promise.race([loadAutofuturRequest, timeoutPromise])
      .then((result) => {
        console.log("Status haettu Autofuturesta")
        setServiceRowsTimeStamp(Date.now());
        props.rowUpdate();
      })
      .catch((error) => {
        if (error.message === 'Request timeout') {
          enqueueSnackbar("Autofutur haku keskeytetty. Timeout.", {variant: "warning"})
        } else {
          enqueueSnackbar("Autofutur haku epäonnistui", {variant: "warning"})
        }
        setServiceRowsTimeStamp(Date.now());
        props.rowUpdate();
      });
  }

  //Get vehicles serviceHistory order
  useEffect(() => {
    if (selectedUuid && selectedUuid.length > 0 ){

      console.log("Haetaan auton huoltotilaus")
      setInProgressIcon(true);
      
      const method="serviceorder"
      LogitarApi.getServiceHistoryV3({uuid:selectedUuid, method:method, extent: "allfile"}).then((result) => {   
        // console.log("Haetun tilauksen tiedot ");        
        // console.log(result.servicehistory[0].id)
        // console.log(result.servicehistory[0].uuid)
        // console.log(result.servicehistory[0].vehicle)
        // console.log(result.servicehistory[0].driver)
        // console.log(result.servicehistory[0].servicedByExt)
        // console.log(result.servicehistory[0].ExternalService_id)
        // console.log(result.servicehistory[0].requestInfo)
        // console.log(result.servicehistory[0].driverByName)
        // console.log(result.servicehistory[0].externalServiceNote)
        // console.log(result.servicehistory[0].priority)
        // console.log(result.servicehistory[0].workOrderNr)   
        // console.log(result.servicehistory[0].status)
        
        if (result.servicehistory[0].externalServiceNote === null) {result.servicehistory[0].externalServiceNote = ""; }
        setServiceHistory(result.servicehistory[0]);
        setPostServiceHistory(result.servicehistory[0]);

        let data = externalServices;  
        let externalServiceExists = 0
        setSelectedExternalService(null)
        data.forEach((value) => {
            if (result.servicehistory[0].ExternalService_id === value.id) {
              if (result.servicehistory[0].servicedByExt.length > 0) {
                externalServiceExists = 1
                setSelectedExternalService(value)
              }
            }
        }) 

        data = workPriority;  
        setSelectedPriority(null)
        data.forEach((value) => {
            if (result.servicehistory[0].priority == value.value) {
                setSelectedPriority(value)
            }
        })

        if (externalServiceExists === 0) {
          data = autofuturConnection;  
          setSelectedConnection(null)
          data.forEach((value) => {
              if (result.servicehistory[0].connection == value.value) {
                  setSelectedConnection(value)
              }
          }) 
        }

      }).catch(err => { 
        console.log("VIRHE TILAUKSEN HAUSSA");
        enqueueSnackbar("Huoltotilauksen haku epäonnistui", {variant: "error"})
      })
      .finally(() => {
        setInProgressIcon(false);
      })   
    }
  }, [serviceTimeStamp])

  //Get vehicles servicehistory rows
  useEffect(() => {
    console.log("Haetaan auton avoimet tilaukset")
    console.log(selectedVehicle)
    setOpenServiceHistory([])
    
    if (selectedVehicle) {
      setInProgressIcon(true);
      const GetServiceHistoryRows = async() => {
        try {  
          const method="servicerows"  
          const result = await LogitarApi.getServiceHistoryV2({timeFrame: false, vehicle: selectedVehicle, method: method})
          let data= [];
          data=result.servicehistory;
          if (isChecked) {
            setOpenServiceHistory(data)
          }
          else {
            let rows = data.filter((j) => j.uuid === originUuid);
            setOpenServiceHistory(rows)         
          }
        }
        catch (err) {
          enqueueSnackbar("Huoltotapahtumien haku epäonnistui", { variant: 'error' });
        }
        finally {
          setInProgressIcon(false);
        }
      }

      if (serviceRowsTimeStamp != "") {GetServiceHistoryRows()} ;
    }
            
  }, [serviceRowsTimeStamp])

  const handleChange = (event) => {
      if (!event.target.name) {
        return;
      }
      if (event.target.type === "checkbox") {

        if (event.target.checked) {
          setServiceHistory({...serviceHistory, [event.target.name]: 1})
          setPostServiceHistory({...postServiceHistory, [event.target.name]: 1})
          
          if (event.target.name == 'alarm') {
            const prevalarmChk = serviceHistory.alarm == 1 ? true: false
            if (event.target.checked !== prevalarmChk ) {
              setPostServiceHistory({...postServiceHistory, [event.target.name]: 1, 'alarmChanged': 1})
            }
          }
        } else {
          setServiceHistory({...serviceHistory, [event.target.name]: 0})
          setPostServiceHistory({...postServiceHistory, [event.target.name]: 0})
        }
      } else{
        //alert("Muutetaan " + event.target.name)
        //alert("arvo " + event.target.value)
        setServiceHistory({...serviceHistory, [event.target.name]: event.target.value})
        setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value})
      }
      //Flag to disable Autofutur button
      setSaved(false);
  }

  const handleChangeVehicle = (event) => {
    if (serviceHistory.id) {
      if (event.target.value !== serviceHistory.vehicle) {
        props.onClick(false)   
      }
    }

    setServiceHistory({...serviceHistory, [event.target.name]: event.target.value})
    setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value})
    setSelectedVehicle(event.target.value)

    //Get all servicehistory
    setSelectedVehicle(event.target.value)
    setServiceRowsTimeStamp(Date.now());
  } 

  const handleChangePriority = (event) => {
    setServiceHistory({...serviceHistory, 'priority': event.target.value.value})
    setPostServiceHistory({...postServiceHistory, 'priority': event.target.value.value})
    setSelectedPriority(event.target.value)
    //Flag to disable Autofutur button
    setSaved(false);
  }
  
  const handleChangeConnection = (event) => {
    if (serviceHistory.status && serviceHistory.status.length > 0 && serviceHistory.status == "EI LÖYDY AUTOFUTURESTA") {
      setServiceHistory({...serviceHistory, 'connection': event.target.value.value, 'ExternalService_id': null, 'servicedByExt': null, 'workOrderNr' : null, 'status': null, 'inProgress': null})
      setPostServiceHistory({...postServiceHistory, 'connection': event.target.value.value, 'ExternalService_id': null, 'servicedByExt': null, 'workOrderNr': null, 'status': null, 'inProgress': null})
    } else {
      setServiceHistory({...serviceHistory, 'connection': event.target.value.value, 'ExternalService_id': null, 'servicedByExt': null})
      setPostServiceHistory({...postServiceHistory, 'connection': event.target.value.value, 'ExternalService_id': null, 'servicedByExt': null})
    }

    setSelectedConnection(event.target.value)
    //Flag to disable Autofutur button
    setSaved(false);
    //Only Autofutur connection or external service is allowed
    setSelectedExternalService(null)
  }

  const handleChangeExternalService = (event) => {
    let companyid = event.target.value.id ? event.target.value.id: null
    let companyname =  event.target.value.companyName ? event.target.value.companyName : null
    setServiceHistory({...serviceHistory, 'ExternalService_id': companyid, 'servicedByExt': companyname, 'connection': null})
    setPostServiceHistory({...postServiceHistory, 'ExternalService_id': companyid, 'servicedByExt': companyname, 'connection': null})
    setSelectedExternalService(event.target.value);
    //Flag to disable Autofutur button
    setSaved(false);
    //Autofutur connection or external service is allowed
    setSelectedConnection(null)
  };

  const handleChangeDriver = (event) => {
    //let drivername = event.target.value.username + ':' +  event.target.value.name;
    let drivername = event.target.label;
    //  alert(drivername);
    setServiceHistory({...serviceHistory, [event.target.name]: event.target.value, 'driverByName':drivername})
    setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value, 'driverByName':drivername})
    //Flag to disable Autofutur button
    setSaved(false);
  }

  const handleDateChange = (value, name) => {
      if (value == "Invalid Date") {
         // alert("Viallinen päivämäärä");
      } else {
          let dateFormat = null;
          if (value) {
              dateFormat= format(value, "yyyy-MM-dd"); 
          }        
          //alert(name);
          //alert(dateFormat);
          setServiceHistory({...serviceHistory, [name]: dateFormat})
          setPostServiceHistory({...postServiceHistory, [name]: dateFormat})
          //Flag to disable Autofutur button
          setSaved(false);
      }
  }

  const setHandleDeleteRowClick = (id) => {
    setDialog(true); setDeleteOrderOrRow({'type':'row','id':id});
    const orderrow = openServiceHistory.find((row) => row.id === id);  
    setConfirmMsg(`Haluatko varmasti poistaa huoltokuvauksen: ${orderrow.requestInfo} ?`)
  }

  const handleDeleteRowClick = () => {
    //  alert(deleteOrderOrRow.type)

    let id= deleteOrderOrRow.id;
    const orderrow = openServiceHistory.find((row) => row.id === id);  

    if (orderrow) {
        let uuid =  orderrow.uuid;
        let workOrderNr = orderrow.workOrderNr2;
        let rowNumber = orderrow.autofuturRowNumber;
        let connection = orderrow.connection;
        
        orderrow.id = orderrow.riviId;
        if (orderrow.riviId === -1) {return}; //order header

        setOpenServiceHistory(openServiceHistory.filter((row) => row.id !== id));
        LogitarApi.setServiceHistoryRow(orderrow, true, false, false).then((result) => {
          enqueueSnackbar("Huoltokuvaus poistettu")
          
          if (workOrderNr > 0) {
            // alert(workOrderNr)
            // alert(rowNumber)
            // alert(connection)
            let workorder = ({'uuid':uuid, 'workOrderNr':workOrderNr ,  'rowNumber':rowNumber, 'connection':connection}); 
            LogitarApi.sendAutofutur(workorder, true).then((result) => {
              console.log("Rivi poistettu Autofuturesta");
            })
          }

          //Get all servicehistory
          setSelectedVehicle(orderrow.vehicle)
          setServiceRowsTimeStamp(Date.now());
          setOpenServiceRequestInfo("")
          props.rowUpdate()
      })
    }
  };

  //Send Autofutur using API
  const handleSendAutofutur  = (id) => () => {    
    setInProgressIcon(true);
    let uuid = ""
    let licenseNumber = ""
    let orderDate = ""
    let descriptions = []
    let workOrderNr = ""
    let rowNumber = ""
    let currentDate = format(Date.now(), "yyyy-MM-dd"); 
    let connection = ""

    let orderrow = openServiceHistory.find((row) => row.id === id);  
    if (orderrow === null) {
      return;
    } else {
      uuid = orderrow.uuid;
      licenseNumber = orderrow.licenseNumber;
      orderDate = orderrow.orderDate;
      workOrderNr = orderrow.workOrderNr;
      rowNumber = orderrow.autofuturRowNumber;
      connection = orderrow.connection;
    }

    //-1 means order header 
    if (orderrow.riviId === -1) {
      let historyrows = openServiceHistory.filter((row) => row.uuid === uuid);  
      historyrows.forEach((value) => { 
        if (value.riviId > 0) {
          let description = {'taskDescription': value.requestInfo, 'rowNumber': value.autofuturRowNumber}
          descriptions.push(description)
        }
      })
    }; 

    if (descriptions.length === 0) {
      return;
    } 

    //  alert("Yhteys1 " +  connection)
    //  alert("Yhteys2 " +  selectedConnection)

    let workorder = ""

    //Update workorder into Autofuture
    if (workOrderNr && workOrderNr.toString().length > 1){
      workorder = ({'uuid':uuid, 'registrationNr':licenseNumber, 'orderDate':orderDate, 'workOrderNr':workOrderNr, 'rowNumber': rowNumber, 'workItems':descriptions, 'connection':connection}); 
    }
    //Insert workorder into Autofuture
    else {
      workorder = ({'uuid':uuid, 'registrationNr':licenseNumber, 'orderDate':orderDate, 'workItems':descriptions, 'acceptedDate':currentDate, 'acceptedBy': LogiTarUser.current.token.user, 'connection':connection}); 
    }

    LogitarApi.sendAutofutur(workorder, false).then((result) => {
      if (result.status) {
        console.log(result.status)
        enqueueSnackbar("Huoltotilaus lähetetty Autofutureen")
        //Get selected order
        setSelectedUuid(uuid);
        setServiceTimeStamp(Date.now());
        //Get all servicehistory
        setSelectedVehicle(postServiceHistory.vehicle)
        setServiceRowsTimeStamp(Date.now());
        props.rowUpdate()
      }
    })
    .catch((err) => {
        enqueueSnackbar("Lähetys epäonnistui. " + err.futursoft_virhe, { variant: 'error' });
    })
    .finally(() => {
        setInProgressIcon(false);
    })
  }

  const handleOpenServiceRowClick = (params) => {
      //alert("uuid " + params.row.uuid + " riviId" + params.row.riviId);

       //Set color
       setSelectedRowId(params.row.id);

      //Set row for update
      setOpenServiceRequestInfo(params.row.requestInfo)
      setRowId(params.row.riviId);
      setOriginUuid(params.row.uuid);
      
      //Flag is order or row selected: initialized 0, order -1, row > 1
      setRowSelectedFlag(params.row.riviId)
      if (params.row.riviId === -1 ) {
        setRowSelectedFlag(-1)
      }

      let historyrows = "[]"
      let historyrowsCount = 0
      historyrows = openServiceHistory.filter((row) => row.uuid === params.row.uuid && row.riviId > -1);  
      historyrowsCount= historyrows.length
      if (historyrowsCount === 0 ) {
        setRowSelectedFlag(0)
      }

      //Get selected order
      setSelectedUuid(params.row.uuid);
      setServiceTimeStamp(Date.now());
  };

  //Initialize new row
  // const handleNewRow = () => {
  //   setOpenServiceRequestInfo("");
  //   setRowId(-1); 
  // }

  //Save order and selected row to database
  const handleSave = (row) => { 
      setInProgressIcon(true);
      props.onClick(true)      

      let serviceHistoryRow = '';
      if (openServiceRequestInfo.length > 0)
      {
        if (rowId === -1) {
          //insert
          //alert("Rivin lisays")
          serviceHistoryRow = ({'uuid':postServiceHistory.uuid, 'requestInfo':openServiceRequestInfo}); 
          LogitarApi.setServiceHistoryRow(serviceHistoryRow).then((result) => {
            //setRowId(result.latestId);
            setRowId(-1); 
          })
        }
        else {
          //update
          //alert("Rivin muutos")
          serviceHistoryRow = ({'id':rowId, 'uuid':postServiceHistory.uuid, 'requestInfo':openServiceRequestInfo}); 
          LogitarApi.setServiceHistoryRow(serviceHistoryRow).then((result) => {})
        }
      }

      let servicehistoryorder = postServiceHistory;

      if(servicehistoryorder.files) {
        servicehistoryorder.files.forEach((e) => {
              if(!String(e.id).includes("NEW-")) {
                  // Delete data if it's not a new file
                  delete e.data;
              }
          })
      }
      
      if (!postServiceHistory.driver) {
        servicehistoryorder = ({...servicehistoryorder, 'driver': LogiTarUser.current.token.user })
        servicehistoryorder = ({...servicehistoryorder, 'driverByName': LogiTarUser.current.info.name })
      }

      //Priority combobox default
      if (!postServiceHistory.priority) {
        workPriority.forEach((value) => {
            if (value.name == 'Ei kiireellinen') {
                servicehistoryorder = ({...servicehistoryorder, 'priority': value.value })
            }
        })
      }

      //Autofutur connection combobox default if no external service
      if (!postServiceHistory.servicedByExt) {
        if (!postServiceHistory.connection) {
          if (autofuturConnection.length == 2) {
            servicehistoryorder = ({...servicehistoryorder, 'connection': autofuturConnection[1].value })
          }      
        }
      }
         
      LogitarApi.setServiceHistory(servicehistoryorder, false).then((result) => {
        console.log("Otsikko tallennettu ")
        console.log(postServiceHistory)
        //alert(result.latestId);

        setServiceHistory({...serviceHistory, 'id':result.latestId})
        setPostServiceHistory({...postServiceHistory, 'id':result.latestId})

        props.rowUpdate()
        enqueueSnackbar("Huoltokuvaus tallennettu")
        setSaved(true);

        //Get selected order
        setSelectedUuid(postServiceHistory.uuid);
        setServiceTimeStamp(Date.now());

        //Get all servicehistory
        setSelectedVehicle(postServiceHistory.vehicle)
        setServiceRowsTimeStamp(Date.now());
                
        //Initialize new row
        setOpenServiceRequestInfo("")
        setRowId(-1);

      })
      .catch((err) => {
          enqueueSnackbar("Huoltokuvauksen tallennus epäonnistui:" + err.dberr, { variant: 'error' });
      })
      .finally(() => {
          setInProgressIcon(false);
      })
  }

  const setHandleRemove = (id) => {
    setDialog(true); setDeleteOrderOrRow({'type':'order','id':id});
    const vehicle = vehiclesList.find((row) => row.id == serviceHistory.vehicle);
    setConfirmMsg(`Haluatko varmasti poistaa huoltotilauksen: ajoneuvo: ${vehicle.id} : ${vehicle.licenseNumber}, tilauspäivä: ${serviceHistory.orderDate} ?`)
  }

  //Remove whole order
  const handleRemove = () => {    
    props.onClick(true)    

      let id= deleteOrderOrRow.id;
      let orderrow = openServiceHistory.find((row) => row.id === id);  
      if (orderrow === null) {return;} 
   
      let serviceHistoryOrder = ({'uuid':orderrow.uuid, orderid: orderrow.orderid }); 
      LogitarApi.setServiceHistoryRow(serviceHistoryOrder, false, true, false).then((result) => {
        LogitarApi.setServiceHistory(serviceHistoryOrder, true).then((result) => {
            enqueueSnackbar("Huoltotilaus poistettu")
              
            // Remove id field for inserting new order
            let updatedServiceHistory = { ...postServiceHistory};
            delete updatedServiceHistory.id              
            setPostServiceHistory(updatedServiceHistory);

            //Get all servicehistory
            setSelectedVehicle(postServiceHistory.vehicle)
            setServiceRowsTimeStamp(Date.now());
            props.rowUpdate()
            // props.onClick(false)
        })
      })
  }

  //If external servive, make only order accepting 
  const handleSaveExternalService  = (id) => () => { 
    let orderrow = openServiceHistory.find((row) => row.id === id);  
     //-1 means order header 
    if (orderrow === null || orderrow.riviId != -1) {
      return;
    } 

    let currentDate = format(Date.now(), "yyyy-MM-dd"); 
    let servicehistoryorder = '';
    servicehistoryorder = ({'uuid':orderrow.uuid, 'method':'acceptExternalService', 'acceptedDate': currentDate,  'acceptedBy': LogiTarUser.current.token.user}); 
  
    LogitarApi.setServiceHistory(servicehistoryorder, false).then((result) => {
      console.log("Otsikko tallennettu ")
      //alert(result.latestId);

      setServiceHistory({...serviceHistory, 'id':result.latestId})
      setPostServiceHistory({...postServiceHistory, 'id':result.latestId})
      props.rowUpdate()
      enqueueSnackbar("Huoltotilaus hyväksytty")
      setSaved(true);

      //Get selected order
      setSelectedUuid(postServiceHistory.uuid);
      setServiceTimeStamp(Date.now());

      //Get all servicehistory
      setSelectedVehicle(postServiceHistory.vehicle)
      setServiceRowsTimeStamp(Date.now());

      //Possible to save serviceDesc (tehty huolto)
      setPopupSummary(true)
      
    })
    .catch((err) => {
        enqueueSnackbar("Huoltotilauksen hyväksyminen epäonnistui", { variant: 'error' });
    })   
  }

  //Save serviceDesc
  const handleChangeOrder = (event) => {    
    let servicehistoryorder = '';
    servicehistoryorder = ({...postServiceHistory, 'serviceDesc': serviceHistory['serviceDesc']}); 
    LogitarApi.setServiceHistory(servicehistoryorder, false).then((result) => {
      console.log("Tehty huolto tallennettu ")
      console.log(servicehistoryorder)
      enqueueSnackbar("Huolto tallennettu")
    })
  }

  //Change serviceDesc
  const handleModifyOrder = (event) => {
    event.preventDefault();
    setServiceHistory({...serviceHistory, [event.target.name]: event.target.value})
    setPostServiceHistory({...postServiceHistory, [event.target.name]: event.target.value})
  }
  

  //Merge selected service rows to the newest orderdate
  const handleMerge = () => { 
    // Find the newest date and its order uuid
    let newestDateString = " "
    let uuid = " "
    let orderrows = []

    let differentUuid=[]
    let orderrow = null;
    let flagAutofuturSend =false;
    
    selectedRows.forEach((value) => { 
      if (!differentUuid.includes(value.uuid)) {
        differentUuid.push(value.uuid);
      }
      //Vehicle is send to autofutur if workOrderNr exists. Then merge is forbidden.
      orderrow = openServiceHistory.find((row) => row.uuid === value.uuid);
        if ( orderrow.workOrderNr && orderrow.workOrderNr.length > 1 ) {
          flagAutofuturSend = true;
        }
    })

    if (differentUuid.length < 2) {   
      setErrorMsg("Valitse huoltokuvaukset eri tilauksilta.")
      enqueueSnackbar("Valitse huoltokuvaukset eri tilauksilta.", {variant: "error"})
      return
    }

    if (flagAutofuturSend === true) {   
      setErrorMsg("Autofutureen lähetettyä tilausta ei voi enää yhdistää. Poista valinta.")
      enqueueSnackbar("Autofutureen lähetettyä tilausta ei voi enää yhdistää. Poista valinta.", {variant: "error"})
      return
    }

    selectedRows.forEach((value) => { 
        //order header
        if (value.riviId === -1) {
          let historyrows = openServiceHistory.filter((row) => row.uuid === value.uuid);  
          historyrows.forEach((value) => { 
            if (value.riviId > 0) {
              orderrows.push(value.riviId)
            }
          })
        }
        else {
          orderrows.push(value.riviId)
        } 

        if (newestDateString=== " " || value.OrderDate > newestDateString) {
          newestDateString = value.orderDate;
          uuid = value.uuid;
        }
    })

    let serviceHistoryRow = ({'uuid':uuid,'orderrows':orderrows}); 
    LogitarApi.setServiceHistoryRow(serviceHistoryRow, false, false, true).then((result) => {
      props.rowUpdate()
  
      enqueueSnackbar("Huoltokuvaukset yhdistetty")

      //Get selected order
      setSelectedUuid(uuid);
      setServiceTimeStamp(Date.now());

      //Get all servicehistory
      setSelectedVehicle(postServiceHistory.vehicle)
      setServiceRowsTimeStamp(Date.now());
    })

    //Flag to disable Autofutur button
    setSaved(false);
  }

  //Close external services
  const setPopup = (b) => {
    //alert("suljetaan");
    setNewRepairman(false);
  };

  const clearText = () => {
      setOpenServiceRequestInfo("")
      setRowId(-1)
  };

  const columns = [
      { 
        field: 'orderDate2',
        headerName: 'Tilauspäivä',
        width: 100,
        editable: false,
        sortable: false,
      },
      { 
        field: 'driverByName',
        headerName: 'Tilaaja',
        width: 150,
        editable: false,
        sortable: false,
      },
      { 
        field: 'requestInfo', 
        headerName: 'Huoltokuvaus', 
        width: 400,
        editable: true,
        sortable: false,
        maxWidth:'1000',
      },
      { 
        field: 'servicedByExt',
        headerName: 'Ulkopuolinen huolto',
        width: 180,
        editable: false,
        sortable: false,
      },
      { 
        field: 'externalServiceNote',
        headerName: 'Lisätieto',
        width: 200,
        editable: false,
        sortable: false,
      },
      { 
        field: 'status',
        headerName: 'Status',
        width: 150,
        editable: false,
        sortable: false,
      },
      { 
        field: 'workOrderNr',
        headerName: 'Työnumero',
        width: 100,
        editable: false,
        sortable: false,
      },
      { 
        field: 'inProgress2',
        headerName: 'Huoltopäivä',
        width: 100,
        editable: false,
        sortable: false,
      },
      { 
        field: 'actions',
        type: 'actions',
        headerName: 'Toiminto',
        width: 140,
        cellClassName: 'actions',
        align: "left",

        getActions: ({ id }) => {

          const orderrow = openServiceHistory.find((row) => row.id === id);
          let autofuturCheck = false;
          let removeCheck = false;
          let acceptedCheck = false;
          let isExternalService = false;
          let historyrows = "[]"
          let historyrowsCount = 0
          let connectionCheck = false;

          if (orderrow) {
            autofuturCheck = (orderrow.isSendAutofutur == 'true') && (orderrow.status2 && orderrow.status2.toString().length != 0) && orderrow.status2 != "KUSTANNUSARVIO" && orderrow.status2 != "MUUTETTU";
            removeCheck = (orderrow.isSendAutofutur == 'true')  && (orderrow.status2 && orderrow.status2.toString().length != 0) && orderrow.status2 != "EI LÖYDY AUTOFUTURESTA";
            acceptedCheck = (orderrow.acceptedDate != null)
            // if (autofutur == false) {
            //   autofutur = orderrow.isSendAutofutur == 'true' && orderrow.uuid != originUuid;
            // }
            isExternalService = orderrow.ExternalService_id > 0 || (orderrow.servicedByExt && orderrow.servicedByExt.length > 0)
            historyrows = openServiceHistory.filter((row) => row.uuid === orderrow.uuid && row.riviId > -1);  
            historyrowsCount= historyrows.length
            if (orderrow.connection != null) {connectionCheck = true}  
          }
          else {
            return [];
          }

           // Header selected when orderrow.riviId = -1
          if (orderrow != null && orderrow.riviId === -1 && (isExternalService === false || isExternalService === null) ) {
            return [
              <Stack direction="row" sx={{width:100}}>
              <GridActionsCellItem
                icon={<Delete />}
                label="Delete"
                title="Koko huoltotilauksen poisto" 
                onClick={() => {setHandleRemove(id)}}
                color="inherit"
                disabled={removeCheck === true}
              />

              <GridActionsCellItem
                icon={<Send />}
                label="Autofutur"
                title="Autofutur-lähetys" 
                onClick={handleSendAutofutur(id)}
                color="inherit"
                disabled={autofuturCheck === true || historyrowsCount === 0 || saved == false || connectionCheck == false}
              />
              </Stack>
            ];
          }
          // Header selected when orderrow.riviId = -1
          else if (orderrow != null && orderrow.riviId === -1 && isExternalService === true) {
            return [
              <Stack direction="row" sx={{width:100}}>
              <GridActionsCellItem
                icon={<Delete />}
                label="Delete"
                title="Koko huoltotilauksen poisto" 
                onClick={() => {setHandleRemove(id)}}
                color="inherit"
                disabled={acceptedCheck == true}
              />

              <Button sx={{ml:1, px:5, fontFamily: 'Arial', fontSize: '10px'}} variant="contained" Title="Hyväksy ja vie historiaan" 
                onClick={handleSaveExternalService(id)}  disabled={(acceptedCheck == true && !isExternalService) || saved == false}
                title="Huoltotilauksen hyväksyminen" 
                >
                Hyväksy
              </Button>  
              </Stack>
            ];
          }
          // Row selected when orderrow.riviId > 0
          else {
            return [
              <Stack direction="row"  sx={{width:100}}> 
              <GridActionsCellItem
                icon={<Delete />}
                label="Delete"
                title="Kuvausrivin poisto" 
                onClick={() => {setHandleDeleteRowClick(id)}}
                //onClick={<ConfirmDelete/>}
                color="inherit"
                disabled={(acceptedCheck == true && isExternalService ) || autofuturCheck == true}
                // width="150"
              />
              </Stack> 
            ];
          }
        },
      },
  ];

  let columnsSummary = [
    { field: "vehicleByNumber", headerName: "Auto"},
    { field: "driverByName", headerName: "Tilaaja"},
    { field: "beforeDate2", headerName: "Huoltoviim. pvm"},
    { field: "beforeKm", headerName: "Huoltoviim. km"},
    { field: "requestInfo", headerName: "Pyydetty huolto"},
    { field: "orderDate2", headerName: "Tilauspäivä"},
    { field: "priorityname", headerName: "Kiireellisyys"},
    { field: "inProgress2", headerName: "Huoltopäivä"},
    { field: "servicedByExt", headerName: 'Ulkopuolinen huoltaja'},
    { field: "externalServiceNote", headerName: "Lisätieto"},
    { field: 'workOrderNr',headerName: 'Työnumero'},
    { field: 'connectionname',headerName: 'Toimipaikka'},   
    { field: 'status',headerName: 'Status'},
    { field: "done", headerName: 'Valmis'},
    { field: "serviceDesc", headerName: 'Suoritettu huolto'},
    { field: "acceptedByName", headerName: "Hyväksyjä"},
    { field: "acceptedDate2", headerName: "Hyväksymispäivä"},
  ];
  const dialogTextStyle = {
    display: "flex", justifyContent: "space-between", my: 0
  }

  const CheckErrors = () => {
    let error = false;
    if (rowSelectedFlag === 0) {return(false)} // nothing selected
    // if (serviceHistory.acceptedDate != null) {return(false)}
    if (postServiceHistory.vehicle && postServiceHistory.vehicle.toString().length > 0) {error=error} else {error = true;}
    if (postServiceHistory.driver && postServiceHistory.driver.toString().length > 0) {error=error} else {error = true;}
    if (postServiceHistory.orderDate && postServiceHistory.orderDate.length > 0) {error=error} else {error = true;}
    if (rowSelectedFlag > 0 || (rowSelectedFlag == 0 && props.new)) {
        if (openServiceRequestInfo && openServiceRequestInfo.length > 0) {error=error} else {error = true;}
    } 
    return(error)
  }

  const handleCompainCheckboxChange = (event) => {    
    //Get all servicehistory
    setIsChecked(event.target.checked);
    setServiceRowsTimeStamp(Date.now());

    if (event.target.checked) {
      let rows = openServiceHistory.filter((j) => j.uuid === originUuid);
      setFilteredOpenServiceHistory(rows)    
    }
    else {
      setFilteredOpenServiceHistory(openServiceHistory)   
    }
  };

  const setNewExternalServiceTimestamp= () => {
    setExternalServiceTimestamp(Date.now());
  };

  const NoDataSavedIcon = () => { 
    return (
      <div style={{ marginLeft: 850, marginTop: -10, color: 'red' }}>
        <WarningIcon />
        <span style={{ marginLeft: 10 }}>Muutoksia ei ole tallennettu</span>
      </div>
    );
  }


  return (
    <PopupScreen
      draggable
      title='Huoltotapahtumien muokkaus'
      onClose={() => props.onClick(false)}
      zIndex={1210}
      forceRightEdge
    >
      <Box>
        <form onChange={handleChange}>
          <FormControl 
          sx={{
            '& .MuiTextField-root': { flex: 1 },
            '& .MuiFormGroup-root': { display: "flex" },
            mx: 2
        }}
      > 
          <FormGroup row sx={{gap: 1.5, flexWrap: lgMediaQuery ? "nowrap" : "wrap"}}>
            <FormGroup sx={{mt:1, flex: "1 1 70%"}}>            
                <FormGroup row>
                    <Typography sx={{fontWeight: 'bold', mb:1}}>Tilauksen tiedot </Typography>
                    {saved == false && <NoDataSavedIcon/>  }            
                    {inProgressIcon && <CircularProgress size={30}  sx={{ml:70, mt:-1}}/>}
                </FormGroup>

                <FormGroup row sx={{gap: 1.5, mb: 1.5}}>    
                     <Autocomplete
                      disablePortal 
                      id='combo-box'
                      name='vehicle'
                      value={serviceHistory.vehicleByNumber || ' '}
                      options={vehiclesList.length > 0 ? vehiclesList.map(item => { return { label: item.id + ':' + item.licenseNumber.toString(), value: item.id, extra: { ...item } } }) : []}
                      renderInput={(params) => <TextField {...params} label='Auto' required={true} />}
                      renderOption={(props, option) => <AutocompleteVehicle key={(option.value)} props={props} option={option} />}
                      onChange={(event, newValue) => handleChangeVehicle({ target: { name: 'vehicle', value: newValue?.value ?? null,  label:newValue?.label ?? null  } })}
                      isOptionEqualToValue={(option, value) => option.value === value} 
                      disabled={rowSelectedFlag == 0}     
                      sx={{ minWidth: 280, heigth: 30, m: 0, flex: 1}}
                    /> 
 
                    <LocalizationProvider adapterLocale={fi}  dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker      
                        id="date-picker"                          
                        mask={"__.__.____"}                                        
                        value={Date.parse(serviceHistory.orderDate)}
                        onChange={(val) => handleDateChange(val, "orderDate")}
                        name="orderDate"
                        label="Tilauspäivä"
                        inputFormat='dd.MM.yyyy'        
                        disabled={true}              
                        renderInput={(params) => <TextField {...params}  helperText={'Date is required'} />}       
                        // sx={{ width: 180, mr: 1 }}
                        sx={{minWidth: 180, flex: 1}}
                        // required={true} 
                       ></DesktopDatePicker>
                    </LocalizationProvider>

                    <Autocomplete
                        disablePortal
                        id='combo-box'
                        name='driver'
                        value={serviceHistory.driverByName}
                        options={drivers.length > 0 ? drivers.map(item => { return { label: item.username.toString() + ':' + item.name.toString(), value: item.id.toString(), extra: { ...item } } }) : []}
                        // renderInput={(params) => <TextField {...params} label='Tilaaja' required={true} />}
                        renderInput={(params) => <TextField {...params} label='Tilaaja'/>}
                        renderOption={(props, option) => <AutocompleteUser key={(option.value)} props={props} option={option} />}
                        onChange={(event, newValue) => handleChangeDriver({ target: { name: 'driver', value: newValue?.value ?? null, label:newValue?.label ?? null  } })}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        defaultValue={serviceHistory.driverByName}
                        disabled={true}   
                        sx={{ minWidth: 180, heigth: 30, m: 0, flex: 1}}
                     />  

                    <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                      <FormControl sx={{minWidth: 250, flex: 1}}>
                        <InputLabel id="service-report-type-label" sx={{ fontSize: 16 }}>
                          Kiireellisyys
                        </InputLabel>
                        <Select
                          name="priority"
                          labelId="service-report-type-label"
                          label="Kiireellisyys"
                          value={selectedPriority || ' '}
                          onChange={(e) => handleChangePriority(e)}
                          // sx={{ width:200, heigth: 30}}
                          disabled={rowSelectedFlag == 0}
                        >
                          {/* <MenuItem value={0}>-</MenuItem> */}
                          {[...workPriority].map((value, key) => {
                            return (
                              <MenuItem value={value} key={key}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </LocalizationProvider>

                    <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                      <FormControl sx={{minWidth: 250, flex: 1}}>
                        <InputLabel id="service-report-type-label" sx={{ fontSize: 16 }}>
                          Huollon toimipaikka
                        </InputLabel>
                        <Select
                          name="connection"
                          labelId="service-report-type-label"
                          label="Huollon toimipaikka"
                          value={selectedConnection || ' '}
                          onChange={(e) => handleChangeConnection(e)}
                          // sx={{ width: 200, heigth: 30}}
                          // disabled={rowSelectedFlag > -1 || (postServiceHistory.workOrderNr && postServiceHistory.workOrderNr > 0)}
                          //disabled={rowSelectedFlag > -1 || (selectedConnection  && selectedConnection > 0)}
                          disabled={rowSelectedFlag == 0 || (serviceHistory.status && serviceHistory.status.length > 0 && 
                            serviceHistory.status != "KUSTANNUSARVIO" && serviceHistory.status != "MUUTETTU" && serviceHistory.status != "EI LÖYDY AUTOFUTURESTA")}
                        >
                          {/* <MenuItem value={0}>-</MenuItem>  */}
                          {[...autofuturConnection].map((value, key) => {
                            return (
                              <MenuItem value={value} key={key}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </LocalizationProvider>

                  <FormControl sx={{minWidth: 280, flex: 1}}> 
                      <InputLabel id="service-external-repairman-label">Ulkopuolinen huolto</InputLabel> 
                      <Select
                          labelId="service-external-repairman-label"
                          label="Ulkopuolinen huolto"
                          value={selectedExternalService || ' '}
                          // onChange={(e) => externalServiceSelected(e.target.value)}
                          onChange={(e) => handleChangeExternalService(e)}
                          sx={{height: 55}}
                          // disabled={rowSelectedFlag > -1 || (postServiceHistory.workOrderNr && postServiceHistory.workOrderNr > 0)}
                          // disabled={rowSelectedFlag == 0 || (postServiceHistory.workOrderNr && postServiceHistory.workOrderNr > 0)}
                          disabled={rowSelectedFlag == 0}
                          >
                              <MenuItem onClick={() => setNewRepairman(true)} sx={{ color: "green" }}>Uusi ulkopuolinen huollon tekijä</MenuItem>
                              {<MenuItem value={0}>-</MenuItem> }
                              {[...externalServices].map((value,key) => {
                                  return(
                                      <MenuItem value={value} key={key}>{value.companyName}</MenuItem>
                                  )
                              })}
                      </Select>
                    </FormControl>  
                    
                    <TextField
                      label="Lisätieto"
                      placeholder="Lisätieto ulkopuoliseen huoltoon"
                      name="externalServiceNote"
                      value={serviceHistory.externalServiceNote}
                      defaultValue={serviceHistory.externalServiceNote}
                      disabled={rowSelectedFlag == 0 || !selectedExternalService}
                      sx={{minWidth: 250, flex: 1}}
                    />           
                </FormGroup>

                <FormGroup row sx={{gap: 1.5, mb: 1.5}}>
                  <TextField
                    label="Km hälytysraja" type={"number"} step={1}
                    placeholder="Mittarilukema"
                    name="beforeKm"
                    value = {serviceHistory.beforeKm}
                    disabled={rowSelectedFlag == 0}
                    sx={{width: 200, maxWidth: 200, minWidth: 160}}
                  />
                  <LocalizationProvider adapterLocale={fi}  dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker      
                        id="date-picker"                          
                        mask={"__.__.____"}                                        
                        value={serviceHistory.beforeDate ? Date.parse(serviceHistory.beforeDate) : null}
                        onChange={(val) => handleDateChange(val, "beforeDate")}
                        name="beforeDate"
                        label="Pvm hälytysraja"
                        inputFormat='dd.MM.yyyy'        
                        disabled={rowSelectedFlag == 0}
                        renderInput={(params) => <TextField {...params} helperText={'Date is required'} fullWidth  />}
                        sx={{width: 200, maxWidth: 200, minWidth: 160}}
                        // required={true} 
                    ></DesktopDatePicker>
                  </LocalizationProvider>
                  <FormControlLabel control={
                    <Checkbox
                      color="primary"
                      name="alarm"
                      checked={!!serviceHistory.alarm}
                      disabled={rowSelectedFlag == 0}
                    />
                  } label="Tee huoltomuistutuksen sähköpostihälytys"/>
                </FormGroup>      
              
                <FormGroup row>
                  <TextField
                      label="Huoltokuvaus"
                      placeholder="Uusi huoltokuvaus"
                      name="requestInfo"
                      value = {openServiceRequestInfo}
                      onChange={(e) => setOpenServiceRequestInfo(e.target.value)}
                      required={rowSelectedFlag == -1 ? false: true}
                      disabled={rowSelectedFlag == 0 }
                      multiline = {true}                            
                      InputProps={{
                        endAdornment: openServiceRequestInfo &&(
                          <IconButton onClick={clearText} edge="end">
                            <Clear />
                          </IconButton>
                        ),
                      }} 
                      inputProps={{
                        maxLength: 100, // Autofututen laskutuksen takia rajoitus
                      }}
                      helperText={`${openServiceRequestInfo.length}/100 merkkiä`}
                  />
                </FormGroup>   
              </FormGroup>   

              <Box sx={{display: 'flex', flex: "1 0 30%", minWidth: "12rem", maxWidth: '80vw', minHeight: "12rem"}}>
                <FileManager 
                    files={(serviceHistory.files && serviceHistory.files.length > 0 )? serviceHistory.files: []}
                    sx={{ flex: 1, my: 1 }}
                    onChange={(files) => {
                      setRowSelectedFlag(0)
                      setServiceHistory({
                              ...serviceHistory,
                              'files': files
                      })
                      setPostServiceHistory({
                        ...postServiceHistory,
                        'files': files
                      })
                    }}
                />
            </Box>    
          </FormGroup>

          <FormGroup row>
            <FormGroup sx={{width: "100%"}}>
              <FormGroup row sx={{width: "100%", justifyContent: "space-between", flexWrap: "nowrap"}}>
                <Stack direction="row">
                    <Typography sx={{fontWeight: 'bold', alignSelf: "end", mb: 0.5}}>Huoltotilaukset</Typography>
                    <Tooltip title="Tietojen muuttamiseksi valitse listalta otsikko- tai huoltokuvausrivi. " placement="right" 
                      arrow
                    >
                      <InfoIcon 
                        sx={{
                          color: 'primary.main',          
                          fontSize: '24px',               
                          cursor: 'pointer',              
                          '&:hover': { color: 'secondary.main' }, 
                          ml: 3,   
                          mt: 3                       
                        }}
                      />
                    </Tooltip>
                  </Stack>
                
                <TipButton variant="contained" icon={Save} 
                      sx={{ mb:1, mr: "10vw", mt: 1}}                 
                      title="Tallenna" 
                      onClick={() => handleSave()} 
                      //disabled={CheckErrors() === true || rowSelectedFlag == 0}
                      //disabled={rowSelectedFlag == 0}
                      disabled={CheckErrors() === true || (props.new == false && rowSelectedFlag == 0)}

                />
              </FormGroup>
              <FormGroup sx={{flexDirection: "row", justifyContent: "space-between", mb: 1}}>
                <FormGroup row>
                  <TipButton variant="contained" icon={Merge} 
                      title="Yhdistä" 
                      onClick={() => handleMerge()} 
                      disabled={selectedRows.length === 0}
                      enabled={selectedRows.length > 0}            
                  />
                  <FormHelperText sx={{  ml: 2, my: 0, alignSelf: "center", color: theme.palette.mode === "light" ? theme.palette.error.light : theme.palette.error.dark, fontSize: '16px' }}>
                    {errormsg}
                  </FormHelperText>
                </FormGroup>
                <FormControlLabel control={
                  <Checkbox
                    color="primary"
                    onClick={handleCompainCheckboxChange}
                    checked={isChecked}
                  />
                } label="Näytä kaikki avoimet huoltotilaukset"/>
              </FormGroup>

              <Box
                sx={{
                  height: 300,
                  // minWidth: 1350,
                  width: '100%',
                  position: "relative",
                }}                                             
                > 
                <DataGridPro
                  rows={openServiceHistory}
                  columns={columns}
                  
                  // onRowClick={handleOpenServiceRowClick}  
                  onRowClick={(params, event) => {
                    if (params.row.done == null) {
                      handleOpenServiceRowClick(params);
                  } else {
                      // Prevent the row click action
                      event.stopPropagation();
                      event.preventDefault();
                  }}}        

                  checkboxSelection
                  disableRowSelectionOnClick
                  disableSelectionOnClick={true}
                  onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = openServiceHistory.filter((row) =>
                      selectedIDs.has(row.id) 
                    );
                    // setErrorMsg("");
                    setSelectedRows(selectedRows);
                  }}   
                  density='compact'
                  getRowClassName={(params) => {
                    if (params.row.done === null || params.row.done === undefined) {
                      if (params.row.status == 'MUUTETTU' && params.row.riviId == -1) {
                        return "autofutur-resend";
                      }
                      else if (params.row.id == selectedRowId) {
                        return "service-edit"
                      } 
                    }
                  }}    
                  // loading={!openServiceHistory[0]}
                  sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                  }}
                  disableColumnMenu
                />   
              </Box>           
            </FormGroup>
          </FormGroup>
          </FormControl>
        </form>

        {newRepairman &&
          <div>
                <PopupScreen>
                  <NewRepairField externalServices={externalServices} onClick={setPopup}  rowUpdate={setNewExternalServiceTimestamp}></NewRepairField>
                </PopupScreen>
            </div>
        }

        {/* Update serviceDesc (tehty huolto) when externalService is given*/}
        {popupSummary && (     
          <PopupScreen
            draggable
            title={"Huoltotilauksen yhteenveto"}
            onClose={() => {setPopupSummary(false);}}
            onSave={() => {handleChangeOrder();}}
          >
              <Box style={{ width: 600, maxWidth: 600 }}>
              {columnsSummary.map((row, key) => {
                  if (row.field == "requestInfo" ) {
                    return (
                      <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                        <span style={{flex: 1 }}>{row.headerName}:</span> 
                        <span style={{flex: 3 }}>
                        <TextField sx={{"& fieldset": { border: 'none'}, width: '100%'}} multiline value =  {serviceHistory[row.field]}></TextField>
                        </span> 
                      </Box>
                    )
                  }
                  else if (row.field == "serviceDesc") {
                    return (
                        <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                          <span style={{flex: 1 }}>{row.headerName}:</span> 
                          <span style={{flex: 3 }}>
                          <TextField name = {row.field} onChange={handleModifyOrder} sx={{width: '100%'}} multiline value = {serviceHistory[row.field]} ></TextField>
                          </span> 
                        </Box>
                      )
                  } else {
                    return(
                      <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                        <span style={{flex: 1 }}>{row.headerName}:</span> 
                        <span style={{ marginLeft: 25, textAlign: "left", flex: 3, overflowWrap: "anywhere" }}>{serviceHistory[row.field]}</span>
                      </Box>
                    )
                  }
              }
              )}
              <br></br>
              </Box>
          </PopupScreen>
        )}

        <Dialog
              open={dialog===true}
              onClose={diaClose}
          >
              <DialogTitle>
                  {confirmMsg}
              </DialogTitle>

              <DialogActions sx={{justifyContent: "space-between"}}>
                  <Button onClick={diaClose}>Peru</Button>

                  {deleteOrderOrRow.type === 'order' && (
                  <Button onClick={() => {handleRemove(); setDialog(false)}} autoFocus>
                      Kyllä
                  </Button>
                  )}
                  {deleteOrderOrRow.type === 'row' && (
                  <Button onClick={() => {handleDeleteRowClick(); setDialog(false)}} autoFocus>
                      Kyllä
                  </Button>
                  )}
              </DialogActions>
        </Dialog> 
      </Box>
    </PopupScreen>
  )
}


