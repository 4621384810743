import { DataGridPro, DataGridProProps, GridCallbackDetails } from "@mui/x-data-grid-pro";
import { UserConfig } from "../../misc/UserConfig";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

export type UserConfigDataGrid = {
    dataGridInitialState?: GridInitialStatePro,
    showPassive?: boolean,
    viewType?: string,
}
type ApiGridCallbackDetails = { api: { exportState: () => GridInitialStatePro } };
type DataGridUserConfigProps = { userConfig: UserConfig<UserConfigDataGrid>, configKey: string }

export default function PersistentDataGridPro(props: DataGridProProps & DataGridUserConfigProps) {
    const userConfig = props.userConfig ? props.userConfig : new UserConfig<UserConfigDataGrid>(props.configKey);

    const updateConfig = (details: GridCallbackDetails, exportOverride?: object) => {
        const fixedDetails = details as ApiGridCallbackDetails; // This is the actual type. GridCallbackDetails is wrong.
        if (exportOverride) {
            userConfig.config = { ...userConfig.config, dataGridInitialState: { ...fixedDetails.api.exportState(), ...exportOverride } };
        } else {
            userConfig.config = { ...userConfig.config, dataGridInitialState: fixedDetails.api.exportState() };
        }
    }

    return (
        <DataGridPro
            {...props}
            initialState={{ ...props.initialState, ...userConfig.config.dataGridInitialState }} // UserConfig overrides props
            onColumnVisibilityModelChange={(model, details) => {
                if (props.onColumnVisibilityModelChange) props.onColumnVisibilityModelChange(model, details);
                updateConfig(details);
            }}
            onColumnWidthChange={(param, event, details) => {
                if (props.onColumnWidthChange) props.onColumnWidthChange(param, event, details);
                updateConfig(details);
            }}
            onColumnOrderChange={(params, event, details) => {
                if (props.onColumnOrderChange) props.onColumnOrderChange(params, event, details);
                updateConfig(details);
            }}
            onFilterModelChange={(model, details) => {
                if (props.onFilterModelChange) props.onFilterModelChange(model, details);
                updateConfig(details);
            }}
            onPaginationModelChange={(model, details) => {
                if (props.onPaginationModelChange) props.onPaginationModelChange(model, details);
                updateConfig(details);
            }}
            onPinnedColumnsChange={(pinnedColumns, details) => {
                if (props.onPinnedColumnsChange) props.onPinnedColumnsChange(pinnedColumns, details);
                updateConfig(details);
            }}
            onPreferencePanelOpen={(params, event, details) => {
                if (props.onPreferencePanelOpen) props.onPreferencePanelOpen(params, event, details);
                updateConfig(details);
            }}
            onPreferencePanelClose={(params, event, details) => {
                if (props.onPreferencePanelClose) props.onPreferencePanelClose(params, event, details);
                // For some reason the closed state doesn't update immediately, so overriding it here.
                updateConfig(details, { preferencePanel: { open: false } });
            }}
            onSortModelChange={(model, details) => {
                if (props.onSortModelChange) props.onSortModelChange(model, details);
                updateConfig(details);
            }}
        />
    )
}