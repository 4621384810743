import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { addMinutes, format, parse, subMinutes } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { fishStatusLabels } from "../../driver/DriverFishFields";
import { Add, Close, Delete, DeleteForever, Undo } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type EditedFishStatus = {
    id?: number,
    jobFish?: number,
    status: FishStatusOptions,
    notes: string | null,
    dateTime?: string | null,
    del?: boolean,
};

type Status = "good" | "ok" | "bad";
type FishStatusOptions = Status | null;

export default function FishStatusEdit(props: {
    open: boolean,
    onClose: () => void,
    fishStatus: EditedFishStatus[],
    onConfirm: (newStatuses: EditedFishStatus[]) => void,
}) {
    const jobFishId = props.fishStatus ? props.fishStatus[0].jobFish : undefined;
    const [newFishStatus, setNewFishStatus] = useState(props.fishStatus);
    const [newStatusRow, setNewStatusRow] = useState<EditedFishStatus>({ jobFish: jobFishId, status: null, notes: "" });

    const handleConfirmChanges = () => {
        props.onConfirm(newFishStatus);
        props.onClose();
    }

    const handleCreateNewRow = () => {
        if (!newStatusRow.status) return;
        setNewFishStatus(prev => Array.isArray(prev) ? [...prev, newStatusRow] : [newStatusRow]);
        setNewStatusRow({ jobFish: jobFishId, status: null, notes: "" });
    }

    const handleRowDelete = (rowId: number | undefined, index?: number) => {
        // Immediately delete newly created rows
        if (!rowId && index) {
            setNewFishStatus(prev => prev.filter((_s, i) => i !== index));
        }
        if (typeof rowId !== "number") return;

        setNewFishStatus(prev => prev.map(s => {
            if (s.id === rowId) {
                if (s.del === true) {
                    const newS = { ...s };
                    delete newS.del;
                    return newS;
                } else {
                    return { ...s, del: true };
                }
            }
            return s;
        }))
    }

    useEffect(() => {
        setNewFishStatus(props.fishStatus);
        setNewStatusRow({ jobFish: jobFishId, status: null, notes: "" });
    }, [props.open])

    return (
        <Dialog open={props.open} onClose={() => props.onClose()} maxWidth={"md"}>
            <DialogTitle>Kalojen kunnon muokkaus</DialogTitle>
            <IconButton
                onClick={() => props.onClose()}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <Close />
            </IconButton>
            <DialogContent>
                <TableContainer>
                    <Table sx={{ "& .MuiTableCell-root": { p: 1 } }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Aikaleima</TableCell>
                                <TableCell>Kalojen kunto</TableCell>
                                <TableCell>Huomiot</TableCell>
                                <TableCell>Toiminto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(newFishStatus) && newFishStatus.map((status, key) =>
                                <TableRow key={key}>
                                    <TableCell sx={status.del ? { textDecorationLine: "line-through", opacity: 0.75 } : {}}>
                                        {/* {status.dateTime && format(subMinutes(new Date(status.dateTime), new Date().getTimezoneOffset()), "dd.MM.yyyy HH:mm:ss")} */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        sx: status.del ? { textDecorationLine: "line-through", opacity: 0.75 } : {},
                                                    }
                                                }}
                                                views={["year", "month", "day", "hours", "minutes", "seconds"]}
                                                format="dd.MM.yyyy HH:mm:ss"
                                                onChange={(v) => {
                                                    if (!v || !v.getTime()) {
                                                        setNewFishStatus(prev => prev.map((s, i) => i === key ? { ...s, dateTime: undefined } : s));
                                                    } else {
                                                        setNewFishStatus(prev => prev.map((s, i) => i === key ?
                                                            { ...s, dateTime: format(addMinutes(v as Date, new Date().getTimezoneOffset()), "yyyy-MM-dd HH:mm:ss") } : s));
                                                    }
                                                }}
                                                value={status.dateTime ? subMinutes(parse(status.dateTime, "yyyy-MM-dd HH:mm:ss", new Date()), new Date().getTimezoneOffset()) : null}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell sx={status.del ? { textDecorationLine: "line-through", opacity: 0.75 } : {}}>
                                        {/* {status.status && fishStatusLabels[status.status]} */}
                                        <Select
                                            size="small"
                                            sx={{ width: "9rem", textDecorationLine: status.del ? "line-through" : "unset", opacity: status.del ? 0.75 : "unset" }}
                                            onChange={(e) => setNewFishStatus(prev => prev.map((s, i) => i === key ? { ...s, status: e.target.value as FishStatusOptions } : s))}
                                            value={status.status}
                                        >
                                            <MenuItem value="good">{fishStatusLabels.good}</MenuItem>
                                            <MenuItem value="ok">{fishStatusLabels.ok}</MenuItem>
                                            <MenuItem value="bad">{fishStatusLabels.bad}</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell sx={status.del ? { textDecorationLine: "line-through", opacity: 0.75 } : {}}>
                                        {/* {status.notes && status.notes} */}
                                        <TextField
                                            size="small"
                                            sx={{ width: "15rem", textDecorationLine: status.del ? "line-through" : "unset", opacity: status.del ? 0.75 : "unset" }}
                                            onChange={(e) => setNewFishStatus(prev => prev.map((s, i) => i === key ? { ...s, notes: e.target.value } : s))}
                                            value={status.notes}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={status.del ? "Kumoa poisto" : !status.id ? "Poista kokonaan lisätty rivi" : "Poista rivi"}>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleRowDelete(status.id, key)}
                                                color={status.del ? "warning" : "error"}
                                                size="small"
                                            >
                                                {status.del ? <Undo /> : !status.id ? <DeleteForever /> : <Delete />}
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell sx={{ width: "15rem" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                }
                                            }}
                                            views={["year", "month", "day", "hours", "minutes", "seconds"]}
                                            format="dd.MM.yyyy HH:mm:ss"
                                            onChange={(v) => {
                                                if (!v || !v.getTime()) {
                                                    setNewStatusRow(prev => ({ ...prev, dateTime: undefined }));
                                                } else {
                                                    setNewStatusRow(prev => ({ ...prev, dateTime: format(addMinutes(v as Date, new Date().getTimezoneOffset()), "yyyy-MM-dd HH:mm:ss") }));
                                                }
                                            }}
                                            value={newStatusRow.dateTime ? subMinutes(parse(newStatusRow.dateTime, "yyyy-MM-dd HH:mm:ss", new Date()), new Date().getTimezoneOffset()) : null}
                                        />
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        size="small"
                                        sx={{ width: "9rem" }}
                                        onChange={(e) => setNewStatusRow(prev => ({ ...prev, status: e.target.value as FishStatusOptions }))}
                                        value={newStatusRow.status ? newStatusRow.status : ""}
                                    >
                                        <MenuItem value="good">{fishStatusLabels.good}</MenuItem>
                                        <MenuItem value="ok">{fishStatusLabels.ok}</MenuItem>
                                        <MenuItem value="bad">{fishStatusLabels.bad}</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        sx={{ width: "15rem" }}
                                        onChange={(e) => setNewStatusRow(prev => ({ ...prev, notes: e.target.value }))}
                                        value={newStatusRow.notes}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Uusi rivi">
                                        <Button
                                            variant="contained"
                                            onClick={() => handleCreateNewRow()}
                                            size="small"
                                        >
                                            <Add />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => props.onClose()}>
                    Peruuta
                </Button>
                <Button variant="contained" onClick={() => handleConfirmChanges()}>
                    Hyväksy muutokset
                </Button>
            </DialogActions>
        </Dialog>
    )
}