import { Button, Card, FormControl, TextField, Typography } from '@mui/material'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { useState } from 'react'
import { getErrorLog } from '../api/Errors'
import { APIPath } from '../api/LogitarApi'
import LogiTarUser from '../misc/User'

export default function Login() {

    const [credentials, setCredentials] = useState({ username: "", password: "" })

    //Loading for login button
    const [load, setLoad] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    /**
     * @brief Set user inputted credentials to hook
     * @param {Event} event 
     */

    const handleChange = (event) => {
        setCredentials({ ...credentials, [event.target.name]: event.target.value })
    }

    /**
     * @brief Authenticates users credentials with POST request via api
     * @param {Event} event 
     */

    const auth = async (event) => {

        setLoad(() => true)

        //Form submit default action disable
        event.preventDefault()

        const formData = new FormData()

        formData.append('username', credentials.username)
        formData.append('password', credentials.password)

        let response = await fetch(`${APIPath}/login.php`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status) {
                    // Clear user vehicle from localStorage
                    localStorage.removeItem("LOGITAR-USER-VEHICLE");
                    const user = new LogiTarUser(result.token, result.user)
                    LogiTarUser.current = user
                    let cookie = "login-token=" + result.token.key + ";max-age=" + result.token.lifetime
                    document.cookie = cookie
                    // If user type is driver or subcontractor:
                    // forward driver to vehicle view
                    if (user.info.userType === 1 || user.info.userType === 2) {
                        window.location = "./vehicleview";
                    }
                    else {
                        // Reload
                        window.location.reload()
                    }
                } else {
                    LogiTarUser.current = null
                    setCredentials({ username: "", password: "" })
                    setLoad(() => false)
                    console.log(`Error code: ${getErrorLog(result.error)}`)
                    enqueueSnackbar("Kirjautuminen epäonnistui", { variant: "error" })
                }
            })
            .catch(err => console.error(err))
    }

    return (
        <div style={{ padding: 20, textAlign: 'center' }}>
            <SnackbarProvider
                maxSnack={3}
                autoHideDuration={5000}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                variant='success'
            >
                <Card elevation={4} sx={{ p: 2, borderRadius: 2 }}>

                    <img src="logo_konnekuljetus.svg" alt="Logo" style={{ width: 220, marginBottom: 4 }}></img>
                    <Typography textAlign='center' marginTop="2%">Tervetuloa</Typography>

                    <form onSubmit={auth}>
                        <FormControl>
                            <TextField
                                margin="normal"
                                name="username"
                                value={credentials.username}
                                onChange={handleChange}
                                label="Käyttäjätunnus"
                                variant="standard"
                            />

                            <TextField
                                name="password"
                                id="standard-basic"
                                value={credentials.password}
                                onChange={handleChange}
                                variant="standard"
                                type="password"
                                label="Salasana"
                            />

                            <Button
                                sx={{ mt: 3 }}
                                type="submit"
                                variant="contained"
                                disabled={load}
                            >KIRJAUDU</Button>
                        </FormControl>
                    </form>
                </Card>
            </SnackbarProvider>
        </div>
    )
}
