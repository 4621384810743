import { Autocomplete, Box, Button, List, ListItemButton, ListItemText, Popover, Switch, SxProps, TextField, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LogitarApi from '../api/LogitarApi';
import { mapThemes } from '../config/Themes/MapThemes';
import LogiTarUser from '../misc/User';
import { googleApiKey } from '../misc/apigoogle';
import VehicleTimeline from '../components/map/VehicleTimeline';
import MapTimeline from '../components/map/MapTimeline';
import MapEventList from '../components/map/MapEventList';
import { logitarTheme } from '../config/Themes';
import { VehicleLocationPath } from '../api/LogitarApiTypes';
import { APIProvider, AdvancedMarker, Map, MapCameraChangedEvent, Marker, useMap } from '@vis.gl/react-google-maps';
import { type Marker as MarkerType, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Polyline } from '../components/map/Polyline';
import { Circle } from '../components/map/Circle';
import { VehicleMarker } from '../components/map/VehicleMarker';
import { VehicleAlert } from '../components/VehicleAlertNotifier';
import { useUserConfig } from '../misc/UserConfig';
import { format } from 'date-fns';

const containerStyle: SxProps = {
    width: '100%',
    height: '100%'
};

/**
 * @brief Measures the distance between two coordinates in meters
 * @returns Distance between two coordinates in meters
 */
export const coordinateDistanceMeters = (p1: [number, number], p2: [number, number]) => {
    var R = 6378.137; // Radius of earth in KM
    var dLat = p2[0] * Math.PI / 180 - p1[0] * Math.PI / 180;
    var dLon = p2[1] * Math.PI / 180 - p1[1] * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(p1[0] * Math.PI / 180) * Math.cos(p2[0] * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 1000; // meters
}

export const inProgressColor = "rgb(138, 204, 255)";
export const readyColor = "rgb(135, 196, 139)";

export type VehiclePosition = {
    vehicle: number;
    licenseNumber: string;
    type: number;
    position: {
        latitude: number;
        longitude: number;
        dateTime: string;
        speed: number;
    };
    job: {
        id: number;
        gpsLoadStart: string | null;
        gpsLoadEnd: string | null;
        gpsUnloadStart: string | null;
        gpsUnloadEnd: string | null;
        loadStart: string | null;
        loadEnd: string | null;
        item: {
            id: number;
            itemNumber: string;
            name: string;
            shortName: string;
            departure: { latitude: number, longitude: number, radius: number };
            arrival: { latitude: number, longitude: number, radius: number };
        }
    } | null,
    alert: VehicleAlert | null
}

type MapViewConfig = {
    showPassive?: boolean;
}

export default function MapView() {

    // Available search params: vehicle: number, date: string
    const searchParams = new URL(window.location.href).searchParams;

    const searchDate = searchParams.has("date") ? new Date(searchParams.get("date") || "") : new Date();

    const mapConfig = useUserConfig<MapViewConfig>("map-view");

    if (mapConfig.config.showPassive === undefined) {
        mapConfig.config = { ...mapConfig.config, showPassive: false };
    }

    const [showPassive, setShowPassive] = useState<boolean>(mapConfig.config.showPassive || false);

    const [date, setDate] = useState<Date | null>(searchDate);
    const [live, setLive] = useState<boolean>(format(new Date(), "yyyy-MM-dd") === format(searchDate, "yyyy-MM-dd"));
    const liveRef = useRef(live);
    const [loading, setLoading] = useState<boolean>(true);

    const [vehicles, setVehicles] = useState<VehiclePosition[]>([]);
    const [initialFetch, setInitialFetch] = useState<boolean>(true);
    const [filteredVehicles, setFilteredVehicles] = useState<VehiclePosition[]>([]);
    const vehiclesRef = useRef(vehicles);
    const [selectedVehicle, setSelectedVehicle] = useState<VehicleLocationPath | null>(null);
    const [selectedPoint, setSelectedPoint] = useState<number | null>(null);
    const [selectedTimestamp, setSelectedTimestamp] = useState<number | null>(null);
    const [currentJob, setCurrentJob] = useState<any | null>(null);
    const [center, setCenter] = useState({ lat: 61.768381, lng: 26.894954 })
    const [zoom, setZoom] = useState(7);

    const [path, setPath] = useState<{ latitude: number, longitude: number, speed: number, dateTime: string }[]>([]);

    const [vehicleMarkers, setVehicleMarkers] = useState<{ [key: string]: MarkerType }>({});
    const theme = useTheme();
    const isDriver: boolean = LogiTarUser.current?.isDriver() || false;

    const map = useMap("logitar3-map");
    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({
            map: map
        });
    }, [map]);

    useEffect(() => {
        if (!clusterer) return;

        clusterer.clearMarkers();
        clusterer.addMarkers(Object.values(vehicleMarkers));
    }, [clusterer, vehicleMarkers]);

    useEffect(() => {
        if (vehicles.length > 0) {
            setInitialFetch(false);
        }
        vehiclesRef.current = vehicles;
        setFilteredVehicles(vehicles.filter(e => showPassive ? true : e.type !== 5));

        // Set search params on initial fetch
        if (initialFetch) {
            if (searchParams.has("vehicle")) {
                const vNum = parseInt(searchParams.get("vehicle") || "");

                const veh = vehicles.find(e => e.vehicle === vNum);
                if (!veh) return;

                setSelectedPoint(null);
                setLoading(true);
                setCenter({ lat: veh.position.latitude, lng: veh.position.longitude });

                LogitarApi.getVehicleLocations(veh.vehicle, live ? null : date).then((results) => {
                    setSelectedVehicle(results.vehicles[0] as VehicleLocationPath);
                    setPath((results.vehicles[0] as VehicleLocationPath).positions);
                }).finally(() => setLoading(false))
            }

        }

    }, [vehicles, showPassive])

    useEffect(() => {
        mapConfig.config = { ...mapConfig.config, showPassive: showPassive };
    }, [showPassive])

    const setVehicleMarkerRef = useCallback((marker: MarkerType | null, key: string) => {
        setVehicleMarkers(markers => {
            if ((marker && markers[key]) || (!marker && !markers[key]))
                return markers;

            if (marker) {
                return { ...markers, [key]: marker };
            } else {
                const { [key]: _, ...newMarkers } = markers;

                return newMarkers;
            }
        });
    }, []);

    const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
        setCenter(ev.detail.center);
        setZoom(ev.detail.zoom);
    }, []);

    useEffect(() => {
        const iv = setInterval(() => {
            if (liveRef.current) {
                setLoading(true);
                LogitarApi.getVehicleLocations(null, null).then((results) => {
                    setVehicles(results.vehicles as VehiclePosition[]);
                }).finally(() => setLoading(false))
            }
        }, 60000);

        return () => {
            clearInterval(iv);
        }
    }, [])

    useEffect(() => {
        // Only for historical data
        if(live || (path && selectedPoint && selectedPoint === path.length - 1)) return;
        
        if(selectedVehicle && path && path.length > 0) {
            // Need timestamp
            const timestamp = new Date(path[selectedPoint || 0].dateTime).getTime();

            // Get the job for the timestamp. Use gps* fields if available, if not use loadTime/unloadTime
            let job = selectedVehicle.jobs.find(e => {
                if(e.gpsLoadStart && e.gpsUnloadEnd) {
                    return new Date(e.gpsLoadStart).getTime() <= timestamp && new Date(e.gpsUnloadEnd).getTime() >= timestamp;
                } else if(e.loadStart && e.loadEnd) {
                    return new Date(e.loadStart).getTime() <= timestamp && new Date(e.gpsUnloadEnd).getTime() >= timestamp;
                }
                return false;
            });
            if(!job) {
                // If job was not found, try to find the closest job (job with the smallest time to the loading timestamp)
                job = selectedVehicle.jobs.reduce((prev, curr) => {
                    if(!prev) return curr;

                    const prevLoad = new Date(prev.gpsLoadStart || prev.loadStart || 0).getTime();
                    const currLoad = new Date(curr.gpsLoadStart || curr.loadStart || 0).getTime();
                    return Math.abs(currLoad - timestamp) < Math.abs(prevLoad - timestamp) ? curr : prev;
                }, null);
            }
            
            if(job) {
                setCurrentJob(job);
            }
        }


    }, [selectedPoint, selectedTimestamp]);

    useEffect(() => {
        setLoading(true);
        LogitarApi.getVehicleLocations(null, live ? null : date).then((results) => {
            setVehicles(results.vehicles as VehiclePosition[]);

        }).finally(() => setLoading(false));
        if (selectedVehicle) {
            LogitarApi.getVehicleLocations(selectedVehicle.vehicle.id, live ? null : date).then((results) => {
                if (results.vehicles.length > 0) {
                    setSelectedVehicle(results.vehicles[0] as VehicleLocationPath);
                    setPath((results.vehicles[0] as VehicleLocationPath).positions);
                    if ((results.vehicles[0] as VehicleLocationPath).positions.length > 0) {
                        setSelectedPoint(0);
                    }
                }
            })
        }
        liveRef.current = live;
    }, [date, live])

    const options = {
        imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    }

    const selVeh = vehicles.find(e => selectedVehicle ? selectedVehicle.vehicle.id == e.vehicle : false);
    const currJob = currentJob || (selectedVehicle ? selVeh?.job : null);
    const currTimestamp = selectedPoint !== null && path[selectedPoint] ? new Date(path[selectedPoint].dateTime).getTime() : null;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }}
        >
            <Map
                center={center}
                zoom={zoom}
                onCameraChanged={handleCameraChange}
                //styles={theme.palette.mode === 'light' ? mapThemes.light : mapThemes.dark}
                mapId={theme.palette.mode === 'light' ? "3faac850744b4148" : "90d08146b99b2543"}
                id={"logitar3-map"}
                onClick={(e) => {
                    setSelectedVehicle(null);
                    setSelectedPoint(null);
                    setPath([]);
                    e.stop();
                }}
            >
                <Polyline
                    key={"path"}
                    path={path.map(e => ({ lat: e.latitude, lng: e.longitude }))}
                    strokeColor={theme.palette.mode === 'light' ? mapThemes.pathLight : mapThemes.pathDark}
                    strokeOpacity={1}
                    strokeWeight={3}
                />

                {
                    !selVeh &&
                    <>
                        {
                            filteredVehicles.map((vehicle, key) => {

                                return <VehicleMarker
                                    key={vehicle.vehicle + ":" + vehicle.licenseNumber}
                                    vehicle={vehicle}
                                    isDriver={isDriver}
                                    isSelected={false}
                                    onClick={isDriver ? undefined : (e, vehicleId) => {
                                        setSelectedPoint(null);
                                        setLoading(true);
                                        LogitarApi.getVehicleLocations(vehicleId, live ? null : date).then((results) => {
                                            setSelectedVehicle(results.vehicles[0] as VehicleLocationPath);
                                            setPath((results.vehicles[0] as VehicleLocationPath).positions as { latitude: number, longitude: number, speed: number, dateTime: string }[]);
                                        }).finally(() => setLoading(false))
                                        e.stop();
                                    }}
                                    setMarkerRef={setVehicleMarkerRef}
                                />
                            })
                        }
                        {
                            filteredVehicles.map((e, i) => {
                                if (!e.job)
                                    return null;

                                const dep = e.job.item.departure;
                                const arr = e.job.item.arrival;

                                return <>
                                    {
                                        (!!dep.latitude && !!dep.longitude) &&
                                        <Circle
                                            key={"dep-" + i}
                                            radius={dep.radius}

                                            fillColor={inProgressColor}
                                            fillOpacity={0.00}
                                            strokeColor={inProgressColor}
                                            strokeOpacity={0.9}
                                            strokeWeight={4}
                                            center={{
                                                lat: dep.latitude,
                                                lng: dep.longitude
                                            }}
                                        />
                                    }
                                    {
                                        (!!arr.latitude && !!arr.longitude) &&
                                        <Circle
                                            key={"arr-" + i}
                                            radius={arr.radius}
                                            fillColor={readyColor}
                                            fillOpacity={0.00}
                                            strokeColor={readyColor}
                                            strokeOpacity={0.9}
                                            strokeWeight={4}
                                            center={{
                                                lat: arr.latitude,
                                                lng: arr.longitude
                                            }}
                                        />
                                    }

                                </>
                            })
                        }
                    </>
                }
                {
                    !!selVeh &&
                    <>
                        <VehicleMarker
                            key={selVeh.vehicle + ":" + selVeh.licenseNumber}
                            vehicle={{
                                ...selVeh,
                                job: {
                                    ...currJob,
                                    gpsLoadStart: (currTimestamp &&currJob?.gpsLoadStart && new Date(currJob?.gpsLoadStart).getTime() <= currTimestamp) ? currJob?.gpsLoadStart : null,
                                    gpsLoadEnd: (currTimestamp && currJob?.gpsLoadEnd && new Date(currJob?.gpsLoadEnd).getTime() <= currTimestamp) ? currJob?.gpsLoadEnd : null,
                                    gpsUnloadStart: (currTimestamp && currJob?.gpsUnloadStart && new Date(currJob?.gpsUnloadStart).getTime() <= currTimestamp) ? currJob?.gpsUnloadStart : null,
                                    gpsUnloadEnd: (currTimestamp && currJob?.gpsUnloadEnd && new Date(currJob?.gpsUnloadEnd).getTime() <= currTimestamp) ? currJob?.gpsUnloadEnd : null
                                }
                            }}
                            forcedSpeed={(selectedPoint !== null && path[selectedPoint]) ? path[selectedPoint].speed : undefined}
                            isDriver={isDriver}
                            isSelected={true}
                            forcedPosition={selectedPoint !== null && path[selectedPoint] ? path[selectedPoint] : undefined}
                            onClick={isDriver ? undefined : (e, vehicleId) => {
                                setSelectedPoint(null);
                                setLoading(true);
                                LogitarApi.getVehicleLocations(vehicleId, live ? null : date).then((results) => {
                                    setSelectedVehicle(results.vehicles[0] as VehicleLocationPath);
                                    setPath((results.vehicles[0] as VehicleLocationPath).positions);
                                }).finally(() => setLoading(false))
                            }}
                        />
                        {
                            (!!currJob) &&
                            <>
                                {
                                    !!currJob.item.departure.latitude && !!currJob.item.departure.longitude &&
                                    <>
                                        <Circle
                                            key={"dep"}
                                            radius={currJob.item.departure.radius}
                                            fillColor={inProgressColor}
                                            fillOpacity={0.00}
                                            strokeColor={inProgressColor}
                                            strokeOpacity={0.9}
                                            strokeWeight={4}
                                            center={{
                                                lat: currJob.item.departure.latitude,
                                                lng: currJob.item.departure.longitude
                                            }}
                                        />
                                        <Marker
                                            label={"A"}
                                            key={"dep-mark"}
                                            position={{
                                                lat: currJob.item.departure.latitude,
                                                lng: currJob.item.departure.longitude
                                            }}
                                        />
                                    </>
                                }
                                {
                                    !!currJob.item.arrival.latitude && !!currJob.item.arrival.longitude &&
                                    <>
                                        <Circle
                                            key={"arr"}
                                            radius={currJob.item.arrival.radius}
                                            fillColor={readyColor}
                                            fillOpacity={0.00}
                                            strokeColor={readyColor}
                                            strokeOpacity={0.9}
                                            strokeWeight={4}
                                            center={{
                                                lat: currJob.item.arrival.latitude,
                                                lng: currJob.item.arrival.longitude
                                            }}
                                        />
                                        <Marker
                                            label={"B"}
                                            key={"arr-mark"}
                                            position={{
                                                lat: currJob.item.arrival.latitude,
                                                lng: currJob.item.arrival.longitude
                                            }}
                                        />
                                    </>
                                }
                            </>
                        }
                    </>
                }

            </Map>
            {
                !isDriver &&
                <>
                    <MapTimeline
                        show={true}
                        loading={loading}
                        date={date!}
                        onDateChange={(date, live) => {
                            setDate(date);
                            setLive(live);
                        }}
                    >
                        <Autocomplete
                            sx={{
                                width: 200,
                                mr: 0.5,
                                "& .MuiAutocomplete-inputRoot": {
                                    borderRadius: 24
                                }
                            }}
                            options={filteredVehicles.map(e => ({ id: e.vehicle, label: `${e.vehicle}: ${e.licenseNumber}` }))}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            size='small'
                            value={selVeh ? { id: selVeh.vehicle, label: `${selVeh.vehicle}: ${selVeh.licenseNumber}` } : null}
                            onChange={(e, v) => {
                                // Blur the input field
                                (document.activeElement as HTMLInputElement).blur();

                                if (!v) {
                                    setSelectedVehicle(null);
                                    setSelectedPoint(null);
                                    setPath([]);
                                    return;
                                }

                                const veh = vehicles.find(e => e.vehicle === v.id);
                                if (!veh) return;

                                setSelectedPoint(null);
                                setLoading(true);
                                setCenter({ lat: veh.position.latitude, lng: veh.position.longitude });

                                LogitarApi.getVehicleLocations(veh.vehicle, live ? null : date).then((results) => {
                                    setSelectedVehicle(results.vehicles[0] as VehicleLocationPath);
                                    setPath((results.vehicles[0] as VehicleLocationPath).positions);
                                }).finally(() => setLoading(false))
                            }}
                            renderInput={(params) => <TextField {...params} label="Hae auto" />}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant="caption" sx={{ lineHeight: '0.85em', mt: 0.8 }}>
                                Passiiviset
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Switch
                                    sx={{

                                    }}
                                    checked={showPassive}
                                    size='small'
                                    onChange={(e) => {
                                        setShowPassive(e.target.checked);
                                    }}
                                />
                            </Box>
                        </Box>
                    </MapTimeline>
                    <VehicleTimeline
                        show={selectedVehicle !== null}
                        selectedVehicle={selectedVehicle !== null ? selectedVehicle : undefined}
                        selectedTimestamp={selectedTimestamp !== null ? selectedTimestamp : undefined}
                        onTimestampChange={(timestamp, index) => {
                            setSelectedPoint(index);
                        }}
                    />
                    <MapEventList
                        show={selectedVehicle !== null}
                        jobs={selectedVehicle ? selectedVehicle.jobs : undefined}
                        alerts={selectedVehicle ? selectedVehicle.alerts : undefined}
                        workhours={selectedVehicle ? selectedVehicle.workhours : undefined}
                        selectedVehicle={selectedVehicle}
                        onClickTimestamp={(t) => {
                            setSelectedTimestamp(t);
                        }}
                    />
                </>
            }
        </Box>
    )
}