import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'

export const AutocompleteUser = ({ props, option }) => {
    return (
      <Box component='li' {...props}>
        <Grid container direction='column'>
        <Grid item container direction='row'>
            <Grid item style={{ fontSize: '0.9rem' }}>
              'Tunnus: '
            </Grid>
            <Grid item style={{ fontWeight: 'bold' }}>
              {option.extra.username}
            </Grid>
          </Grid>

          <Grid item container direction='row'>
            <Grid item style={{ fontSize: '0.9rem' }}>
              'Nimi: '
            </Grid>
            <Grid item style={{ fontWeight: 'bold' }}>
              {option.extra.name}
            </Grid>
          </Grid>
          {/* <Grid item container direction='row'>
            <Grid item style={{ fontSize: '0.9rem' }}>
              'Puhelin: '
            </Grid>
            <Grid item style={{ fontWeight: 'bold' }}>
              {option.extra.phoneNumber}
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
    )
    }