import Invoicing from "../../views/Invoicing";
import Messages from "../../views/Messages";
import News from "../../views/News";
import Training from "../../views/Training";
import { ReadPermissions } from "../Permissions";

/**
 * @type {{[key: string]: {header: string, component: JSX.Element, path: string, perms: BigInt}[]}}
 */
export const billingRoutes = {
    "Toimisto": [ 
        {
            header: "Laskutus",
            component: <Invoicing />,
            path: "/invoicing",
            perms: ReadPermissions.PERM_GETJOBS | ReadPermissions.PERM_GETPLANNING | ReadPermissions.PERM_GETORDERS
        }
    ],
    "Tiedotteet": [
        {
            header: "Tiedotelista",
            component: <News />,
            path: "/news",
            perms: ReadPermissions.PERM_GETNEWS
        },
        {
            header: "Viestit",
            component: <Messages />,
            path: "/messages",
            perms: ReadPermissions.PERM_GETMESSAGES
        },
        {
            header: "Koulutukset",
            component: <Training />,
            path: "/training",
            perms: ReadPermissions.PERM_GETNEWS
        }
    ],
}
