import { Box, Card, Divider, Typography } from "@mui/material";

/**
 * @typedef {{
 *  title?: string | JSX.Element,
 *  subtitle?: string | JSX.Element,
 *  width?: number,
 *  children: JSX.Element | JSX.Element[],
 *  scrollX?: boolean,
 *  id?: string,
 * }} ReportCardProps
 */

/**
 * 
 * @param {ReportCardProps} props 
 * @returns 
 */
export default function ReportCard(props) {

    return <Card sx={{ p: 1, mr: 0, mb: 2, height: 'auto',
                    width: props.width ? props.width : 'auto',
                    overflowX: props.scrollX ? 'auto' : undefined,
                    breakInside: "avoid-page",
                }}
                id={props.id ? props.id : undefined}>
        {
            (!!props.title || !!props.subtitle) &&
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {
                    (!!props.title) &&
                    (typeof props.title === 'string' ? (
                        <Typography variant="h6" sx={{ mx: 1 }}>{props.title}</Typography>
                    ) : (
                        props.title
                    ))
                }
                {
                    (!!props.subtitle) &&
                    (typeof props.subtitle === 'string' ? (
                        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'flex-end' }} >
                            {props.subtitle}
                        </Typography>
                    ) : (
                        props.subtitle
                    ))

                }
            </Box>
        }
        {
            (!!props.title || !!props.subtitle) &&
            <Divider sx={{ m: 0.5 }} />
        }
        {props.children}
    </Card>

}