import { Marker } from "@googlemaps/markerclusterer";
import { VehiclePosition, coordinateDistanceMeters, inProgressColor, readyColor } from "../../views/MapView";
import { useCallback, useEffect, useRef, useState } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { GetVehicleAlertTranslation, VehicleSeverity } from "../VehicleAlertNotifier";
import { Tooltip } from "@mui/material";
import { format } from "date-fns";


const noColor = "rgb(120, 120, 120)";

export const GetVehicleAlertColor = (severity: VehicleSeverity) => {
    let alertColor: string;
    switch(severity) {
        case VehicleSeverity.HIGH:
            alertColor = "#cc2121";
            break;
        case VehicleSeverity.MEDIUM:
            alertColor = "#e68722";
            break;
        case VehicleSeverity.LOW:
        default:
            alertColor = "#4b8ed1";
    }
    return alertColor;
}

const easeInOutCubic = (t: number) => {
    return t < 0.5
        ? 4 * t * t * t
        : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

const VehicleMarkerElement = (props: { vehicle: VehiclePosition, forcedSpeed?: number, isDriver: boolean, isSelected: boolean }) => {

    const vehicle = props.vehicle;
    const isDriver = props.isDriver
    const isSelected = props.isSelected;

    // Get received timestamp
    const recvTime = new Date(vehicle.position.dateTime + "Z");

    const dataExpired = (Date.now() - recvTime.getTime() >= 1000 * 60 * 15) && vehicle.position.speed > 0;

    const boxWidth = 70;
    let outerBoxWidth = 70;
    const boxHeight = 55;
    const driverBoxWidth = 60;
    const driverBoxHeight = 25;

    let depColor = noColor;
    let arrColor = noColor;
    let progColor = noColor;

    let progress = 1;
    if (vehicle.job) {
        if (vehicle.job.gpsUnloadEnd || vehicle.job.gpsUnloadStart) {
            progress = 0;
            depColor = readyColor;
            arrColor = vehicle.job.gpsUnloadEnd ? readyColor : inProgressColor;
            progColor = readyColor;
        }
        else if (vehicle.job.gpsLoadEnd || vehicle.job.gpsLoadStart) {
            depColor = vehicle.job.gpsLoadEnd ? readyColor : inProgressColor;
            arrColor = noColor;
            if (vehicle.job.gpsLoadEnd) {
                // Total distance
                const tdist = coordinateDistanceMeters(
                    [vehicle.job.item.departure.latitude, vehicle.job.item.departure.longitude],
                    [vehicle.job.item.arrival.latitude, vehicle.job.item.arrival.longitude],
                );
                // Distance left
                const vdist = coordinateDistanceMeters(
                    [vehicle.position.latitude, vehicle.position.longitude],
                    [vehicle.job.item.arrival.latitude, vehicle.job.item.arrival.longitude],
                );

                progress = Math.min(1, vdist / tdist);
                progColor = inProgressColor;
            }
        }
    }

    let alertColor = "#4b8ed1";
    let alertTitle = "?";

    let borderColor = "rgb(255, 255, 255, 0)";

    if(dataExpired) {
        borderColor = "rgb(255, 80, 80, 0.7)";
    } 
    else if(isSelected) {
        borderColor = "rgb(255, 255, 255, 0)";
    }

    if(vehicle.alert) {
        outerBoxWidth = 100;
        alertColor = GetVehicleAlertColor(vehicle.alert.severity);

        const tr = GetVehicleAlertTranslation(vehicle.alert);
        alertTitle = tr.name + " " + (tr.description || "");
    }

    // alert box
    const ab = {
        x: boxWidth + 2,
        y: 0,
        width: outerBoxWidth - boxWidth - 2,
        height: boxHeight / 2
    }

    const jobName = (vehicle.job && vehicle.job.item) ? vehicle.job.item.shortName : "-";

    const textMedium: any = {
        font: '12px sans-serif',
        textAnchor: 'middle'
    }
    const textSmall: any = {
        font: '10px sans-serif',
        textAnchor: 'middle'
    }

    if (isDriver) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={driverBoxWidth} height={driverBoxHeight + 10}>
                <rect rx="4" ry="4" width={driverBoxWidth} height={driverBoxHeight} style={{ fill: 'black', opacity: 0.8 }} />
                <g fill="white">
                    <text style={{ ...textMedium }} x={"50%"} y={17}>{vehicle.licenseNumber}</text>
                </g>
                <polygon
                    points={`${driverBoxWidth * 0.33},${driverBoxHeight} ${driverBoxWidth * 0.66},${driverBoxHeight} ${driverBoxWidth * 0.5},${driverBoxHeight + 10}`}
                    style={{ fill: 'black', opacity: 0.8 }}
                />
            </svg>
        );
    }

    return (
        <Tooltip
            title={dataExpired ? `Sijainti vanhentunut (${format(recvTime, "dd.MM.yyyy HH:mm:ss")}, ${vehicle.position.speed.toFixed(0)} km/h)` : ""}
            enterDelay={1000}
        >

            <svg xmlns="http://www.w3.org/2000/svg" width={outerBoxWidth} height={boxHeight + 10}>
                <mask id="job-text-mask">
                    <rect x="0" y="0" width={boxWidth} height="100%" fill="white" />
                </mask>
                <rect
                    rx="4" ry="4" width={boxWidth} height={boxHeight}
                    fill={isSelected ? "black" : "rgb(20, 20, 20)"}
                    style={{ opacity: 0.8 }}
                />
                <rect
                    x="1" y="1"
                    rx="4" ry="4" width={boxWidth - 2} height={boxHeight - 2}
                    fill="none"
                    stroke={borderColor}
                    strokeWidth={3}
                />
                <g fill="white">
                    <text style={{ ...textMedium }} x={boxWidth / 2} y={15}>{vehicle.licenseNumber}</text>
                    <text style={{ ...textSmall }} x={boxWidth / 2} y={27} mask={`url(#job-text-mask)`}>
                        {jobName}
                        {jobName.length > 10 &&
                            <animate attributeType="XML"
                                attributeName="x" values={`${boxWidth};0`} dur="5s" repeatCount="indefinite"
                            />}
                    </text>
                    <text style={{ ...textSmall }} x={boxWidth / 2} y={38}>{props.forcedSpeed ? props.forcedSpeed.toFixed(0) : vehicle.position.speed.toFixed(0)} km/h</text>
                </g>
                <rect
                    x={boxWidth * 0.25}
                    y={boxHeight * 0.76}
                    width={boxWidth * 0.50}
                    height={boxHeight * 0.15}
                    fill={"rgb(120, 120, 120)"}
                    rx={2}
                />
                <rect
                    x={boxWidth * 0.25}
                    y={boxHeight * 0.76}
                    width={boxWidth * 0.50 * (1 - progress)}
                    height={boxHeight * 0.15}
                    fill={progColor}
                    rx={2}
                />
                <rect
                    x={boxWidth * 0.1}
                    y={boxHeight * 0.76}
                    width={boxWidth * 0.13}
                    height={boxHeight * 0.15}
                    fill={depColor}
                    rx={2}
                />
                <rect
                    x={boxWidth * 0.77}
                    y={boxHeight * 0.76}
                    width={boxWidth * 0.13}
                    height={boxHeight * 0.15}
                    fill={arrColor}
                    rx={2}
                />
                <polygon points={`${outerBoxWidth * 0.33},${boxHeight} ${outerBoxWidth * 0.66},${boxHeight} ${outerBoxWidth * 0.5},${boxHeight + 10}`} style={{ fill: 'rgb(20, 20, 20)', opacity: 0.8 }} />
                {
                    vehicle.alert &&
                    <g>
                        <Tooltip title={alertTitle}>
                            <rect 
                                rx={4} ry={4} x={boxWidth + 2} y={0} width={outerBoxWidth - boxWidth - 2} height={boxHeight / 2} 
                                fill={isSelected ? "black" : "rgb(20, 20, 20)"}
                                style={{ opacity: 0.8 }} 
                            />
                        </Tooltip>
                        <path 
                            d={`M ${ab.x + ab.width * 0.45} ${ab.y + ab.height * 0.15}
                                A 15 15 0 0 1 ${ab.x + ab.width * 0.55} ${ab.y + ab.height * 0.15}
                                L ${ab.x + ab.width * 0.9} ${ab.y + ab.height * 0.8}
                                A 15 15 0 0 1 ${ab.x + ab.width * 0.85} ${ab.y + ab.height * 0.875}
                                L ${ab.x + ab.width * 0.15} ${ab.y + ab.height * 0.875}
                                A 15 15 0 0 1 ${ab.x + ab.width * 0.1} ${ab.y + ab.height * 0.8}
                                Z`
                            }
                            style={{
                                fill: "none", 
                                stroke: alertColor, 
                                strokeWidth: 2,
                                pointerEvents: 'none'
                            }}
                        />
                        <text 
                            x={ab.x + ab.width / 2}
                            y={ab.y + ab.height / 2 + 5} 
                            textAnchor="middle" 
                            style={{
                                fontSize: '1em', 
                                fontWeight: '900',
                                fontFamily: "Arial, sans-serif", 
                                fill: alertColor,
                                pointerEvents: 'none'
                            }}
                        >
                            !
                        </text>
                    </g>
                }
            </svg>
        </Tooltip>
    );
};

type VehicleMarkerProps = {
    vehicle: VehiclePosition;
    isSelected: boolean;
    isDriver: boolean;
    forcedPosition?: VehiclePosition["position"];
    forcedSpeed?: number;
    disableEasing?: boolean;
    setMarkerRef?: (marker: Marker | null, key: string) => void;
    onClick?: (e: any, vehicle: number) => void;
}

export const VehicleMarker = (props: VehicleMarkerProps) => {

    const vehicle = props.vehicle;
    const isDriver = props.isDriver;
    const isSelected = props.isSelected;
    const animationDurationRef = useRef<number>(1000);

    const targetPositionRef = useRef<VehiclePosition["position"]>(props.forcedPosition ? props.forcedPosition : vehicle.position);
    const positionRef = useRef<VehiclePosition["position"]>(targetPositionRef.current);
    const [animatePosition, setAnimatePosition] = useState<VehiclePosition["position"]>(positionRef.current);

    const startTimeRef = useRef<number | null>(null);
    const animationFrameRef = useRef<number | null>(null);

    const ref = useCallback(
        (marker: google.maps.marker.AdvancedMarkerElement) => {
            if (props.setMarkerRef)
                props.setMarkerRef(marker, vehicle.vehicle + ":" + vehicle.licenseNumber)
        },
        [props.setMarkerRef, vehicle]
    )

    const animate = () => {
        if (!startTimeRef.current) {
            return;
        }
        const position = positionRef.current;
        const targetPosition = targetPositionRef.current;

        const deltaTime = Date.now() - startTimeRef.current;
        const progress = Math.min(1, deltaTime / animationDurationRef.current);
        const easedProgress = easeInOutCubic(progress);

        const lat = position.latitude + (targetPosition.latitude - position.latitude) * easedProgress;
        const lng = position.longitude + (targetPosition.longitude - position.longitude) * easedProgress;
        setAnimatePosition({ latitude: lat, longitude: lng, dateTime: targetPosition.dateTime, speed: targetPosition.speed });

        if (progress < 1) {
            animationFrameRef.current = requestAnimationFrame(animate);
        }
        else {
            positionRef.current = targetPosition;
            startTimeRef.current = null;
        }
    }

    useEffect(() => {
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        if(props.disableEasing) {
            targetPositionRef.current = props.forcedPosition ? props.forcedPosition : vehicle.position;
            setAnimatePosition(targetPositionRef.current);
            positionRef.current = targetPositionRef.current;
            startTimeRef.current = null;
        }
        else {
            targetPositionRef.current = props.forcedPosition ? props.forcedPosition : vehicle.position;
            positionRef.current = animatePosition;
            const distance = coordinateDistanceMeters([positionRef.current.latitude, positionRef.current.longitude], [targetPositionRef.current.latitude, targetPositionRef.current.longitude]);
            animationDurationRef.current = Math.max(1000 / distance * 1000, 1);
            startTimeRef.current = Date.now();
            
            animationFrameRef.current = requestAnimationFrame(animate);
        }

    }, [props.vehicle, props.forcedPosition]);

    useEffect(() => {

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        }
    }, [])

    return <AdvancedMarker
        key={vehicle.vehicle + ":" + vehicle.licenseNumber}
        position={{lat: animatePosition.latitude, lng: animatePosition.longitude}}
        onClick={(e) => {
            if (props.onClick)
                props.onClick(e, vehicle.vehicle)
        }}
        ref={ref}
    >
        <VehicleMarkerElement vehicle={{...vehicle, position: props.forcedPosition || vehicle.position}} forcedSpeed={props.forcedSpeed} isDriver={isDriver} isSelected={isSelected} />
    </AdvancedMarker>
};