import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { FuelType, fuelTypeList } from "./FuelPriceCard";
import { useUserConfig } from "../misc/UserConfig";

type EmissionMultipliersProps = {
    open: boolean,
    onClose: () => void,
    onReset: () => void,
    onAccept: (newMultipliers: Multiplier[]) => void,
    multipliers: Multiplier[],
}

type Multiplier = { type: FuelType, perUnit: number | string };

export type EmissionMultipliersConfig = {
    multipliers: Multiplier[] | undefined
}


export default function EmissionMultipliers(props: EmissionMultipliersProps) {
    const userConfig = useUserConfig<EmissionMultipliersConfig>('emissionMultipliers')

    const [multipliers, setMultipliers] = useState<Multiplier[]>(props.multipliers);

    const handleAccept = () => {
        const newMultipliers = multipliers.map(m => ({ ...m, perUnit: Number(m.perUnit) }));
        props.onAccept(newMultipliers);
        userConfig.config = { multipliers: newMultipliers };
    }

    const handleReset = () => {
        props.onReset();
        userConfig.config = { multipliers: undefined };
    }

    useEffect(() => {
        setMultipliers(props.multipliers);
    }, [props.open])

    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitle>Päästökertoimet (kg CO<sub>2</sub>)</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        {multipliers.map((m, i) => {
                            const fType = fuelTypeList.find(t => t.value === m.type);
                            return (
                                <TableRow key={"mul-" + i} sx={{ "&:last-child td": { borderBottomWidth: 0 } }}>
                                    <TableCell>{fType?.label} {fType?.unit && `(per ${fType.unit})`}</TableCell>
                                    <TableCell>
                                        <TextField
                                            value={m.perUnit}
                                            error={Number.isNaN(Number(m.perUnit))}
                                            helperText={Number.isNaN(Number(m.perUnit)) && "Arvon täytyy olla numero"}
                                            size="small"
                                            sx={{ width: 200 }}
                                            onChange={(e) =>
                                                setMultipliers(prev =>
                                                    prev.map(v => v.type === m.type ? ({ ...v, perUnit: e.target.value.replaceAll(",", ".") }) : v)
                                                )
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => props.onClose()}>Peruuta</Button>
                <Button variant="contained" onClick={() => handleReset()}>Palauta oletukset</Button>
                <Button
                    variant="contained"
                    onClick={() => handleAccept()}
                    disabled={multipliers.some(m => Number.isNaN(Number(m.perUnit)))}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}