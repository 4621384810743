import { LogiTarUserType } from "../User";
import { adminRoutes } from "./Admin";
import { billingRoutes } from "./Billing";
import { clientRoutes } from "./Client";
import { contractorRoutes } from "./Contractor";
import { developerRoutes } from "./Developer";
import { driverRoutes } from "./Driver";
import { officeRoutes } from "./Office";
import { planningRoutes } from "./Planning";
import { serviceRoutes } from "./Service";

/**
 * @brief Get sidebar routes
 * @param {LogiTarUserType} userType 
 * @returns 
 */
export default function getRoutes(userType) {
    let items = [];
    switch (userType) {
        case LogiTarUserType.ADMIN:
            items = adminRoutes;
            break;
        case LogiTarUserType.DEVELOPER:
            items = developerRoutes;
            break;
        case LogiTarUserType.BILLING:
            items = billingRoutes;
            break;
        case LogiTarUserType.MANAGEMENT:
            items = officeRoutes;
            break;
        case LogiTarUserType.PLANNING:
            items = planningRoutes;
            break;
        case LogiTarUserType.SERVICE:
            items = serviceRoutes;
            break;
        case LogiTarUserType.DRIVER:
            items = driverRoutes;
            break;
        case LogiTarUserType.CONTRACTOR:
            items = contractorRoutes;
            break;
        case LogiTarUserType.CLIENT:
            items = clientRoutes;
            break;
        default:
            items = [];
            break;
    }

    return items;
}


