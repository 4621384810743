import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import { useState} from 'react';

export default function UnreadMessagesPopup (props) {

    return (
        <div>
            <Dialog open={props.open}>
            <DialogTitle sx={{textAlign: 'center', fontWeight: 'bold'}}>Uusi viesti</DialogTitle>

            <DialogContent sx={{textAlign: 'center', width: 'max-content'}}>
            <p>Paina OK ja valitse viestiketju.</p>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={() => { if(props.onClose) props.onClose()}} variant="contained">OK</Button>
            </DialogActions>
            </Dialog>
        </div>
    )
}