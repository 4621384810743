import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'

export const AutocompleteVehicle = ({ props, option }) => {
  const logitarVehicleTypes = ["Vetoauto", "Perävaunu", "Pyöräkuormaaja", "Pakettiauto", "Passiivi"]
  //Get vehicles type from list
  const getType = (params) => {
    return logitarVehicleTypes[parseInt(params) - 1]
}

return (
  <Box component='li' {...props}>
    <Grid container direction='column'>
      {/* <Grid item container direction='row'>
        <Grid item style={{ fontSize: '0.9rem' }}>
          Nro:  
        </Grid>
        <Grid item style={{ fontWeight: 'bold' }}>
          {option.extra.id} 
        </Grid>
      </Grid> */}
      <Grid item container direction='row'>
        <Grid item style={{ fontSize: '0.9rem', marginRight: 5 }}>
          Rekisterinro:  
        </Grid>
        <Grid item style={{ fontWeight: 'bold'}}>
          {option.extra.vehicle1}
        </Grid>
      </Grid>
      <Grid item container direction='row'>
        <Grid item style={{ fontSize: '0.9rem', marginRight: 5 }}>
          Nimi: 
        </Grid>
        <Grid item style={{ fontSize: '0.7rem', marginTop: 3 }}>
          {option.extra.name}
        </Grid>
      </Grid>
      <Grid item container direction='row'>
        <Grid item style={{ fontSize: '0.9rem', marginRight: 5 }}>
          Tyyppi: 
        </Grid>
        <Grid item style={{ fontWeight: 'bold' }}>
          {option.extra.typename1}
        </Grid>
      </Grid>

      {(option.extra.type === 1 || option.extra.type === 2) && (
      <Grid item container direction='row'>
        <Grid item style={{ fontSize: '0.9rem', marginRight: 5 }}>
          {option.extra.type == 2 ? 'Vetoauto:' : null}
          {option.extra.type == 1 ? 'Perävaunu:' : null}
        </Grid>
        <Grid item style={{ fontSize: '0.7rem', marginTop: 3  }}>
          {option.extra.vehicle2 ? option.extra.vehicle2 : '-'}
        </Grid>
      </Grid>
      )}

    </Grid>

  </Box>
)
}