import { useEffect, useRef, useState } from 'react';

import { ListItem, ListItemText, Divider, List, Toolbar, Drawer, Box, Button, Collapse, IconButton, ListItemButton } from '@mui/material'
import { drawerWidth, drawerWidthCollapsed } from '../styles/styles';

import { Link } from "react-router-dom";
import LogiTarUser from '../misc/User';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { logitarTheme } from '../config/Themes';
import LogitarApi from '../api/LogitarApi';
import LogitarEventProvider from '../api/LogitarEventProvider';
import { PlaySound } from '../misc/SoundPlayer';

const menuHeaderStyle = {
	fontSize: 16,
	textAlign: "left",
	margin: 0,
	fontWeight: "bold",
	padding: 0
}

const sidebarButtonStyle = {
	ml: 4,
}

const sidebarItemStyle = {
	py: 0
}

const sidebarHeaderStyle = {
	//Almost same as sidebarItemStyle, but makes small tweaks possible for headers
	padding: "2px"
}

const versionNumberContStyle = {
	color: '#AAA',
	flexDirection: 'column',
	ml: 1
}

const versionNumberTextStyle = {
	flexDirection: 'row'
}

const selectedStyle = {
	backgroundColor: logitarTheme.palette.action.hover,
	borderRadius: 1
}
// Path names where not to play sound for new messages
const soundIgnorePaths = ["vehicleview"];

// Persistent unread message count
const getStoredUnreadMessageCount = () => window.localStorage.getItem("LogiTar-unreadMessages");
const setStoredUnreadMessageCount = (val) => window.localStorage.setItem("LogiTar-unreadMessages", val);

export default function SideBar(props) {

	const [open, setOpen] = useState([...Array(Object.keys(props.routes).length).keys()])

	const [selected, setSelected] = useState(props.header)

	//Lock the sidebar if a driver has signed in and they have not selected a vehicle/car yet
	const [vehicleLock, setVehicleLock] = useState(LogiTarUser.current.vehicle === null && LogiTarUser.current.isDriver())

	const [unseenMessagesCount, setUnseenMessagesCount] = useState(Number(getStoredUnreadMessageCount()))
	const [messagesInterval, setMessagesInterval] = useState(0)

	const [events, setEvents] = useState([]);

	const admin = LogiTarUser.current.isManagement()

	const backupMessagesInterval = useRef(null);

	const [allowContractorReport, setAllowContractorReport] = useState(false )

	useEffect(() => {

		let _lock = null;

		_lock = LogiTarUser.current.addChangeListener(() => {
			if (LogiTarUser.current.vehicle !== null) {
				setVehicleLock(false)
			}
		})

		return () => {
			if (_lock !== null) {
				LogiTarUser.current.removeChangeListener(_lock)
			}
		}

	},[])

	useEffect(() => {
		if ( LogiTarUser.current.isContractor() === true) {
			if ( LogiTarUser.current.info.allowContractorReport === 1 ) {
				setAllowContractorReport(true)
			} else if ( LogiTarUser.current.info.allowContractorReport === 0 ) {
				setAllowContractorReport(false)
			}
		} else {
			setAllowContractorReport(true)
		}

	},[])

	const handleCollapse = (index) => {
        if (open.includes(index)) {
            setOpen((prev) => prev.filter((element) => {return element !== index}))
        } else {
            setOpen((prev) => [...prev, index])
        }
	}

	const fetchUnreadMessageCount = () => {
        //Fetch unread messages count
        let method= "unreadmessagescount"
		let usertype = LogiTarUser.current.info.userType
		let drivervehicle = null
		if (LogiTarUser.current.isDriver() && LogiTarUser.current.vehicle) {
			drivervehicle = LogiTarUser.current.vehicle.id;
		} 
        LogitarApi.getUnreadMessages(drivervehicle, method, usertype).then((result) => {
            console.log("Uusien viestien lkm " + result.messagescount)
			if (unseenMessagesCount < result.messagescount) {
				// Don't play sound in /vehicleview because that already plays a sound
				if (!soundIgnorePaths.some(path => window.location.href.includes(path))) {
					PlaySound("ring");
				}
			}
            setUnseenMessagesCount(result.messagescount)
			setStoredUnreadMessageCount(result.messagescount);
        })
        .catch((err) => {
            console.error(err)
        })
	}

	// Interval fetch (backup if events are not connected)
	useEffect(() => {
		fetchUnreadMessageCount();
	}, [messagesInterval]);

	// Initial fetch for unread message count
	useEffect(() => fetchUnreadMessageCount(), []);

	useEffect(() => {
		if (LogiTarUser.current.vehicle !== null) {
			fetchUnreadMessageCount();
		}
		if (events.length < 1) return;
		events.forEach(event => {
			if (event.data.type === "accepted" || event.data.type === "sent" || event.data.type === "deleted") {
				fetchUnreadMessageCount();
            }
        })
		setEvents([]);
	}, [events, LogiTarUser.current.vehicle])

	return (
		<Drawer
			sx={{
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box',
					background: logitarTheme.sidebar.background.backgroundColor
				}
			}}
			
			variant="persistent"
			anchor='left'
			open={props.open}
			//position="fixed"
			
		>
			<Box sx={{display: 'flex', boxSizing: 'border-box', width: '100%', height: 64}}>
				<Box sx={{flex: 1, my: 1, ml: 3}}>
					<img className='logo' style={{height: '100%'}} src="logo_konnekuljetus.svg" alt="Logo"></img>
				</Box>
				<Box sx={{flex: 1, maxWidth: 32, mr: 2}}>
					<IconButton
						onClick={() => { props.onCollapse() }}
					>
						<KeyboardDoubleArrowLeft />
					</IconButton> 
				</Box>
			</Box>
			<Divider />
			{/** Mapping sidebar headers and links, items come from Routes.js */}
			<List sx={{ overflowY: 'auto' }}>
				{Object.keys(props.routes).map((menuHeader, index) => {
					if(menuHeader.startsWith("_")) {
						// Hidden menu
						return null;
					}

					if(Object.keys(props.routes[menuHeader]).filter(e => LogiTarUser.current.checkPermission(props.routes[menuHeader][e].perms)).length === 0)
						return null;
					
					return (
						<List key={index} sx={sidebarHeaderStyle}>
							<ListItem sx={{cursor: "pointer"}} onClick={() => handleCollapse(index)}>
								<ListItemText disableTypography={true} sx={menuHeaderStyle}>{menuHeader}</ListItemText>
								{open.includes(index) ? <KeyboardArrowUpIcon sx={{marginLeft: "auto"}}/> : <KeyboardArrowDownIcon sx={{marginLeft: "auto"}}/>}
							</ListItem>
							<Collapse in={open.includes(index)}>
								{props.routes[menuHeader].map(({ header, component, path, perms }, index) => {
									if (!LogiTarUser.current.checkPermission(perms))
										return null

									const ifSelected = header === selected

									return (
										<List sx={ ifSelected ? {...sidebarItemStyle, ...selectedStyle} : sidebarItemStyle} key={index+100}> 
											<Link style={{ textDecoration: 'none', color: 'inherit'}} to={(!vehicleLock && header != 'Alihankintaraportit')  || (!vehicleLock && (allowContractorReport === true && header === 'Alihankintaraportit')) ? path : "#"}> 
												<ListItemButton 
												disabled={vehicleLock || (allowContractorReport === false && header === 'Alihankintaraportit')} 
												sx={{p:0}} 
												onClick={() => {
													if (vehicleLock || (allowContractorReport === false && header === 'Alihankintaraportit')) return;
													props.onClick(header); 
													setSelected(header)
												}}
												>
												{header === 'Viestit' ? (
													<ListItemText disableTypography={true} sx={sidebarButtonStyle}>{header} ({unseenMessagesCount})</ListItemText>
												) : (
													<ListItemText disableTypography={true} sx={sidebarButtonStyle}>{header}</ListItemText>
												)}
												</ListItemButton>
											</Link>
										</List>
									)
								}
								)}
							</Collapse>
							{
								index < Object.keys(props.routes || {}).length - 1 &&
								<Divider />
							}
						</List>
						
					)
				})}
			</List>
			<Divider />
			<Box sx={versionNumberContStyle}>
				<Box sx={versionNumberTextStyle}>
					{
						//<Box sx={{display: 'inline'}} id="TEST_product_name"> {process.env.REACT_APP_NAME}</Box>
					}
					<Box sx={{display: 'inline'}} id="TEST_version_number_web">{process.env.REACT_APP_VERSION}</Box> (<Box sx={{display: 'inline', fontSize: '0.7em'}} id="TEST_version_number_server">
						{LogiTarUser.current.token.server_version}
						</Box>) <Box sx={{display: 'inline', fontSize: '0.7em'}}>{"SoftRain Blobs Oy"}</Box>
				</Box>
			</Box>
			{ LogiTarUser.current.isDriver() && 
				<LogitarEventProvider 
					subscriptions={["message"]} 
					onEvent={(s, d, i) => setEvents(prev => [...prev, { source: s, data: d, id: i }])}
					onConnect={() => {
						fetchUnreadMessageCount();
						if (backupMessagesInterval.current) {
							clearInterval(backupMessagesInterval.current)
							backupMessagesInterval.current = null;
						}
					}}
					onDisconnect={() => {
						if (!backupMessagesInterval.current) {
							backupMessagesInterval.current = setInterval(() => {
								setMessagesInterval(prev => prev + 1)
							}, 15000)
						}
					}}
				/> }
			{ LogiTarUser.current.isManagement() && 
				<LogitarEventProvider 
					subscriptions={["messageOffice"]} 
					onEvent={(s, d, i) => setEvents(prev => [...prev, { source: s, data: d, id: i }])}
					onConnect={() => {
						fetchUnreadMessageCount();
						if (backupMessagesInterval.current) {
							clearInterval(backupMessagesInterval.current)
							backupMessagesInterval.current = null;
						}
					}}
					onDisconnect={() => {
						if (!backupMessagesInterval.current) {
							backupMessagesInterval.current = setInterval(() => {
								setMessagesInterval(prev => prev + 1)
							}, 15000)
						}
					}}
				/> }
		</Drawer>
	);
}
