
import { Box, Button, FormGroup, Paper, TextField, Typography,  Stack, Checkbox,  CircularProgress, useTheme, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import { useContext, useEffect, useState, useRef } from 'react'
import LogitarApi from '../api/LogitarApi'
import { exitContainerStyle, exitIconStyle, headerStyle } from '../styles/styles'
import { Close, Save } from '@mui/icons-material'
import { SnackContext } from '../App'
import { TipButton } from '../misc/InternalFeatures'
import PopupScreen from './PopupScreen'
import { useSnackbar } from 'notistack'
// import LogiTarUser from '../misc/User';

const newsPopStyle = {
    pb: 0, display: "flex", flexDirection: "column",
}

export default function NewsPop(props) {

    const [news, setNews] = useState({ ...props.news })
    const { enqueueSnackbar, closeSnackbar} = useSnackbar()
    const [file, setFile] = useState((props.news.fileID) ? {
        id: props.news.fileID, name: props.news.fileName,
        MIME: props.news.MIME, size: props.news.size,
        data: props.news.data
    } : {})
    const [postNews, setPostNews] = useState((!props.new) ? { id: props.news.id, date: props.news.date } : { ...props.news})
    const [fileState, setFileState] = useState(false)
    const [isUsersChecked, setIsUsersChecked] = useState(props.news.isUsersChecked);
    const [isContractorsChecked, setIsContractorsChecked] = useState(props.news.isContractorsChecked);
    const [inProgressIcon, setInProgressIcon] = useState(false);

    const theme = useTheme();
    const mdBreakPoint = useMediaQuery(theme.breakpoints.up("md"));
    const smBreakPoint = useMediaQuery(theme.breakpoints.up("sm"));

    const handleChange = (event) => {
        if (event.target.type === "checkbox") {
            if (event.target.checked) {
                setNews(prev => ({ ...prev, [event.target.name]: 1 }))
                setPostNews(prev => ({ ...prev, [event.target.name]: 1 }))
            }
            else {
                setNews(prev => ({ ...prev, [event.target.name]: 0 }))
                setPostNews(prev => ({ ...prev, [event.target.name]: 0 }))
            }
        }
        else {
            setNews(prev => ({ ...prev, [event.target.name]: event.target.value }))
            setPostNews(prev => ({ ...prev, [event.target.name]: event.target.value }))
        }
    }

    //Wrap functions here to reduce Button clutter
    const handleUpdate = () => {
   
        if (news.header == "" || news.content == "" || (isUsersChecked == false && isContractorsChecked == false)) {
            enqueueSnackbar("Anna tiedotteen otsikko, sisältö ja valitse tiedotteen vastaanottajat", { variant: 'error' });
            return;
        }

        setInProgressIcon(true);
        LogitarApi.setNews({ ...postNews, date: format(new Date(postNews.date), "yyyy-MM-dd HH:mm:ss") }).then((result) => {

            let latestid = result.latestId ? result.latestId : postNews.id
            let snackInfo = "Rivi otsikolla: '" + news.header + "' tallennettu"

            if (latestid) {
                if (fileState) {
                    LogitarApi.setFile({ ...file, news: props.new ? latestid : postNews.id }, "news").then((result) => {
                        setInProgressIcon(false);
                        props.onClick(false)
                        props.rowUpdate(props.count + 1)                     
                    })
                    .catch((err) => console.error(err))
                } else {
                    try {
                        if (file.id) LogitarApi.setFile({...file, MIME: null, data: null}, "news");
                    } catch (error) {
                        console.log(error)
                    }
                    setInProgressIcon(false);
                    props.onClick(false)
                    props.rowUpdate(props.count + 1)            
                }
    
                let news = ({...postNews, 'id':latestid, 'usersNews': isUsersChecked, 'contractorsNews': isContractorsChecked  })
                LogitarApi.insertNotification(news).then((result) => {})
                .catch((err) => console.error(err))
    
                enqueueSnackbar(snackInfo)
            }
        })
        .catch((err) => {
            setInProgressIcon(false);
            console.error(err);
            enqueueSnackbar(`Rivi otsikolla: ${news.header} tallennus epäonnistui`, {variant: "error"})
            
        })
    }

    //base64 encode file, so it can be sent to server or shown in page
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }


    const onFileChange = (event) => {
        getBase64(event.target.files[0]).then((result) => {
            let fileData = result.split(",")[1]
            setFile(prev => ({
                ...prev,
                name: event.target.files[0].name,
                MIME: event.target.files[0].type,
                size: event.target.files[0].size,
                data: fileData
            }))
            setFileState(true)
        })
    }

    //Removes photo from the item
    const removeFile = () => {
        setFileState(false)
        setFile({...file, name: null, MIME: null, size: null, data: null})
        fileInput.current.value = "";
    }

    const fileInput = useRef()

    if (props.admin) {
        return (
            <PopupScreen
                title={"Tiedote"}
                draggable
                onClose={() => props.onClick(false)}
                onSave={() => handleUpdate()}
                staticButtons
            >
                <div>
                    <Box sx={{
                        ...newsPopStyle, '& .MuiTextField-root': {
                            mb: 1.5,
                            mt: 1
                        },
                        '& .MuiButton-root': {
                            mb: 1
                        }, '& .MuiTypograpgy-root': { mb: 0 }
                    }}>

                    {inProgressIcon && <CircularProgress size={25} sx={{ml:25}}/>}
                    <Stack direction="row">
                        <Checkbox
                            name="isUsersChecked"
                            onClick={() => setIsUsersChecked(!isUsersChecked)}
                            onChange={handleChange} 
                            checked={!!isUsersChecked}
                            disabled={!props.new}
                        />
                        <Typography variant="caption"  sx={{ mt:2, mr: 1}}>Käyttäjät</Typography>
                        <Checkbox
                            name="isContractorsChecked"
                            onClick={() => setIsContractorsChecked(!isContractorsChecked)}
                            onChange={handleChange} 
                            checked={!!isContractorsChecked}
                            disabled={!props.new}
                        />
                        <Typography variant="caption"  sx={{ mt:2, mr: 1}}>Alihankkijat</Typography>
                    </Stack>

                        <TextField label="Otsikko" onChange={handleChange} name="header" value={news.header}></TextField>
                        <TextField label="Pvm" disabled name="date" value={format(new Date(news.date), "yyyy.MM.dd HH:mm:ss")}></TextField>
                        <TextField label="Tiedote" onChange={handleChange} name="content" sx={{ width: "100%" }} multiline minRows={smBreakPoint ? 10 : 5} maxRows={smBreakPoint ? 15 : 7} value={news.content}></TextField>
                        <div>
                            <FormGroup row >
                                <Button onClick={() => fileInput.current.click()} sx={{ mr: 1 }} variant="contained" >Valitse Tiedosto</Button>
                                <input
                                    name="Liitetiedosto"
                                    ref={fileInput}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={onFileChange}
                                />
                                <Button variant="contained" onClick={() => removeFile()}>Poista tiedosto</Button>

                                <div>
                                    <Typography sx={{ color: (file.MIME) ? (theme.palette.mode === 'light') ? "blue" : "lightblue" : "text.primary", mt: 1, ml: 5, cursor: (file.MIME) ? "pointer" : "normal", ":hover": (file.MIME) ? {textDecoration: "underline"} : {textDecoration: "none"}}}
                                        onClick={(file.MIME) ? (event) => { if (fileState == true) { props.openFile(event, file.id, file) } else { props.openFile(event, file.id) } } : () => { }}>
                                        {file.MIME ? file.name : "Ei tiedostoa"}
                                    </Typography>
                                </div>
                            </FormGroup>
                        </div>
                    </Box>
                </div>
            </PopupScreen>
        )
    }

    return (
        <PopupScreen
            title={props.news.header}
            draggable
            onClose={() => props.onClick(false)}
        >
            <div style={{ width: mdBreakPoint ? 600 : "100%", maxWidth: 600 }}>
                <Box>

                </Box>
                <Box sx={{
                    ...newsPopStyle, '& .MuiTypography-root': {
                        mb: 2,
                        wordWrap: "break-word",
                    }
                }}>
                    <Typography >{props.news.date}</Typography>
                    <Paper elevation={5} sx={{ display: "block", p: 1, mb: 3, width: "100%", maxHeight: "50vh", overflowY: "auto" }}>
                        <Typography sx={{ whiteSpace: "pre-line" }}>{props.news.content}</Typography>

                    </Paper>
                    <Typography sx={{ color: (file.name) ? (theme.palette.mode === 'light') ? "blue" : "lightblue" : "text.primary", mt: 1, cursor: (file.name) ? "pointer" : "normal", ":hover": (file.name) ? {textDecoration: "underline"} : {textDecoration: "none"}}}
                        onClick={(file.id) ? (event) => { props.openFile(event, file.id) } : () => { }}>
                        {file.name ? file.name : "Ei tiedostoa"}</Typography>
                </Box>
                {props.accept && (
                    <div style={{ textAlign: 'right' }}> {/* Aligns button to the right */}
                        {/* <Button  onClick={() => acceptNotification()} variant="contained">Hyväksy tiedote</Button> */}
                    </div>
                )}

            </div>
        </PopupScreen>
    )
}