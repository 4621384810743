import { ArrowDropDown, ChevronRight, Download, ExpandMore, Upload } from "@mui/icons-material";
import { Alert, Box, Button, Card, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Paper, Popover, Radio, RadioGroup, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { Fragment, useEffect, useState } from "react";
import LogitarApi, { APIPath } from "../api/LogitarApi";
import Config from "../config/Config";
import { DateRangeSelect } from "../components/DateSelect";

export default function Invoicing() {

    const [vehicles, setVehicles] = useState([]);
    const [fetchingVehicles, setFetchingVehicles] = useState(true);
    const [clients, setClients] = useState([]);
    const [fetchingClients, setFetchingClients] = useState(true);

    const [allVehiclesSelected, setAllVehiclesSelected] = useState(1);
    const [allClientsSelected, setAllClientsSelected] = useState(1);

    const _sdate = new Date();
    _sdate.setDate(_sdate.getDate() - 14);
    const [dateStart, setDateStart] = useState(_sdate);
    const [dateEnd, setDateEnd] = useState(new Date());

    const [anchorEl, setAnchorEl] = useState(null);
    const [vehicleMenuOpen, setVehicleMenuOpen] = useState(false);
    const [clientMenuOpen, setClientMenuOpen] = useState(false);

    const [invoiceData, setInvoiceData] = useState(/** @type {GetInvoiceResponse} */({}));

    const [fetching, setFetching] = useState(false);

    const [invoiceVehicle, setInvoiceVehicle] = useState(null);

    const [invoiceItem, setInvoiceItem] = useState(null);

    const [invoiceClient, setInvoiceClient] = useState(null);

    let defaultInvoicePreviewMode = 'vehicle';
    if (Config.moduleEnabled("talenom") && Config.moduleEnabled("tkuinvoice")) {
        defaultInvoicePreviewMode = 'vehicle';
    }
    else if (Config.moduleEnabled("tkuinvoice")) {
        defaultInvoicePreviewMode = 'vehicle';
    }
    else if (Config.moduleEnabled("talenom")) {
        defaultInvoicePreviewMode = 'talenom';
    }

    const [invoicePreviewMode, setInvoicePreviewMode] = useState(/** @type {'vehicle'|'item'|'talenom'} */(defaultInvoicePreviewMode));

    const [selectedJobs, setSelectedJobs] = useState([]);

    const [downloadURLCSV, setDownloadURLCSV] = useState("javascript:void");
    const [downloadURLTKU, setDownloadURLTKU] = useState("javascript:void");

    const [talenomInProgress, setTalenomInProgress] = useState(false);
    const [talenomFileUploadInProgress, setTalenomFileUploadInProgress] = useState(false);

    const [invoiceAlertSeverity, setInvoiceAlertSeverity] = useState('success');
    const [invoiceAlertText, setInvoiceAlertText] = useState(null);

    /**
     * 
     * @param {string} message 
     * @param {import("@mui/material").AlertColor} severity 
     */
    function showAlert(message, severity) {
        setInvoiceAlertSeverity(severity);
        setInvoiceAlertText(message);
    }


    const _defaultTalenomConfig = {
        billed: false,
        /**
         * @type {'separate'|'group-cars'}
         */
        billMode: 'separate',
        addWaybillNumber: false,
        onlyWaybillNumber: false,
        addDate: false
    }

    const [talenomConfig, setTalenomConfig] = useState(_defaultTalenomConfig);

    useEffect(() => {
        LogitarApi.getJobs({ timeFrame: { start: dateStart, end: dateEnd }, extent: "alljoin", dateCompare: "billingDate" }).then((data) => {
            const vehiclesOfJobs = data.jobs.filter(job => job.checkState === 3).map(job => job.vehicle);
            const clientsOfJobs = data.jobs.filter(job => job.checkState === 3).map(job => job.client);
            setFetchingVehicles(true);
            LogitarApi.getVehicles().then((data) => {
                let cars = [];
                for (let v of data.vehicles) {
                    if ((v.type === 1 || v.type === 5) && vehiclesOfJobs.includes(v.id)) {
                        cars.push({ id: v.id, name: v.name, licenseNumber: v.licenseNumber, selected: 1 });
                    }
                }
                setVehicles(cars);
            }).catch(err => {
                console.error(err);
                setVehicles([]);
            }).finally(() => setFetchingVehicles(false))
            
            setFetchingClients(true);
            LogitarApi.getClients({ extent: 'listjoin' }).then((data) => {
                const clis = data.clients.filter(c => clientsOfJobs.includes(c.id)).map((e) => {
                    return {
                        id: e.id,
                        name: e.name,
                        selected: 1
                    }
                })
                setClients(clis);
            }).catch(err => {
                console.error(err);
                setClients([]);
            }).finally(() => setFetchingClients(false))
        }).catch(err => {
            console.error(err);
            setVehicles([]);
            setClients([]);
        })         
    }, [dateStart, dateEnd])

    function fetchInvoiceData() {
        setFetching(true);

        const filters = getFilters();
        LogitarApi.getInvoice(filters).then(data => {

            setInvoiceData(data);
            if (Object.keys(data.invoice.vehicles).length > 0) {
                // Set first vehicle select active
                setInvoiceVehicle(Object.keys(data.invoice.vehicles)[0]);
            }
            else {
                setInvoiceVehicle(null);
            }
            if (Object.keys(data.invoice.items).length > 0) {
                // Set first item select active
                setInvoiceItem(Object.keys(data.invoice.items)[0]);
            }
            else {
                setInvoiceItem(null);
            }
            if (Object.keys(data.invoice.clients).length > 0) {
                // Set first client select active
                setInvoiceClient(Object.keys(data.invoice.clients)[0]);
            }
            else {
                setInvoiceItem(null);
            }
            setSelectedJobs(data.invoice.jobs.filter(e => data.invoice.items[e.item].talenomProduct != null).map(e => e.id));

            updateDownloadURLs();

            setFetching(false);
        }).catch((err) => {
            setFetching(false);
            showAlert("Laskun tietojen hakeminen epäonnistui", "error");
        })
    }

    /**
     * 
     * @param {number|null} id 
     * @param {boolean} state 
     */
    function updateVehicleSelect(id, state) {
        const nVehicles = [...vehicles];
        if (id === null) {
            nVehicles.forEach(n => {
                n.selected = state ? 1 : 0;
            })
            setAllVehiclesSelected(state ? 1 : 0);
        }
        else if (id !== null) {
            const c = nVehicles.find(e => e.id === id);
            if (c) {
                c.selected = state ? 1 : 0;
                let indeterminate = false;
                for (let e of nVehicles) {
                    if (e.selected !== (state ? 1 : 0)) {
                        indeterminate = true;
                        break;
                    }
                }
                if (indeterminate) {
                    nVehicles.selected = 2;
                }
                else if (state === false) {
                    nVehicles.selected = 0;
                }
                else if (state === true) {
                    nVehicles.selected = 1;
                }
            }
        }

        setVehicles(nVehicles);
    }

    /**
     * 
     * @param {number|null} client
     * @param {boolean} state 
     */
    function updateClientSelect(client, state) {
        const nClients = [...clients];

        if (client === null) {
            nClients.forEach(e => {
                e.selected = state ? 1 : 0;
            })
            setAllClientsSelected(state ? 1 : 0);
        }
        else {
            const c = nClients.find(e => e.id === client);
            if (c) {
                c.selected = state ? 1 : 0;
                let indeterminate = false;
                for (let e of nClients) {
                    if (e.selected !== (state ? 1 : 0)) {
                        indeterminate = true;
                        break;
                    }
                }
                if (indeterminate) {
                    setAllClientsSelected(2);
                }
                else if (state === false) {
                    setAllClientsSelected(0);
                }
                else if (state === true) {
                    setAllClientsSelected(1);
                }
            }
        }

        setClients(nClients);
    }

    const createVehicleLabel = (caption, checked, id) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
                id={`checkbox-${caption}`}
                color="primary"
                onClick={e => { updateVehicleSelect(id, e.target.checked); return e.stopPropagation() }}
                checked={checked === 1}
                indeterminate={checked === 2}
            />
            <Typography variant="caption">{caption}</Typography>
        </div>
    }

    const createClientLabel = (caption, checked, client, readOnly = false) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
                id={`checkbox-${caption}`}
                color="primary"
                onClick={e => { updateClientSelect(client, e.target.checked); return e.stopPropagation() }}
                checked={checked === 1}
                indeterminate={checked === 2}
                disabled={readOnly}
            />
            <Typography variant="caption">{caption}</Typography>
        </div>
    }

    function onTalenomConfigChange(field, value) {
        const tc = { ...talenomConfig };
        tc[field] = value;
        // Logic to set certain fields false
        if (tc.billMode === 'group-cars') {
            tc.addDate = false;
        }
        if (tc.addWaybillNumber)
            tc.onlyWaybillNumber = false;

        if (tc.onlyWaybillNumber) {
            tc.addWaybillNumber = false;
            tc.addDate = false;
        }

        setTalenomConfig(tc);
    }

    /**
     * @brief Selects a job (checkbox in the list)
     * 
     * @param {number|null} job Job ID. Select/deselect everything if null
     * @param {boolean} select true if selected
     * @returns 
     */
    function onSelectJob(job, select) {
        if (job === null) {
            const clientJobs = invoiceData.invoice.jobs.filter(e => !(invoiceData.invoice.items[e.item].talenomProduct == null && e.talenomVariant == null) && e.client == invoiceClient).map(e => e.id);
            if (select) {
                setSelectedJobs([...selectedJobs, ...clientJobs.filter(e => !selectedJobs.includes(e))]);
            }
            else {
                setSelectedJobs(selectedJobs.filter(e => !clientJobs.includes(e)));
            }
            return;
        }

        const newSelectedJobs = [...selectedJobs];
        const ix = newSelectedJobs.findIndex(e => e == job);
        if (select) {
            if (ix < 0) {
                // Push 
                newSelectedJobs.push(job);
            }
        }
        else {
            if (ix >= 0) {
                // Splice
                newSelectedJobs.splice(ix, 1);
            }
        }
        setSelectedJobs(newSelectedJobs);
    }

    /**
     * @brief Creates filters
     * @param {boolean} includeJobs 
     * @param {boolean} includeTalenom
     * @returns 
     */
    function getFilters(includeJobs = false, includeTalenom = false) {

        /*
        {
            start:      string, // YYYY-MM-DD
            end:        string, // YYYY-MM-DD
            vehicles:   number[],
            clients:    number[],
            jobs?:      number[], // IDs of jobs to be included (unset = all within other filters)
            talenom?: { // Talenom configuration
                billed:     boolean, // Send already billed rows too
                billMode:   'separate'|'group-cars', // Billing mode
                addWaybillNumber: boolean, // Add waybill number? (disabled if billMode === 'group-cars' or onlyWaybillNumber === true)
                onlyWaybillNumber: boolean, // Add waybill number? (disabled if billMode === 'group-cars' or addWaybillNumber === true)
                addDate:    boolean
            }
        } 
        */
        let filters = {
            start: format(dateStart, "yyyy-MM-dd"),
            end: format(dateEnd, "yyyy-MM-dd"),
            vehicles: vehicles.filter(e => e.selected === 1).map(x => x.id),
            clients: clients.filter(e => e.selected === 1).map(x => x.id),
        }

        if (includeJobs) {
            // Since ALL jobs from ALL clients are selected, this line filters selectedJobs
            // to only include the jobs on the current client (invoiceClient)
            const currentClientJobs = invoiceData.invoice.jobs.filter(y => y.client == invoiceClient).map(y => y.id);
            filters.jobs = selectedJobs.filter(e => currentClientJobs.includes(e));
        }
        if (includeTalenom) {
            filters.talenom = { ...talenomConfig };
            // Talenom only requires a single client
            filters.clients = [invoiceClient];
        }

        return filters;
    }

    function sendTalenomInvoice() {
        setTalenomInProgress(true);

        LogitarApi.createTalenomInvoice(getFilters(true, true)).then((msg) => {
            if (msg.status) {
                showAlert("Lasku lähetetty onnistuneesti", "success");
            }
            else {
                showAlert("Laskun lähettäminen epäonnistui (Talenom)", "error");

            }
            fetchInvoiceData();
            setTalenomInProgress(false);
        }).catch((err) => {
            showAlert("Laskun lähettäminen epäonnistui (Palvelinvirhe)", "error");
            setTalenomInProgress(false);
        })
    }

    function updateDownloadURLs() {
        setDownloadURLCSV(APIPath + `/createinvoice.php?key=${LogitarApi.getCookie("login-token")}&format=csv&filters=${encodeURIComponent(JSON.stringify(getFilters()))}`);
        setDownloadURLTKU(APIPath + `/createinvoice.php?key=${LogitarApi.getCookie("login-token")}&format=tku&filters=${encodeURIComponent(JSON.stringify(getFilters()))}`);
    }

    function talenomUploadFile(type, file) {
        if (!file)
            return;

        setTalenomFileUploadInProgress(true);
        LogitarApi.setTalenomData(type, file).then((data) => {
            console.log(data);
            setTalenomFileUploadInProgress(false);
            showAlert((type === 'clients' ? "Asiakas" : "Tuote") + "rekisteri päivitetty", "success");

        }).catch((err) => {
            console.log(err);
            setTalenomFileUploadInProgress(false);
            showAlert((type === 'clients' ? "Asiakas" : "Tuote") + "rekisterin päivittäminen epäonnistui", "error");
        })
    }

    function DownloadLinkList() {
        return <Paper sx={{ marginTop: 2 }}>
            <Button sx={{ flexDirection: 'column' }} href={downloadURLCSV}>
                <Download />
                <Typography sx={{ fontSize: '0.8em' }}>.csv</Typography>
            </Button>
            {
                false &&
                <Button sx={{ flexDirection: 'column' }} href={downloadURLTKU}>
                    <Download />
                    <Typography sx={{ fontSize: '0.8em' }}>.tku</Typography>
                </Button>
            }
        </Paper>
    }

    function TalenomConfigBox() {

        const TalenomPreview = () => {
            let preData = [
                {
                    productNumber: "1234R",
                    productName: "Äänekoski-Keuruu",
                    description: "Metsähake",
                    quantity: "42.4",
                    unit: "tn",
                    waybillNumber: "15183513",
                    date: "19.8.2023"
                },
                {
                    productNumber: "4321R",
                    productName: "Vaasa-Seinäjoki",
                    description: "Tikkuhake",
                    quantity: "4",
                    unit: "kpl",
                    waybillNumber: "53184103",
                    date: "20.8.2023"
                },
                {
                    productNumber: "9312R",
                    productName: "Lappeenranta-Joensuu",
                    description: "Puumurske",
                    quantity: "132",
                    unit: "m3",
                    waybillNumber: "24242245",
                    date: "20.8.2023"
                },
                {
                    productNumber: "9312R",
                    productName: "Lappeenranta-Joensuu",
                    description: "Puumurske",
                    quantity: "122",
                    unit: "m3",
                    waybillNumber: "335381",
                    date: "21.8.2023"
                }
            ]

            if (talenomConfig.billMode === "group-cars") {
                const oldData = [...preData];
                preData = [];
                oldData.forEach(e => {
                    const row = preData.find(y => y.productNumber === e.productNumber);
                    if (row) {
                        row.quantity = String(Number(row.quantity) + Number(e.quantity));
                        row.waybillNumber += ", " + e.waybillNumber;
                    }
                    else {
                        preData.push(e);
                    }
                })

            }

            return <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.8em', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>Tuotenumero</th>
                        <th>Tuote</th>
                        <th>Määrä</th>
                        <th>Yksikkö</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        preData.map((e, i) => {
                            return <Fragment>
                                <tr>
                                    <td>{e.productNumber}</td>
                                    <td>{talenomConfig.onlyWaybillNumber ? e.waybillNumber : e.productName}</td>
                                    <td>{e.quantity}</td>
                                    <td>{e.unit}</td>
                                </tr>
                                <tr style={{ fontSize: '0.7em' }}>
                                    <td></td>
                                    <td>
                                        {
                                            !talenomConfig.onlyWaybillNumber &&
                                            <Fragment>
                                                {
                                                    talenomConfig.onlyWaybillNumber ?
                                                        "" :
                                                        (
                                                            e.description + " " +
                                                            (talenomConfig.addWaybillNumber ? e.waybillNumber : "") + " " +
                                                            (talenomConfig.addDate ? e.date : "")
                                                        )
                                                }
                                            </Fragment>
                                        }
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </Fragment>
                        })
                    }

                </tbody>
            </table>
        }

        if (!invoiceData.invoice.clients[invoiceClient])
            return null;

        return <Box sx={{ flex: 2 }}>
            <Paper sx={{ padding: 1, ml: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h6" sx={{ flex: 1 }}>
                        Talenom lasku
                    </Typography>
                    <Typography variant="h6" sx={{ flex: 1, textAlign: 'right', color: '#33b3de' }}>
                        {invoiceData.invoice.clients[invoiceClient].name}
                    </Typography>
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <FormGroup>
                    <RadioGroup
                        aria-labelledby="talenom-billmode-label"
                        value={talenomConfig.billMode}
                        onChange={(e, v) => onTalenomConfigChange('billMode', v)}
                    >
                        <FormControlLabel value="separate" control={<Radio />} label="Kuormat eritelty" />
                        <FormControlLabel value="group-cars" control={<Radio />} label="Kuormat autokohtaisesti yhteensä" />
                    </RadioGroup>
                    <FormControlLabel
                        control={<Checkbox checked={!!talenomConfig.addWaybillNumber} onChange={e => onTalenomConfigChange('addWaybillNumber', e.target.checked)} />}
                        label="Lisää rahtikirjan numero"
                        disabled={talenomConfig.onlyWaybillNumber}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={!!talenomConfig.onlyWaybillNumber} onChange={e => onTalenomConfigChange('onlyWaybillNumber', e.target.checked)} />}
                        label="Vain rahtikirjan numero"
                        disabled={talenomConfig.addWaybillNumber}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={!!talenomConfig.addDate} onChange={e => onTalenomConfigChange('addDate', e.target.checked)} />}
                        label="Lisää päivämäärä"
                        disabled={talenomConfig.billMode === 'group-cars' || talenomConfig.onlyWaybillNumber}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={!!talenomConfig.billed} onChange={e => onTalenomConfigChange('billed', e.target.checked)} />}
                        label="Lähetä myös lähetetyt toteutukset"
                    />
                </FormGroup>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography variant="caption">Muodon esikatselu</Typography>
                <Paper sx={{ mt: 0.5, m: 0, p: 0 }}>
                    <TalenomPreview />
                </Paper>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Box>
                    {
                        /*
                        <Button>
                            <Rule />
                            Rivien valinta
                        </Button>
                        */
                    }
                    <Tooltip
                        title={
                            talenomInProgress ? "Lähetetään..." :
                                ((invoiceData.invoice.clients[invoiceClient].talenomClient == null) ?
                                    "Talenom asiakasnumeroa ei ole asetettu tälle asiakkaalle" :
                                    "Lähetä Talenom lasku")
                        }
                    >
                        <span>
                            <Button
                                sx={{ flexDirection: 'row' }}
                                onClick={() => { sendTalenomInvoice() }}
                                disabled={talenomInProgress || (invoiceData.invoice.clients[invoiceClient].talenomClient == null)}
                            >
                                {
                                    talenomInProgress &&
                                    <CircularProgress size={24} />
                                }
                                {
                                    !talenomInProgress &&
                                    <Upload />
                                }
                                Lähetä lasku
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Paper>
        </Box>
    }

    /**
     * @brief List jobs of a client
     */
    function clientJobSelectList() {
        // Group by nothing, filter by client
        const list = invoiceData.invoice.jobs.filter(e => e.client == invoiceClient);

        const billingReasonColor = '#f2cc8f';
        const nonBilledColor = 'inherit';
        const billedColor = '#9adbb7';

        const hasFishItems = Object.keys(invoiceData.invoice.items).some(key => invoiceData.invoice.items[key].itemType === "fish");

        const legendStyles = {
            padding: 2, borderRadius: 5, marginRight: 10,
            border: '1px solid', borderColor: "primary.contrastText"
        }

        return <Box>
            <Tabs
                TabIndicatorProps={{ sx: { display: 'none' } }}
                sx={{
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                    },
                    width: '100%'
                }}
                value={invoiceClient}
                onChange={(e, v) => { if (v !== null) setInvoiceClient(v) }}
                exclusive="true"
            >
                {
                    Object.keys(invoiceData.invoice.clients).map((k, i) => {
                        return <Tab
                            key={"vehicle-button-group-" + k}
                            sx={{
                                whiteSpace: 'nowrap', padding: 1, paddingTop: 1, paddingBottom: 1, border: "1px solid rgba(0, 0, 0, 0.12);",
                                borderRadius: 1, minHeight: 0
                            }}
                            className={invoiceData.invoice.clients[k].talenomClient == null ? "default-grey" : "primary.default"}
                            value={k}
                            label={invoiceData.invoice.clients[k].name}
                        />
                    })
                }
            </Tabs>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                <Box sx={{ flex: 4 }}>
                    <TableContainer sx={{ maxHeight: '60vh' }} component={Paper}>
                        <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Tooltip title="Valitse kaikki">
                                            <Checkbox
                                                sx={{ margin: 0, ml: 1, padding: 0 }}
                                                checked={
                                                    invoiceData.invoice.jobs.filter(e => e.client == invoiceClient)
                                                        .filter(e => !(invoiceData.invoice.items[e.item].talenomProduct == null && !e.talenomVariant))
                                                        .filter(e => !selectedJobs.includes(e.id))
                                                        .length === 0
                                                }
                                                onChange={(e, v) => onSelectJob(null, v)}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Nimike</TableCell>
                                    <TableCell align="right">Laji</TableCell>
                                    <TableCell align="right">Tonnit</TableCell>
                                    <TableCell align="right">Kuutiot</TableCell>
                                    <TableCell align="right">Kpl</TableCell>
                                    {hasFishItems && <>
                                        <TableCell align="right">Tunnit</TableCell>
                                        <TableCell align="right">Kilometrit</TableCell>
                                    </>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    list.map(i => {
                                        const variant = invoiceData.invoice.items[i.item].talenomVariants?.find(e => e.product == i.talenomVariant) || null;

                                        return <TableRow
                                            key={"job-preview-row" + i.id}
                                            className={i.talenomBilled ? "default-green" : ""}
                                        >
                                            <TableCell
                                                size="small"
                                            >
                                                <Tooltip
                                                    title={
                                                        (invoiceData.invoice.items[i.item].talenomProduct == null && !i.talenomVariant) ? 
                                                        "Nimikkeeltä puuttuu Talenom-tuotenumero" : 
                                                        ""
                                                    }
                                                >
                                                    <span>
                                                        <Checkbox
                                                            checked={!!selectedJobs.includes(i.id)}
                                                            disabled={invoiceData.invoice.items[i.item].talenomProduct == null && !i.talenomVariant}
                                                            onChange={(e, v) => { onSelectJob(i.id, v) }}

                                                        />
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                size="small"
                                            >
                                                {invoiceData.invoice.items[i.item].name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {variant ? (variant.name) : ""}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={invoiceData.invoice.items[i.item].billingReason == 1 ? "default-orange" : ""}
                                            >
                                                {
                                                    `${Number(i.tons).toFixed(2)} ${(Number(i.tons) < Number(invoiceData.invoice.items[i.item].warrantyLoad)) ? 
                                                    ("(" + Number(invoiceData.invoice.items[i.item].warrantyLoad).toFixed(2) + ")") : ""}`
                                                }
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={invoiceData.invoice.items[i.item].billingReason == 2 ? "default-orange" : ""}
                                            >
                                                {Number(i.m3).toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={invoiceData.invoice.items[i.item].billingReason == 3 ? "default-orange" : ""}
                                            >
                                                {Number(i.count).toFixed(0)}
                                            </TableCell>
                                            {hasFishItems && <>
                                                <TableCell
                                                    align="right"
                                                    className={invoiceData.invoice.items[i.item].billingReason == 4 ? "default-orange" : ""}
                                                >
                                                    {Number(i.hours).toFixed(2)}
                                                </TableCell>
                                            <   TableCell
                                                    align="right"
                                                    className={invoiceData.invoice.items[i.item].billingReason == 5 ? "default-orange" : ""}
                                                >
                                                    {Number(i.kilometres).toFixed(2)}
                                                </TableCell>
                                            </>}
                                        </TableRow>;
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Card sx={{ mt: 0.5, p: 0.5 }}>
                        <span style={{ ...legendStyles }}>Ei laskutettu</span>
                        <span className="default-green" style={{ ...legendStyles }}>Laskutettu</span>
                        <span className="default-orange" style={{ ...legendStyles }}>Laskutusperuste</span>
                    </Card>
                </Box>
                <TalenomConfigBox />
            </Box>

        </Box>
    }

    const generateCheckupLink = (item) => {
        let link = "checkup?";
        const params = [];
        if (dateStart && dateEnd) {
            params.push(`startDate=${format(dateStart, "yyyy-MM-dd")}`);
            params.push(`endDate=${format(dateEnd, "yyyy-MM-dd")}`);
            // Old date based on item.date
            // if (item.date)
            // params.push(`startDate=${item.date}`);
            // params.push(`endDate=${format(addDays(new Date(item.date), 1), "yyyy-MM-dd")}`);
        }
        if (item.client) params.push(`client=${item.client}`);
        if (item.vehicle) params.push(`vehicle=${item.vehicle}`);
        if (item.item) params.push(`item=${item.item}`);
        if (item.checkState) params.push(`checkState=${item.checkState}`);
        if (item.itemType === "fish") params.push("itemType=fish");
        for (let i = 0; i < params.length; i++) {
            link += i > 0 ? "&" : "";
            link += params[i];
        }
        return link;
    }

    /**
     * @brief List vehicle's jobs grouped by item
     */
    function vehicleSelectList() {

        const list = {};
        // Group by item, filter by vehicle
        invoiceData.invoice.jobs.forEach(e => {
            if (e.vehicle == invoiceVehicle) {
                if (list[e.item] === undefined) {
                    list[e.item] = {
                        id: Number(e.id),
                        date: e.date,
                        client: e.client,
                        vehicle: e.vehicle,
                        item: e.item,
                        itemType: invoiceData?.invoice?.items[e.item]?.itemType,
                        cargo: e.cargoTypeLabel,
                        checkState: e.checkState,
                        basicType: invoiceData.invoice.items[e.item].basicTypeLabel,
                        name: invoiceData.invoice.items[e.item].name,
                        billingReason: invoiceData.invoice.items[e.item].billingReason,
                        totTons: (Number(e.tons) < Number(invoiceData.invoice.items[e.item].warrantyLoad)) ? Number(invoiceData.invoice.items[e.item].warrantyLoad) : Number(e.tons),
                        totM3: Number(e.m3),
                        totCount: Number(e.count)
                    }
                }
                else {
                    list[e.item].totTons += (Number(e.tons) < Number(invoiceData.invoice.items[e.item].warrantyLoad)) ? Number(invoiceData.invoice.items[e.item].warrantyLoad) : Number(e.tons);
                    list[e.item].totM3 += Number(e.m3);
                    list[e.item].totCount += Number(e.count);
                }
            }
        })

        return <Box>
            <Tabs
                TabIndicatorProps={{ sx: { display: 'none' } }}
                sx={{
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                    },
                    width: '100%'
                }}
                value={invoiceVehicle}
                onChange={(e, v) => { if (v !== null) setInvoiceVehicle(v) }}
                exclusive="true"
            >
                {
                    Object.keys(invoiceData.invoice.vehicles).map((k, i) => {
                        return <Tab
                            key={"vehicle-button-group-" + k}
                            sx={{ whiteSpace: 'nowrap', padding: 1, paddingTop: 1, paddingBottom: 1, border: "1px solid rgba(0, 0, 0, 0.12);", borderRadius: 1, minHeight: 0 }}
                            value={k}
                            label={invoiceData.invoice.vehicles[k].licenseNumber}
                        />
                    })
                }
            </Tabs>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                <Box sx={{ flex: 4 }}>
                    <TableContainer sx={{ maxHeight: '60vh' }} component={Paper}>
                        <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nimike</TableCell>
                                    <TableCell>Tuote</TableCell>
                                    <TableCell>Tyyppi</TableCell>
                                    <TableCell align="right">Tonnit</TableCell>
                                    <TableCell align="right">Kuutiot</TableCell>
                                    <TableCell align="right">Kpl</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(list).map(i => {

                                        return <TableRow
                                            key={"vehicle-preview-row" + i}
                                            onDoubleClick={() => window.open(generateCheckupLink(list[i]), "_blank")}
                                            sx={{ ":hover": { cursor: "pointer", textDecoration: "underline" } }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                size="small"
                                            >
                                                {list[i].name}
                                            </TableCell>
                                            <TableCell>{list[i].cargo}</TableCell>
                                            <TableCell>{list[i].basicType}</TableCell>
                                            <TableCell
                                                align="right"
                                                className={list[i].billingReason == 1 ? "default-green" : ""}
                                            >
                                                {list[i].totTons.toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={list[i].billingReason == 2 ? "default-green" : ""}
                                            >
                                                {list[i].totM3.toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={list[i].billingReason == 3 ? "default-green" : ""}

                                            >
                                                {list[i].totCount.toFixed(2)}
                                            </TableCell>
                                        </TableRow>;
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DownloadLinkList />
                </Box>
            </Box>

        </Box>
    }

    /**
     * @brief List item's jobs grouped by vehicle
     */
    function itemSelectList() {
        const list = {};
        // Group by vehicle, filter by item
        invoiceData.invoice.jobs.forEach(e => {
            if (e.item == invoiceItem) {
                if (list[e.vehicle] === undefined) {
                    list[e.vehicle] = {
                        id: Number(e.id),
                        date: e.date,
                        client: e.client,
                        vehicle: e.vehicle,
                        item: e.item,
                        cargo: e.cargoTypeLabel,
                        checkState: e.checkState,
                        basicType: invoiceData.invoice.items[e.item].basicTypeLabel,
                        name: invoiceData.invoice.vehicles[e.vehicle].licenseNumber,
                        shortName: invoiceData.invoice.items[e.item].shortName,
                        billingReason: invoiceData.invoice.items[e.item].billingReason,
                        totTons: (Number(e.tons) < Number(invoiceData.invoice.items[e.item].warrantyLoad)) ? Number(invoiceData.invoice.items[e.item].warrantyLoad) : Number(e.tons),
                        totM3: Number(e.m3),
                        totCount: Number(e.count)
                    }
                }
                else {
                    list[e.vehicle].totTons += (Number(e.tons) < Number(invoiceData.invoice.items[e.item].warrantyLoad)) ? Number(invoiceData.invoice.items[e.item].warrantyLoad) : Number(e.tons);
                    list[e.vehicle].totM3 += Number(e.m3);
                    list[e.vehicle].totCount += Number(e.count);
                }
            }
        })

        return <Box>
            <Tabs
                TabIndicatorProps={{ sx: { display: 'none' } }}
                sx={{
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                    },
                    width: '100%'
                }}
                value={invoiceItem}
                onChange={(e, v) => { if (v !== null) setInvoiceItem(v) }}
                exclusive="true"
            >
                {
                    Object.keys(invoiceData.invoice.items).map((k, i) => {
                        return <Tab
                            key={"item-button-group-" + k}
                            sx={{ whiteSpace: 'nowrap', padding: 1, paddingTop: 1, paddingBottom: 1, border: "1px solid rgba(0, 0, 0, 0.12);", borderRadius: 1, minHeight: 0 }}
                            value={k}
                            label={invoiceData.invoice.items[k].shortName}
                        />
                    })
                }
            </Tabs>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                <Box sx={{ flex: 4 }}>
                    <TableContainer sx={{ maxHeight: '60vh' }} component={Paper}>
                        <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Auto</TableCell>
                                    <TableCell>Tuote</TableCell>
                                    <TableCell>Tyyppi</TableCell>
                                    <TableCell align="right">Tonnit</TableCell>
                                    <TableCell align="right">Kuutiot</TableCell>
                                    <TableCell align="right">Kpl</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(list).map(i => {

                                        return <TableRow
                                            key={"vehicle-preview-row" + i}
                                            onDoubleClick={() => window.open(generateCheckupLink(list[i]), "_blank")}
                                            sx={{ ":hover": { cursor: "pointer", textDecoration: "underline" } }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {list[i].name}
                                            </TableCell>
                                            <TableCell>{list[i].cargo}</TableCell>
                                            <TableCell>{list[i].basicType}</TableCell>
                                            <TableCell
                                                align="right"
                                                className={list[i].billingReason == 1 ? "default-green" : ""}
                                            >
                                                {list[i].totTons.toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={list[i].billingReason == 2 ? "default-green" : ""}
                                            >
                                                {list[i].totM3.toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={list[i].billingReason == 3 ? "default-green" : ""}

                                            >
                                                {list[i].totCount.toFixed(2)}
                                            </TableCell>
                                        </TableRow>;
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DownloadLinkList />
                </Box>
            </Box>

        </Box>
    }


    return <Box sx={{ padding: 2, flexDirection: 'column', overflowX: "scroll" }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
                    <DateRangeSelect
                        value={[dateStart, dateEnd]}
                        onChange={(v) => {
                            if (v[0] && v[1]) {
                                setDateStart(v[0]);
                                setDateEnd(v[1]);
                            }
                        }}
                        labels={{ start: "Ajopvm. alku", end: "Ajopvm. loppu" }}
                    />
                </Box>
                <Box>
                    <Button
                        onClick={(e) => { setVehicleMenuOpen(true); setAnchorEl(e.currentTarget) }}
                        disabled={Object.keys(vehicles).length === 0 || fetchingVehicles}
                    >
                        Autot
                        {
                            (fetchingVehicles) &&
                            <CircularProgress size={15} />
                        }
                        {
                            Object.keys(vehicles).length > 0 &&
                            <ArrowDropDown />
                        }
                    </Button>
                    <Button
                        onClick={(e) => { setClientMenuOpen(true); setAnchorEl(e.currentTarget) }}
                        disabled={clients.length === 0 || fetchingClients}
                    >
                        Asiakaskoodit
                        {
                            (fetchingClients) &&
                            <CircularProgress size={15} />
                        }
                        {
                            clients.length > 0 &&
                            <ArrowDropDown />
                        }
                    </Button>
                    <Popover
                        open={vehicleMenuOpen}
                        anchorEl={anchorEl}
                        onClose={() => { setAnchorEl(null); setVehicleMenuOpen(false) }}
                    >
                        <TreeView
                            aria-label="vehicle-selection"
                            defaultCollapseIcon={<ExpandMore />}
                            defaultExpandIcon={<ChevronRight />}
                            defaultExpanded={['999']}
                        >
                            <TreeItem
                                key={"veh-group-all"}
                                nodeId={String(999)}
                                label={createVehicleLabel("Kaikki", allVehiclesSelected, null, null)}
                            >
                                {
                                    vehicles.map((e, i) => {
                                        return <TreeItem
                                            key={"veh-" + i}
                                            nodeId={String(e.id)}
                                            label={createVehicleLabel(e.licenseNumber, e.selected, e.id)}
                                        />
                                    })
                                }
                            </TreeItem>
                        </TreeView>
                    </Popover>
                    <Popover
                        open={clientMenuOpen}
                        anchorEl={anchorEl}
                        onClose={() => { setAnchorEl(null); setClientMenuOpen(false) }}
                    >
                        <TreeView
                            aria-label="client-selection"
                            defaultCollapseIcon={<ExpandMore />}
                            defaultExpandIcon={<ChevronRight />}
                            defaultExpanded={['9999']}
                        >
                            <TreeItem
                                key={"clients-all"}
                                nodeId={String(9999)}
                                label={createClientLabel("Kaikki", allClientsSelected, null, false)}
                            >
                                {
                                    clients.map((e, i) => {

                                        return <TreeItem
                                            key={"client-" + i}
                                            nodeId={String(10000 + e.id)}
                                            label={createClientLabel(e.name, e.selected, e.id, false)}
                                        />
                                    })
                                }
                            </TreeItem>
                        </TreeView>
                    </Popover>
                    <Button
                        onClick={() => fetchInvoiceData()}
                        variant='contained'
                    >
                        Hae
                    </Button>
                </Box>
            </Box>
            {
                // Talenom files
            }
            {
                // Only if talenom is enabled
                Config.moduleEnabled("talenom") &&
                <Fragment>
                    <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }} />
                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <Button
                            component="label"
                            sx={{ flex: 1 }}
                            disabled={talenomFileUploadInProgress}
                        >
                            {
                                !talenomFileUploadInProgress &&
                                <Upload />
                            }
                            {
                                talenomFileUploadInProgress &&
                                <CircularProgress size={24} />
                            }
                            Päivitä asiakasrekisteri
                            <input
                                type="file"
                                accept=".xls,.xlsx"
                                hidden
                                onChange={(e) => { talenomUploadFile("clients", e.target.files[0]) }}
                            />
                        </Button>
                        <Button
                            component="label"
                            sx={{ flex: 1 }}
                            disabled={talenomFileUploadInProgress}
                        >
                            {
                                !talenomFileUploadInProgress &&
                                <Upload />
                            }
                            {
                                talenomFileUploadInProgress &&
                                <CircularProgress size={24} />
                            }
                            Päivitä tuoterekisteri
                            <input
                                type="file"
                                accept=".xls,.xlsx"
                                hidden
                                onChange={(e) => { talenomUploadFile("products", e.target.files[0]) }}
                            />
                        </Button>
                    </Box>
                </Fragment>
            }
            {
                // View altering
            }
            {
                (invoiceData.invoice && invoiceData.invoice.items) &&
                <Fragment>
                    <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }} />
                    <Box>
                        {
                            // Both invoicing types enabled
                            (Config.moduleEnabled("tkuinvoice") && Config.moduleEnabled("talenom")) &&
                            <ToggleButtonGroup
                                value={invoicePreviewMode}
                                onChange={(e, v) => { if (v !== null) setInvoicePreviewMode(v) }}
                                exclusive={true}

                            >
                                <ToggleButton value={"vehicle"}>Auto</ToggleButton>
                                <ToggleButton value={"item"}>Nimike</ToggleButton>
                                <ToggleButton value={"talenom"}>Talenom</ToggleButton>
                            </ToggleButtonGroup>
                        }
                        {
                            // Only tkuinvoice
                            (Config.moduleEnabled("tkuinvoice")) &&
                            <ToggleButtonGroup
                                value={invoicePreviewMode}
                                onChange={(e, v) => { if (v !== null) setInvoicePreviewMode(v) }}
                                exclusive={true}

                            >
                                <ToggleButton value={"vehicle"}>Auto</ToggleButton>
                                <ToggleButton value={"item"}>Nimike</ToggleButton>
                            </ToggleButtonGroup>
                        }
                    </Box>
                </Fragment>
            }
        </Box>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        <Box sx={{ width: '80vw' }}>
            {
                (invoiceData.invoice && invoiceData.invoice.items && !fetching) &&
                <Fragment>
                    {
                        (invoicePreviewMode === 'vehicle') && vehicleSelectList()
                    }
                    {
                        (invoicePreviewMode === 'item') && itemSelectList()
                    }
                    {
                        (invoicePreviewMode === 'talenom') && clientJobSelectList()
                    }
                </Fragment>
            }
            {
                fetching &&
                <CircularProgress size={40} />
            }
        </Box>

        <Snackbar
            open={invoiceAlertText != null}
            autoHideDuration={6000}
            resumeHideDuration={1000}
            onClose={() => { setInvoiceAlertText(null) }}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
            <Alert severity={invoiceAlertSeverity}>
                {invoiceAlertText}
            </Alert>
        </Snackbar>
    </Box>
}