

/**
 * @typedef {Object} ConfigFormat
 * @property {"softrain" | "tarkkala" | "konnekuljetus" | "metsanen"} name
 * @property {{[key: string]: ModuleDef}} modules
 */

/**
 * @typedef {Object} ModuleDef
 * @property {string} name Name of the module
 * @property {boolean} enabled Is the module enabled?
 * @property {string[]} dependencies Modules which this module depends on
 */

import LogitarConfig from "./config.json";

export default class Config {

    /**
     * @brief Checks if a module is enabled
     * @param {string} module 
     * @param {boolean} checkDeps
     * 
     * @return {boolean}
     */
    static moduleEnabled (module, checkDeps = true) {
        /**
         * @type {ConfigFormat}
         */
        const conf = LogitarConfig;

        const mod = conf.modules[module];
        if(mod === undefined) {
            return false;
        }
        if(!mod.enabled) {
            return false;
        }
        
        if(checkDeps) {
            for(let dep of mod.dependencies) {
                if(!Config.moduleEnabled(dep, false)) {
                    return false;
                }
            }
        }
        
        return true;
    }

    /**
     * 
     * @param {string} module 
     */
    static getModule (module) {
        /**
         * @type {ConfigFormat}
         */
        const conf = LogitarConfig;

        return conf.modules[module];
    }

    /**
     * @brief Get current configuration branch name
     */
    static getBranch () {
        /**
         * @type {ConfigFormat}
         */
        const conf = LogitarConfig;
        return conf.name;
    }
}