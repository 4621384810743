import { Box, Button, Popover, Table, TableBody, TableCell, TableRow, Typography, TableHead, Fade, useTheme, useMediaQuery} from '@mui/material'
import { useEffect, useCallback, useState, useContext } from 'react';
import LogitarApi from '../api/LogitarApi';
import LogiTarUser from '../misc/User';
import { contentBoxStyle } from '../styles/styles';
import PopupScreen from '../components/PopupScreen';
import NewsPop from '../components/NewsPop';
import { format } from 'date-fns';
import { Buffer } from 'buffer';
import { useSnackbar } from 'notistack'
import { Delete, Save} from '@mui/icons-material'
import { TipButton } from '../misc/InternalFeatures'
import PDFViewer from '../components/PDFViewer';
import FilePopup from '../components/FilePopup';

/**
 * 
 * @param {string} base64 
 * @returns 
 */
export function base64PDFToBlobUrl(base64) {
    const arr = Buffer.from(base64, "base64");
    const blob = new Blob([arr], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    return url;
}  

export default function NewsAccept(props) {
    const [news, setNews] = useState(props.news)    
    const [file, setFile] = useState({})
    const [singleNews, setSingleNews] = useState()
    const [popup, setPopup] = useState(false)
    
    const { enqueueSnackbar, closeSnackbar} = useSnackbar()

    const theme = useTheme();
    const mdBreakPoint = useMediaQuery(theme.breakpoints.up("md"));

    //Popover functions and state for multiple popovers, job instructions
    const [anchorEl, setAnchorEl] = useState({ anchorEl: null, popoverId: null });

    const newsStyle = {
        cursor: "pointer",
    }

    const open = Boolean(anchorEl)

    const handleFileClose = () => {
        setAnchorEl(null)
        setFile({})
    }

    const openFile = (event, fileID, file = null) => {
        if (file) {
            setFile(file)
        } else {
            getNewsFile(fileID).then((file) => {
                setFile({
                    ...file,
                    data: base64PDFToBlobUrl(file.data)
                });
            })
        }
        handleFileClick(event)
    }

    const handleFileClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopupNews = (rowId) => {
        //Fetch news info set to a hook, give it to newspopup, row id is supplied

        LogitarApi.getNews({ id: rowId, extent: "allfile" }).then((result) => {
            if (result.status) {
                setSingleNews(result.news[0])
                setPopup(true)
            }
        })
            .catch((err) => console.error(err))
    }

    //Fetch file data for a news
    const getNewsFile = (id) => {
        return new Promise((resolve, reject) => {
            LogitarApi.getFile(id).then((result) => {
                resolve(result.file)
            })
        })
    }

    //Remove users selected reference to news row
    const acceptNotification = (id) => {  
        let newsid = []
        let ids = {'id': id}
        newsid.push(ids)

        LogitarApi.acceptNotification({news: newsid, user: LogiTarUser.current.token.user}).then((result) => {
            if (result.status) {
                enqueueSnackbar("Tiedote on merkitty luetuksi")
                props.rowUpdate()
            }
            props.rowUpdate()
        })
        .catch((err) => {
            // alert(err)
            enqueueSnackbar("Tiedotteen kuittaus epäonnistui", {variant: "warning"})
        })
    }
    
    //Remove users all references to news row
    const acceptNotifications = () => {  

        let newsid = []
        news.forEach((value) => { 
            if (value.id > 0) {
              let ids = {'id': value.id}
              newsid.push(ids)
            }
          })

        LogitarApi.acceptNotification({news: newsid, user: LogiTarUser.current.token.user}).then((result) => {
            if (result.status) {
                enqueueSnackbar("Tiedotteet on merkitty luetuksi")
                props.rowUpdate()
            }
            props.rowUpdate()
        })
        .catch((err) => {
            // alert(err)
            enqueueSnackbar("Tiedotteiden kuittaus epäonnistui", {variant: "warning"})
        })
    }

    let columns = [
        { field: 'header', headerName: 'Otsikko', width: 400 },
        { field: 'info', headerName: 'Tiedote', width: 800 },
        { field: 'file', headerName: 'Tiedosto', width: 250 },
        { field: 'date', headerName: 'Päivämäärä', width: 150 },
    ]

    return (
    <PopupScreen
        title={"Tiedotteet"}
        draggable
        onClose={() => props.rowUpdate()}
        zIndex={1300}
        forceRightEdge={!mdBreakPoint}
    >
    
    <div style={{ width: mdBreakPoint ? "70vw" : "80vw" }}>

    <Box sx={{ ...contentBoxStyle, maxHeight: "85vh", overflowY: "auto" }}>
    <Table size='small'>
        <TableHead>
            <TableRow>
                {columns.map((value, key) => {
                    return <TableCell sx={{ fontWeight: "bold" }} key={key} width={value.width}>{value.headerName}</TableCell>
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {news.map((row, i) => {
                return (
                    <Fade timeout={700} in key={row.id}>
                        <TableRow sx={newsStyle} hover>
                            <TableCell sx={{width: '20%'}} onClick={() => handlePopupNews(row.id)}>{row.header}</TableCell>
                            <TableCell sx={{width: '50%'}} onClick={() => handlePopupNews(row.id)}>
                                <Typography variant="body1" fontWeight="bold">
                                    {row.content}
                                </Typography>
                            </TableCell>
                            
                            {/* <TableCell sx={{width: '10%'}} style={{ color: (row.fileName) ? "blue" : "black" }}> */}
                            <TableCell sx={{width: '10%'}}>
                                {/* <Typography onClick={(row.fileID) ? (event) => { openFile(event, row.fileID) } : () => handlePopupNews(row.id)}>{row.fileName ? row.fileName : "Ei tiedostoa"}</Typography>  */}
                                <Typography onClick={(row.fileID) ? (event) => { openFile(event, row.fileID) } : () => handlePopupNews(row.id)}>{row.fileName}</Typography> 
                            </TableCell>


                            <TableCell sx={{width: '10%'}} onClick={() => handlePopupNews(row.id)}>{format(new Date(row.date), "dd.MM.yyyy H:mm")}</TableCell>
                            <TableCell sx={{width: '10%'}}><Button variant="contained" Title="Hyväksy" onClick={() => acceptNotification(row.id)}>Hyväksy</Button></TableCell>

                        </TableRow>
                    </Fade>
                )
            })}
        </TableBody>
    </Table>
    {
        file.data &&
        <FilePopup file={file} onClose={handleFileClose} >
            {/* <Button sx={{ zIndex: 1000, m: 1, position: "absolute", width: "100px", height: "40px", bottom: 5 }} variant="contained" onClick={handleFileClose}>Poistu</Button> */}
        </FilePopup>
    }

    <div style={{ textAlign: 'right' }}> 
        <Button sx={{m:2}} onClick={() => acceptNotifications()} variant="contained">Hyväksy tiedotteet</Button>
    </div>

    {/* {popup &&
        <NewsPop onClick={setPopup} openFile={openFile} news={singleNews}></NewsPop>
    } */}


    </Box>
    </div>
    </PopupScreen>
    )
}
