import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { Fragment, useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";

const columns = [
    { obj: "item", field: "name", label: "Nimike", href: "/orders?search=" },
    { obj: "item", field: "cargoLabel", label: "Tuote" },
    { field: "doneCount", label: "Kpl" },
    { obj: "order", field: "details", label: "Lisätieto" },
    { obj: "order", field: "detailDays", label: "Lisätiedot" },
];

const weekdays = ["Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai", "Sunnuntai"];

const defaultDate = new Date();
// Set default date monday
defaultDate.setDate(defaultDate.getDate() + (1 - defaultDate.getDay()));

export default function WeekOrders(props) {

    const [date, setDate] = useState(defaultDate);
    // Order days data
    const [orders, setOrders] = useState({});

    useEffect(() => {
        LogitarApi.getWeekOrders(format(date, "yyyy-MM-dd")).then((v) => {
            console.log("Viikkotilaukset ", v.days )
            setOrders(v.days);
        }).catch((e) => {
            console.error(e);
        })
    }, [date])

    const currDate = new Date(date);
    currDate.setDate(currDate.getDate() - 1);

    const totals = [];
    for(let i = 0; i < 7; i++) {
        totals.push({count: 0, hours: 0});
    }
    const fullWeekTotals = {count: 0, hours: 0};

    return <Box sx={{ width: '100%', height: '90vh', padding: 2, ml: -1 }}>
        <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker

                label="Tilaukset viikolta"
                inputFormat='dd.MM.yyyy'
                mask={"__.__.____"}
                value={date}
                onChange={(e) => {
                    if (e.getDay() !== 1) {
                        e.setDate(e.getDate() + (1 - e.getDay()));

                    }
                    setDate(e);
                }}
                renderInput={(params) => <TextField sx={{ width: 175 }} {...params} />}
            />
        </LocalizationProvider>
        <TableContainer sx={{ width: '100%', height: '99%', overflowY: 'scroll', mt: 1 }}>
            <Table stickyHeader sx={{emptyCells: "show"}}>
                <TableHead>
                    <TableRow>
                        {
                            columns.map((e, i) => 
                                e.field !== "detailDays" && (
                                    <TableCell key={i}>
                                        {e.label}
                                    </TableCell>
                                )
                            )
                        }
                    </TableRow>
                </TableHead>
                
                    {
                        weekdays.map((e, i) => {
                            currDate.setDate(currDate.getDate() + 1);
                            return <Fragment>
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="small" colSpan={columns.length} sx={{ backdropFilter: 'brightness(85%)', background: "none", textAlign: 'center', top: 57 }}>
                                            {e} - {format(currDate, "dd.MM.yyyy")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                {
                                    (orders[format(currDate, "yyyy-MM-dd")] !== undefined) &&
                                    Object.keys(orders[format(currDate, "yyyy-MM-dd")].sort((a,b) => a.item.clientName.localeCompare(b.item.clientName))).map((e, id) => {
                                        console.log(orders[format(currDate, "yyyy-MM-dd")])
                                        const row = orders[format(currDate, "yyyy-MM-dd")][e];
                                        const orderCount = row.orderCount;
                                        const isDone = orderCount <= row.jobCount;
                                        totals[i].count += Number(row.orderCount);
                                        totals[i].hours += Number(row.orderCount) * Number(row.item.hours);
                                        fullWeekTotals.count += Number(row.orderCount);
                                        fullWeekTotals.hours += Number(row.orderCount) * Number(row.item.hours);

                                        const detailDays = row.order.detailDays
                                        let arr = detailDays.split(",");
                                        const detailDay = arr[i];
                                        let  detailDayStr = '';
                                        if (detailDay) {
                                            detailDayStr = detailDay.replace(/"/g, '');
                                            detailDayStr = detailDayStr.replace("[", "");
                                            detailDayStr = detailDayStr.replace("]", "");
                                            detailDayStr = detailDayStr.replace("null", "");
                                        }
                                        if (detailDayStr.length == 0) {
                                            detailDayStr = row.order.details;
                                        }

                                        return <TableRow>
                                            {
                                                columns.map((c, ik) => {
                                                    if(c.field === "doneCount") {
                                                        return <TableCell
                                                                className={isDone ? "default-green" : ""}
                                                                size="small"
                                                            >
                                                            {row.jobCount} / {orderCount}
                                                        </TableCell>
                                                    }
                                                    else if (c.field === "details") {
                                                        return <TableCell
                                                            className={isDone ? "default-green" : ""}
                                                            size="small"
                                                        >
                                                             {detailDayStr}
                                                        </TableCell>
                                                    }
                                                    else if (c.field !== "detailDays") {
                                                        return <TableCell
                                                            className={isDone ? "default-green" : ""}
                                                            size="small"
                                                            sx={{
                                                                color: 'text.primary'
                                                            }}
                                                        >
                                                            {
                                                                c.href &&
                                                                <Link href={c.href + encodeURIComponent(row.item.name)} sx={{color: 'text.primary'}}>
                                                                    {row.item.id}:{(c.obj ? row[c.obj][c.field] : row[c.field])}
                                                                </Link>
                                                            }
                                                            {
                                                                !c.href &&
                                                                <>
                                                                {(c.obj ? row[c.obj][c.field] : row[c.field])}
                                                                </>
                                                            }
                                                        </TableCell>
                                                    }
                                                })
                                            }
                                        </TableRow>
                                    })
                                }
                                {
                                    // Totals
                                    <TableRow>
                                        <TableCell align="right" size="small" sx={{fontWeight: 'bold'}} variant="footer">
                                            Yhteensä:
                                        </TableCell>
                                        <TableCell size="small" sx={{fontWeight: 'bold'}} variant="footer">
                                            {totals[i].count} kpl
                                        </TableCell>
                                        <TableCell size="small" sx={{fontWeight: 'bold'}} variant="footer">
                                            {totals[i].hours} h
                                        </TableCell>
                                        <TableCell/>
                                    </TableRow>
                                }
                                </TableBody>
                            </Fragment>
                        })
                    }
                    <TableRow>
                        <TableCell size="small" colSpan={columns.length} sx={{ filter: 'brightness(80%)', textAlign: 'center', fontWeight: 'bold' }}>
                            Koko viikko
                        </TableCell>
                    </TableRow>
                    {
                        // Full week totals
                        <TableRow>
                            <TableCell align="right" sx={{fontWeight: 'bold'}}>
                                Yhteensä:
                            </TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                {fullWeekTotals.count} kpl
                            </TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                {fullWeekTotals.hours} h
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    }
            </Table>
        </TableContainer>
    </Box >
}