import { ReportOutlined } from "@mui/icons-material";
import { FuelType } from "../FuelPriceCard";
import { EmissionReportData } from "./EmissionsOverview";
import { Box, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

export type EmissionReportJob = {
    id: number,
    billingDate: string,
    itemName: string,
    client: number,
    vehicle: number,
    vehicleLicense: string,
    tons?: string,
    loadStart?: string,
    unloadEnd?: string,
    nextLoadStart?: string,
    distance?: number,
    fuel?: number,
    vehicleFuelType: number,
    shiftFuelType: number,
    travelTime?: string,
    travelTimestamp?: number,
    driverLoadDiffTime?: string,
    driverLoadDiffTimestamp?: number,
    driverNextLoadDiffTime?: string,
    driverNextLoadDiffTimestamp?: number,
}

export type EmissionSummary = {
    totalTons: number,
    totalKm: number,
    totalFuel: number,
    totalCO2: number,
    CO2PerKm: number,
    CO2PerTons: number,
}

type InvalidReason =
    "fuelNull" |
    "fuelNegative" |
    "loadTimeDiff" |
    "nextLoadTimeDiff" |
    "timeNegative" |
    "timeTooLong"

export function calculateEmissionSummary(reportJobs: EmissionReportJob[], multipliers: { type: FuelType, perUnit: number }[], reportConfig: EmissionReportData) {
    const totals: EmissionSummary = {
        totalFuel: 0,
        totalTons: 0,
        totalKm: 0,
        totalCO2: 0,
        CO2PerKm: 0,
        CO2PerTons: 0,
    }
    reportJobs.forEach(job => {
        // Calculate summary data only if valid data is available
        if (isJobInvalid(job) !== false) return;
        totals.totalFuel += job.fuel ? job.fuel : 0;
        totals.totalTons += Number(job.tons) > 0 ? Number(job.tons) : 0;
        totals.totalKm += job.distance && job.distance > 0 ? job.distance : 0;
        const fuelType = job.shiftFuelType ? job.shiftFuelType : job.vehicleFuelType;
        const fuelMultiplier = multipliers.find(e => e.type === fuelType);
        totals.totalCO2 += job.fuel && fuelMultiplier ? job.fuel * fuelMultiplier.perUnit : 0;
    })
    totals.CO2PerKm = totals.totalKm > 0 ? totals.totalCO2 / totals.totalKm : 0;
    totals.CO2PerTons = totals.totalTons > 0 ? totals.totalCO2 / totals.totalTons : 0;

    return ({ ...reportConfig, ...totals });
}

export function isJobInvalid(job: EmissionReportJob) {
    const reasons: InvalidReason[] = [];
    if (!job.fuel) reasons.push("fuelNull");
    if (job.fuel && job.fuel < 0) reasons.push("fuelNegative");
    if (job.driverLoadDiffTimestamp) {
        if (Math.abs(job.driverLoadDiffTimestamp) > 28800000) reasons.push("loadTimeDiff");
    }
    if (job.driverNextLoadDiffTimestamp) {
        if (Math.abs(job.driverNextLoadDiffTimestamp) > 28800000) reasons.push("nextLoadTimeDiff");
    }
    if (job.travelTimestamp) {
        if (job.travelTimestamp < 0) reasons.push("timeNegative");
        if (job.travelTimestamp > 86400000) reasons.push("timeTooLong");
    }
    return reasons.length > 0 ? reasons : false;
}

export function renderRowInvalidReason(params: GridRenderCellParams) {
    const job: EmissionReportJob = params.row;
    const invalidReasons = isJobInvalid(job);
    let error: string[] = [];
    if (invalidReasons !== false) {
        invalidReasons.map(reason => {
            switch (reason) {
                case "fuelNull":
                    error.push("Polttoaineen kulutus puuttuu");
                    break;
                case "fuelNegative":
                    error.push("Polttoaineen kulutus on negatiivinen: " + job.fuel);
                    break;
                case "loadTimeDiff":
                    error.push("A:n kuljettajan painama ja GPS aikaleima eroavat yli 8h: " + job.driverLoadDiffTime);
                    break;
                case "nextLoadTimeDiff":
                    error.push("C:n kuljettajan painama ja GPS aikaleima eroavat yli 8h: " + job.driverNextLoadDiffTime);
                    break;
                case "timeNegative":
                    error.push("A→C aikaväli on negatiivinen: " + job.travelTime);
                    break;
                case "timeTooLong":
                    error.push("A→C aikaväli on yli 24h: " + job.travelTime);
                    break;
                default:
                    break;
            }
        })
    }
    if (error.length > 0) {
        return (
            <Tooltip title={<Box>
                <Box sx={{ fontWeight: "bold" }}>Riviä ei käytetä raportin laskennassa:</Box>
                {error.map(e => <Box>• {e}</Box>)}
            </Box>}>
                <ReportOutlined color="warning" />
            </Tooltip>
        )
    }
    return null;
}

export function calculateEmissionsForFuel(job: EmissionReportJob, multipliers: { type: FuelType, perUnit: number }[]) {
    const fuelType = job.shiftFuelType ? job.shiftFuelType : job.vehicleFuelType;
    const fuelMultiplier = multipliers.find(e => e.type === fuelType);
    return job.fuel && fuelMultiplier ? job.fuel * fuelMultiplier.perUnit : null;
}