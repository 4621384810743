import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef } from "react";

// A4 ratio
const A4_ratio = 1 / 1.41;

/**
 * @typedef {{
 *  width: number | 'a4',
 *  children: JSX.Element | JSX.Element[],
 *  printTriggered?: boolean,
 * }} PrintableReportBaseProps
 */

// Create forward ref
const PrintableReportBase = forwardRef(PrintableReportBaseComponent);

/**
 * 
 * @param {PrintableReportBaseProps} props 
 * @returns 
 */
function PrintableReportBaseComponent(props, ref) {


    const children = Array.isArray(props.children) ? props.children : [props.children];

    return <Box
        sx={{
            // width: props.width === 'a4' ? '297mm' : `${props.width}`,
            // height: props.width === 'a4' ? '210mm' : `${props.width * A4_ratio * children.length}px`,
            // backgroundSize: props.width === 'a4' ? '297mm 210mm' : `${props.width}px ${props.width * A4_ratio}px`,
            width: "100%",
            height: "100%",
            maxHeight: props.printTriggered ? "100vh" : undefined,
            maxWidth: props.printTriggered ? "100vw" : undefined,
            aspectRatio: "1.414/1",
            backgroundSize: "cover",
            backgroundImage: 'url("./bg/emission-report-bg-blur.webp")',
            backgroundPosition: "center",
            backgroundRepeat: 'repeat-y',
            p: 0,
            m: 0,
            overflow: 'hidden',
            borderRadius: props.printTriggered ? undefined : '0.25rem',
        }}
        ref={ref}
    >
        <Box
            sx={{ m: 0, width: '100%', height: '100%' }} // backdropFilter: 'blur(5px)'
        >
            {
                children.map((e, i) => {

                    return <Box
                            key={"prbd-" + i} 
                            // sx={{ width: '100%', height: props.width === 'a4' ? '210mm' : `${props.width * A4_ratio}px` }}
                            sx={{ width: '100%', height: '100%' }}
                        >
                            {e}
                        </Box>
                })
            }
        </Box>

    </Box>
}

export default PrintableReportBase;