import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

type MapRoutesProps = {
    mapId: string;
    route: {
        origin: { lat: number, lng: number };
        destination: { lat: number, lng: number };
        provideRouteAlternatives?: boolean;
    }
};

export default function MapRoutes(props: MapRoutesProps) {
    const map = useMap(props.mapId);
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
    const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
    const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>();
    const [routeIndex, setRouteIndex] = useState<number>(0);


    // Initialize directions service and renderer
    useEffect(() => {
        if (!routesLibrary || !map) return;
        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map]);

    // Use directions service
    useEffect(() => {
        if (!directionsService || !directionsRenderer) return;

        directionsService
            .route({
                origin: `${props.route.origin.lat},${props.route.origin.lng}`,
                destination: `${props.route.destination.lat},${props.route.destination.lng}`,
                travelMode: google.maps.TravelMode.DRIVING,
                provideRouteAlternatives: props.route.provideRouteAlternatives
            })
            .then(response => {
                directionsRenderer.setDirections(response);
                setRoutes(response.routes);
            });

        return () => directionsRenderer.setMap(null);
    }, [directionsService, directionsRenderer]);

    // Update direction route
    useEffect(() => {
        if (!directionsRenderer) return;
        directionsRenderer.setRouteIndex(routeIndex);
    }, [routeIndex, directionsRenderer]);

    if (!routes)
        return null;

    const selected = routes[routeIndex];
    const leg = selected?.legs[0];

    if (!leg) return null;

    return (null);

}