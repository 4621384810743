import { Backdrop, Box, Card, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { Loader } from "../misc/InternalFeatures";

type SystemStatusWeb = {
    pipeline: {
        date: string;
        status: string;
    };
    repo: string;
    branch: string;
};

type SystemStatusApi = {
    pipeline: {
        date: string;
        status: string;
    };
    repo: string;
    branch: string;
};

type SystemStatusActiveCenter = {
    name: string;
    status: string;
    status_pre: string;
};

type SystemStatusService = {
    variant: string;
    web: SystemStatusWeb;
    api: SystemStatusApi;
    activecenter: SystemStatusActiveCenter;
}

type SystemStatus = {
    server: {
        memory: {
            total: string;
            used: string;
            percent: string;
        };
        swap: {
            total: string;
            used: string;
            percent: string;
        };
        cpu: {
            usage: string;
            cores: string;
        };
        disk: {
            total: string;
            used: string;
            percent: string;
        };
    };
    services: {
        [serviceName: string]: SystemStatusService;
    };
    status: boolean;
};

const fs = { display: "flex", justifyContent: "space-between" }

function ServiceStatusStackSide({ side }: { side: SystemStatusApi | SystemStatusWeb }) {

    const rowP = { fontSize: "0.85rem", margin: 0 };

    return (
        <>
            <div style={fs}>
                <p style={rowP}>Repo:</p>
                <a href={side.repo}>{side.repo}</a>
            </div>
            <div style={fs}>
                <p style={rowP}>Branch:</p>
                {side.branch}
            </div>
            <div style={fs}>
                <div>Latest: </div>
                <p style={rowP}>{format(new Date(side.pipeline.date), "dd.MM.yyyy HH:mm:ss", { locale: fi })}
                    <span
                        style={{ marginLeft: 8, paddingLeft: 8, paddingRight: 8 }}
                        className={side.pipeline.status === "success" ? "default-green" : "default-red"}>
                        {side.pipeline.status}
                    </span>
                </p>
            </div>
        </>
    )
}


function ServiceStatusCard({ name, service }: { name: string, service: SystemStatusService }) {

    const rowStyle = { fontSize: "0.9rem", paddingBottom: 4, paddingTop: 4 };
    const headerStyle = { marginBottom: 8, marginTop: 4 };

    return (
        <Card sx={{ border: "1px solid black", p: 1, m: 1, width: "auto" }}>
            <h2 style={headerStyle}>{name}</h2>
            <div style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                Pipelines
            </div>
            <div>
                <div style={rowStyle}>
                    <h4 style={headerStyle}>Web</h4>
                    <ServiceStatusStackSide side={service.web} />
                </div>
                <div style={rowStyle}>
                    <h4 style={headerStyle}>API</h4>
                    <ServiceStatusStackSide side={service.api} />
                </div>
                <div style={rowStyle}>
                    <h4 style={headerStyle}>Active Center</h4>
                    <div style={fs}>
                        <p style={{ fontSize: "0.85rem", margin: 0 }}>Name:</p>
                        {service.activecenter.name}
                    </div>
                    <div style={fs}>
                        <p style={{ fontSize: "0.85rem", margin: 0 }}>Status:</p>
                        <span
                            style={{ marginLeft: 8, paddingLeft: 8, paddingRight: 8 }}
                            className={service.activecenter.status === "active" ? "default-green" : "default-red"}>
                                {service.activecenter.status}
                        </span>
                    </div>
                </div>

            </div>

        </Card>
    )
}

export default function ServerStatus() {

    const [statusInfo, setStatusInfo] = useState<SystemStatus | null>(null);
    const [pipeCount, setPipeCount] = useState<number>(0);
    const [systemCount, setSystemCount] = useState<number>(0);

    useEffect(() => {
        LogitarApi.getServerStatus().then((response) => {
            setStatusInfo(response as SystemStatus);
        })
            .catch((error) => {
                console.error(error);
            })

    }, [pipeCount])
    
    useEffect(() => {
        LogitarApi.getServerStatus(true).then((response) => {
            const resp = response as SystemStatus;
            resp.services = { ...statusInfo?.services };

            setStatusInfo(resp);
        })
            .catch((error) => {
                console.error(error);
            })
    }, [systemCount]);

    useEffect(() => {
        let _pipeInterval: NodeJS.Timeout | null = null;
        setTimeout(() => {
            // Offset first interval to avoid immediate double fetch
            _pipeInterval = setInterval(() => {
                setPipeCount(prev => prev + 1)
            }, 30000);
        }, 1000);

        const _systemInterval = setInterval(() => {
            setSystemCount(prev => prev + 1)
        }, 5000);

        return () => {
            if(_pipeInterval) clearInterval(_pipeInterval);
            clearInterval(_systemInterval);
        }
    }, [])

    return (
        <Box sx={{ p: 1 }}>
            <Backdrop open={statusInfo === null}>
                <Loader />
            </Backdrop>
            <div style={{ 
                display: "grid", 
                gridTemplateColumns: "repeat(4, 25%)",
                gridTemplateRows: "auto auto auto",                
                width: "100%",
            }}>
                {/** Server Status */}
                <Card sx={{ border: "1px solid black", p: 1, m: 1, "& p": {margin: 0, paddingBottom: 0.5}}}>
                    <h3 style={{margin: 0}}>Server Status</h3>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <p>Memory: {statusInfo?.server.memory.used} MB / {statusInfo?.server.memory.total} MB ({statusInfo?.server.memory.percent}%)</p>
                        <p>CPU: {statusInfo?.server.cpu.usage}% / {statusInfo?.server.cpu.cores} cores</p>
                    </div>
                    <LinearProgress value={Number(statusInfo?.server.memory.percent)} valueBuffer={100} variant="buffer"></LinearProgress>

                    <p style={{marginTop: 4}}>Swap: {statusInfo?.server.swap.used} MB / {statusInfo?.server.swap.total} MB ({statusInfo?.server.swap.percent}%)</p>
                    <LinearProgress value={Number(statusInfo?.server.swap.percent)} valueBuffer={100} variant="buffer"></LinearProgress>
                </Card>
                {/** Disk Usage */}
                <Card sx={{ border: "1px solid black", p: 1, m: 1, "& p": {margin: 0, paddingBottom: 0.5}}}>
                    <h3 style={{margin: 0}}>Disk Usage</h3>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <p>Used: {(Number(statusInfo?.server.disk.used) / 1_000_000).toFixed(3)} GB / {(Number(statusInfo?.server.disk.total) / 1_000_000).toFixed(3)} GB ({statusInfo?.server.disk.percent}%)</p>
                    </div>
                    <LinearProgress value={Number(statusInfo?.server.disk.percent)} valueBuffer={100} variant="buffer"></LinearProgress>
                </Card>
                <Card sx={{ border: "1px solid black", p: 1, m: 1, "& p": {margin: 0, paddingBottom: 0.5}}}></Card>
                <Card sx={{ border: "1px solid black", p: 1, m: 1, "& p": {margin: 0, paddingBottom: 0.5}}}></Card>
                {/** Service Status' */}
                {
                    statusInfo &&
                    Object.keys(statusInfo.services).sort((a, b) => a.localeCompare(b)).map((serviceName, index) => (
                        <ServiceStatusCard key={index + serviceName} name={serviceName} service={statusInfo.services[serviceName]} />
                    ))
                }
            </div>                                                
        </Box>
    )
}