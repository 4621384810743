import { Box, CircularProgress } from "@mui/material"
import PopupScreen from "./PopupScreen"
import { LogitarFile } from "./FileManager"
import { PropsWithChildren, useEffect, useState } from "react"
import { Buffer } from 'buffer';
import LogitarApi from "../api/LogitarApi";
import PDFViewer from "./PDFViewer";


type FilePopupProps = {
    file?: LogitarFile,
    onClose: () => void
}

export function base64PDFToBlobUrl(base64: string): string {
    const arr = Buffer.from(base64, "base64");
    const blob = new Blob([arr], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    return url;
}

function convertFileDataByType(file: LogitarFile): string {
    if (file.data) {
        // If already blob url, return as is
        if (file.data.startsWith('blob:')) return file.data;
        // Always convert pdfs to blob url
        else if (file.MIME.includes('pdf')) {
            return base64PDFToBlobUrl(file.data);
        }
        // Return base64 text as is for Window.atob()
        else if (file.MIME.includes('text')) return file.data;
        // The rest should be image types and use this base64 string for those
        else {
            return `data:${file.MIME};base64,${file.data}`;
        }
    }
    return "";
}

export default function FilePopup(props: PropsWithChildren<FilePopupProps>) {

    const [data, setData] = useState<string | null>(null);

    useEffect(() => {
        if (!props.file)
            return;

        if (props.file.data) {
            setData(convertFileDataByType(props.file));
        }
        else {
            // Fetch file if id is number
            if (typeof props.file.id === 'number') {
                LogitarApi.getFile(props.file.id)
                    .then(resp => {
                        const file = resp.file as LogitarFile;

                        if (file && file.data) {
                            setData(convertFileDataByType(file));
                        }
                    })
            }
            else {
                console.warn("FilePopup: File id is not a number, cannot fetch file data.");
            }
        }
    }, [props.file])

    if (!props.file)
        return null;

    return (
        <PopupScreen
            draggable
            title={props.file.name}
            onClose={props.onClose}
            zIndex={1052}
        >
            <Box sx={{ maxWidth: '80vw', maxHeight: '80vh' }}>
                {
                    !data &&
                    // Centered circular progress
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                }
                {
                    (!!data && props.file.MIME.includes('pdf')) &&
                    <PDFViewer file={{ ...props.file, data: data }} width='80vw' height='80vh' />
                    // <iframe
                    //     src={data}
                    //     style={{ width: '100%', height: '100%' }}
                    // />
                }
                {
                    (!!data && props.file.MIME.includes('image')) &&
                    <img alt={props.file.name} style={{ width: '100%' }} src={data} />
                }
                {
                    (!!data && props.file.MIME.includes("text")) &&
                    <div
                        style={{
                            maxHeight: "80vh",
                            minHeight: "40vh",
                            padding: 10,
                            width: "auto",
                            marginRight: 16,
                        }}
                    >
                        <pre style={{ whiteSpace: "pre-wrap" }}>{atob(data)}</pre>
                    </div>
                }

                {props.children}
            </Box>
        </PopupScreen>
    )
}