import { ArrowRight, FilterList, KeyboardArrowDown, KeyboardArrowUp, LocalShipping, ReportOff, WarningAmber } from "@mui/icons-material";
import { Box, Card, Collapse, Divider, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { format, intervalToDuration } from "date-fns";
import { useEffect, useState } from "react";
import { MapTimelineEventType, VehicleTimelineEventLabel } from "./VehicleTimeline";
import { GetVehicleAlertTranslation, VehicleAlert, VehicleSeverity } from "../VehicleAlertNotifier";
import { GetVehicleAlertColor } from "./VehicleMarker";
import { useUserConfig } from "../../misc/UserConfig";


type MapJobCardProps = {
    job: any;
    
    selectedVehicle: any;

    onClickTimestamp?: (timestamp: number) => void;
}

export function MapJobCard (props: MapJobCardProps) {

    const [expanded, setExpanded] = useState(false);

    const loadDuration = (props.job.gpsLoadStart && props.job.gpsLoadEnd) ? 
                            intervalToDuration({start: new Date(props.job.gpsLoadStart + "Z"), end: new Date(props.job.gpsLoadEnd + "Z")}) : undefined;
    const transportDuration = (props.job.gpsLoadEnd && props.job.gpsUnloadStart) ?
                            intervalToDuration({start: new Date(props.job.gpsLoadEnd + "Z"), end: new Date(props.job.gpsUnloadStart + "Z")}) : undefined;
    const unloadDuration = (props.job.gpsUnloadStart && props.job.gpsUnloadEnd) ?
                            intervalToDuration({start: new Date(props.job.gpsUnloadStart + "Z"), end: new Date(props.job.gpsUnloadEnd + "Z")}) : undefined;

    const zeroPad = (num: number | undefined) => String(num).padStart(2, '0');

    return <Card
        sx={{
            background: 'rgba(0, 0, 0, 0.8)',
            m: 0.2,
            boxSizing: 'border-box',
            borderRadius: 1,
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            color: 'primary.contrastText',
            width: 200
        }}
    >
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 0.5,
            }}
        >
            <Box
                sx={{
                    overflow: 'visible',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="caption" sx={{p: 0, m: 0}}>
                        { format(new Date(props.job.date), "dd.MM.yyyy") }
                    </Typography>
                    <Divider 
                        orientation="vertical"
                        flexItem
                    />
                    <Typography variant="caption" sx={{p: 0, m: 0}}>
                        { props.job.shift }
                    </Typography>
                    <Divider 
                        orientation="vertical"
                        flexItem
                    />
                    <Typography variant="caption" sx={{p: 0, m: 0}}>
                        #{ props.job.slot + 1 }
                    </Typography>
                </Box>
                <Typography 
                    variant="body1"
                    sx={{
                        fontSize: '0.8em'
                    }}
                >
                    {props.job.item.shortName}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pl: 1,
                    cursor: 'pointer'
                }}
                onClick={() => setExpanded(!expanded)}
            >
                {
                    expanded ? 
                        <KeyboardArrowUp /> : <KeyboardArrowDown />
                }
                
            </Box>
        </Box>
        <Collapse in={expanded}>
            <Card
                sx={{
                    width: '100%',
                    background: 'rgba(50, 50, 50, 0.3)',
                    color: 'primary.contrastText',
                    p: 0.5,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <VehicleTimelineEventLabel 
                            type={MapTimelineEventType.LoadStart} 
                            data={props.job}  
                            onClickTimestamp={(t) => props.onClickTimestamp && props.onClickTimestamp(t)}
                        />
                    </Box>
                    <Box>
                        {
                            (props.job.gpsLoadStart && props.job.gpsLoadEnd) ?
                            `${zeroPad(loadDuration?.hours)}:${zeroPad(loadDuration?.minutes)}:${zeroPad(loadDuration?.seconds)}` : "-"
                        }
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <VehicleTimelineEventLabel 
                            type={MapTimelineEventType.LoadEnd} 
                            data={props.job} 
                            onClickTimestamp={(t) => props.onClickTimestamp && props.onClickTimestamp(t)}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <LocalShipping />
                    </Box>
                    <Box>
                        {
                            (props.job.gpsLoadEnd && props.job.gpsUnloadStart) ?
                            `${zeroPad(transportDuration?.hours)}:${zeroPad(transportDuration?.minutes)}:${zeroPad(transportDuration?.seconds)}` : "-"
                        }
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ArrowRight />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <VehicleTimelineEventLabel 
                            type={MapTimelineEventType.UnloadStart} 
                            data={props.job} 
                            onClickTimestamp={(t) => props.onClickTimestamp && props.onClickTimestamp(t)}
                        />
                    </Box>
                    <Box>
                        {
                            (props.job.gpsUnloadStart && props.job.gpsUnloadEnd) ?
                            `${zeroPad(unloadDuration?.hours)}:${zeroPad(unloadDuration?.minutes)}:${zeroPad(unloadDuration?.seconds)}` : "-" 
                        }
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <VehicleTimelineEventLabel 
                            type={MapTimelineEventType.UnloadEnd} 
                            data={props.job} 
                            onClickTimestamp={(t) => props.onClickTimestamp && props.onClickTimestamp(t)}
                        />
                    </Box>
                </Box>
            </Card>
        </Collapse>
    </Card>
}

export function MapAlertCard (props: { alert: VehicleAlert }) {

    const alert = props.alert;

    const alertTranslation = GetVehicleAlertTranslation(alert);

    return <Card
        sx={{
            background: 'rgba(0, 0, 0, 0.8)',
            m: 0.2,
            boxSizing: 'border-box',
            borderRadius: 1,
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            color: 'primary.contrastText',
            width: 200
        }}
    >
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 0.5,
            }}
        >
            <Box
                sx={{
                    overflow: 'visible',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant="caption" sx={{p: 0, m: 0, fontSize: '0.7em', lineHeight: '1em'}}>
                            { format(new Date(alert.dateTime), "dd.MM.yyyy") }
                        </Typography>
                        <Typography variant="caption" sx={{p: 0, m: 0, fontSize: '0.6em', lineHeight: '0.75em'}}>
                            { format(new Date(alert.dateTime), "HH:mm:ss") }
                        </Typography>
                    </Box>
                    <Divider 
                        orientation="vertical"
                        flexItem
                    />
                    <WarningAmber 
                        sx={{
                            color: GetVehicleAlertColor(alert.severity)
                        }}
                    />
                </Box>
                <Typography 
                    variant="body1"
                    sx={{
                        fontSize: '0.8em'
                    }}
                >
                    {alertTranslation.name}
                </Typography>
                <Typography 
                    variant="body1"
                    sx={{
                        fontSize: '0.6em'
                    }}
                >
                    {alertTranslation.description}
                </Typography>
            </Box>
        </Box>
    </Card>
}

type MapEventListProps = {

    show?: boolean;

    jobs?: any[];
    alerts?: VehicleAlert[];
    workhours?: any[];

    selectedVehicle: any;

    onClickTimestamp?: (timestamp: number) => void;
}

type MapEventListConfig = {
    filterSeverity?: VehicleSeverity | null;
}


export default function MapEventList (props: MapEventListProps) {

    const mapEventConfig = useUserConfig<MapEventListConfig>("mapEventList");

    if(mapEventConfig.config.filterSeverity === undefined) {
        // Set default show all alerts
        mapEventConfig.config.filterSeverity = VehicleSeverity.LOW;
    }

    const [filterPopupAnchor, setFilterPopupAnchor] = useState<null | HTMLElement>(null);
    const [filterSeverity, setFilterSeverity] = useState<VehicleSeverity | null>(mapEventConfig.config.filterSeverity || null);
    const [listCombo, setListCombo] = useState<any[]>([]);


    useEffect(() => {
        const lc = [
            ...(props.jobs || []).map(e => ({...e, _evtType: "job"})), 
            ...(props.alerts || []).filter(e => (filterSeverity !== null ? e.severity >= filterSeverity : false)).map(e => ({...e, _evtType: "alert"}))
        ]
        .sort((a, b) => {
            let aTime = 0;
            let bTime = 0;

            switch(a._evtType) {
                case "job":
                    aTime = new Date(a.gpsLoadStart || a.date).getTime();
                    break;
                case "alert":
                    aTime = new Date(a.dateTime).getTime();
                    break;
            }

            switch(b._evtType) {
                case "job":
                    bTime = new Date(b.gpsLoadStart || b.date).getTime();
                    break;
                case "alert":
                    bTime = new Date(b.dateTime).getTime();
                    break;
            }

            return bTime - aTime;
        });

        setListCombo(lc);
    }, [props.jobs, props.alerts, filterSeverity]);

    if(!props.show) {
        return null;
    }

    return <Box
        sx={{
            position: "absolute",
            top: '12vh',
            bottom: '20vh',
            right: 0,
            overflow: 'visible',
            padding: 1
        }}
    >
        <Box
            sx={{
                background: 'rgba(0, 0, 0, 0.5)',
                p: 1,
                m: 0,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Typography 
                    variant="caption"
                    sx={{
                        p: 0.5,
                        m: 0,
                        color: 'primary.contrastText'
                    }}
                >
                    { props.selectedVehicle.vehicle.id } - { props.selectedVehicle.vehicle.licenseNumber }
                </Typography>
                <IconButton
                    onClick={(e) => setFilterPopupAnchor(e.target as HTMLElement)}
                    color="primary"
                >
                    <FilterList />
                </IconButton>
            </Box>
            <Box
                sx={{
                    overflowY: 'auto',
                }}
            >
            {
                listCombo.map((ev, i) => {
                    switch(ev._evtType) {
                        case "job":
                            return <MapJobCard key={"job-" + ev.id} job={ev} onClickTimestamp={props.onClickTimestamp} selectedVehicle={props.selectedVehicle} />
                        case "alert":
                            return <MapAlertCard key={"alert-" + ev.id} alert={ev} />;
                    }
                })
            }
            </Box>
        </Box>
        <Menu
            id="map-event-list-filter-menu"
            anchorEl={filterPopupAnchor}
            open={Boolean(filterPopupAnchor)}
            onClose={() => setFilterPopupAnchor(null)}
        >
            <ListItem>
                <Typography variant="caption">Näytä varoitukset</Typography>
            </ListItem>
            <MenuItem
                onClick={() => {
                    setFilterSeverity(VehicleSeverity.LOW);
                    setFilterPopupAnchor(null);
                    (mapEventConfig.config as MapEventListConfig) = { ...mapEventConfig.config, filterSeverity: VehicleSeverity.LOW };
                }}
            >
                <ListItemIcon>
                    <WarningAmber fontSize="small" sx={{ color: GetVehicleAlertColor(VehicleSeverity.LOW) }} />
                </ListItemIcon>
                <ListItemText>Matala</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setFilterSeverity(VehicleSeverity.MEDIUM);
                    setFilterPopupAnchor(null);
                    (mapEventConfig.config as MapEventListConfig) = { ...mapEventConfig.config, filterSeverity: VehicleSeverity.MEDIUM };
                }}
            >
                <ListItemIcon>
                    <WarningAmber fontSize="small" sx={{ color: GetVehicleAlertColor(VehicleSeverity.MEDIUM) }} />
                </ListItemIcon>
                <ListItemText>Keskitaso</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setFilterSeverity(VehicleSeverity.HIGH);
                    setFilterPopupAnchor(null);
                    (mapEventConfig.config as MapEventListConfig) = { ...mapEventConfig.config, filterSeverity: VehicleSeverity.HIGH };
                }}
            >
                <ListItemIcon>
                    <WarningAmber fontSize="small" sx={{ color: GetVehicleAlertColor(VehicleSeverity.HIGH) }} />
                </ListItemIcon>
                <ListItemText>Korkea</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setFilterSeverity(null);
                    setFilterPopupAnchor(null);
                    (mapEventConfig.config as MapEventListConfig) = { ...mapEventConfig.config, filterSeverity: null };
                }}
            >
                <ListItemIcon>
                    <ReportOff />
                </ListItemIcon>
                <ListItemText>Ei varoituksia</ListItemText>
            </MenuItem>
        </Menu>
    </Box>
}