import { Box, Collapse, Link, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { TypeBox, extrasList, typeList } from "./ApiDoc";
import { RenderAPIRow } from "./ApiCallsDef";

/**
 * @typedef {{
 *  name: string,
 *  def: {
 *      name: string,
 *      attrs: {[key:string]: string},
 *      def: {[key: string]: string}
 *  },
 *  generics: {
 *      name: string,
 *      attrs: {[key:string]: string},
 *      def: {[key: string]: string}
 *  }[],
 *  collapsed: boolean,
 *  onCollapse: () => void,
 *  linkOpen?: (name: string) => void,
 *  highlighted?: boolean
 * }} ApiGenericDefProps
 */



/**
 * 
 * @brief Object type renderer
 * @param {{
 *  attrs: {[key: string]: string},
 *  fields: {[key: string]: string},
 *  generics: {[key: string]: string}
 * }} props 
 */
export function ApiObjectTypeRenderer(props) {
    return <Box sx={{ p: 1 }}>
        <Table>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '60%' }} />
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    Object.keys(props.fields).map((k, i) => {

                        return <RenderAPIRow 
                            key={"row-" + k}
                            field={k}
                            value={props.fields[k]}
                            description={props.attrs["#" + k.replace("?", "")]}
                            generics={props.generics}
                            linkOpen={props.linkOpen}
                        />
                    })
                }
            </TableBody>
        </Table>
    </Box>
}

/**
 * @brief Enum renderer
 * @param {{
*  attrs: {[key: string]: string},
*  fields: {[key: string]: string}
* }} props 
*/
export function ApiEnumTypeRenderer(props) {

    return <Box sx={{ p: 1 }}>
        <Table>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '60%' }} />
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    Object.keys(props.fields).map((k, i) => {
                        const e = props.fields[k];
                        const desc = props.attrs["#" + k.replace("?", "")];

                        return (
                            <TableRow key={i}>
                                <TableCell>
                                    {e}
                                </TableCell>
                                <TableCell>{k}</TableCell>
                                <TableCell>{desc}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    </Box>
}


/**
 * 
 * @param {ApiGenericDefProps} props 
 */
export default function ApiGenericDef(props) {

    const [attrs, setAttrs] = useState(props.def.attrs);

    const [fields, setFields] = useState(props.def.def);

    let type = "object";
    let TypeRenderer = ApiObjectTypeRenderer;

    const theme = useTheme();

    if (attrs) {
        switch (attrs["@type"]) {
            case "enum":
                type = "enum";
                TypeRenderer = ApiEnumTypeRenderer;
                break;
            case "object":
            default:
                type = "object";
                TypeRenderer = ApiObjectTypeRenderer;
                break;
        }

    }

    return (
        <Paper elevation={2} sx={{ mb: 1 }}>
            <Box onClick={() => props.onCollapse()} sx={{ display: "flex", flexDirection: "row", mb: 1, p: 1, cursor: "pointer", background: props.highlighted ? 'lightyellow' : undefined }}>
                <Typography sx={{ fontWeight: "bold", mr: 1 }} id={props.name.replace("$", "")}>{props.name}</Typography>
                <TypeBox background={theme.palette.mode === "light" ? typeList[type].background : typeList[type].backgroundDark} name={typeList[type].name} />
                {!props.collapsed ? <KeyboardArrowUpIcon sx={{ marginLeft: "auto" }} /> : <KeyboardArrowDownIcon sx={{ marginLeft: "auto" }} />}
            </Box>

            <Collapse in={!props.collapsed} >
                <TypeRenderer attrs={attrs} fields={fields} generics={props.generics} linkOpen={props.linkOpen} />
            </Collapse>
        </Paper>
    )
}