import News from "../../views/News";
import ServiceHistory from "../../views/ServiceHistory";
import ServiceMonitor from "../../views/ServiceMonitor";
import Training from "../../views/Training";
import { ReadPermissions } from "../Permissions";

/**
 * @type {{[key: string]: {header: string, component: JSX.Element, path: string, perms: BigInt}[]}}
 */
export const serviceRoutes = {
    "Tiedotteet": [
        {
            header: "Tiedotelista",
            component: <News />,
            path: "/news",
            perms: ReadPermissions.PERM_GETNEWS
        },
        {
            header: "Koulutukset",
            component: <Training />,
            path: "/training",
            perms: ReadPermissions.PERM_GETNEWS
        }
    ],
    "Huolto": [
        {
            header: "Huoltotapahtuma",
            component: <ServiceHistory />,
            path: "/servicehistory",
            perms: ReadPermissions.PERM_GETSERVICEHISTORY
        },
        {
            header: "Huoltoseuranta",
            component: <ServiceMonitor />,
            path: "/servicemonitor",
            perms: ReadPermissions.PERM_GETSERVICEHISTORY
        }
    ],
}
