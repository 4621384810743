/**
 * @brief File which defines all permissions
 */

// Maximum of 64 permissions

// Write permissions start at 0x100000000
const PERM_WRITEOFFSET = 0x100000000n

// READ PERMISSIONS
export const ReadPermissions = {
    // Get `Item`
    PERM_GETITEMS:          1n << 0n,
    // Get `Toteutus`
    PERM_GETJOBS:           1n << 1n,
    // Get `Suunnitelma`, `AjojSuunnitelma`
    PERM_GETPLANNING:       1n << 2n,
    // Get `AsiakasTiedot`
    PERM_GETCLIENTS:        1n << 3n,
    // Get `Kalusto`
    PERM_GETVEHICLES:       1n << 4n,
    //Get `User`
    PERM_GETUSERS:          1n << 5n,
    //Get `Tiedosto`
    PERM_GETFILES:          1n << 6n,
    //Get `Ketju`
    PERM_GETCHAINS:         1n << 7n,
    //Get `Viesti`
    PERM_GETMESSAGES:       1n << 8n,
    //Get `Tiedote`
    PERM_GETNEWS:           1n << 9n,
    //Get `Tuote`
    PERM_GETCARGOS:         1n << 10n,
    //Get `Log`
    PERM_GETLOGS:           1n << 11n,
    //Get Api documentation
    PERM_GETAPIDOC:         1n << 12n,
    //Get `Tilaus`
    PERM_GETORDERS:         1n << 13n,
    //Get `Alue`
    PERM_GETAREAS:          1n << 14n,
    //Get `Huoltohistoria`
    PERM_GETSERVICEHISTORY: 1n << 15n,
    //Get `Arkipyhä`
    PERM_GETHOLIDAYS:       1n << 16n,
}

// WRITE PERMISSIONS - starting from 0x100000000

export const WritePermissions = {
    // Set `Item`
    PERM_SETITEMS:                          1n << 0n << 32n,
    // Set `Jobs`
    PERM_SETJOBS:                           1n << 1n << 32n,
    // Set `Suunnitelma`, `AjojSuunnitelma`
    PERM_SETPLANNING:                       1n << 2n << 32n,
    // Set `AsiakasTiedot`
    PERM_SETCLIENTS:                        1n << 3n << 32n,
    // Set `Kalusto`
    PERM_SETVEHICLES:                       1n << 4n << 32n,
    // Set `User`
    PERM_SETUSERS:                          1n << 5n << 32n,
    // Set `Tiedosto`
    PERM_SETFILES:                          1n << 6n << 32n,
    // Set `Ketju`
    PERM_SETCHAINS:                         1n << 7n << 32n,
    // Set `Viesti`
    PERM_SETMESSAGES:                       1n << 8n << 32n,
    // Set `Tiedote`
    PERM_SETNEWS:                           1n << 9n << 32n,
    // Set `Tuote`
    PERM_SETCARGOS:                         1n << 10n << 32n,
    // Set `Log`
    PERM_SETLOGS:                           1n << 11n << 32n,
    // Set apidoc, not used
    // PERM_SETAPIDOC: PERM_WRITEOFFSET +   1n << 12n << 32n,
    // Set orders
    PERM_SETORDERS:                         1n << 13n << 32n,
    // Set `Alue`
    PERM_SETAREAS:                          1n << 14n << 32n,
    // Set `Huoltohistoria`
    PERM_SETSERVICEHISTORY:                 1n << 15n << 32n,
    // Set `Arkipyhä`
    PERM_SETHOLIDAYS:                       1n << 16n << 32n,
}


/**
 * @type {{bit: bigint, label: string, description?: string}[]}
 */
export const ReadPermissionInfo = [
    { bit: ReadPermissions.PERM_GETITEMS, label: "Nimikkeet" },
    { bit: ReadPermissions.PERM_GETJOBS, label: "Työt" },
    { bit: ReadPermissions.PERM_GETPLANNING, label: "Suunnitelmat" },
    { bit: ReadPermissions.PERM_GETCLIENTS, label: "Asiakkaat" },
    { bit: ReadPermissions.PERM_GETVEHICLES, label: "Kalusto" },
    { bit: ReadPermissions.PERM_GETUSERS, label: "Käyttäjät" },
    { bit: ReadPermissions.PERM_GETFILES, label: "Tiedostot" },
    { bit: ReadPermissions.PERM_GETCHAINS, label: "Viestiketjut" },
    { bit: ReadPermissions.PERM_GETMESSAGES, label: "Viestit" },
    { bit: ReadPermissions.PERM_GETNEWS, label: "Uutiset" },
    { bit: ReadPermissions.PERM_GETCARGOS, label: "Tuotteet" },
    { bit: ReadPermissions.PERM_GETLOGS, label: "Logit" },
    { bit: ReadPermissions.PERM_GETAPIDOC, label: "API dokumentaatio" },
    { bit: ReadPermissions.PERM_GETORDERS, label: "Tilaukset" },
    { bit: ReadPermissions.PERM_GETSERVICEHISTORY, label: "Huolto" },
    { bit: ReadPermissions.PERM_GETHOLIDAYS, label: "Arkipyhät" },
    { bit: ReadPermissions.PERM_GETAREAS, label: "Alueet" }
]

/**
 * @type {{bit: bigint, label: string, description?: string}[]}
 */
export const WritePermissionInfo = [
    { bit: WritePermissions.PERM_SETITEMS, label: "Nimikkeet" },
    { bit: WritePermissions.PERM_SETJOBS, label: "Työt" },
    { bit: WritePermissions.PERM_SETPLANNING, label: "Suunnitelmat" },
    { bit: WritePermissions.PERM_SETCLIENTS, label: "Asiakkaat" },
    { bit: WritePermissions.PERM_SETVEHICLES, label: "Kalusto" },
    { bit: WritePermissions.PERM_SETUSERS, label: "Käyttäjät" },
    { bit: WritePermissions.PERM_SETFILES, label: "Tiedostot" },
    { bit: WritePermissions.PERM_SETCHAINS, label: "Viestiketjut" },
    { bit: WritePermissions.PERM_SETMESSAGES, label: "Viestit" },
    { bit: WritePermissions.PERM_SETNEWS, label: "Uutiset" },
    { bit: WritePermissions.PERM_SETCARGOS, label: "Tuotteet" },
    { bit: WritePermissions.PERM_SETLOGS, label: "Logit" },
    //{ bit: WritePermissions.PERM_SETAPIDOC, label: "API dokumentaatio" },
    { bit: WritePermissions.PERM_SETORDERS, label: "Tilaukset" },
    { bit: WritePermissions.PERM_SETSERVICEHISTORY, label: "Huolto" },
    { bit: WritePermissions.PERM_SETHOLIDAYS, label: "Arkipyhät" },
    { bit: WritePermissions.PERM_SETAREAS, label: "Alueet" }
]

// PERMISSION TEMPLATES
export const PermissionTemplate = {
    // ALL PERMISSIONS
    PERM_DEF_ALL:           0x7FFFFFFFFFFFFFFFn,
    // OFFICE PERMISSIONS (TODO)
    PERM_DEF_OFFICE:        0x7FFFFFFFFFFFFFFFn,
    // MESSAGE PERMISSIONS
    PERM_DEF_MESSAGES:      (
                                ReadPermissions.PERM_GETCHAINS | ReadPermissions.PERM_GETMESSAGES | ReadPermissions.PERM_GETFILES | ReadPermissions.PERM_GETNEWS |
                                WritePermissions.PERM_SETCHAINS | WritePermissions.PERM_SETMESSAGES | WritePermissions.PERM_SETFILES
                            ),
    // JOBS AND PLANNING
    PERM_DEF_JOBS:          (
                                ReadPermissions.PERM_GETJOBS | ReadPermissions.PERM_GETPLANNING | 
                                WritePermissions.PERM_SETJOBS | WritePermissions.PERM_SETPLANNING | ReadPermissions.PERM_GETAREAS
                            ),
    PERM_DEF_SERVICE:       (
                                ReadPermissions.PERM_GETSERVICEHISTORY | WritePermissions.PERM_SETSERVICEHISTORY
                            )
}