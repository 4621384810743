import { format, parse, subMinutes } from "date-fns";
import { FishStatus, JobInfoJobFish } from "../../misc/LogitarTypes";
import { fishStatusLabels } from "../../driver/DriverFishFields";
import { Box, Tooltip } from "@mui/material";
import { ReactNode } from "react";

export { };

export function formatJobFishData(jobFish: JobInfoJobFish) {
    const formatted: { [Property in keyof JobInfoJobFish]: ReactNode } = {
        id: jobFish.id ? jobFish.id : "",
        loadTime: jobFish.loadTime ? format(parse(jobFish.loadTime as string, "HH:mm:ss", new Date()), "HH:mm") : "",
        unloadTime: jobFish.unloadTime ? format(parse(jobFish.unloadTime as string, "HH:mm:ss", new Date()), "HH:mm") : "",
        washTime: jobFish.washTime ? format(parse(jobFish.washTime as string, "HH:mm:ss", new Date()), "HH:mm") : "",
        washLocation: jobFish.washLocation ? jobFish.washLocation : "",
        fishStatus: jobFish.fishStatus?.map((status, key) => {
            const sDate = subMinutes(new Date(status.dateTime), new Date().getTimezoneOffset());
            const sLabel = status.status ? fishStatusLabels[status.status] : "";
            const sNotes = status.notes ? status.notes : "";
            return (<Tooltip
                title={
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                        <span>{format(sDate, "dd.MM.yyyy HH:mm:ss")}</span>|
                        <span>{sLabel}</span>|
                        <span>{sNotes}</span>
                    </Box>
                }
                placement="right"
                key={key + "f"}
                slotProps={{
                    tooltip: {
                        sx: { maxWidth: "500px" }
                    }
                }}
            >
                <Box sx={{ textDecoration: "underline" }}>
                    {`${format(sDate, "HH:mm")} ${sLabel}`}
                </Box>
            </Tooltip>)
        }),
    }
    return formatted;
}

export function formatFishStatus(fishStatus: FishStatus[]) {
    if (fishStatus == null) return null;
    return <Box>
        {fishStatus.map((status, key) => {
            const sDate = subMinutes(new Date(status.dateTime), new Date().getTimezoneOffset());
            const sLabel = status.status ? fishStatusLabels[status.status] : "";
            const sNotes = status.notes ? status.notes : "";
            return <Box key={key} sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                p: 0.25,
                whiteSpace: "nowrap",
                ":nth-of-type(even)": {
                    background: "#FFF2"
                }
            }}>
                <span>{format(sDate, "dd.MM.yyyy HH:mm:ss")}</span>
                <span style={{ flex: 1 }}>{sLabel}</span>
                <span>{sNotes}</span>
            </Box>
        })}
    </Box>
}

export function timeStringToMinutes(timeString: string) {
    if (typeof timeString !== "string") return 0;
    const date = parse(timeString, "HH:mm:ss", new Date());
    let minutes = date.getHours() * 60;
    minutes += date.getMinutes();
    return minutes;
}

export function minutesToHoursMinutes(minutes: number) {
    if (typeof minutes !== "number") return "";
    const hrs = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    return `${String(hrs).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
}

export function timeStringToHoursMinutes(timeString: string) {
    if (typeof timeString !== "string") return "";
    const date = parse(timeString, "HH:mm:ss", new Date());
    return format(date, "HH:mm");
}