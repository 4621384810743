import { Autocomplete, Box, Checkbox, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel, TextField, ToggleButton, ToggleButtonGroup, Typography, Divider, FormGroup, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { Add, Height, Remove, } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider, PickersDay, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";
import { format, setDay } from "date-fns";
import LogitarApi from "../api/LogitarApi";
import { CustomPopper } from "../misc/InternalFeatures";
import PopupScreen from "./PopupScreen";
import { useSnackbar } from "notistack";

const dayButtonStyle = { cursor: "pointer" }

export const dayTemplates = {
    mape: [1, 1, 1, 1, 1, 0, 0],
    masu: [1, 1, 1, 1, 1, 1, 1]
}

// Last monday as date
const lastMonday = new Date();
if (lastMonday.getDay() !== 1) {
    lastMonday.setDate(lastMonday.getDate() + (1 - lastMonday.getDay()));
}
// Week forwards from this
const nextSunday = new Date(lastMonday);
nextSunday.setDate(nextSunday.getDate() + 6);

const defaultOrder = {
    item: "",
    count: 1,
    date: new Date(),
    details: "",
    startDate: lastMonday,
    endDate: nextSunday,
    recurring: false,
    replay: 0
}

export const dayShortNames = ["Ma", "Ti", "Ke", "To", "Pe", "La", "Su"];
export const dayLongNames = ["Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai", "Sunnuntai"];


const tabs = [{ value: "mape", label: "MA-PE" }, { value: "masu", label: "MA-SU" }, { value: "free", label: "Vapaa" }]

export default function OrderSet({ exit, options, update, editOrder, predictType, dialogOpen }) {

    const [type, setType] = useState(predictType)

    const [search, setSearch] = useState("")

    const [order, setOrder] = useState(editOrder ? editOrder : { ...defaultOrder });

    const [days, setDays] = useState(editOrder ? JSON.parse(editOrder.days) : [0, 0, 0, 0, 0, 0, 0])

    const [detailsDays, setDetailsDays] = useState(editOrder ? JSON.parse(editOrder.detailDays) : [])

    const [ending, setEnding] = useState(editOrder ? (editOrder.endDate ? "date" : "count") : "count")

    const [template, setTemplate] = useState(false)
    const [countForTemplate, setCountForTemplate] = useState(editOrder ? JSON.parse(editOrder.days)[0] : 1)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    const orderChange = (e) => {

        if (e.target.type === "search") return

        if (e.target.type === "checkbox") {
            setOrder((prev) => ({ ...prev, [e.target.name]: Number(e.target.checked) }))
            return
        }

        if (Object.keys(order).includes(e.target.name)) {

            setOrder((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        }

    }

    const allDaysChange = (count) => {
        setDays((prev) => prev.map((value, key) => { if ((value + count) < 0) return value; return value + count }))
    }

    const getDaysByTemplate = (name = null, count = null) => {

        let templateToUse = name ? name : template
        let countToUse = count ? count : countForTemplate
        if (templateToUse) return dayTemplates[templateToUse].map((value, key) => { return value * parseInt(countToUse) })

    }

    const updateTemplateCount = (e) => {
        setCountForTemplate(e.target.value)

        if (template) setDays(getDaysByTemplate(null, e.target.value))

    }

    const itemSelected = (e, v, r) => {

        e.preventDefault();

        setSearch("")

        if (!v) {
            setOrder(prev => ({ ...prev, item: 0 }))
            return
        }

        setOrder(prev => ({ ...prev, item: v.id }))
    }

    //Workflow: Get days by template if type is in templates, then use orders count number
    //null dates if not set correctly
    const sendOrder = () => {

        let getDays = []
        let getDetailsDays = []
        let calcCount = null

        getDetailsDays = detailsDays;
        if (Object.keys(dayTemplates).includes(type)) {
            console.log(type)
            getDays = getDaysByTemplate(type)
        } else {
            getDays = days
        }

        calcCount = order.count

        let sentOrder = {
            ...order,
            days: JSON.stringify(getDays),
            detailDays: JSON.stringify(getDetailsDays),
            count: calcCount,
            date: format(new Date(order.date), "yyyy-MM-dd"),
            endDate: (ending === "date" && !order.recurring) ? format(new Date(order.endDate), "yyyy-MM-dd") : null,
            startDate: format(new Date(order.startDate), "yyyy-MM-dd"),
            recurring: Number(order.recurring),
        }

        console.log("sentOrder: ", sentOrder)

        LogitarApi.setOrder(sentOrder).then((result) => {
            console.log(result)
            update(prev => prev + 1)
            exit(false)
            enqueueSnackbar("Tilaus tallennettu")
        })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar("Tilauksen tallennus epäonnistui", { variant: "error" })
            })
    }

    const renderPickerDate = useCallback((props) => {
        const date = props.day;
        let isSelected = false;
        const orderDate = new Date(order.startDate);
        orderDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        let ed = new Date(orderDate);

        if (ending === "count") {
            ed.setDate(ed.getDate() + Number(order.count) * 7);
            if (order.replay > 0) {
                ed.setDate(ed.getDate() + Number(order.count) * order.replay);
            }
            ed.setHours(0, 0, 0, 0);
        }
        else if (ending === "date") {
            ed = new Date(order.endDate);
        }
        if (orderDate.getTime() <= date.getTime() && ((ed.getTime() > date.getTime()) || order.recurring)) {
            if (type === "mape") {
                if (date.getDay() > 0 && date.getDay() < 6) {
                    isSelected = true;
                }
            }
            else if (type === "masu") {
                isSelected = true;
            }
            else if (type === "free") {
                if (Number(order.replay) > 0) {
                    let dIndex = 0;
                    let dValue = days[0];
                    for (let d = new Date(orderDate); d < date; d.setDate(d.getDate() + 1)) {
                        dIndex++;
                        if (dIndex > 6) {
                            dValue = 0;
                            if (dIndex > 6 + Number(order.replay)) {
                                dIndex = 0;
                                dValue = days[0];
                                continue;
                            }
                        }
                        else {
                            dValue = days[dIndex];
                        }
                    }
                    if (dValue > 0) {
                        isSelected = true;
                    }
                }
                else {
                    if (days[(date.getDay() + 6) % 7] > 0) {
                        isSelected = true;
                    }
                }
            }
        }

        return <PickersDay {...props} selected={isSelected} />
    }, [days, ending, type, order])

    return (
        <PopupScreen
            title={editOrder ? "Tilauksen muokkaus" : "Tilauksen luonti"}
            onClose={() => exit(false)}
            onDelete={editOrder ? (() => dialogOpen(true)) : undefined}
            onSave={() => sendOrder()}
            draggable
        >
            <Box>
                <ToggleButtonGroup
                    value={type}
                    exclusive
                    onChange={e => {
                        setType(e.target.value);
                        setOrder({ ...order, recurring: false });
                        if (e.target.value === "mape" || e.target.value === "masu") {
                            setEnding("count");
                        }
                    }}
                >
                    {tabs.map((tab, key) => (
                        <ToggleButton key={key} value={tab.value}>{tab.label}</ToggleButton>
                    ))}

                </ToggleButtonGroup>
                <Box sx={{ minHeight: 100 }}>
                    <Box sx={{ '& .MuiTextField-root': { m: 0, mr: 1, my: 1 }, mt: 1 }} onChange={orderChange}>
                        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "100%" }}>
                            <Box sx={{ width: "90%", display: "flex" }}>
                                <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Aloituspäivämäärä"
                                        inputFormat='dd.MM.yyyy'
                                        mask={"__.__.____"}
                                        value={new Date(order.startDate)}
                                        sx={{ flex: 1 }}
                                        onChange={(value) => setOrder(prev => ({ ...prev, startDate: setDay(value, 1, { weekStartsOn: 1 }) }))}
                                        renderInput={(params) => <TextField {...params} />}
                                    ></DesktopDatePicker>
                                </LocalizationProvider>

                                <Autocomplete
                                    options={options}
                                    PopperComponent={CustomPopper}
                                    id="item-search"

                                    sx={{ flex: 3 }}

                                    onChange={itemSelected}
                                    componentsProps={{
                                        paper: {
                                            elevation: 10,
                                        },
                                    }}

                                    value={order.item ? options.find((item => item.id === order.item)) : null}

                                    getOptionLabel={option => `${option.id}: ${option.name} - ${option.cargoLabel}`}

                                    renderOption={(props, option) => 
                                        <li {...props} style={option.active === 0 ? { textDecoration: "line-through", opacity: 0.5 } : undefined}>
                                            {option.id}: {option.name} - {option.cargoLabel}
                                        </li>}

                                    open={search.length > 0}

                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label={"Nimike"}
                                            name="item-search"
                                            onChange={(e) => setSearch(e.target.value)}
                                            sx={options?.find(item => item.id === order.item)?.active === 0 && search.length < 1
                                                ? { "& .MuiAutocomplete-input": { textDecoration: "line-through", opacity: 0.5 }}
                                                : undefined
                                            }
                                        />
                                    }
                                />
                            </Box>

                            {type !== "free" &&
                                <>
                                    <TextField name="details" label="Lisätieto" sx={{ width: 445 }} value={order.details} />
                                    <FormGroup row>
                                        <TextField
                                            name="updateTemplateCount"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            type="number"
                                            label="Kpl per pv"
                                            onChange={updateTemplateCount}
                                            value={countForTemplate}
                                            sx={{ flex: 1 }}
                                        />

                                        <TextField
                                            name="count"
                                            disabled={order.recurring}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            type="number"
                                            label="Viikkoja jäljellä"
                                            value={order.count}
                                            sx={{ flex: 1 }}
                                        />

                                        <FormControlLabel
                                            control={<Checkbox
                                                name="recurring"
                                                value={Boolean(order.recurring)}
                                                checked={order.recurring} />}
                                            label="Voimassa toistaiseksi"
                                            sx={{ flex: 2 }}
                                        />
                                    </FormGroup>
                                </>
                            }

                        </Box>
                    </Box>
                    {type === "free" ?
                        <div>
                            <Stack
                                spacing={{ xs: 1, sm: 1 }}
                                direction="row"
                                useFlexGap
                                flexWrap="wrap"
                            >
                                <Box sx={{ "& .MuiTextField-root": { mr: 1 } }}>
                                    {/* <Box> */}
                                    <RadioGroup
                                        value={ending}
                                        onChange={(e) => {
                                            setEnding(e.target.value);
                                        }}
                                        name="ending"
                                        sx={{ flexDirection: "row", flexWrap: "wrap", ml: 26, pb:1 }}
                                    >
                                        <FormControlLabel disabled={order.recurring} value="count" control={<Radio />} label="Toistoa jäljellä" />
                                        <FormControlLabel disabled={order.recurring} value="date" control={<Radio />} label="Päättyy pvm" />
                                    </RadioGroup>

                                    <FormControl >
                                        {/* <FormLabel>Päättyy</FormLabel> */}
                                        <FormControlLabel
                                            control={<Checkbox
                                                name="recurring"
                                                value={order.recurring}
                                                onChange={(e, v) => {
                                                    setOrder((prev) => ({ ...prev, [e.target.name]: Number(e.target.checked), count: 1 }));
                                                    if (!ending) {
                                                        setEnding("count")
                                                    }
                                                }}
                                                checked={order.recurring} />}
                                            label="Voimassa toistaiseksi" />

                                    </FormControl>                                    
                                    <TextField
                                        name="count"
                                        disabled={ending !== "count" || order.recurring}
                                        onChange={orderChange}
                                        InputProps={{ inputProps: { min: 1 } }}
                                        type="number"
                                        label="Viikkoja jäljellä"
                                        sx={{ width: 120 }}
                                        value={order.count}
                                    />
                                    <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Lopetuspäivämäärä"
                                            inputFormat='dd.MM.yyyy'
                                            mask={"__.__.____"}
                                            value={new Date(order.endDate)}
                                            disabled={ending !== "date" || order.recurring}
                                            sx={{ color: "#ffffff", width: 160, ml: 1 }}
                                            onChange={(value) => setOrder(prev => ({ ...prev, endDate: format(value, "yyyy-MM-dd") }))}
                                            renderInput={(params) => <TextField {...params} sx={{ color: "#ffffff" }} />}
                                        ></DesktopDatePicker>
                                    </LocalizationProvider>
                                    <TextField
                                        name="replay"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        type="number"
                                        label="Toista päivän välein"
                                        sx={{ width: 140, ml: 1 }}
                                        value={order.replay || "0"}
                                        onChange={(e) => setOrder(prev => ({ ...prev, replay: e.target.value }))}
                                    />
                                </Box>
                            </Stack>                            
                            <Stack
                                spacing={{ xs: 1, sm: 1 }}
                                direction="row"
                                useFlexGap
                                flexWrap="wrap"
                            >
                                <Stack
                                    spacing={{ xs: 2, sm: 1 }}
                                    direction="column"
                                    useFlexGap
                                    flexWrap="wrap"
                                >
                                    <Box sx={{ display: 'flex', gap: 2, ml: 1, mb: 0.5}}>
                                        <Tooltip placement="left" title="Lisää kaikille päiville">
                                            <Add sx={dayButtonStyle} onClick={() => allDaysChange(1)}>plus</Add>
                                        </Tooltip>
                                        <Tooltip placement="right" title="Vähennä kaikilta päiviltä">
                                            <Remove sx={dayButtonStyle} onClick={() => allDaysChange(-1)}>minus</Remove>
                                        </Tooltip>
                                    </Box>
                                    {days.map((value, key) => (
                                        <Box key={key} sx={{ display: "flex", columnGap: "7px" }}>
                                            <TextField
                                                sx={{ width: 90 }}
                                                label={dayLongNames[key]}
                                                type="number"
                                                value={value}
                                                InputProps={{ inputProps: { min: 0, max: 999 } }}
                                                onChange={(e) => { setDays(prev => { let tempDays = [...prev]; tempDays[key] = parseInt(e.target.value); return tempDays }) }}
                                            />
                                            <TextField name="details" label="Lisätieto" sx={{ width: 565 }} value={detailsDays[key]}
                                                onChange={(e) => { setDetailsDays(prev => { let tempDetailsDays = [...prev]; tempDetailsDays[key] = e.target.value; return tempDetailsDays }) }}
                                            />
                                        </Box>
                                    ))}
                                </Stack>
                                <Box>
                                    <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                        <StaticDatePicker
                                            readOnly={true}
                                            showDaysOutsideCurrentMonth
                                            ToolbarComponent={() => null}
                                            slotProps={{
                                                actionBar: {
                                                    actions: []
                                                },
                                                toolbar: {
                                                    hidden: true
                                                }
                                            }}
                                            slots={{
                                                day: renderPickerDate
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                            {/* </Box> */}
                        </div>
                        : <></>}
                </Box>
                <Divider sx={{ mt: 1 }} />
            </Box>
        </PopupScreen>
    )
}