import { Refresh } from "@mui/icons-material";
import { Alert, Backdrop, Box, Button, Collapse, Divider, Paper, Skeleton, Switch, SxProps, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, format } from "date-fns";
import { fi } from "date-fns/locale";
import { createContext, useEffect, useRef, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import LogitarEventProvider from "../api/LogitarEventProvider";
import PlanningCarRow from "../components/PlanningCarRow";
import PlanningICRow from "../components/PlanningICRow";

import { Loader } from "../misc/InternalFeatures";
import { DragItem, PlanningItem, PlanningJobInfo, PlanningOrder, PlanningRow, PlanningRowIC, PlanningSlot } from "../misc/LogitarTypes";
import { contentBoxStyle } from '../styles/styles';
import PlanningOrderList from "./PlanningOrderList";
import { vehicleGroupSorter } from "./Vehicles";
import PlanningNotes from "../components/PlanningNotes";


const mainBoxStyle: SxProps = {
    display: "flex",
    flexDirection: "row",
    height: '100%',
    overflowX: "auto",
}

const leftBoxStyle: SxProps = {
    display: 'flex',
    flexDirection: "column",
    flexWrap: "no-wrap",
    flex: 5,
    mr: 1,
    maxWidth: "1370px",
}

const rightBoxStyle: SxProps = {
    mx: 0.2,
    display: 'flex',
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    maxHeight: "100%",
    maxWidth: 500,
    minWidth: 180,
}

const datesStyle: SxProps = {
    mx: 1,
    p: 1,
    textAlign: "center",
    borderRadius: 2,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    mb: 1
}


const carsStyle: SxProps = {
    p: 2
}

const flexRowStyle: SxProps = {
    display: 'flex',
    flexDirection: "row",

}

const dayButtonStyle: SxProps = {
    fontSize: 26,
    height: 30
}


const shiftStyle: SxProps = {
    display: 'inline-flex',
    flexDirection: "row",
    justifyContent: "center",
    height: 30,
    "& .MuiTypography-root": {
        px: 1,
        pt: 1
    },
}

//Plan coloring
export const slotClasses: string[] = [
    //Created / not sent, red, pending changes
    "default-red",
    //Sent job, yellow
    "default-yellow",
    //Job seen, green
    "default-green",
    //Job loaded, blue
    "default-blue",
    //Job unloaded, white
    ""
]

export const convertShift = (shift: boolean): 'A' | 'I' => shift ? 'I' : 'A';

// Initial planning slot count
export const PLANNING_SLOT_INITIAL_COUNT = 8;
// Update rate in ms
export const PLANNING_POLL_RATE = 30000;

export const UpdateContext = createContext<any | undefined>(undefined);
export const SendingContext = createContext<any | undefined>(undefined);

export default function PlanningNew() {

    // Planning view date
    const [date, setPDate] = useState(new Date());
    const dateRef = useRef(date);
    // Planning view shift. false = 'A', true = 'I'
    const [shift, setShift] = useState(false);
    const shiftRef = useRef(shift);

    // Used for force fetch of planning data
    const [count, setCount] = useState(0);

    // Planning rows
    const [rows, setRows] = useState<PlanningRow[]>([]);
    const rowsRef = useRef(rows);

    // Inter client rows
    const [ics, setIcs] = useState<PlanningRowIC[]>([]);
    const icsRef = useRef(ics);

    const [unassigned, setUnassigned] = useState<{client: {name: string, displayName: string}, jobs: any[]}[]>([]);
    const unassignedRef = useRef(unassigned);

    // Item that is currently being dragged
    const [dragItem, setDragItem] = useState<DragItem | null>(null);

    const [events, setEvents] = useState<{ source: string, data: any, id: number }[]>([]);
    // Events reference for simultaneous events
    const eventsRef = useRef(events);

    const [loadingStates, setLoadingStates] = useState<{
        orders: boolean,
        items: boolean,
        clients: boolean,
        planning: boolean
    }>({
        orders: true,
        items: true,
        clients: true,
        planning: true
    });

    const [sending, setSending] = useState<boolean>(false);

    const [eventDisconnected, setEventDisconnected] = useState(false);

    // Sets a single loading state
    const setLoadingState = (key: keyof typeof loadingStates, value: boolean) => {
        setLoadingStates((prev) => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    const dragStart = (e: DragEvent, item: PlanningItem, order?: PlanningOrder, job?: PlanningJobInfo) => {
        setDragItem({
            order: order,
            item: item,
            job: job
        });
    };

    useEffect(() => {

        if (events.length === 0)
            return;

        setEventDisconnected(false);

        const handledEvents: number[] = [];
        const nCars = rows.map((e) => ({ ...e }));

        for (let ev of events) {
            // Add event id to handled events
            handledEvents.push(ev.id);

            const source = ev.source, data = ev.data;

            console.log("Event " + source, data);

            if (source === "jobsplit") {

                const jobsplit = data.jobsplit;
                const job = data.job;

                //Data vehicle is string sometimes, so convert it to number
                const refVeh = nCars.find(e => Number(e.vehicle.id) === Number(jobsplit.vehicle));

                if (refVeh) {

                    const refJob = refVeh.slots.findIndex(e => e.job && (e.job.id === job.id));

                    if (refJob > -1) {
                        const j = refVeh.slots[refJob].job;
                        if (j) {
                            //Not completely necessary, but format the split data to be sure
                            if (jobsplit.id && jobsplit.del) {
                                j.jobSplit = null;
                            } else {
                                j.jobSplit = { ...refVeh.slots[refJob]?.job?.jobSplit, ...jobsplit }
                            }

                            refVeh.slots[refJob].job = { ...j, draft: job.draft };
                        }
                    }
                }
                continue;
            } 
            else if (source === "drivershift") {

                let ds: any;

                if (Array.isArray(data)) {
                    ds = data[0];
                } else {
                    ds = data;
                }

                const nextShift = shift ? "A" : "I"

                const curDate = shift ? addDays(new Date(date), 1) : new Date(date)

                const nextDate = format(curDate, "yyyy-MM-dd")

                if (ds.shift !== nextShift || ds.date !== nextDate) {
                    //Not the correct shift, skip
                    console.log("drivershift, Not the correct shift, skip", ds.shift, nextShift, ds.date, nextDate)
                    continue
                }

                if (data.id && data.del) {

                    //Find vehicle
                    const refVeh = nCars.find(e => e.vehicle.id == data.vehicle);

                    if (refVeh) {

                        //Clear vehicles driverShifts
                        refVeh.vehicle.driverShifts = [];
                        console.log("setting drivershifts empty", refVeh.vehicle.id)
                    }

                    continue
                }

                //Find vehicle
                const refVeh = nCars.find(e => e.vehicle.id == ds.vehicle);

                if (refVeh) {
                    console.log("setting drivershift", refVeh, ds)
                    //next shift
                    //Find drivershift
                    const refDS = refVeh.vehicle.driverShifts.findIndex((e) => e.id === ds.id)
                    if (refDS > -1 && refVeh.vehicle.driverShifts[refDS].user !== ds.user) {
                        //If drivershift exists, replace it
                        refVeh.vehicle.driverShifts[refDS] = ds
                    } else {
                        //If drivershift does not exist, push it
                        refVeh.vehicle.driverShifts.push(ds)
                    }
                }


                continue;
            }
            else if (source === "job") {
                if (data.id) {
                    // Single job
                    if (data.del) {
                        // Check if job is in unassigned
                        for (let ic of unassignedRef.current) {
                            const jobIndex = ic.jobs.findIndex((e) => e.id === data.id);
                            if (jobIndex > -1) {
                                ic.jobs.splice(jobIndex, 1);
                                break;
                            }
                        }

                        // Delete job
                        for (let v in nCars) {
                            const veh = nCars[v];
                            const jindex = veh.slots.findIndex(e => e.job && (e.job.id == data.id));
                            if (jindex >= 0) {
                                if (veh.slots[jindex].hiddenJobs && (veh.slots[jindex].hiddenJobs as PlanningJobInfo[]).length > 0) {
                                    // Refresh page
                                    setCount(Date.now());
                                }
                                // Reset job
                                veh.slots[jindex] = {
                                    ...veh.slots[jindex],
                                    job: null
                                };
                                break;
                            }
                            else {
                                // Also check hidden jobs
                                for (let slot of veh.slots) {
                                    if (slot.hiddenJobs) {
                                        const hindex = slot.hiddenJobs.findIndex(e => e.id == data.id);
                                        if (hindex >= 0) {
                                            // Reset job
                                            slot.hiddenJobs.splice(hindex, 1);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    continue
                }

                // Remove possibly moved jobs
                nCars.forEach((veh) => {
                    for (let _v in data) {
                        const d = data[_v];
                        if (!d)
                            return;

                        // Remove possibly moved jobs
                        veh.slots.filter(s => !!s.job).forEach((slot) => {

                            const djob = d.jobs.find((job: any) => job.id === slot.job?.id);
                            if (djob) {

                                // Replace data with draft data
                                if (djob.draft && !Array.isArray(djob.draft)) {
                                    // Replace date, shift, vehicle and slot
                                    djob.date = djob.draft.date !== undefined ? djob.draft.date : djob.date;
                                    djob.shift = djob.draft.shift !== undefined ? djob.draft.shift : djob.shift;
                                    djob.slot = djob.draft.slot !== undefined ? djob.draft.slot : djob.slot;
                                    djob.vehicle = djob.draft.vehicle !== undefined ? djob.draft.vehicle : veh.vehicle.id;
                                    djob.planDetails = djob.draft.planDetails !== undefined ? djob.draft.planDetails : djob.planDetails;
                                }
                                if (djob.date !== slot.date || djob.shift !== slot.shift || djob.vehicle !== veh.vehicle.id || djob.slot !== slot.slot) {
                                    // Replace original value
                                    // Check for hidden jobs
                                    if (slot.hiddenJobs && slot.hiddenJobs.length > 0) {
                                        console.log("Hidden jobs found, pop");
                                        slot.job = slot.hiddenJobs.pop() as PlanningJobInfo;
                                    }
                                    else {
                                        slot.job = null;
                                    }
                                }
                            }
                        })
                    }
                });

                // Loop through event data
                for (let v in data) {

                    // Check if row is found in unassigned
                    for (let ic of unassignedRef.current) {
                        for (let job of data[v].jobs) {
                            const jobIndex = ic.jobs.findIndex((z: any) => z.id === job.id);
                            if (jobIndex > -1) {
                                ic.jobs.splice(jobIndex, 1);
                                break;
                            }
                        }
                    }
                    if(v === "") {
                        // Unassigned
                        // Add jobs to unassigned

                        for(let job of data[v].jobs) {
                            const ic = unassignedRef.current.find((e) => e.client.name === job.interClient);
                            if(ic) {
                                let existingJob = ic.jobs.find((e) => e.id === job.id);
                                if(existingJob) {
                                    // Update job
                                    existingJob = { ...existingJob, ...job };
                                }
                                else {
                                    // Add new job
                                    ic.jobs.push(job);
                                }
                            }
                        }
                        continue;
                    }

                    let refVeh = nCars.find(e => String(e.vehicle.id) == v);
                    if (refVeh) {
                        //Deleting drivershifts from vehicle event, since it's always empty and shifts are handled separately
                        delete data[v].vehicle.driverShifts;
                        refVeh.vehicle = { ...refVeh.vehicle, ...data[v].vehicle };
                        for (let p of data[v].jobs) {
                            const actualVehicle = nCars.find(e => e.vehicle.id == p.vehicle);
                            if (actualVehicle && (!refVeh || (refVeh.vehicle.id !== actualVehicle.vehicle.id))) {
                                // Update refVeh to actual vehicle
                                refVeh = actualVehicle;
                            }
                            // Should never be empty, fix for TS
                            if (!refVeh)
                                continue;

                            if ((p.date === refVeh.shiftInfo.date && p.shift === refVeh.shiftInfo.shift)
                                || (p.jobSplit && p.jobSplit.date === refVeh.shiftInfo.date && p.jobSplit.shift === refVeh.shiftInfo.shift)) {

                                console.log("Set new job in to slot", p.date, p.shift, p.slot, p.vehicle, p.id, p.state);

                                let draft: any = {}

                                if ((p.draft && !Array.isArray(p.draft))) {
                                    draft = p.draft
                                }

                                let insertSlot = p.slot

                                //Splitted should be always at the first slot
                                if (p.jobSplit && p.jobSplit.date === refVeh.shiftInfo.date && p.jobSplit.shift === refVeh.shiftInfo.shift) {
                                    insertSlot = 0
                                } else {
                                    //If plan exists, find index
                                    insertSlot = refVeh.slots.findIndex((e: PlanningSlot) => e.job?.id === p.id)

                                    if (insertSlot < 0) {
                                        //Plan is new, insert to slot 
                                        insertSlot = p.slot
                                        console.log("inserting to slot", insertSlot, p)
                                    }

                                    const existingJob = refVeh.slots[insertSlot].job;
                                    if (existingJob && existingJob.id !== p.id) {
                                        console.log("Existing job found", existingJob)
                                        if (!refVeh.slots[insertSlot].hiddenJobs) {
                                            refVeh.slots[insertSlot].hiddenJobs = [];
                                        }
                                        (refVeh.slots[insertSlot].hiddenJobs as PlanningJobInfo[]).push(existingJob);
                                    }
                                }

                                refVeh.slots[insertSlot] = {
                                    ...refVeh.slots[insertSlot],
                                    job: {
                                        id: p.id,
                                        count: p.count,
                                        draft: p.draft,
                                        hours: p.hours,
                                        icItem: p.icItem,
                                        interClient: p.interClient,
                                        item: p.item,
                                        jobDetails: p.jobDetails,
                                        jobSplit: p.jobSplit,
                                        m3: p.m3,
                                        planDetails: p.planDetails,
                                        state: p.state,
                                        tons: p.tons,
                                        user: p.user,
                                        vehicle: p.vehicle,
                                        waitTime: p.waitTime,
                                        waitTimeReason: p.waitTimeReason,
                                        kilometres: p.kilometres,
                                        date: p.date,
                                        shift: p.shift,
                                        talenomVariant: p.talenomVariant,
                                        jobFish: p.jobFish,
                                    },
                                }
                            }
                            // Set refveh back to original vehicle
                            refVeh = nCars.find(e => String(e.vehicle.id) == v);
                        }
                    }
                }
                setUnassigned(unassignedRef.current.map(x => x));

            }

        }
        setRows(nCars);
        // Filter handled events from events
        // use reference instead of state, since state might not be up to date
        eventsRef.current = eventsRef.current.filter((e) => !handledEvents.includes(e.id));
        setEvents([...eventsRef.current]);

    }, [events])

    useEffect(() => {
        setLoadingState('planning', true);

        dateRef.current = date;
        shiftRef.current = shift;

        // Initial fetch of planning data
        LogitarApi.getPlanning(date, convertShift(shift))
            .then((data) => {
                if (dateRef.current !== date || shiftRef.current !== shift)
                    return;

                const ndata = [...data.vehicles];

                // Replace all jobs with draft data
                // Loop throug data.vehicles instead of ndata, some of jobs must be moved to a different vehicle
                data.vehicles.forEach((vehicle, i) => {

                    vehicle.jobs.forEach((job, k) => {
                        const rjob = ndata[i].jobs[k];
                        if (job.draft && !Array.isArray(job.draft)) {

                            // Replace date, shift, vehicle and slot
                            rjob.date = job.draft.date !== undefined ? job.draft.date : job.date;
                            rjob.shift = job.draft.shift !== undefined ? job.draft.shift : job.shift;
                            rjob.slot = job.draft.slot !== undefined ? job.draft.slot : job.slot;
                            rjob.planDetails = job.draft.planDetails !== undefined ? job.draft.planDetails : job.planDetails;

                            if (job.draft.vehicle !== undefined) {
                                // Move job to correct vehicle
                                const vehicle = ndata.find((v) => v.vehicle.id === job.draft.vehicle);
                                if (vehicle) {
                                    vehicle.jobs.push(rjob);
                                    ndata[i].jobs.splice(k, 1);
                                }
                            }
                        }
                    });
                });

                const sorted = vehicleGroupSorter(ndata, 'planning');

                let _rows: PlanningRow[] = [];

                // Create planning rows
                sorted.forEach((vehicle) => {
                    const row: PlanningRow = {
                        vehicle: vehicle.vehicle,
                        shiftInfo: {
                            date: format(date, "yyyy-MM-dd"),
                            shift: convertShift(shift)
                        },
                        slots: []
                    };


                    // Remove deleted jobs EDIT: Don't remove, since drafted deleted jobs are still needed
                    // vehicle.jobs = vehicle.jobs.filter((e: any) => e.draft === null || Object.keys(e.draft).length < 1 || Array.isArray(e.draft) || e.draft['>deleted'] !== true);

                    // Find if there is a job split for this date and shift
                    const jsp = vehicle.jobs.find((e: any) => e.jobSplit.date === row.shiftInfo.date && e.jobSplit.shift === row.shiftInfo.shift);

                    let startIndex = 0;

                    if (jsp) {
                        let draft: any = {};

                        if ((jsp.draft && !Array.isArray(jsp.draft))) {
                            draft = jsp.draft;
                        }

                        const slot: PlanningSlot = {
                            date: format(date, "yyyy-MM-dd"),
                            shift: convertShift(shift),
                            slot: 0, // Forced 0 for job split end
                            vehicle: vehicle.vehicle.id,
                            job: null
                        };

                        if (draft['>hasJobSplit'] !== 3) {
                            slot.job = jsp as unknown as PlanningJobInfo;
                        }

                        row.slots.push(slot);
                        // Skip first slot since it's filled
                        startIndex = 1;
                        // Remove jsp from vehicle jobs
                        vehicle.jobs = vehicle.jobs.filter((e: any) => e.id !== jsp.id);
                    }

                    for (let i = startIndex; i < PLANNING_SLOT_INITIAL_COUNT; i++) {
                        const slot: PlanningSlot = {
                            date: format(date, "yyyy-MM-dd"),
                            shift: convertShift(shift),
                            slot: i,
                            vehicle: vehicle.vehicle.id,
                            job: null
                        };
                        // Filter instead of find, since there may be two jobs in the same slot
                        const existingJobs = vehicle.jobs.filter((e: any) => e.slot === i);

                        // If there is only one job in the slot, set it
                        if (existingJobs.length === 1) {
                            slot.job = existingJobs[0] as unknown as PlanningJobInfo;
                        }
                        // If there are multiple jobs in the slot, set the one that is not deleted, but if all are deleted, set the last one
                        else if (existingJobs.length > 1) {
                            // If more than one job, init hiddenJobs
                            slot.hiddenJobs = [];
                            const notDeleted = existingJobs.find((e: any) => !e.draft || Object.keys(e.draft).length < 1 || Array.isArray(e.draft) || e.draft['>deleted'] !== true);
                            if (notDeleted) {
                                slot.job = notDeleted as unknown as PlanningJobInfo;
                            }
                            else {
                                slot.job = existingJobs[existingJobs.length - 1] as unknown as PlanningJobInfo;
                            }
                            // Set hidden jobs
                            slot.hiddenJobs = existingJobs.filter((e: any) => e.id !== slot.job?.id);
                        }

                        row.slots.push(slot);
                    }

                    _rows.push(row);
                })

                // Create inter client rows
                const _ics: PlanningRowIC[] = [];
                data.ics.forEach((ic) => {
                    const row: PlanningRowIC = {
                        client: ic.client,
                        shiftInfo: {
                            date: format(date, "yyyy-MM-dd"),
                            shift: convertShift(shift)
                        },
                        slots: []
                    };

                    for (let i = 0; i < PLANNING_SLOT_INITIAL_COUNT; i++) {
                        const slot: PlanningSlot = {
                            date: format(date, "yyyy-MM-dd"),
                            shift: convertShift(shift),
                            slot: i,
                            vehicle: 0,
                            job: null
                        };
                        const existingJob = ic.jobs[i];
                        if (existingJob) {
                            slot.job = existingJob as unknown as PlanningJobInfo;
                        }
                        row.slots.push(slot);
                    }

                    _ics.push(row);
                })
                setUnassigned(data.unassigned);

                setRows(_rows);
                setIcs(_ics);
                setLoadingState('planning', false);
            })
            .catch((error) => {

            });
    }, [date, shift, count]);

    // Update rows reference when rows change
    useEffect(() => {
        rowsRef.current = rows;
    }, [rows])

    // Update ics reference when ics change
    useEffect(() => {
        icsRef.current = ics;
    }, [ics])

    // Update unassigned reference when unassigned change
    useEffect(() => {
        unassignedRef.current = unassigned;
    }, [unassigned])

    const handleDateButton = (direction: number) => {
        if (direction < 0) {
            if (shift) {
                setShift(false);
            } else {
                setShift(true);
                setPDate(addDays(date, direction));
            }
            return;
        }
        if (shift) {
            setPDate(addDays(date, direction));
        }
        setShift(prev => !prev);
    }

    let lastGroup = "";

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: '100%' }}>
            <Collapse in={eventDisconnected} orientation="vertical">
                <Alert sx={{ alignItems: "center", "& .MuiAlert-action": { p: 0 } }} severity="warning"
                    action={<Button onClick={() => window.location.reload()} startIcon={<Refresh />} variant="outlined" color="warning">Lataa uudelleen</Button>}>
                    <Typography>Event palvelimeen ei saatu yhteyttä. Sivulla voi näkyä vanhentunutta tietoa.</Typography>
                </Alert>
            </Collapse>
            <Box sx={{ ...mainBoxStyle }}>
                <Backdrop sx={{ zIndex: 100 }} open={sending}>
                    <Loader color={"white"} />
                </Backdrop>
                <Box sx={leftBoxStyle}> {/** Planning, details, shift selection column */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: "wrap",
                        }}
                    >
                        <Box sx={{ display: "flex", flex: 3, flexDirection: "column", minWidth: 360, maxWidth: 900 }}>{/**col */}
                            <PlanningNotes />
                        </Box>
                        <Box sx={datesStyle}>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: "row",
                                justifyContent: "center",
                                mt: 0.5
                            }}>
                                <Button sx={dayButtonStyle} onClick={() => handleDateButton(-1)}>{"<"}</Button>
                                <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        format='dd.MM.yyyy'
                                        value={date}
                                        onChange={(value) => { if (value) setPDate(value) }}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                p: 0.5
                                            },
                                            minWidth: 140
                                        }}
                                    ></DesktopDatePicker>
                                </LocalizationProvider>
                                <Button sx={dayButtonStyle} onClick={() => handleDateButton(1)}>{">"}</Button>
                            </Box>
                            <Box sx={shiftStyle}>
                                <Typography>Aamu</Typography>
                                <Switch checked={shift} onChange={() => setShift(!shift)} />
                                <Typography>Ilta</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Paper elevation={5} sx={{ ml: 1, mt: -1, mb: 0.5, p: 0.2, overflowY: "auto" }}>
                        <Box sx={carsStyle}>
                            <UpdateContext.Provider
                                value={(d: PlanningSlot & { interClient?: number, error?: number }) => {
                                    if (d.interClient || d.error) {
                                        // Refresh whole page 
                                        setCount(Date.now());
                                    }
                                    else if (d.job?.state === 0 && d.hiddenJobs && d.hiddenJobs.length > 0) {
                                        // Should update slot with the latest hidden job
                                        // But just refresh the whole page for now
                                        setCount(Date.now());
                                    }
                                }}
                            >
                                <SendingContext.Provider value={setSending}>
                                    {(!loadingStates.planning) ?
                                        <>
                                            {
                                                rows.map((planRow, rowKey) => {
                                                    if (planRow.vehicle.group !== lastGroup) {
                                                        lastGroup = planRow.vehicle.group
                                                        return (
                                                            [
                                                                <VehicleGroupDivider key={"div" + planRow.vehicle.group}>{lastGroup ? lastGroup : "Ei ryhmää"}</VehicleGroupDivider>,
                                                                <PlanningCarRow
                                                                    planRowInfo={planRow}
                                                                    dragItem={dragItem}
                                                                    key={planRow.vehicle.id}
                                                                    onStartDrag={(event, slot) => {
                                                                        if (slot.job) {
                                                                            dragStart(event, slot.job?.item, undefined, slot.job);
                                                                        }
                                                                    }}
                                                                />
                                                            ]
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <PlanningCarRow
                                                                planRowInfo={planRow}
                                                                dragItem={dragItem}
                                                                key={planRow.vehicle.id}
                                                                onStartDrag={(event, slot) => {
                                                                    if (slot.job) {
                                                                        dragStart(event, slot.job?.item, undefined, slot.job);
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                ics.map((ic, i) => {
                                                    return <>
                                                        <VehicleGroupDivider key={"vdiv" + ic.client.name}>{ic.client.displayName}</VehicleGroupDivider>
                                                        {
                                                            <PlanningICRow
                                                                planRowInfo={ic}
                                                                dragItem={dragItem}
                                                                key={"IC-" + ic.client.name}
                                                                interClient={ic.client.name}
                                                                onStartDrag={(event, slot) => {

                                                                }}
                                                            />
                                                        }
                                                    </>
                                                })
                                            }
                                        </>
                                        : <SkeleBox />}

                                </SendingContext.Provider>
                            </UpdateContext.Provider>
                        </Box>
                    </Paper>
                </Box>
                <Box sx={rightBoxStyle}>{/**column */}
                    <PlanningOrderList
                        date={date}
                        shift={convertShift(shift)}
                        onDragStart={dragStart}
                        onDragStartIC={(e, job) => {
                            setDragItem({
                                item: job.item,
                                job: job
                            })
                        }}
                        events={events}
                        unassigned={unassigned}
                    />
                </Box>
                {
                    // Subscribe to events
                    <LogitarEventProvider
                        subscriptions={["job", "icjob", "drivershift", "jobsplit"]}
                        onEvent={(source, data, id) => {
                            // Push to events reference
                            eventsRef.current.push({ source: source, data: data, id: id });
                            setEvents([...eventsRef.current]);
                        }}
                        onConnect={() => { setCount(Date.now()); setEventDisconnected(false) }}
                        onDisconnect={() => setEventDisconnected(true)}
                    />
                }
            </Box>
        </Box>
    )

}


const SkeleBox = () => {
    const allSkeles = [];
    for (let headI = 0; headI < 10; headI++) {
        const tempSkeles = [];
        for (let i = 0; i < PLANNING_SLOT_INITIAL_COUNT; i++) {
            tempSkeles.push(<Skeleton key={i} variant="rectangular" sx={{ borderRadius: 1, mb: 2, mr: 1 }} height="40px" width="130px"></Skeleton>)
        }
        allSkeles.push(
            <Box key={headI} sx={flexRowStyle}>
                <Skeleton variant="rectangular" sx={{ mt: 1 }} height="24px" width="106px"></Skeleton>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap", height: "auto" }}>
                    {tempSkeles}
                </Box>
                <Skeleton variant="rectangular" sx={{ borderRadius: 1, mb: 2, mr: 1 }} height="40px" width="106px"></Skeleton>
            </Box>)
    }
    return <>{allSkeles}</>;
}

const VehicleGroupDivider = (props: { children?: JSX.Element[] | string }) => {
    return <Divider
        textAlign='left'
    >
        <Typography variant='caption'>
            {props.children}
        </Typography>
    </Divider>
}