import { Box } from '@mui/material'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import ClientEdit from '../components/ClientEdit'
import CustomToolbar, { toolbarSlotProps } from '../components/CustomToolbar'
import { localeText } from '../misc/LocaleText'
import { contentBoxStyle } from '../styles/styles'
import PersistentDataGridPro from '../components/persistent/PersistentDataGridPro'
import { useUserConfig } from '../misc/UserConfig'

const columns = [
    { field: 'id', headerName: 'Asiakas ID', width: 150 },
    { field: 'companyName', headerName: 'Yrityksen nimi', width: 150 },
    { field: 'name', headerName: 'Asiakaskoodi', width: 150 },
    { field: 'businessID', headerName: 'Y-Tunnus', width: 150 },
    { field: 'email', headerName: 'Sähköposti', width: 300 },
    { field: 'phoneNumber', headerName: 'Puhelin', width: 130 },
    { field: 'currency', headerName: 'Valuutta', width: 80 },
    { field: 'talenomClient', headerName: 'Talenom asiakas', width: 150 },
];

export default function Clients() {
    const userConfig = useUserConfig('clients');

    const [rows, setRows] = useState([])
    const [popup, setPopup] = useState(false)
    const [client, setClient] = useState({})
    //Count for handling grid update
    const [count, setCount] = useState(0)
    const [createNew, setCreateNew] = useState(false)

    //Create an empty client to the user to be filled
    const handleNewClient = () => {

        setClient({
            name: "", companyName: "", currency: "", email: "", businessID: "",
            phoneNumber: "", fax: "", destination: "", VATNumber: ""
        })
        setPopup(true)
        setCreateNew(true)
    }

    /**
     * Call clients, map clients with id added to rows hook
     */
    useEffect(() => {
        LogitarApi.getClients({ extent: "all" }).then((result) => {
            if (!result.clients) {
                return
            }

            setRows(result.clients)
        })
            .catch((err) => console.error(err))
    }, [count])

    //Fetch client information when row selected and open popup
    const handlePopupClient = (params) => {

        LogitarApi.getClients({ id: params.row.id, extent: "alljoin" }).then((result) => {
            if (result.status) {
                setClient(result.clients[0])
                setPopup(true)
                setCreateNew(false)
                console.log("Fetched: " + params.row.id)
            }
        })
            .catch((err) => console.error(err))
    }

    return (
        <Box sx={{
            ...contentBoxStyle, '& .MuiDataGrid-row': {
                cursor: "pointer"

            },
            display: 'flex', flexDirection: 'column', height: '100%', width: '100%'
        }}>
            <PersistentDataGridPro
                userConfig={userConfig}
                rows={rows}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                disableSelectionOnClick={true}
                width="100%"
                onRowClick={(params) => { handlePopupClient(params) }}
                components={{ Toolbar: () => <CustomToolbar userConfig={userConfig} text={"Uusi Asiakas"} onClick={handleNewClient} hidePrinting></CustomToolbar> }}
                localeText={localeText}
                loading={!rows[0]}
                cursor="pointer"
                density="compact"
                slotProps={toolbarSlotProps}
            />

            {popup &&
                <ClientEdit client={client} onClick={setPopup} new={createNew} rowUpdate={setCount} rows={rows}></ClientEdit>
            }
        </Box>
    )
}