import ClientReportGrid from "../../views/ClientReportGrid";
import EmissionReports from "../../views/EmissionReports";
import { ReadPermissions } from "../Permissions";

/**
 * @type {{[key: string]: {header: string, component: JSX.Element, path: string, perms: BigInt}[]}}
 */
export const clientRoutes = {
    "Raportit": [
        {
            header: "Asiakasraportit",
            component: <ClientReportGrid />,
            path: "/clientreports",
            perms: ReadPermissions.PERM_GETJOBS
        },
        // {
        //     header: "Päästöraportit",
        //     component: <EmissionReports />,
        //     path: "/emissionreports",
        //     perms: ReadPermissions.PERM_GETVEHICLES | ReadPermissions.PERM_GETJOBS
        // },
    ]
}