import { Box, InputLabel, MenuItem, Select, Tooltip, styled } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, subMinutes } from "date-fns";
import { useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { localeText } from "../misc/LocaleText";
import { contentBoxStyle } from "../styles/styles";
import { getErrorDescription } from "../api/Errors";

const LogsDataGridPro = styled(DataGridPro)(({ theme }) => ({
    "& .error": {
        backgroundColor: theme.palette.mode === "light" ? "#FF9494" : "#662929",
        // Need to have both of these hover classes to hide flickering
        "&.MuiDataGrid-row.Mui-hovered": {
            backgroundColor: theme.palette.mode === "light" ? "#ffb3b3" : "#803333"
        },
        "&:hover": {
            backgroundColor: theme.palette.mode === "light" ? "#ffb3b3" : "#803333"
        }
    },
    "& .warn": {
        backgroundColor: theme.palette.mode === "light" ? "#ffe066" : "#665a29",
        // Need to have both of these hover classes to hide flickering
        "&.MuiDataGrid-row.Mui-hovered": {
            backgroundColor: theme.palette.mode === "light" ? "#ffeb99" : "#807033"
        },
        "&:hover": {
            backgroundColor: theme.palette.mode === "light" ? "#ffeb99" : "#807033"
        }
    }
}));

export default function Logs() {

    const [logs, setLogs] = useState([])
    const [fileNames, setFileNames] = useState(new Set())
    const [currentFile, setCurrentFile] = useState(false)

    const columns = [
        { field: "id", headerName: "id", width: 90 },
        { field: "source", headerName: "source", valueFormatter: (source) => source.value === "B" ? "Backend" : source.value === "F" ? "Frontend" : "", width: 120 },
        { field: "severity", headerName: "severity" },
        {
            field: "datetime",
            headerName: "datetime",
            type: "date",
            width: 200,
            valueFormatter: (option) => format(subMinutes(new Date(option.value), new Date().getTimezoneOffset()), "d.MM.yyyy HH:mm:ss"),
        },
        { field: "description", headerName: "description", width: 650, flex: 1 },
        {
            field: "file", headerName: "file",
            valueFormatter: (file) => (
                file.value.includes(".js") ?
                    file.value.includes("node_modules") ? "node_modules" + file.value.split("node_modules")[1] : file.value.split("/").pop()
                    : file.value.split("/").pop()
            ),
            width: 200
        },
        { field: "row", headerName: "row", width: 90 },
        {
            field: "error", headerName: "error", width: 120,
            renderCell: (params) => (
                <Tooltip placement="top" title={getErrorDescription(params.value)}>
                    <Box>{params.value}</Box>
                </Tooltip>
            )
        }
    ]


    useEffect(() => {
        LogitarApi.getLogs().then((result) => {
            const files = new Set()
            result.logs.forEach((value) => {
                files.add(value.file)
            })
            setFileNames(files)
            setLogs(result.logs)

        })

    }, [])

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', ...contentBoxStyle }}>

            {/**Toolbar */}
            <Box sx={{
                height: 100,
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",

                "& .MuiButton-root": { height: 40, mr: 2 }
            }}>
                <Box sx={{ flexDirection: "column" }}>
                    <InputLabel sx={{ fontSize: 16 }}>Tiedosto valinta</InputLabel>
                    <Select
                        value={currentFile}
                        onChange={(e) => setCurrentFile(e.target.value)}
                        sx={{ width: 200, height: 50 }}>
                        <MenuItem value={false}>-</MenuItem>
                        {[...fileNames].map((value, key) => {
                            let f = value;
                            if (f.endsWith(".js")) {
                                if (f.includes("node_modules")) {
                                    f = f.split("node_modules");
                                    f = "node_modules" + f[1];
                                } else {
                                    f = f.split("/");
                                    f = f[f.length - 1];
                                }
                            } else {
                                f = value.split("/").slice(5).join("/")
                            }
                            return (
                                <MenuItem value={value} key={key}>{f}</MenuItem>
                            )
                        })}
                    </Select>
                </Box>
            </Box>

            {/**Logs */}

            <LogsDataGridPro
                rows={logs}
                columns={columns}
                disableRowSelectionOnClick={true}
                density="compact"
                localeText={localeText}
                loading={!logs[0]}
                getRowClassName={(params) => params.row.severity}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }]
                    }
                }}
            />
        </Box>
    )
}