import { useEffect, useRef, useState } from "react"
import { DriverReportRow } from "../../api/LogitarApiTypes"
import ReportCard from "../ReportCard"
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, IconButton } from "@mui/material"
import { format } from "date-fns"
import DriverScoreGraph from "./DriverScoreGraph"
import DriverJobGraph from "./DriverJobGraph"
import {  } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

type DriverReportCardProps = {
    data: DriverReportRow
}

/**
 * @brief Formats duration in milliseconds to HH'h' mm'm' ss's'
 */
export const formatDuration = (duration: number) => {
    const az = (v: number) => v > 9 ? v.toString() : ('0' + v);

    return `${az(Math.floor(duration / 3600000))}h ${az(Math.floor(duration / 60000) % 60)}m ${az(duration / 1000 % 60)}s`;
}

const viewList = [
    { name: "scores", title: "Pisteet", component: DriverScoreGraph },
    { name: "jobs", title: "Kuormien ajat", component: DriverJobGraph }
];

export default function DriverReportCard(props: DriverReportCardProps) {

    const [showMore, setShowMore] = useState(false);

    const [view, setView] = useState<number>(0);

    // Calculate average tons (keskikuorma)
    let avgTons = 0;
    let tonsCount = 0;
    let avgScore = 0;
    let avgFuelConsumption = 0;
    props.data.jobs.forEach((e) => {
        if (e.tons && Number(e.tons) > 0) {
            tonsCount++;
            avgTons += Number(e.tons);
        }
    });
    if (avgTons > 0) {
        avgTons /= tonsCount;
    }
    // Get main vehicle TODO: use vehicle with MOST jobs, not the first one
    let vehicle = null;
    if (props.data.jobs.length > 0) {
        vehicle = props.data.jobs[0].vehicle;
    }

    props.data.scores.forEach((e) => {
        avgScore += e.total;
        avgFuelConsumption += e.avgFuelConsumption;
    });

    if (props.data.scores.length > 0) {
        avgScore /= props.data.scores.length;
        avgFuelConsumption /= props.data.scores.length;
    }

    const currView = viewList[view];

    return (
        <ReportCard title={`${props.data.driver.id} - ${props.data.driver.name}`} id={`card-${props.data.driver.id}`} scrollX>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex'
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Auto</TableCell>
                                <TableCell>Keskikuorma</TableCell>
                                <TableCell>Kulutus</TableCell>
                                <TableCell>Pisteet</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{vehicle ? vehicle.licenseNumber : "-"}</TableCell>
                                <TableCell>{avgTons.toFixed(2)}</TableCell>
                                <TableCell>{props.data.scores.length > 0 ? (avgFuelConsumption.toFixed(2) + "L/100km") : "-"}</TableCell>
                                <TableCell>{props.data.scores.length > 0 ? avgScore.toFixed(0) : "-"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                {
                    <Box sx={{flex: 1}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant="h6">
                                {currView.title}
                            </Typography>
                            <Box>
                                <IconButton onClick={() => setView((prevView) => prevView - 1)} disabled={view === 0}>
                                    <ChevronLeft />
                                </IconButton>
                                <IconButton onClick={() => setView((prevView) => prevView + 1)} disabled={view === viewList.length - 1}>
                                    <ChevronRight />
                                </IconButton>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{flex: 1}}>
                            <currView.component data={props.data} />
                        </Box>
                    </Box>
                }
            </Box>

            <Table sx={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Pvm</TableCell>
                        <TableCell>Auto</TableCell>
                        <TableCell>Nimike</TableCell>
                        <TableCell>Tonnit</TableCell>
                        <TableCell>Lastausaika</TableCell>
                        <TableCell>Kuljetusaika</TableCell>
                        <TableCell>Purkuaika</TableCell>
                        <TableCell>Arvioitu aika</TableCell>
                        <TableCell>Toteutunut aika</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.data.jobs.map((e, i) => {
                            if (!showMore && i > 10) {
                                return null;
                            }
                            const date: Date = new Date(e.date);
                            const loadStart: Date = new Date(e.gpsLoadStart);
                            const loadEnd: Date = new Date(e.gpsLoadEnd);
                            const unloadStart: Date = new Date(e.gpsUnloadStart);
                            const unloadEnd: Date = new Date(e.gpsUnloadEnd);
                            const hours: number = Number(e.item.hours);
                            const workhours = props.data.driver.workhours.map(wh => {
                                return {
                                    startTime: wh.startTime ? new Date(wh.startTime) : null,
                                    endTime: wh.endTime ? new Date(wh.endTime) : null
                                }
                            });

                            let loadDuration: number = (e.gpsLoadStart && e.gpsLoadEnd) ? (loadEnd.getTime() - loadStart.getTime()) : 0;
                            let transportDuration: number = (e.gpsLoadEnd && e.gpsUnloadStart) ? (unloadStart.getTime() - loadEnd.getTime()) : 0;
                            let unloadDuration: number = (e.gpsUnloadStart && e.gpsUnloadEnd) ? (unloadEnd.getTime() - unloadStart.getTime()) : 0;
                            let jobDuration: number = (e.gpsLoadStart && e.gpsUnloadEnd) ? (unloadEnd.getTime() - loadStart.getTime()) : 0;
                            
                            // Adjust durations so that they are during work hours
                            if(loadEnd && loadStart) {
                                let realLoadDuration = 0;
                                workhours.forEach((wh) => {
                                    if(!wh.startTime || !wh.endTime)
                                        return;

                                    if(loadStart.getTime() < wh.endTime.getTime() && loadEnd.getTime() > wh.startTime.getTime()) {
                                        realLoadDuration += Math.min(loadEnd.getTime(), wh.endTime.getTime()) - Math.max(loadStart.getTime(), wh.startTime.getTime());
                                    }
                                });
                                loadDuration = realLoadDuration;
                            }
                            if(unloadEnd && unloadStart) {
                                let realUnloadDuration = 0;
                                workhours.forEach((wh) => {
                                    if(!wh.startTime || !wh.endTime)
                                        return;

                                    if(unloadStart.getTime() < wh.endTime.getTime() && unloadEnd.getTime() > wh.startTime.getTime()) {
                                        realUnloadDuration += Math.min(unloadEnd.getTime(), wh.endTime.getTime()) - Math.max(unloadStart.getTime(), wh.startTime.getTime());
                                    }
                                });
                                unloadDuration = realUnloadDuration;
                            }
                            if(loadEnd && unloadStart) {
                                let realTransportDuration = 0;
                                workhours.forEach((wh) => {
                                    if(!wh.startTime || !wh.endTime)
                                        return;

                                    if(loadEnd.getTime() < wh.endTime.getTime() && unloadStart.getTime() > wh.startTime.getTime()) {
                                        realTransportDuration += Math.min(unloadStart.getTime(), wh.endTime.getTime()) - Math.max(loadEnd.getTime(), wh.startTime.getTime());
                                    }
                                });
                                transportDuration = realTransportDuration;
                            }
                            if(loadStart && unloadEnd) {
                                let realJobDuration = 0;
                                workhours.forEach((wh) => {
                                    if(!wh.startTime || !wh.endTime)
                                        return;

                                    if(loadStart.getTime() < wh.endTime.getTime() && unloadEnd.getTime() > wh.startTime.getTime()) {
                                        realJobDuration += Math.min(unloadEnd.getTime(), wh.endTime.getTime()) - Math.max(loadStart.getTime(), wh.startTime.getTime());
                                    }
                                });
                                jobDuration = realJobDuration;
                            }

                            const estimateJobDuration: number = (e.estDuration ? Number(e.estDuration) : hours) * 3600000;
                            const expectedJobDuration: number = hours * 3600000;

                            const jobDurationClass: string = jobDuration <= expectedJobDuration ? "default-green" : "default-red";

                            const jobDurationDiff: number = estimateJobDuration - jobDuration;

                            return <TableRow key={i}>
                                <TableCell size="small">{format(date, "dd.MM.yyyy")}</TableCell>
                                <TableCell size="small">{e.vehicle.licenseNumber}</TableCell>
                                <TableCell size="small">{e.item.name}</TableCell>
                                <TableCell size="small">{e.tons}</TableCell>
                                <TableCell size="small">{loadDuration > 0 ? formatDuration(loadDuration) : '-'}</TableCell>
                                <TableCell size="small">{transportDuration > 0 ? formatDuration(transportDuration) : '-'}</TableCell>
                                <TableCell size="small">{unloadDuration > 0 ? formatDuration(unloadDuration) : '-'}</TableCell>
                                <TableCell size="small">
                                    <Tooltip title={e.estDuration ? `Tunteja muokattu suunnittelussa, alkuperäinen: ${formatDuration(expectedJobDuration)}` : ""}>
                                        <span>{estimateJobDuration > 0 ? formatDuration(estimateJobDuration) : '-'}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell size="small" className={jobDurationClass}>
                                    <Tooltip
                                        title={jobDuration > 0 ?
                                            <span>Ero: <span className={jobDurationClass}>{formatDuration(Math.abs(jobDurationDiff))}</span></span> : ""
                                        }
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    color: 'black',
                                                    bgcolor: '#FFF',
                                                    border: '1px solid #CCC'
                                                }
                                            }
                                        }}
                                    >
                                        <span>{jobDuration > 0 ? formatDuration(jobDuration) : '-'}</span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        })

                    }
                    {
                        (!showMore && props.data.jobs.length > 10) &&
                        <TableRow>
                            <TableCell
                                colSpan={8}
                                onClick={() => setShowMore(true)}
                                align="center"
                                size="small"
                                sx={{
                                    cursor: 'pointer',
                                    fontWeight: 'bold'
                                }}
                            >
                                ...{props.data.jobs.length - 10} lisää
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </ReportCard>
    )

}