import { Download, Print } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, Divider, FormGroup, Paper, TextField, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format, subDays } from "date-fns";
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import LogitarApi, { APIPath } from "../api/LogitarApi";
import { DateRangeSelect } from "../components/DateSelect";
import FuelConsumptionReport, { reportFuelTypes } from "../components/fuelreports/FuelConsumptionReport";

const printStyleOverrides = {
    "& .MuiPaper-root": {
        background: 'none',
        backgroundColor: 'transparent',
        color: 'black',
        boxShadow: 'none',
    },
    "& *": {
        background: 'none',
        backgroundColor: 'transparent',
        color: 'black',
        boxShadow: 'none',
    },
    "& .MuiChartsAxis-line, .MuiChartsAxis-tick": {
        stroke: 'rgba(0,0,0,0.8)',
    },
    "& tspan": {
        fill: 'rgba(0,0,0,0.8)',
    },
    "& hr, th, td": {
        borderColor: 'rgba(0,0,0,0.2)',
    },
}

const PrintFuelConsumtionReport = forwardRef((props, ref) => {
    const children = Array.isArray(props.children) ? props.children : [props.children];
    const theme = useTheme();
    const [count, setCount] = useState();

    // This fixes changing themes messing with printStyleOverrides
    useEffect(() => {
        setCount(prev => prev + 1);
    }, [theme.palette.mode]);

    return <Box
        sx={{
            display: props.show ? 'block' : 'none',
            ...printStyleOverrides,
        }}
        ref={ref}
    >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Polttoaineraportti</Typography>
            <Typography>Käyttövoima: {props.fuelType ? props.fuelType.label : ""}</Typography>
            <Typography>{format(props.range.from, "dd.MM.yyyy")} - {format(props.range.to, "dd.MM.yyyy")}</Typography>
        </Box>
        {
            children.map((e, i) => {
                return (
                    <Paper
                        key={"prbd-" + i}
                        sx={{ width: props.width, height: '300' }}
                    >
                        {e}
                    </Paper>
                )
            })
        }
    </Box>
})

/**
 * @typedef {{
 *  total: object[],
 *  monthly: object[],
 *  yearly: object[],
 * }} FuelTypeReport
 * @typedef {{
 *  range: string[],
 *  all: FuelTypeReport,
 *  diesel: FuelTypeReport,
 *  gas: FuelTypeReport,
 *  electric: FuelTypeReport,
 *  hydrogen: FuelTypeReport,
 *  biodiesel: FuelTypeReport,
 * }} FuelReportData
 */

export default function FuelReports() {

    const boxRef = useRef();

    const printAreaRef = useRef();

    const reportRef = useRef();

    const [maxWidth, setMaxWidth] = useState(0);

    const [reportConfig, setReportConfig] = useState({
        range: [
            subDays(new Date(), 14),
            new Date()
        ],
        vehicles: []
    })

    const [allVehiclesSelected, setAllVehiclesSelected] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const autoCompleteRef = useRef();

    const [reportData, setReportData] = useState(/** @type {FuelReportData | null} */(null));
    const [reportOfFuelType, setReportOfFuelType] = useState(null);
    const [showFuelType, setShowFuelType] = useState("all");
    const [currentFuelType, setCurrentFuelType] = useState(reportFuelTypes.find((t) => t.name === showFuelType) || reportFuelTypes[0]);

    const [printTriggered, setPrintTriggered] = useState(false);

    const [contWidth, setContWidth] = useState(0);

    const [searchTimeStamp, setSearchTimeStamp] = useState("");
    const [fetching, setFetch] = useState(false)

    const theme = useTheme();
    const lgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));

    const handlePrint = useReactToPrint({
        content: () => printAreaRef.current,
        pageStyle: "@print { size: A4 landscape; }",
        onAfterPrint: () => { setPrintTriggered(false) }
    });

    useEffect(() => {
        if (printTriggered) {
            handlePrint();
        }
    }, [printTriggered]);

    useLayoutEffect(() => {
        setContWidth(boxRef.current.clientWidth)
    }, [boxRef])

    useEffect(() => {
        LogitarApi.getFuelReports({
            from: reportConfig.range[0],
            to: reportConfig.range[1],
            vehicles: reportConfig.vehicles.map(e => e.id)
        })
            .then((resp) => {
                setReportData(resp.report);
            })
    }, [reportConfig, searchTimeStamp]);

    useEffect(() => {
        if (reportData) setReportOfFuelType(reportData[showFuelType]);
    }, [showFuelType, reportData]);

    useEffect(() => {
        LogitarApi.getVehicles()
            .then((resp) => {
                setVehicles(resp.vehicles.filter(e => e.type === 1 && e.subcontractor !== 1).map(e => ({ id: e.id, label: `${e.id}:${e.licenseNumber}` })));
            })
    }, []);

    useEffect(() => {
        if (boxRef.current) {
            setMaxWidth(boxRef.current.offsetWidth);
            const handleResize = () => {
                setMaxWidth(boxRef.current.offsetWidth);
            };
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [boxRef.current]);


    const handlePrintTriggered = (bool) => {
        console.log("Data", reportOfFuelType)
        setSearchTimeStamp(Date.now());
        setPrintTriggered(bool)
    };

    const fetchReport = () => {
        const formd = new FormData()
        setFetch(true)
        const dates = [];
        const minDate = format(new Date(reportConfig.range[0]), "yyyy-MM-dd")
        const maxDate = format(new Date(reportConfig.range[1]), "yyyy-MM-dd")

        formd.append("format", "xls")
        formd.append("variant", "fuel")
        formd.append("key", LogitarApi.getCookie("login-token"))
        formd.append("filters", JSON.stringify({ from: minDate, to: maxDate, vehicles: reportConfig.vehicles.map(e => e.id) }))

        fetch(APIPath + `/getgeneralreports.php`, { method: "POST", body: formd })
            .then((res) => res.blob())
            .then(blob => {
                console.log(blob)
                const newFile = window.URL.createObjectURL(new File([blob], ``, { type: blob.type }))

                //window.location.assign(newFile);
                const link = document.createElement('a');
                link.href = newFile;

                link.setAttribute('download', `LogiTar_Polttoaine_${minDate}_${maxDate}.xlsx`)
                link.click()

                setFetch(false)
            })
            .catch(() => {
                setFetch(false)
            })
    }

    return <Box

        sx={{
            display: 'flex',
            flexDirection: lgBreakPoint ? 'row' : 'column-reverse',
            height: '100%',
            p: 1,
        }}
    >

        <Box
            sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: "max-content", p: 1, overflowX: 'hidden', flex: 1 }}
            ref={boxRef}
        >
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%", height: "max-content", justifyContent: "center", alignItems: "center", mb: 2, gap: 2, overflowX: "auto" }}>
            <Typography variant="h6">Käyttövoima</Typography>
            <ToggleButtonGroup sx={{ height: 50 }} value={showFuelType} color="primary" exclusive onChange={(e, v) => {
                if (!v) return;
                setShowFuelType(v);
                setCurrentFuelType(reportFuelTypes.find((t) => t.name === v));
            }}>
                {reportFuelTypes.map((type, i) => {
                    return <ToggleButton key={`fuelType-${i}`} value={type.name}>{type.label}</ToggleButton>
                })}
            </ToggleButtonGroup>
        </Box>
            {
                boxRef.current &&
                (
                    <>
                        <FuelConsumptionReport
                            width={maxWidth}
                            data={reportOfFuelType}
                            mode="table"
                            show={true}
                            fuelType={currentFuelType}
                        />
                        <FuelConsumptionReport
                            width={maxWidth}
                            data={reportOfFuelType}
                            mode="total"
                            fuelType={currentFuelType}
                        />
                        <FuelConsumptionReport
                            width={maxWidth}
                            data={reportOfFuelType}
                            mode="monthly"
                            fuelType={currentFuelType}
                        />
                        <FuelConsumptionReport
                            width={maxWidth}
                            data={reportOfFuelType}
                            mode="yearly"
                            fuelType={currentFuelType}
                        />

                        {
                            contWidth > 0 && reportOfFuelType && (
                                <PrintFuelConsumtionReport
                                    width={printTriggered ? 'a4' : contWidth}
                                    ref={printAreaRef}
                                    show={printTriggered}
                                    range={{
                                        from: reportConfig.range[0],
                                        to: reportConfig.range[1]
                                    }}
                                    fuelType={currentFuelType}
                                >
                                    <div>
                                        <FuelConsumptionReport
                                            width={printTriggered ? 'a4' : contWidth}
                                            data={reportOfFuelType}
                                            mode="table"
                                            fuelType={currentFuelType}
                                        />

                                        <FuelConsumptionReport
                                            width={1000}
                                            data={reportOfFuelType}
                                            mode="total"
                                            fuelType={currentFuelType}
                                        />

                                        <FuelConsumptionReport
                                            width={1000}
                                            data={reportOfFuelType}
                                            mode="monthly"
                                            fuelType={currentFuelType}
                                        />
                                        <FuelConsumptionReport
                                            width={1000}
                                            data={reportOfFuelType}
                                            mode="yearly"
                                            fuelType={currentFuelType}
                                        />
                                    </div>
                                </PrintFuelConsumtionReport>
                            )}
                    </>
                )
            }

        </Box>
        {/* Report settings */}
        <Box
            sx={{
                width: lgBreakPoint ? 360 : '100%',
            }}
        >
            <Paper sx={{
                width: 'calc(100% - 16px)', // Minus margin
                padding: 1,
                m: 1,
                mr: 2,
                "& .MuiTextField-root": {
                    margin: 1,
                },
                "& .MuiStack-root": {
                    "& .MuiTypography-root": {
                        mx: 1
                    },
                },
                "& .MuiAutocomplete-root": {
                    margin: 1,
                    "& .MuiTextField-root": {
                        margin: 0
                    }
                }
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h6" sx={{ flex: 1, mx: 1 }}>
                        Polttoaineraportti
                    </Typography>
                    {
                        /*
                            <Typography variant="h6" sx={{ flex: 1, textAlign: 'right', color: '#33b3de' }}>
                                {invoiceData.invoice.clients[invoiceClient].name}
                            </Typography>
                        */
                    }
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Box sx={{ maxWidth: 500 }}>
                    <DateRangeSelect
                        value={reportConfig.range}
                        onChange={(v) => setReportConfig({ ...reportConfig, range: v })}
                        labels={{ start: "Ajopvm alku", end: "Ajopvm loppu" }}
                    />
                </Box>
                <Autocomplete
                    options={[{ id: -1, label: !allVehiclesSelected ? "Valitse kaikki" : "Poista valinnat" }, ...vehicles]}
                    value={reportConfig.vehicles}
                    size="small"
                    multiple
                    limitTags={5}
                    onChange={(e, v) => {
                        if (v.some((e) => e.id === -1)) {
                            if (allVehiclesSelected) {
                                setReportConfig({ ...reportConfig, vehicles: [] });
                                setAllVehiclesSelected(false);
                            }
                            else {
                                setReportConfig({ ...reportConfig, vehicles: vehicles });
                                setAllVehiclesSelected(true);
                                autoCompleteRef.current.blur();
                            }
                        }
                        else {
                            setReportConfig({ ...reportConfig, vehicles: v });
                        }
                    }}
                    sx={{
                        ml: 1,
                        flex: 1
                    }}
                    renderInput={(params) => <TextField inputRef={autoCompleteRef} label="Kalusto" {...params} />}
                />

                <FormGroup row sx={{ alignItems: "center", gap: 1, p: 1 }}>
                    <Button
                        variant="contained"
                        onClick={() => handlePrintTriggered(true)}
                    >
                        <Print />
                    </Button>

                    <Button
                        onClick={() => fetchReport()}
                        type="submit"
                    >
                        {fetching ?
                            <CircularProgress size={"1.2rem"} />
                            : <Download />
                        }

                    </Button>
                </FormGroup>

            </Paper>
        </Box>
    </Box>
}