import { Autocomplete, Box, Card, FormControl, FormGroup, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import LogitarApi from '../api/LogitarApi'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import Config from '../config/Config'
import { CustomPopper } from '../misc/InternalFeatures'
import AreaEdit from './AreaEdit'
import PopupScreen from './PopupScreen'

const formLeftStyle = {
    width: "auto",
    '& .MuiTextField-root': { minWidth: 300 },
}

const formRightStyle = {

    width: "auto"
}

/**
 * @type {import("@mui/x-data-grid").GridColDef[]}
 */
const dockingAreaColumns = [
    { field: 'id', headerName: 'ID', flex: 1, maxWidth: 60 },
    { field: 'name', headerName: 'Nimi', flex: 1 },
    { field: 'address', headerName: 'Osoite', flex: 1 },
]

// Transforms docking area field names to correct ones
const dockingAreaMapper = (a) => {
    return {
        id: a.dockingID,
        name: a.dockingName,
        type: a.dockingType,
        address: a.address,
        coordinates: a.coordinates
    }
}

//{value: "cargos", label: "Tuotteet"}
export default function ClientEdit(props) {

    const [client, setClient] = useState(props.client)
    const [postClient, setPostClient] = useState((!props.new) ? { id: props.client.id } : { ...props.client })
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [loadingAreas, setLoadingAreas] = useState((!props.new) ? props.client.dockingAreas.filter(e => e.dockingType === 0).map(dockingAreaMapper) : []);
    const [unloadingAreas, setUnloadingAreas] = useState((!props.new) ? props.client.dockingAreas.filter(e => e.dockingType === 1).map(dockingAreaMapper) : []);

    const [editDockingArea, setEditDockingArea] = useState(null);

    //const [cargos, setCargos] = useState( (!props.new) ? props.client.cargoTypes: [])
    const [dockAreaRef, setDockAreaRef] = useState()

    const [talenomClients, setTalenomClients] = useState([]);

    const [talenomClient, setTalenomClient] = useState({ id: 0, label: "" });

    const [dockingAreaView, setDockingAreaView] = useState(/** @type {'loading'|'unloading'} */('loading'));
    const [saveMode, setSaveMode] = useState(null);

    // Initializing currency 
    useEffect(()=>{
        if (props.new) {
            setClient(prev => ({ ...prev, ['currency']: 'EUR' }))
            setPostClient(prev => ({ ...prev, ['currency']: 'EUR' }))
        }
    }, [])

    useEffect(() => {

        LogitarApi.getTalenomData("clients").then((res) => {
            setTalenomClients(res.clients);
            const stp = res.clients.find(e => e.clientNumber == client.talenomClient);
            if (stp) {
                setTalenomClient({ id: stp.clientNumber, label: stp.clientName })
            }
        }).catch((err) => {

        })
    }, []);

    const setRef = (area) => {
        if (area === dockAreaRef) {
            // Deselect
            setDockAreaRef(null)
        }
        else {
            setDockAreaRef(area)
        }
    }

    const handleChange = (event) => {

        setClient(prev => ({ ...prev, [event.target.name]: event.target.value }))
        setPostClient(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleTalenomClientChange = (v) => {
        setTalenomClient(v)
        setClient(prev => ({ ...prev, talenomClient: v ? v.id : null }))
        setPostClient(prev => ({ ...prev, talenomClient: v ? v.id : null }))
    }

    //Wrap functions here to reduce Button clutter
    const handleUpdate = () => {

        setSaveMode(true);
        if (checkSaveIsValid() == false) {
            enqueueSnackbar("Täytä kaikki pakolliset kentät", {variant: "error"})
            return;
        }

        props.onClick(false)
        LogitarApi.setClient(postClient).then((result) => {
            props.rowUpdate(prev => prev + 1)
            enqueueSnackbar("Asiakas tallennettu")
        })
        .catch((err) => {
            console.error(err)
            enqueueSnackbar("Asiakkaan tallennus epäonnistui", { variant: 'error' });
        }) 
    }

    const checkIsEmpty = (field, value) => {
       if (props.new == true && saveMode == null) return false;
        if (!value) {
            return true;
        }
        if (value && value.length < 1) {
          return true;
        }
        return false; 
    };

    const checkSaveIsValid = () => {
        if (client.companyName == null || client.companyName.length < 1 ) return false;
        if (client.businessID == null || client.businessID.length < 1 ) return false;
        return true;
    }

    console.log(editDockingArea);

    return (
        <PopupScreen
            title={"Asiakas"}
            draggable
            onClose={() => props.onClick(false)}
            onSave={() => handleUpdate()}
            staticButtons
        >
            <form onChange={handleChange} >
                <FormControl
                    sx={{
                        '& .MuiTextField-root': { my: 1, mr: 1, width: "100%" },
                        '& .MuiFormGroup-root': { display: "inline-flex", flexWrap: "nowrap", justifyContent: "space-between" },
                        overflowY: 'auto', width: '100%', pt: 3
                    }}

                >
                    <FormGroup row>
                        <FormControl sx={formLeftStyle}>
                            <FormGroup row={false}>
                                <FormGroup row>
                                    <TextField
                                        disabled={true}

                                        inputProps={{ readOnly: true }}
                                        label="Asiakasnumero"
                                        name="id"
                                        value={client.id}
                                    />

                                    <Autocomplete
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label="Yrityksen nimi"
                                                name="companyName"
                                                InputLabelProps={{shrink: true}}
                                                style={{margin: 0}}
                                                error={checkIsEmpty("companyName",client.companyName)}
                                                required aria-required 
                                                />}
                                        onChange={(e, v) => handleChange({...e, target: {...e.target, value: v, name: "companyName"}})}
                                        value={client.companyName}
                                        freeSolo
                                        options={Array.from(new Set(props.rows.filter(row => row.companyName).map(row => row.companyName)))}
                                        sx={{mr: 1, my: 1}}
                                    />

                                </FormGroup>
                                <FormGroup row>
                                    <TextField
                                        label="Asiakaskoodi"
                                        name="name"
                                        value={client.name}
                                    />

                                    <TextField
                                        label="Y-tunnus"
                                        name="businessID"
                                        value={client.businessID}
                                        error={checkIsEmpty("businessID",client.businessID )}
                                        required aria-required 
                                        onInput = {(e) =>{
                                            e.target.value = e.target.value.toString().slice(0,9)
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <TextField
                                        label="Sähköposti"
                                        name="email"
                                        value={client.email}
                                    />

                                    <TextField
                                        label="Puhelin"
                                        name="phoneNumber"
                                        value={client.phoneNumber}
                                        onInput = {(e) =>{
                                            e.target.value = e.target.value.toString().slice(0,12)
                                        }}
                                    />

                                </FormGroup>
                                <FormGroup row>
                                    <TextField 
                                        label="Valuutta"
                                        name="currency"
                                        value={client.currency}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                {
                                    Config.moduleEnabled("talenom") &&
                                    <Autocomplete
                                        sx={{flex: 1}}
                                        PopperComponent={CustomPopper}
                                        id="talenom-client-search"
                                        name="talenomClient"
                                        options={talenomClients.map(e => { return { id: e.clientNumber, label: `${e.clientNumber} - ${e.clientName}` } })}
                                        renderInput={(params) => <TextField {...params} label="Talenom asiakas" />}
                                        value={talenomClient}
                                        onChange={(e, v) => { handleTalenomClientChange(v); }}
                                    />
                                }
                                </FormGroup>
                            </FormGroup>
                        </FormControl>
                        <FormControl sx={formRightStyle}>
                            <FormGroup
                                sx={{ml: 1}}
                            >
                                <Card elevation={3}>
                                    <ToggleButtonGroup
                                        value={dockingAreaView}
                                        exclusive
                                        onChange={(e, v) => { if (v) setDockingAreaView(v) }}
                                        sx={{ m: 1 }}
                                    >
                                        <ToggleButton
                                            value={'loading'}
                                        >
                                            Lastaus
                                        </ToggleButton>
                                        <ToggleButton
                                            value={'unloading'}
                                        >
                                            Purku
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <DataGridPro
                                        rows={dockingAreaView === 'loading' ? loadingAreas : unloadingAreas}
                                        disableRowSelectionOnClick
                                        sx={{
                                            width: 400,
                                            height: 400
                                        }}
                                        columns={dockingAreaColumns}
                                        onRowClick={(e) => {
                                            setEditDockingArea(e.row);
                                        }}
                                    />
                                </Card>
                            </FormGroup>
                            {
                                editDockingArea &&
                                <AreaEdit 
                                    onClose={() => setEditDockingArea(null)}
                                    info={editDockingArea}
                                />
                            }
                        </FormControl>
                    </FormGroup>
                </FormControl>
            </form>
        </PopupScreen>
    )

}