import { Autocomplete, Chip, Divider, FormGroup, TextField, Tooltip } from "@mui/material";
import PopupScreen from "./PopupScreen";
import { useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { enqueueSnackbar } from "notistack";


export default function UserClientItemEdit(props) {

    const [clients, setClients] = useState([]);
    const [items, setItems] = useState([]);

    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        // Fetch clients
        LogitarApi.getClients().then((result) => {
            setClients(result.clients.sort((a, b) => a.id - b.id).map(e => ({ id: e.id, label: e.name })));
            if (props.user.clients)
                setSelectedClients(result.clients.filter(e => props.user.clients.includes(e.id)).map(e => ({ id: e.id, label: e.name })));
        })
            .catch(err => {
                enqueueSnackbar("Asiakkaiden haku epäonnistui", { variant: "error" });
                console.error(err);
            })
        // Fetch items
        LogitarApi.getItems().then((result) => {
            setItems(result.items.sort((a, b) => a.id - b.id).map(e => ({ id: e.id, label: e.name + " " + e.cargoLabel })));

            if (props.user.items)
                setSelectedItems(result.items.filter(e => props.user.items.includes(e.id)).map(e => ({ id: e.id, label: e.name + " " + e.cargoLabel })));
        })
            .catch(err => {
                enqueueSnackbar("Nimikkeiden haku epäonnistui", { variant: "error" });
                console.error(err);
            })
    }, [])

    const onSave = () => {
        props.onSave(selectedClients.map(e => e.id), selectedItems.map(e => e.id));
        /*
        LogitarApi.setUser({
            id: props.user.id,
            clients: selectedClients.map(e => e.id),
            items: selectedItems.map(e => e.id)
        })
        .then(() => {
            enqueueSnackbar("Käyttäjän yhteydet päivitetty", {variant: "success"});
            
        })
        .catch(err => {
            enqueueSnackbar("Käyttäjän yhteyksien päivitys epäonnistui", {variant: "error"});
            console.error(err);
        })
        */
    }

    return <PopupScreen
        title="Lisää yhteys asiakkaisiin ja nimikkeisiin"
        onClose={props.onClose}
        onSave={onSave}
        staticButtons
        draggable
    >
        <FormGroup row>
            <Autocomplete
                multiple
                options={clients}
                disableCloseOnSelect
                getOptionLabel={(option) => `${option.id}: ${option.label}`}
                renderInput={(params) => <TextField {...params} label="Asiakkaat" />}
                onChange={(event, value) => setSelectedClients(value)}
                value={selectedClients}
                sx={{
                    width: 300,
                    maxHeight: 300,
                    m: 1
                }}
            />
            <Divider flexItem orientation="vertical" />
            <Autocomplete
                multiple
                options={items}
                disableCloseOnSelect
                getOptionLabel={(option) => `${option.id}: ${option.label}`}
                renderInput={(params) => <TextField {...params} label="Nimikkeet" />}
                onChange={(event, value) => setSelectedItems(value)}
                value={selectedItems}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (                
                        <Tooltip arrow placement="right" title={`${option.id}: ${option.label}`}>
                            <Chip
                                label={`${option.id}: ${option.label}`}
                                {...getTagProps({ index })}
                            />
                        </Tooltip>
                    ))
                }
                sx={{
                    minWidth: 300,
                    maxWidth: 500,
                    maxHeight: 300,
                    m: 1
                }}
            />
        </FormGroup>
    </PopupScreen>

}