import { Add, Delete } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogTitle, Fade, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { format } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import ChainPop from '../components/ChainPop'
import { TipButton } from '../misc/InternalFeatures'
import LogiTarUser from "../misc/User"
import { contentBoxStyle } from '../styles/styles'
import LogitarEventProvider from '../api/LogitarEventProvider'

export default function Messages(props) {

    const [rows, setRows] = useState([])
    const [popup, setPopup] = useState(false)
    const [chain, setChain] = useState(null)    
    const [messages, setMessages] = useState([])
    const admin = LogiTarUser.current.isManagement()
    const [createNew, setCreateNew] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [events, setEvents] = useState([])

    let columns = [
        { field: 'chainStart', headerName: 'Viestiketjun aloittaja', width: 200 },
        { field: 'header', headerName: 'Otsikko', width: 400 },
        { field: 'vehicle', headerName: 'Ajoneuvo', width: 200 },
        { field: 'latestMsg', headerName: 'Viimeisin viesti', width: 250 },
    ]

    const stateClasses = [
        //no color
        "",
        //orange
        "default-orange",
    ]

    const updateChains = () => {
        LogitarApi.getChains(LogiTarUser.current.info.userType)
            .then(res => {
                if (admin) setRows(res.chains);
                else setRows(res.chains.filter((c) => c.vehicleid === LogiTarUser.current.vehicle.id));
            }).catch(err => console.error(err))
    }

    // Initial chains update
    useEffect(() => updateChains(), []);

    useEffect(() => {
        if (events.length < 1) return;
        events.forEach(event => {
            if (event.data.type === "accepted" || event.data.type === "sent" || event.data.type === "deleted") {
                updateChains();
            }
        })
        setEvents([]);
    }, [events])

    const rowStyle = {
        cursor: "pointer",
    }

    //Dialog related functions
    const [dialog, setDialog] = useState(false)

    const diaClose = () => {
        setDialog(false)
    }

    const diaOpen = (row) => {
        setChain(row)
        setDialog(true)

    }
    //////////

    //Chain related functions
    const handlePopup = (chain) => {
        if (props.onChainOpenChange) props.onChainOpenChange(chain);
        LogitarApi.getChainMessages(chain.id).then((result) => {
            setMessages(result.messages)
            setPopup(true)
            setChain(chain)
            setCreateNew(false)
        })
            .catch((err) => console.error(err))
    }

    const handleNew = () => {
        setChain({ vehicle: "", header: "" })
        setCreateNew(true)
        setPopup(true)
    }

    const removeChain = () => {
        //True parameter is for deleting
        LogitarApi.setChain(chain, true).then((result) => {
            console.log(result)
            enqueueSnackbar("Viestiketju otsikolla: " + chain.header + " poistettu")
        })
            .catch(() => enqueueSnackbar("Viestiketju otsikolla: '" + chain.header + "' poisto epäonnistui", { variant: "error" }))

        setChain(null)
        setDialog(false)
    }

    const handleAcceptChain = () => {
        if (props.onAccept)
            props.onAccept(true)
    };

    //////////

    return (
        <Box sx={{ ...contentBoxStyle, maxHeight: "85vh", overflowY: "auto", maxWidth: "100%" }}>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        {columns.map((value, key) => {
                            return <TableCell sx={{ fontWeight: "bold" }} key={key} width={value.width}>{value.headerName}</TableCell>
                        })}
                        <TableCell><TipButton onClick={() => handleNew()} variant="contained" title="Uusi viesti" icon={Add}></TipButton></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => {
                        return (
                            <Fade timeout={700} in key={row.id}>
                                <TableRow className={stateClasses[row.newmessage]} sx={rowStyle} hover>
                                    <TableCell onClick={() => handlePopup(row)}>{row.startedByName}</TableCell>
                                    <TableCell onClick={() => handlePopup(row)}>{row.header}</TableCell>
                                    <TableCell onClick={() => handlePopup(row)}>{row.vehicle}</TableCell>
                                    <TableCell onClick={() => handlePopup(row)}>{row.latest ? format(new Date(row.latest), "dd.MM.yyyy HH:mm") : "Ei viestejä"}</TableCell>
                                    <TableCell onClick={() => diaOpen(row)} style={{ color: "blue" }}><Button variant="contained"><Delete></Delete></Button></TableCell>
                                </TableRow>
                            </Fade>
                        )
                    })}
                </TableBody>
            </Table>
            {popup &&
                <ChainPop onClick={setPopup} onAccept={handleAcceptChain} rowUpdate={updateChains} onClose={() => {if (props.onChainOpenChange) props.onChainOpenChange(null)}}
                    new={createNew} admin={admin} messages={messages} chain={chain} waitToAccept={props.waitToAccept != null ? props.waitToAccept : undefined} driverMode={props.driverMode}></ChainPop>
            }

            <Dialog
                open={dialog}
                onClose={diaClose}
            >
                <DialogTitle>
                    {admin && (
                        "Haluatko varmasti poistaa viestiketjun myös autolta? Kaikki ketjun viestit poistuvat."
                    )}
                    {!admin && (
                        "Haluatko varmasti poistaa viestiketjun myös toimistolta? Kaikki ketjun viestit poistuvat."
                    )}

                </DialogTitle>

                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Button variant="contained" onClick={diaClose}>Ei</Button>
                    <Button variant="contained" onClick={removeChain} autoFocus>
                        Kyllä
                    </Button>
                </DialogActions>
            </Dialog>
            { LogiTarUser.current.isDriver() && <LogitarEventProvider subscriptions={["message"]} onEvent={(s, d, i) => setEvents(prev => [...prev, { source: s, data: d, id: i }])}/> }
			{ LogiTarUser.current.isManagement() && <LogitarEventProvider subscriptions={["messageOffice"]} onEvent={(s, d, i) => setEvents(prev => [...prev, { source: s, data: d, id: i }])}/> }
        </Box>
    )
}