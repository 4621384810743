import { ChangeEvent, useEffect, useRef, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { Box, TextField } from "@mui/material";
import { Loader } from "../misc/InternalFeatures";
import { PlanningNotes as PlanningNotesType } from "../misc/LogitarTypes";
import LogitarEventProvider from "../api/LogitarEventProvider";

const notesStyle = {
    ml: 1,
    flex: 5,
    height: "auto",
    pt: 1,
    borderRadius: 2,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderWidth: 3,
      },
    }
}

export type PlanningNotesProps = {
    disabled?: boolean;
}

export default function PlanningNotes(props: PlanningNotesProps) {

    const [notes, setNotes] = useState<PlanningNotesType>({id: 1, content: ""})
    const [count, setCount] = useState<number>(0)
    const [events, setEvents] = useState<any[]>([])
    const [eventDisconnected, setEventDisconnected] = useState<boolean>(false)
    const eventsRef = useRef<any[]>([]);
    const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

    useEffect(() => {

        LogitarApi.getPlanningNotes().then((r) => {
            console.log(r)
            setNotes(r.planningnotes || {id: 1, content: ""})

        })
            .catch((err) => console.error(err))

    }, [count])

    useEffect(() => {
        if (events.length === 0)
            return;

        setEventDisconnected(false);

        const handledEvents: number[] = [];        

        for (let ev of events) {
            // Add event id to handled events
            handledEvents.push(ev.id);

            const source = ev.source, data = ev.data;

            console.log("Event " + source, data);

            if (source === "planningnote") {
                // Update notes
                if (data.content !== notes.content)
                    setNotes(prev => ({ ...prev, content: data.content }));
            }
        }        
        // Filter handled events from events
        // use reference instead of state, since state might not be up to date
        eventsRef.current = eventsRef.current.filter((e) => !handledEvents.includes(e.id));
        setEvents([...eventsRef.current]);
    }, [events])

    const handleNotes = (e: ChangeEvent<HTMLInputElement>) => {
        setNotes((prev) => ({ ...prev, content: e.target.value }))
    }

    useEffect(() => {

        if (eventDisconnected && !interval.current) {
            interval.current = setInterval(() => {
                setCount(Date.now())
            }, 20000)
        } else if (interval.current) {
            clearInterval(interval.current);
            interval.current = undefined;
        }

        return () => {
            clearInterval(interval.current);
            interval.current = undefined;
        }
    }, [eventDisconnected])

    const sendNotes = () => {

        if(props.disabled)
            return;

        const currentNote = { ...notes }        

        LogitarApi.setPlanningNotes(currentNote).then((r) => {
            console.log(r)
        })
            .catch((err) => console.error(err))
    }


    return (
        <Box sx={notesStyle}>
            {notes ?
                <TextField
                    label="Ohjeet"
                    key={"Notes-component"}
                    multiline
                    
                    sx={{ width: "100%", height: "100%", mb:2,                        
                    }}
                    onChange={handleNotes}
                    value={notes.content}
                    inputProps={{
                        resize: "both",
                    }}

                    disabled={props.disabled}

                    onBlur={sendNotes}

                >
                </TextField>
                : <Loader height={"100%"}></Loader>}

            
                {
                    // Subscribe to events
                    <LogitarEventProvider
                        subscriptions={["planningnote"]}
                        onEvent={(source, data, id) => {
                            // Push to events reference
                            eventsRef.current.push({ source: source, data: data, id: id });
                            setEvents([...eventsRef.current]);
                        }}
                        onConnect={() => { setCount(Date.now()); setEventDisconnected(false) }}
                        onDisconnect={() => setEventDisconnected(true)}
                    />
                }
        </Box>
    )

}