
/**
 * @callback EditableTimelineRowAction
 * @param {number} row
 */

/**
 * @typedef {{
 *  title: string, 
 *  subtitle?: string, 
 *  data: {
 *      text: string, 
 *      subtext?: string, 
 *      size: number, 
 *      start: number, 
 *      background?: string, 
 *      color?: string,
 *      cursor?: string
 *  }[],
 *  onRowClick?: EditableTimelineRowAction
 * }[]} EditableTimelineData
 */

/**
 * @callback EditableTimelineMouseEvent
 * @param {{pos: [number, number], type: 'move'|'resize-start'|'resize-end'|'pan', newStart: number, newSize: number}} event
 */

/**
 * @callback EditableTimelineHeaderFormatter
 * @param {number} value
 * @returns {string} Formatted value
 */

/**
 * @callback EditableTimelineDropEvent
 * @param {{event: Event, point: {start: number, column: number, row: number}, bar: {column: number, row: number}}} e
 */

/**
 * @callback EditableTimelineHoverEvent
 * @param {{event: Event, point: {start: number, column: number, row: number}, bar: {column: number, row: number}, type: 'enter'|'exit'}} e
 */

/**
 * @typedef {{color1: string, color2: string, interval: number, offset: number}} EditableTimelineBackground
 */

/**
 * @typedef {{data: EditableTimelineData, width?: number, height?: number, onDrag?: EditableTimelineMouseEvent, 
 * headerFormatter?: EditableTimelineHeaderFormatter, onDrop?: EditableTimelineDropEvent, onHover?: EditableTimelineHoverEvent, barMinWidth?: number, 
 * origin: number, scale: number, headerStep: number, step: number, cursor?: number, background?: EditableTimelineBackground}} EditableTimelineProps
 */

import { useRef, useState } from "react";
import { logitarTheme } from "../config/Themes";

export function EditableTimelinePopup(props) {


    return <div style={
        {
            position: 'absolute',
            left: props.x,
            top: props.y,
            pointerEvents: 'none'
        }
    }>
        <div style={
            {
                position: 'absolute',
                padding: 10,
                width: 300,
                background: logitarTheme.palette.background.default,
                color: logitarTheme.palette.text.primary,
                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.3)",
                bottom: 0
            }
        }>
            {props.children}
        </div>
    </div>
}

/**
 * @class
 * @param {EditableTimelineProps} props 
 * @returns 
 */
export default function EditableTimeline(props) {

    const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    /**
     * @type {[number, number]}
     */
    const [dragElement, setDragElement] = useState(null);

    const [dragOriginalTransform, setDragOriginalTransform] = useState({ start: 0, size: 0 });

    const [dragType, setDragType] = useState(null);

    const [hovering, setHovering] = useState(false);

    const [originY, setOriginY] = useState(0);
    const [startPanOriginY, setStartPanOriginY] = useState(0);


    const svgElement = useRef();

    const barPadding = 3;

    const rwidth = props.width || 800;
    const rheigth = props.height || 300;

    const headerCount = props.scale / props.headerStep;

    const rowHeight = 50;

    const scrollSpeed = 0.05;

    let headers = [];

    for (let i = 0; i < headerCount; i++) {
        headers.push(Math.ceil((props.origin - props.scale / 2 + ((i - 1) * (props.scale / headerCount))) / props.headerStep) * props.headerStep);
    }

    let rowY = rowHeight;
    let titleMaxX = 10;

    for (let dth of props.data) {
        if (dth.title.length > titleMaxX)
            titleMaxX = dth.title.length;

        if (dth.subtitle && dth.subtitle.length * 0.75 > titleMaxX)
            titleMaxX = dth.subtitle.length * 0.75;
    }

    titleMaxX *= 8;

    function fixStep(x, floor = false) {
        return (floor ? Math.floor(x / props.step) : Math.round(x / props.step)) * props.step;
    }

    function px2Val(x) {
        return (x - titleMaxX - rwidth / 2) / rwidth * props.scale + props.origin;
    }

    function px2ColRow(x, y) {
        const vx = px2Val(x);

        const row = Math.floor((y - rowHeight - originY) / rowHeight);
        if (row < 0 || row >= props.data.length) {

            return { row: -1, col: -1 };
        }

        let col = 0;
        for (col = 0; col < props.data[row].data.length; col++) {
            if (props.data[row].data[col].start > vx) {
                break;
            }
        }
        return { row: row, col: col };
    }

    function px2Bar(x, y) {
        const vx = px2Val(x);

        const row = Math.floor((y - rowHeight - originY) / rowHeight);
        if (row < 0 || row >= props.data.length) {

            return { row: -1, col: -1 };
        }

        let col = 0;
        let colf = false;
        for (col = 0; col < props.data[row].data.length; col++) {
            if (props.data[row].data[col].start <= vx && props.data[row].data[col].start + props.data[row].data[col].size >= vx) {
                colf = true;
                break;
            }
        }
        return colf ? { row: row, col: col } : { row: -1, col: -1 };
    }

    return <div>
        <svg
            style={{
                "& text": {
                    fill: logitarTheme.palette.text.primary
                }
            }}
            ref={svgElement}
            width={rwidth}
            height={rheigth}
            onMouseMove={(z) => {
                if (dragging) {
                    z.preventDefault(); z.stopPropagation();
                    if (props.onHover) {
                        props.onHover({
                            e: z,
                            point: null,
                            bar: null,
                            type: 'exit'
                        })
                    }

                    if (props.onDrag) {
                        const dragDelta = (z.clientX - startDragPosition.x) / rwidth * props.scale
                        const dragDeltaY = (z.clientY - startDragPosition.y);
                        switch (dragType) {
                            case 'move':
                                props.onDrag({
                                    pos: dragElement,
                                    type: dragType,
                                    newStart: fixStep((dragOriginalTransform.start + dragDelta)),
                                    newSize: fixStep(Math.max(props.barMinWidth || 30, dragOriginalTransform.size))
                                })
                                break;
                            case 'resize-end':
                                props.onDrag({
                                    pos: dragElement,
                                    type: dragType,
                                    newStart: dragOriginalTransform.start,
                                    newSize: fixStep(Math.max(props.barMinWidth || 30, dragOriginalTransform.size + dragDelta))
                                })
                                break;
                            case 'resize-start':
                                props.onDrag({
                                    pos: dragElement,
                                    type: dragType,
                                    newStart: fixStep(dragOriginalTransform.start + dragDelta),
                                    newSize: fixStep(Math.max(props.barMinWidth || 30, dragOriginalTransform.size - dragDelta))
                                })
                                break;
                            case 'pan':
                                props.onDrag({
                                    type: dragType,
                                    delta: {
                                        x: dragOriginalTransform.start - dragDelta,
                                        y: 0
                                    }
                                })
                                setOriginY(Math.max(-rowHeight * props.data.length + (rowHeight * 5), Math.min(0, startPanOriginY + dragDeltaY)));
                                break;
                        }
                    }
                }
            }}
            onMouseDown={(z) => {
                setDragging(true);
                setStartDragPosition({ x: z.clientX, y: z.clientY });
                setDragOriginalTransform({ start: props.origin, size: 0 })
                setStartPanOriginY(originY);
                setDragType('pan');
            }}
            onMouseUp={(z) => {
                setDragging(false);
                setDragElement(null);
            }}
            onWheelCapture={(z) => {
                if (z.shiftKey) {
                    if (props.onDrag) {
                        props.onDrag({
                            type: 'pan',
                            delta: {
                                x: props.origin + z.deltaY / 100 * scrollSpeed * props.scale,
                                y: 0
                            }
                        })
                    }
                }
                else {
                    setOriginY(Math.max(-rowHeight * props.data.length + (rowHeight * 5), Math.min(0, originY - z.deltaY / 2.5)));
                }

            }}
            onDrop={(e) => {
                if (props.onDrop) {
                    const rect = svgElement.current.getBoundingClientRect();
                    const x = e.clientX - rect.left; //x position within the element.
                    const y = e.clientY - rect.top;  //y position within the element.
                    const start = px2Val(x);
                    const rc = px2ColRow(x, y);
                    const bar = px2Bar(x, y);

                    props.onDrop({
                        event: e,
                        point: {
                            start: fixStep(start),
                            column: rc.col,
                            row: rc.row
                        },
                        bar: {
                            column: bar.col,
                            row: bar.row
                        }
                    });

                }
            }}
            onDragOver={(e) => {
                if (props.onDrop) {
                    //Default dragOver function to prevent it from making onDrop function work
                    e.stopPropagation();
                    e.preventDefault();
                }
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                /*
                if(props.onHover) {
                    props.onHover({
                        e: e,
                        point: null,
                        bar: null,
                        type: 'exit'
                    })
                }
                */

                if (props.onContextMenu) {
                    const rect = svgElement.current.getBoundingClientRect();
                    const x = e.clientX - rect.left; //x position within the element.
                    const y = e.clientY - rect.top;  //y position within the element.
                    const start = px2Val(x);
                    const rc = px2ColRow(x, y);
                    const bar = px2Bar(x, y);

                    props.onContextMenu({
                        event: e,
                        point: {
                            start: fixStep(start),
                            column: rc.col,
                            row: rc.row
                        },
                        bar: {
                            column: bar.col,
                            row: bar.row
                        }
                    });

                }
            }}
            onDoubleClick={(e) => {
                if (props.onDoubleClick) {
                    e.preventDefault();
                    e.stopPropagation();
                    const rect = svgElement.current.getBoundingClientRect();
                    const x = e.clientX - rect.left; //x position within the element.
                    const y = e.clientY - rect.top;  //y position within the element.
                    const start = px2Val(x);
                    const rc = px2ColRow(x, y);
                    const bar = px2Bar(x, y);

                    props.onDoubleClick({
                        event: e,
                        point: {
                            start: fixStep(start, true),
                            column: rc.col,
                            row: rc.row
                        },
                        bar: {
                            column: bar.col,
                            row: bar.row
                        }
                    });

                }
            }}
        >
            {
                // Headers
            }
            <mask id="ed-timeline-mask-content" maskUnits="userSpaceOnUse">
                <rect x={titleMaxX} y={0 + rowHeight} width={rwidth - titleMaxX} height={rheigth - rowHeight} fill={'white'} />
            </mask>
            <mask id="ed-timeline-mask-timeline" maskUnits="userSpaceOnUse">
                <rect x={titleMaxX} y={0} width={rwidth - titleMaxX} height={rheigth} fill={'white'} />
            </mask>
            <mask id="ed-timeline-mask-heads" maskUnits="userSpaceOnUse">
                <rect x={0} y={rowHeight} width={titleMaxX} height={rheigth - rowHeight} fill={'white'} />
            </mask>
            <g>
                {
                    headers.map((e, i) => {
                        let txt = e;
                        if (props.headerFormatter)
                            txt = props.headerFormatter(e);

                        const x1 = titleMaxX + (e - props.origin) / props.scale * rwidth + (rwidth / 2);
                        const x2 = i >= (headers.length - 1) ? x1 : (titleMaxX + (headers[i + 1] - props.origin) / props.scale * rwidth + (rwidth / 2));
                        const y1 = rowY;
                        const color = props.background ?
                            (Math.floor((e + props.background.offset) / props.background.interval) % 2 === 0 ? props.background.color1
                                : props.background.color2) : "rgba(0, 0, 0, 0)";

                        return <g key={"editable-timeline-header-" + i} mask={"url(#ed-timeline-mask-timeline)"}>
                            <rect
                                x={x1}
                                y={y1}
                                width={x2 - x1}
                                height={rheigth - y1}
                                fill={color}
                            />
                            <text x={x1} y={25} textAnchor={'middle'} fill="currentColor">{txt}</text>
                            <line x1={x1} y1={y1 - 10} x2={x1} y2={rheigth} stroke={'rgba(0,0,0,0.2'} strokeWidth={1} />

                        </g>
                    })
                }
                <line x1={0} y1={rowY} x2={rwidth} y2={rowY} stroke={'black'} strokeWidth={0.5} />
            </g>
            {
                // Rows
                props.data?.map((e, i) => {
                    
                    if(i * rowHeight + rowHeight > -originY + rheigth || i * rowHeight + rowHeight < -originY) {
                        // Don't need to render
                        rowY += rowHeight;
                        return null;
                    }
                    const row = <g key={"editable-timeline-row-" + i}>
                        {
                            // Bars
                            e.data.map((ex, ix) => {
                                const barWidth = ex.size / props.scale * rwidth - barPadding * 2;
                                // Offset bar a bit if another bar is underneath this
                                let barOffset = 0;
                                const textMaxLength = barWidth / 10;
                                let txt = "";
                                for (let i = 0; i < ex.text.length; i++) {
                                    if (i > textMaxLength) {
                                        txt += "...";
                                        break;
                                    }
                                    txt += ex.text[i];
                                }
                                // Check if there's a other bar underneath this bar, scale it to account for it
                                if(ix > 0) {
                                    for(let z = 0; z < ix; z++) {
                                        if(e.data[z].start + e.data[z].size > ex.start) {
                                            barOffset += 3;
                                        }
                                    }
                                }

                                const isDragging = dragging && !!dragElement && dragElement[0] === i && dragElement[1] === ix;
                                const xTransition = isDragging ? 'x 0.2s ease-in-out, width 0.2s ease-in-out' : undefined;
                                const transformTransition = isDragging ? 'transform 0.2s ease-in-out' : undefined;

                                return <g key={"editable-timeline-data-" + ix} mask={"url(#ed-timeline-mask-content)"}>
                                    <rect
                                        x={titleMaxX + (ex.start - props.origin) / props.scale * rwidth + (rwidth / 2) + barPadding + barOffset}
                                        y={rowY + barPadding + originY + barOffset}
                                        width={barWidth}
                                        height={rowHeight - barPadding}
                                        rx={3}
                                        className={ex.background}
                                        style={{ 
                                            strokeWidth: 1, 
                                            cursor: ex.cursor || 'grab', 
                                            fill: ex.background ? undefined : '#888',
                                            transition: xTransition
                                        }}
                                        onMouseDown={(z) => {
                                            z.preventDefault(); z.stopPropagation();
                                            setDragging(true); setStartDragPosition({ x: z.clientX, y: z.clientY });
                                            setDragElement([i, ix]); setDragOriginalTransform({ start: ex.start, size: ex.size });
                                            setDragType('move');
                                        }}
                                        onMouseOver={(e) => {
                                            setHovering(true);
                                            if (props.onHover) {
                                                const rect = svgElement.current.getBoundingClientRect();
                                                const x = e.clientX - rect.left; //x position within the element.
                                                const y = e.clientY - rect.top;  //y position within the element.
                                                const start = px2Val(x);
                                                props.onHover({
                                                    event: e,
                                                    point: {
                                                        start: fixStep(start),
                                                        column: ix,
                                                        row: i
                                                    },
                                                    bar: {
                                                        column: ix,
                                                        row: i
                                                    },
                                                    type: 'enter'
                                                })
                                            }
                                        }}
                                        onMouseOut={(e) => {
                                            setHovering(false);
                                            if (props.onHover) {
                                                const rect = svgElement.current.getBoundingClientRect();
                                                const x = e.clientX - rect.left; //x position within the element.
                                                const y = e.clientY - rect.top;  //y position within the element.
                                                const start = px2Val(x);
                                                const rc = px2ColRow(x, y);
                                                props.onHover({
                                                    event: e,
                                                    point: {
                                                        start: fixStep(start),
                                                        column: rc.col,
                                                        row: rc.row
                                                    },
                                                    bar: {
                                                        column: -1,
                                                        row: -1
                                                    },
                                                    type: 'exit'
                                                })
                                            }
                                        }}
                                    />
                                    <rect
                                        x={titleMaxX + (ex.start - props.origin) / props.scale * rwidth + (rwidth / 2) + barOffset}
                                        y={rowY + originY + barOffset}
                                        width={10}
                                        height={rowHeight}
                                        style={{ fill: 'rgba(255,255,255,0)', cursor: 'e-resize', transition: xTransition }}
                                        onMouseDown={(z) => {
                                            z.preventDefault(); z.stopPropagation();
                                            setDragging(true); setStartDragPosition({ x: z.clientX, y: z.clientY });
                                            setDragElement([i, ix]); setDragOriginalTransform({ start: ex.start, size: ex.size });
                                            setDragType('resize-start');
                                        }}
                                    />
                                    <rect
                                        x={titleMaxX + ((ex.start - props.origin) + ex.size) / props.scale * rwidth - 5 + (rwidth / 2) + barOffset}
                                        y={rowY + originY + barOffset}
                                        width={10}
                                        height={rowHeight}
                                        style={{ fill: 'rgba(255,255,255,0)', cursor: 'e-resize', transition: xTransition}}
                                        onMouseDown={(z) => {
                                            z.preventDefault(); z.stopPropagation();
                                            setDragging(true); setStartDragPosition({ x: z.clientX, y: z.clientY });
                                            setDragElement([i, ix]); setDragOriginalTransform({ start: ex.start, size: ex.size });
                                            setDragType('resize-end');
                                        }}
                                    />
                                    <text
                                        id={`editable-timeline-data-text-${i}-${ix}`}
                                        pointerEvents={'none'}
                                        x={0}
                                        y={rowY + 25 + originY + barOffset}
                                        textAnchor={'middle'}
                                        style={{ 
                                            fontSize: '1em', 
                                            transform: `translate(${titleMaxX + ((ex.start - props.origin) + ex.size / 2) / props.scale * rwidth + (rwidth / 2) + barOffset}px, 0)`,
                                            transition: transformTransition 
                                        }}
                                        fill="currentColor"
                                    >
                                        {txt}
                                    </text>
                                    <text
                                        pointerEvents={'none'}
                                        x={0}
                                        y={rowY + 45 + originY + barOffset}
                                        textAnchor={'middle'}
                                        style={{ 
                                            fontSize: '0.8em', 
                                            transform: `translate(${titleMaxX + ((ex.start - props.origin) + ex.size / 2) / props.scale * rwidth + (rwidth / 2) + barOffset}px, 0)`,
                                            transition: transformTransition 
                                        }}
                                        fill="currentColor"
                                    >
                                        {ex.subtext}
                                    </text>
                                </g>
                            })
                        }
                        <g mask={"url(#ed-timeline-mask-heads)"}>
                            <text x={0} y={rowY + 20 + originY} style={{ fontSize: '1em' }} fill="currentColor">{e.title}</text>
                            <text x={0} y={rowY + 30 + originY} style={{ fontSize: '0.7em' }} fill="currentColor">{e.subtitle}</text>
                            <line x1={0} y1={rowY + rowHeight + originY} x2={rwidth} y2={rowY + rowHeight + 2 + originY} stroke={'rgba(0, 0, 0, 0.1)'} strokeWidth={1} />

                        </g>
                        <line mask={"url(#ed-timeline-mask-content)"} x1={0} y1={rowY + rowHeight + originY} x2={rwidth} y2={rowY + rowHeight + 2 + originY} stroke={'rgba(0, 0, 0, 0.1)'} strokeWidth={1} />


                    </g>
                    rowY += rowHeight;
                    return row;
                })
            }
            <line
                x1={titleMaxX}
                y1={0}
                x2={titleMaxX}
                y2={rheigth}
                stroke={'rgba(0, 0, 0, 0.5)'}
                strokeWidth={1}
            />
            {
                // Cursor
                props.cursor &&
                <line
                    x1={titleMaxX + (props.cursor - props.origin) / props.scale * rwidth + (rwidth / 2)}
                    y1={0}
                    x2={titleMaxX + (props.cursor - props.origin) / props.scale * rwidth + (rwidth / 2)}
                    y2={rheigth}
                    stroke={'rgba(255, 0, 0, 0.5)'}
                    strokeWidth={1}
                    mask={"url(#ed-timeline-mask-content)"}
                />
            }
            {
                // Action buttons
                props.data.map((ex, ix) => {
                    if(!ex.onRowClick)
                        return null;

                    return <g mask="url(#ed-timeline-mask-content)" key={"row-action-" + ix}>
                        <rect 
                            strokeWidth={1}
                            y={ix * rowHeight + rowHeight + originY + 5}
                            x={rwidth * 0.94}
                            width={rowHeight}
                            height={rowHeight - 7.5}
                            rx={5}
                            fill={logitarTheme.palette.primary.main}
                            cursor={"pointer"}
                            onClick={() => ex.onRowClick(ix)}
                        />
                        <path
                            d={`
                                M${rwidth * 0.94 + 16} ${ix * rowHeight + rowHeight + originY + 5 + 12}
                                l${rowHeight - 32} ${rowHeight/2 - 16}
                                l${-rowHeight + 32} ${rowHeight/2 - 16}
                                Z
                            `}
                            fill={logitarTheme.palette.primary.contrastText}
                            pointerEvents={"none"}
                        />
                    </g>;
                })
            }
        </svg>
    </div>

}