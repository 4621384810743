import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { Checkbox, Popover, Typography } from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { useEffect, useState } from "react";

export interface MultiTreeSelectValue {
    id: number | string;
    label: string;
}

export interface MultiTreeSelectOption extends MultiTreeSelectValue {
    options?: MultiTreeSelectOption[];
}

export type MultiTreeSelectProps = {
    options: MultiTreeSelectOption[],
    value: MultiTreeSelectValue[],
    onChange: (value: MultiTreeSelectValue[]) => void,
    onClose: () => void,
    open?: boolean,
    anchorEl?: HTMLElement | null,
    id?: string
}

export type CheckState = "checked" | "unchecked" | "indeterminate";

type MultiTreeSelectLabelProps = {
    caption: string,
    checked: CheckState,
    onChange: (id: string, s: CheckState) => void,
    id: string
}

const MultiTreeSelectLabel = (props: MultiTreeSelectLabelProps) => {

    return <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
            id={"cb-" + props.id}
            color="primary"
            /** @ts-ignore Must be ignored because e.target.checked throws an error */
            onClick={(e) => { props.onChange(props.id, e.target.checked ? "checked" : "unchecked"); return e.stopPropagation() }}
            checked={props.checked === 'checked'}
            indeterminate={props.checked === 'indeterminate'}
        />
        <Typography variant="caption">{props.caption}</Typography>
    </div>
}

export default function MultiTreeSelect(props: MultiTreeSelectProps) {

    const [options, setOptions] = useState<MultiTreeSelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<MultiTreeSelectOption[]>([]);

    useEffect(() => {
        setOptions(props.options);
        if(props.value)
            setSelectedOptions(props.value);

    }, [props.options, props.value]);

    const realId = props.id || 'mts';

    const isAllChecked = (options: MultiTreeSelectOption[], selected: MultiTreeSelectOption[]) => {
        if (options.length === 0) return false;
        if (selected.length === 0) return false;

        for (let opt of options) {
            if (opt.options) {
                if (!isAllChecked(opt.options, selected)) return false;
            }
            else {
                if (!selected.find(s => s.id === opt.id)) return false;
            }
        }

        return true;
    }

    const findOption = (options: MultiTreeSelectOption[], id: string): MultiTreeSelectOption | undefined => {
        for (let opt of options) {
            if (opt.id.toString() === id) return opt;
            if (opt.options) {
                const found = findOption(opt.options, id);
                if (found) return found;
            }
        }
        return undefined;
    }

    const onSelectOption = (id: string, checked: CheckState) => {

        if (id === `${realId}-all`) {
            const sel = checked === "checked" ? options.flatMap(option => { 
                if(option.options) 
                    return [...option.options];
                
                return option;
            }) : [];
            setSelectedOptions(sel);
            console.log(sel);
            if (props.onChange) props.onChange(sel);
            return;
        }

        const sel = [...selectedOptions];
        if (checked === "checked") {
            const opt = findOption(options, id);
            if (opt) {
                if(opt.options) {
                    sel.push(...opt.options.filter(o => !sel.find(s => s.id.toString() === o.id.toString())));
                }
                else {
                    sel.push(opt);
                }
            }
        }
        else {
            const opt = findOption(options, id);
            if(opt) {
                if(opt.options) {
                    for(let o of opt.options) {
                        sel.splice(sel.findIndex(s => s.id.toString() === o.id.toString()), 1);
                    }
                }
                else {
                    sel.splice(sel.findIndex(s => s.id.toString() === id), 1);
                }
            }
        }
        setSelectedOptions(sel);
        console.log(sel);
        if (props.onChange) props.onChange(sel);
    }

    const renderOption = (option: MultiTreeSelectOption, selectedOptions: MultiTreeSelectValue[]) => {
        let checked: CheckState = "unchecked";
        if(option.options) {
            if (isAllChecked(option.options, selectedOptions)) {
                checked = "checked";
            }
            else if (option.options.some(o => selectedOptions.find(s => s.id === o.id))) {
                checked = "indeterminate";
            }
        }
        else {
            if (selectedOptions.find(s => s.id === option.id)) {
                checked = "checked";
            }
        }
        return (
            <TreeItem
                key={option.id}
                nodeId={option.id.toString()}
                label={<MultiTreeSelectLabel
                    caption={option.label}
                    id={option.id.toString()}
                    checked={checked}
                    onChange={(uuid, checked) => { onSelectOption(uuid, checked) }}
                />}
            >
                {
                    option.options?.map((opt) => {
                        return renderOption(opt, selectedOptions);
                    })
                }
            </TreeItem>
        )
    }

    let allChecked: CheckState = "unchecked";
    if (isAllChecked(options, selectedOptions)) {
        allChecked = "checked";
    }
    else if (selectedOptions.length > 0) {
        allChecked = "indeterminate";
    }

    return (
        <Popover
            open={!!props.open}
            anchorEl={props.anchorEl}
            onClose={() => { props.onClose() }}
            sx={{
                maxHeight: '50vh'
            }}
            id={props.id || undefined}
        >
            <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                defaultExpanded={[`${realId}-all`]}
                sx={{ backgroundColor: 'background.default', filter: 'brightness(110%)' }}
            >
                <TreeItem
                    key={"select-all"}
                    nodeId={`${realId}-all`}
                    label={<MultiTreeSelectLabel
                        caption={"Kaikki"}
                        id={`${realId}-all`}
                        checked={allChecked}
                        onChange={(uuid, checked) => { onSelectOption(uuid, checked) }}
                    />}
                >
                    {
                        options.map((option) => {
                            return renderOption(option, selectedOptions);
                        })
                    }
                </TreeItem>
            </TreeView>
        </Popover>
    )

}