import { Backdrop, Box, CircularProgress, Divider, Fab, Tooltip, Typography, useTheme } from '@mui/material'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { drawerWidth, exitIconStyle, exitIconStyleDark, headerBarHeight } from '../styles/styles'
import { Close, Delete, DragHandle, Save } from '@mui/icons-material';
import { addSidebarChangeListener, removeSidebarChangeListener, sidebarIsOpen } from '../App';
import { logitarTheme } from '../config/Themes';

/**
 * @typedef {{
 * 	zIndex?: number,
 *  draggable?: boolean,
 *  maxHeight?: number | string,
 *  title?: string,
 *  onClose?: () => void,
 *  onSave?: () => void,
 *  onDelete?: () => void,
 *  disableScroll?: boolean,
 *  saveIcon?: JSX.Element,
 *  saveLoading?: boolean,
 *  deleteIcon?: JSX.Element,
 * 	saveProps?: import('@mui/material').FabProps,
 * 	deleteProps?: import('@mui/material').FabProps,
 * 	disableEscape?: boolean,
 * 	staticButtons?: boolean,
 * 	deleteTooltip?: string | JSX.Element,
 * 	saveTooltip?: string | JSX.Element,
 * 	children: JSX.Element | JSX.Element[],
 * 	forceRightEdge?: boolean,
 * }} PopupScreenProps
 */


/**
 * 
 * @param {PopupScreenProps} props
 * @returns 
 */
export default function PopupScreen(props) {

	props = {
		// Defaults
		...{
			zIndex: 1051,
			draggable: false,
			maxHeight: '75vh',
		},
		...props
	}

	const popupRef = createRef();
	const [position, setPosition] = useState({ x: null, y: 40 });
	const [dragging, setDragging] = useState(false);
	const [lastMouse, setLastMouse] = useState({ x: 0, y: 0 });
	const [sidebarOpen, setSidebarOpen] = useState(sidebarIsOpen);

	const theme = useTheme();

	const popupScreenBoxStyle = {
		height: "fit-content",
		overflow: "hidden",
		width: "fit-content",
		backgroundColor: logitarTheme.palette.background.default,
		pb: 2,
		px: 2,
		m: 2,
		borderRadius: 1,
	}

	function onMouseMove(event) {
		if (dragging) {
			if (popupRef.current) {
				const pos = {
					x: lastMouse.x - event.clientX,
					y: lastMouse.y - event.clientY
				};
				// Minimum top and left for popup (relative to container). Adjust for padding/margin
				const min_x = 0;
				const min_y = headerBarHeight;
				// Difference between popup and container size, adjust for padding/margin
				const max_x = popupRef.current.parentElement.offsetWidth - popupRef.current.offsetWidth + 5;
				const max_y = popupRef.current.parentElement.offsetHeight - popupRef.current.offsetHeight + 5;

				pos.x = Math.min(max_x, Math.max(min_x, (popupRef.current.offsetLeft - pos.x)));
				pos.y = Math.min(max_y, Math.max(min_y, (popupRef.current.offsetTop - pos.y)));

				setPosition(pos);
			}
			setLastMouse({
				x: event.clientX,
				y: event.clientY
			});
		}
	}

	const onMouseUp = (e) => {
		setDragging(false);
	}

	const onKeyDown = (e) => {
		if(!props.disableEscape && e.key === "Escape" && props.onClose)
				props.onClose();
	}

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown);
		window.addEventListener("mouseup", onMouseUp);
		const sclid = addSidebarChangeListener((open) => { setSidebarOpen(open) });
		return () => {
			window.removeEventListener("keydown", onKeyDown);
			window.removeEventListener("mouseup", onMouseUp);
			removeSidebarChangeListener(sclid);
		}
	}, []);

	let saveButtonStyle = {
		position: 'absolute',
		right: 15,
		bottom: 20
	}

	let deleteButtonStyle = {
		position: 'absolute',
		right: 150,
		bottom: 20
	}

	if(props.staticButtons) {
		saveButtonStyle = {
			marginTop: 1,
			marginLeft: 1
		}
		deleteButtonStyle = {
			marginTop: 1
		}
	}

	return (
		<Backdrop
			sx={{
				zIndex: props.zIndex,
				ml: sidebarOpen ? `${drawerWidth}px` : 0,
			}}
			onMouseMove={(event) => {
				onMouseMove(event);
			}}
			open
		>
			<div
				style={{
					position: "absolute",
					left: position.x === null ? undefined : position.x,
					top: position.y === null ? undefined : position.y,
					right: props.forceRightEdge ? position.x === null ? "-5px" : undefined : undefined,
				}}
				id="popupele"
				position="absolute"
				ref={popupRef}
			>
				<Box
					sx={popupScreenBoxStyle}
					position="relative"
					overflow="auto"
				>
					<Box>
						{
							props.draggable &&
							<div
								id="popupeleheader"
								style={{ textAlign: "center", height: 30, paddingBottom: 0, display: "flex", justifyContent: "center" }}
								onMouseDown={(_ev) => {
									if (popupRef.current) {
										setLastMouse({
											x: _ev.clientX,
											y: _ev.clientY
										});
										setDragging(true);
									}
								}}
							>
								<DragHandle sx={{ mt: 0.5, width: "30%", bgcolor: 'background.default', borderRadius: 1, position: "absolute", cursor: "move" }}></DragHandle>
							</div>
						}
						<Tooltip title={"Sulje"}>
							<Close 
								sx={theme.palette.mode === 'light' ? exitIconStyle : {...exitIconStyle, ...exitIconStyleDark}}
								variant="contained" 
								onClick={() => {
									if(props.onClose)
										props.onClose();
								}}
							>
								Poistu
							</Close>
						</Tooltip>
					</Box>
					{
						props.title &&
						<Typography sx={{ m: 0 }} variant="h5">{props.title}</Typography>
					}
					<Box
						sx={{
							overflow: props.disableScroll ? 'hidden' : 'auto',
							maxHeight: props.maxHeight
						}}
					>
						{props.children}
					</Box>
					<Box sx={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
						<div></div>
						<div>
							{
								props.onDelete &&
								<Tooltip title={props.deleteTooltip}>
									<span>
										<Fab
											onClick={() => {
												if(props.onDelete)
													props.onDelete();
											}}
											variant='extended'
											sx={deleteButtonStyle}
											color='error'
											{...props.deleteProps}
										>
											{
												props.deleteIcon
											}
											{
												!props.deleteIcon &&
												<>
													<Delete />
												</>
											}
										</Fab>
									</span>
								</Tooltip>
							}
							{
								props.onSave &&
								<Tooltip title={props.saveTooltip}>
									<span>
										<Fab
											onClick={() => {
												if(props.onSave)
													props.onSave();
											}}
											variant='extended'
											sx={saveButtonStyle}
											color='primary'
											disabled={props.saveLoading}
											{...props.saveProps}
										>
											{
												props.saveIcon
											}
											{
												!props.saveIcon &&
												<>
													<Save />
													Tallenna
												</>
											}
											{
												props.saveLoading &&
												<CircularProgress size={24} style={{color: 'white'}} />
											}
										</Fab>
									</span>
								</Tooltip>
							}
						</div>
					</Box>
				</Box>
			</div>

		</Backdrop>
	)
}